import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import {
  ENDPOINTS,
  COMPONENT_TYPE,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_CREATED_BY,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_ATTACHMENTS
} from '../../../../constants';
import { Http, handleError } from '../../../../core';
import {
  removeDuplicate,
  getPrePathLinkToTicket,
  buildQueryForFilterMongo,
  checkIsNotEmptyObject
} from '../../../../common/utils';

/**
 * Get new query select
 */
const getNewQuerySelect = select => {
  const oldSelect = typeof select == 'string' ? select : '';
  const selectArr = oldSelect.split(' ');
  let newSelect = oldSelect;

  if (!selectArr.includes(SYSTEM_FIELD_KEY)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_KEY}`;
  }

  if (!selectArr.includes(SYSTEM_FIELD_CREATED_BY)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_CREATED_BY}`;
  }

  if (!selectArr.includes(SYSTEM_FIELD_ASSIGN_TO)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_ASSIGN_TO}`;
  }

  if (!selectArr.includes(SYSTEM_FIELD_ATTACHMENTS)) {
    newSelect = `${newSelect} ${SYSTEM_FIELD_ATTACHMENTS}`;
  }

  return newSelect;
};

export const model = {
  /**
   * State
   */
  fieldConfig: null,
  data: [],
  fieldList: [],
  allSubFieldList: [],
  total: 0,
  query: {},
  detailWorkTicket: {},
  selectedRows: [],
  selectedRowKeys: [],
  collapseKeys: [], // For collapse/expand boxes on detail form
  activeActivityTab: '',
  loadingList: false,
  loadingWorkTicketItem: false,
  resetView: false,
  fullScreen: false,

  /**
   * Action: Set field config
   */
  setFieldConfig: action((state, payload) => {
    if (state?.fieldConfig === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.fieldConfig = null;
    }

    state.fieldConfig = payload;
  }),

  /**
   * Action: Set work ticket field list contain field relation
   */
  setAllSubFieldList: action((state, payload) => {
    if (state.allSubFieldList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.allSubFieldList = payload;
  }),

  /**
   * Action: Set test case field list
   */
  setFieldList: action((state, payload) => {
    if (state.fieldList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.fieldList = payload;
  }),

  /**
   * Action: Set filter list
   */
  setWorkTicketList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload?.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    let newData = [];

    if (payload?.forceSetList) {
      newData = [...payload?.rows];
    } else {
      newData = [...payload?.rows].map((item, index) => {
        return {
          ...item,
          xNumber: index + 1
        };
      });
    }

    state.data = newData;
    state.total = payload?.count;
  }),

  /**
   * Action: Set total
   */
  setTotal: action((state, payload) => {
    if (state?.total === undefined) {
      return;
    }

    state.total = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set detail work ticket
   */
  setDetailWorkTicket: action((state, payload) => {
    if (state?.detailWorkTicket === undefined) {
      return;
    }

    state.detailWorkTicket = payload;
  }),

  /**
   * Action: Set selected row
   */
  setSelectedRows: action((state, payload) => {
    if (state?.selectedRows === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRows = [];
      return;
    }

    state.selectedRows = payload;
  }),

  /**
   * Action: Set selected row keys
   */
  setSelectedRowKeys: action((state, payload) => {
    if (state?.selectedRowKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.selectedRowKeys = [];
      return;
    }

    state.selectedRowKeys = payload;
  }),

  /**
   * Action: Set active activity tab
   */
  setActiveActivityTab: action((state, payload) => {
    if (state?.activeActivityTab === undefined) {
      return;
    }

    state.activeActivityTab = payload;
  }),

  /**
   * Action: Set collapse keys
   */
  setCollapseKeys: action((state, payload) => {
    if (state?.collapseKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.collapseKeys = [];
      return;
    }

    state.collapseKeys = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingWorkTicketItem === undefined) {
      return;
    }

    state.loadingWorkTicketItem = payload;
  }),

  /**
   * Action: Set reset view
   */
  setResetView: action((state, payload) => {
    if (state?.resetView === undefined) {
      return;
    }

    state.resetView = payload;
  }),

  /**
   * Action: Set full screen
   */
  setFullScreen: action((state, payload) => {
    if (state?.fullScreen === undefined) {
      return;
    }

    state.fullScreen = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: On change collapse
   */
  onChangeCollapse: thunk(async (action, payload, helpers) => {
    if (!payload) {
      throw new Error('There are no key');
    }

    let collapseKeys = helpers.getState()['collapseKeys'];
    let newKeys = [];

    collapseKeys = Array.isArray(collapseKeys) && collapseKeys.length ? [...collapseKeys] : [];

    if (collapseKeys.includes(payload)) {
      newKeys = [...collapseKeys].filter(item => item !== payload);
    } else {
      newKeys = [...collapseKeys, payload];
    }

    action.setCollapseKeys(newKeys);
  }),

  /**
   * Action: Call api to get work item list
   */
  getWorkTicketList: thunk(async (action, payload, helpers) => {
    const newPayload = { ...payload };

    try {
      action.setLoadingList(true);
      action.setQuery(payload);

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      newPayload.select = getNewQuerySelect(newPayload.select);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${
        project?.projectKey
      }/ticket?${buildQueryForFilterMongo(newPayload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setWorkTicketList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get work item by Id
   */
  getWorkTicketById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get work ticket by id: Invalid Params');
      }

      action.setLoadingItem(true);

      const { id } = payload;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket/${id}`;
      const res = await Http.get(url).then(res => res.data);

      const newData = { ...res?.data };

      action.setDetailWorkTicket(newData);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to change status work item
   */
  changeStatusWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.id) {
        throw new Error('Change status work ticket: There are no ID');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { id } = newPayload;
      delete newPayload.id;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket/${id}/status`;
      const res = Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      console.error(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create work ticket
   */
  createWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      action.setLoadingItem(true);

      let newPayload;
      let hideSuccessMessage = false;
      if (Array.isArray(payload) && payload.length) {
        newPayload = payload;
      } else {
        newPayload = { ...payload };
        hideSuccessMessage = newPayload?.hideSuccessMessage;
        delete newPayload.hideSuccessMessage;
      }

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket`;

      const res = await Http.post(url, payload).then(res => res.data);

      if (!hideSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.newItem'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update work ticket
   */
  updateWorkTicketByKey: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update work ticket by key: Invalid Params');
      }

      action.setLoadingItem(true);

      const { id } = payload;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket/${id}`;
      const res = await Http.put(url, payload.data).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete work ticket
   */
  deleteWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Delete work ticket: Invalid Params');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { keys, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const body = {
        data: { keys } // Example: keys is ["6", "8", "99"]
      };

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket`;
      const res = await Http.delete(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:process.messageDeletingWorkTicket'),
          description: i18next.t('akaat:message.deletedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to clone multi work ticket
   */
  cloneWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Clone work ticket: Invalid Params');
      }

      if (!Array.isArray(payload?.keys)) {
        throw new Error('Clone work ticket: Invalid Array');
      }

      action.setLoadingList(true);

      const { keys, ticketTypeId, noShowSuccessMessage } = payload;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.WORK_TICKET_CLONE}`;
      const res = await Http.post(url, { keys, ticketTypeId }).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:process.messageCloningWorkTicket'),
          description: i18next.t('akaat:message.clonedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to create multi work ticket
   */
  createMultiWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      if (!Array.isArray(payload?.body)) {
        throw new Error('Create multi work ticket: Invalid payload');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create multi work ticket: Invalid Params');
      }

      action.setLoadingList(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket/multiple`;
      const res = await Http.post(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:process.messageCreatingWorkTicket'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to update multi work ticket
   */
  updateMultiWorkTicket: thunk(async (action, payload, helpers) => {
    try {
      if (!Array.isArray(payload?.body)) {
        throw new Error('Update multi work ticket: Invalid payload');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update multi work ticket: Invalid Params');
      }

      action.setLoadingList(true);

      const newPayload = { ...payload };
      const { body, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.WORK_TICKET}/${tenant?.tenantKey}/${project?.projectKey}/ticket`;
      const res = await Http.put(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.success'),
          description: i18next.t('akaat:message.yourChangesHasBeenSaved')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    const { visibleDetailRelation, fieldListData, workTicketId } = { ...payload };

    let processList = helpers.getStoreState()?.global?.globalProcessList;
    processList = Array.isArray(processList) && processList.length ? [...processList] : [];

    let workTicketFieldList = fieldListData?.[workTicketId];
    workTicketFieldList =
      Array.isArray(workTicketFieldList) && workTicketFieldList.length ? [...workTicketFieldList] : [];

    const listWorkTicket = fieldListData;
    const newFieldList = [];

    for (let index = 0; index < workTicketFieldList.length; index++) {
      const field = workTicketFieldList[index];

      if (field?.componentType === COMPONENT_TYPE.RELATION && visibleDetailRelation) {
        if (fieldListData?.[field?.lookup?.workTicketId]) {
          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;
          const list = convertSubRelationField(field, fieldListData?.[field?.lookup?.workTicketId], workTicketName);

          newFieldList.push(...list);
        } else if (listWorkTicket && !Object.keys(listWorkTicket).includes(field?.lookup?.workTicketId)) {
          const res = await helpers.getStoreActions()?.global?.getTicketTypeById(field?.lookup?.workTicketId);

          listWorkTicket[field?.lookup?.workTicketId] = res?.fields;
          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;

          const list = convertSubRelationField(field, res?.fields, workTicketName);
          newFieldList.push(...list);
        } else {
        }
      }

      newFieldList.push({
        ...field,
        workTicketId
      });
    }

    let list = newFieldList.filter(field => field && !field.invisible);

    if (visibleDetailRelation) {
      list = list.filter(field => field.componentType !== COMPONENT_TYPE.RELATION);
    }

    list = removeDuplicate(list, 'refName');

    return list;
  })
};
/**
 * Handle return sub field relation
 */
const convertSubRelationField = (rootField, list = [], workTicketName) => {
  const relationField = [];

  list.forEach(fieldRelation => {
    relationField.push({
      ...fieldRelation,
      refName: `${rootField?.refName}.${fieldRelation.refName}`,
      name: i18next.exists(`akaat:workItem.${fieldRelation.refName}`)
        ? `${workTicketName || rootField?.name} - ${i18next.t(`akaat:workItem.${fieldRelation.refName}`)}`
        : `${workTicketName || rootField?.name} - ${fieldRelation.name}`,
      workTicketId: rootField?.lookup?.workTicketId,
      refNameParent: rootField?.refName,
      isRelationDisplayField: rootField?.data.displayField === fieldRelation.refName,
      prePath: getPrePathLinkToTicket({ workTicketId: rootField?.lookup?.workTicketId })
    });
  });

  return relationField;
};
