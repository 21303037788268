import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  teamUserList: null, // All of users in current team
  loadingTeamUserList: false,
  loadingTeamUserItem: false,

  /**
   * Action: Set team user list
   */
  setTeamUserList: action((state, payload) => {
    if (!(state?.teamUserList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.teamUserList = [];
    }

    state.teamUserList = payload;
  }),

  /**
   * Action: Set loading team user list
   */
  setLoadingTeamUserList: action((state, payload) => {
    if (!(state?.loadingTeamUserList !== undefined)) {
      return;
    }

    state.loadingTeamUserList = payload;
  }),

  /**
   * Action: Set loading team user item
   */
  setLoadingTeamUserItem: action((state, payload) => {
    if (!(state?.loadingTeamUserItem !== undefined)) {
      return;
    }

    state.loadingTeamUserItem = payload;
  }),

  /**
   * Action: Call api to get team user list
   */
  getTeamUserList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('No Team Key');
      }

      action.setLoadingTeamUserList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload}${ENDPOINTS.USERS}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTeamUserList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTeamUserList(false);
    }
  }),

  /**
   * Action: Call api to add user to team
   */
  addUserToTeam: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Add user to team: No User ID');
      }

      action.setLoadingTeamUserItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload.teamKey}${ENDPOINTS.USERS}/${payload.userId}`;
      const res = await Http.put(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTeamUserItem(false);
    }
  }),

  /**
   * Action: Call api to remove user from team
   */
  removeUserFromTeam: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Remove user from team: No User ID');
      }

      action.setLoadingTeamUserItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload.teamKey}${ENDPOINTS.USERS}/${payload.userId}`;
      await Http.delete(url).then(res => res.data);

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTeamUserItem(false);
    }
  })
};
