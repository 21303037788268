import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';
import axios from 'axios';

import env from '../../../../env';
import { ENDPOINTS, PRODUCT_KEY_ON_URL_ENGINE, PRODUCT_KEY_ON_URL_MANAGER } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { parseRsql, buildQueryForParamsUrl } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: null,
  total: null,
  editingAgentPool: {},
  query: {},
  loadingList: false,
  loadingItem: false,
  loadingDownload: false,
  dataDownload: null,

  /**
   * Action: Set agent pool list
   */
  setAgentPoolList: action((state, payload) => {
    const threePath = window.location.pathname.split('/')[+env.REACT_APP_PROJECT_PATH_INDEX + 1];
    const envAgent = threePath === PRODUCT_KEY_ON_URL_ENGINE ? PRODUCT_KEY_ON_URL_ENGINE : PRODUCT_KEY_ON_URL_MANAGER;

    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    // Reset
    if (payload === null) {
      state.data = null;
      state.total = null;
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows.filter(item => item?.environment === envAgent);
    state.total = payload.rows.filter(item => item?.environment === envAgent)?.length;
  }),

  /**
   * Action: Set editing agent pool
   */
  setEditingAgentPool: action((state, payload) => {
    if (state?.editingAgentPool === undefined) {
      return;
    }

    state.editingAgentPool = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  setLoadingDownload: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  setDataDownload: action((state, payload) => {
    if (!payload) {
      state.dataDownload = null;
      return;
    }

    if (payload.type === 'test_flow') {
      state.dataDownload = payload.data.find(item => item.attributes.type === payload.system);
    } else {
      state.dataDownload = payload.data.find(item => item.attributes.platform === payload.system);
    }
  }),

  /**
   * Action: Call api to get agent pool list by aql
   */
  getAgentPoolList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent pool list: Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload.aql)) {
        throw new Error('Invalid aql');
      } // aql is invalid

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_AGENT_POOL
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAgentPoolList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get agent pool by Id
   */
  getAgentPoolById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent pool by id: Invalid Params');
      }

      if (!payload) {
        throw new Error('Get agent pool by id: There are no ID');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT_POOL}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingAgentPool(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create agent pool
   */
  createAgentPool: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent pool by id: Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get agent pool by id: Invalid Payload');
      }

      action.setLoadingItem(true);

      // ==========> Create agent pool
      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT_POOL}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:agentPool.messageCreatingAgentPool'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  downloadAgentPool: thunk(async (action, payload) => {
    try {
      action.setLoadingDownload(true);

      // ==========> Download agent pool
      const url = `${env.REACT_APP_SUPPORT_URL}${ENDPOINTS.DOWNLOAD_AGENT}`;
      const res = await axios.get(url).then(res => res.data);

      action.setDataDownload({
        data: res?.data,
        system: payload
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  downloadTestFlowAgent: thunk(async (action, payload) => {
    try {
      action.setLoadingDownload(true);

      // ==========> Download agent pool
      const url = `${env.REACT_APP_SUPPORT_URL}${ENDPOINTS.DOWNLOAD_TEST_FLOW_AGENT}`;
      const res = await axios.get(url).then(res => res.data);
      action.setDataDownload({ data: res?.data, system: payload, type: 'test_flow' });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update agent pool
   */
  updateAgentPool: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent pool by id: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Get agent pool by id: There are no ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      delete newPayload.id;

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT_POOL}/${payload.id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:agentPool.messageUpdatingAgentPool'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete agent pool
   */
  deleteAgentPool: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Get agent pool by id: No agent pool ID');
      }

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.AUTOMATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.AUTOMATION_AGENT_POOL}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:agentPool.messageDeletingAgentPool'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
