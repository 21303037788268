import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserCircle } from '../../../assets/svg-icons';

const LANG_COMMON_UNASSIGNED = 'common.unassigned';

const useUnassignedUser = () => {
  // For language
  const [t] = useTranslation('akaat');

  // Unassigned user
  const UNASSIGNED = {
    label: (
      <span title={t(LANG_COMMON_UNASSIGNED)} className="text-unassigned">
        <UserCircle /> {t(LANG_COMMON_UNASSIGNED)}
      </span>
    ),
    value: t(LANG_COMMON_UNASSIGNED)
  };

  return [UNASSIGNED];
};

export default useUnassignedUser;
