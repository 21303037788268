import { action, thunk } from 'easy-peasy';
import qs from 'qs';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';
import { buildQueryForFilterMongo } from '../../common/utils';

export default {
  /**
   * State
   */
  relationList: [],
  editingRelation: {},
  loadingRelationList: false,
  loadingRelationItem: false,

  /**
   * Action: Set global relation list
   */
  setRelationList: action((state, payload) => {
    if (!(state?.relationList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.relationList = null;
      return;
    }

    state.relationList = payload;
  }),

  /**
   * Action: Set loading relation list
   */
  setLoadingRelationList: action((state, payload) => {
    if (!(state?.loadingRelationTypeList !== undefined)) {
      return;
    }

    state.loadingRelationTypeList = payload;
  }),

  /**
   * Action: Set loading relation item
   */
  setLoadingRelationItem: action((state, payload) => {
    if (!(state?.loadingRelationItem !== undefined)) {
      return;
    }

    state.loadingRelationItem = payload;
  }),

  /**
   * Action: Set editing relation
   */
  setEditingRelation: action((state, payload) => {
    if (state?.editingRelation === undefined) {
      return;
    }

    state.editingRelation = payload;
  }),

  /**
   * Action: Call api to create relation
   */
  createRelation: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.relType) {
        throw new Error('Create relation: No relType');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create relation: Invalid Params');
      }

      action.setLoadingRelationItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.RELATION}`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRelationItem(false);
    }
  }),

  /**
   * Action: Call api to create relation for repository tree item
   */
  createRelationForRepositoryFolder: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.relType) {
        throw new Error('Create relation for repository folder: No relType');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create relation for repository folder: Invalid Params');
      }

      action.setLoadingRelationItem(true);

      const newPayload = { ...payload };
      const { folderId } = newPayload;
      delete newPayload.folderId;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}/folders/${folderId}/auto-ralated`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRelationItem(false);
    }
  }),

  /**
   * Action: Call api to create relation for test suite folder
   */
  createRelationForTestSuiteFolder: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.relType) {
        throw new Error('Create relation for test suite folder: No relType');
      }

      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create relation for test suite folder: Invalid Params');
      }

      action.setLoadingRelationItem(true);

      const newPayload = { ...payload };
      const { folderId } = newPayload;
      delete newPayload.folderId;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${folderId}/auto-ralated`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRelationItem(false);
    }
  }),

  /**
   * Action: Call api to delete relation
   */
  deleteRelation: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Delete relation: Invalid Params');
      }

      action.setLoadingRelationItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.RELATION}/${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingRelationItem(false);
    }
  }),

  /**
   * Action: Call api to get relation list
   */
  getRelationList: thunk(async (action, payload, helpers) => {
    const newPayload = { ...payload };
    const noSetDataToStore = newPayload?.noSetDataToStore;
    delete newPayload.noSetRelationList;

    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get relation list: Invalid Params');
      }

      if (!noSetDataToStore) {
        action.setLoadingRelationList(true);
      }

      const { query, key } = newPayload;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.RELATION
      }/${key}?${buildQueryForFilterMongo(query)}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setRelationList(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!noSetDataToStore) {
        action.setLoadingRelationList(false);
      }
    }
  }),

  /**
   * Action: Call api to get relation list by source keys
   */
  getRelationListBySourceKeys: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get relation list: Invalid Params');
      }

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.RELATION
      }/by-source-keys?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
    }
  })
};
