import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  data: null,
  editingRelease: {},
  loadingItem: false,
  loadingList: false,

  /**
   * Action: Set release list
   */
  setReleaseList: action((state, payload) => {
    if (state?.data === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.data = payload;
  }),

  /**
   * Action: Set editing release
   */
  setEditingRelease: action((state, payload) => {
    if (state?.editingRelease === undefined) {
      return;
    }

    state.editingRelease = payload;
  }),

  /**
   * Action: Set loading item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Call api to get release list
   */
  getReleaseList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}`;
      const res = await Http.get(url).then(res => res.data);

      action.setReleaseList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get release by Id
   */
  getReleaseById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Get release by id: There are no release ID');
      }

      action.setLoadingItem(true);

      const { _id, noSetDataToStore } = payload;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/${_id}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setEditingRelease(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create release
   */
  createRelease: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create release: Invalid Payload');
      }

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:common.successful'),
        description: i18next.t('akaat:testPlan.messageCreatingRelease')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update release
   */
  updateRelease: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Update release: There are no release ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id, noShowSuccessMessage } = newPayload;
      delete newPayload._id;
      delete newPayload.noShowSuccessMessage;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageUpdatingRelease'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete release
   */
  deleteRelease: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Delete release: There are no release ID');
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageDeletingRelease'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to change status release
   */
  changeStatusRelease: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Change status release: There are no release ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to clone format release
   */
  cloneFormatRelease: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalTenant?.tenantKey || !globalProject?.projectKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) {
        throw new Error('Clone release: No Id');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_RELEASE}/${payload}/clone-tree`;
      const res = await Http.get(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageCloning', { name: i18next.t('akaat:testPlan.release') }),
        description: i18next.t('akaat:message.clonedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
