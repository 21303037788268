import { thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * Action: Call api to get data compare
   */

  getTestCaseList: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}${ENDPOINTS.LIST_TEST_RUN_BY_KEYS}`;
      const res = await Http.get(url, {
        params: {
          listKeys: payload
        }
      }).then(res => res.data);
      return res.data;
    } catch (err) {
      handleError(err);
    }
  })
};
