import React from 'react';
import objectPath from 'object-path';

import { SYSTEM_FIELD_NAME } from '../../../../constants';
import RelationLink from '../../relation-link';

const ReleaseCycleTestSuiteField = ({ workTicketId, row, field, disableEditingCells }) => {
  /**
   * Render cell text utils
   */
  const renderCellTextUtilRelationField1 = ({ title, row, field }) => {
    return (
      <RelationLink title={title} row={row} field={field}>
        {title}
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ title, row, field }) => {
    return (
      <div title={title} className="cell-text">
        <RelationLink row={row} field={field} className="cell-text-value min-h-22">
          {title}
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ title }) => {
    return <span title={title}>{title}</span>;
  };

  const renderCellTextUtilReadonly2 = ({ title }) => {
    return (
      <div title={title} className="cell-text cursor-default hide-after">
        <span className="cell-text-value min-h-22">{title}</span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const title = objectPath.get(row, `${field?.refName}.${SYSTEM_FIELD_NAME}`) || '';

    // Relation field
    if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ title, row, field });
    }

    // Relation field
    if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ title, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ title });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ title });
    }
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return <div className={`field-wrapper release-cycle-test-suite-field`}>{renderCellText()}</div>;
};

export default ReleaseCycleTestSuiteField;
