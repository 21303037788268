import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { buildQueryForFilterMongo } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  query: {},
  editingTestCaseVersion: {},
  comparedTestCaseList: [],
  loadingList: false,
  loadingItem: false,
  expandedRowKeys: [],

  /**
   * Action: Set test case version list
   */
  setTestCaseVesitonList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set editing test case version
   */
  setEditingTestCaseVersion: action((state, payload) => {
    if (state?.editingTestCaseVersion === undefined) {
      return;
    }

    state.editingTestCaseVersion = payload;
  }),

  /**
   * Action: Set current test case list
   */
  setComparedTestCaseList: action((state, payload) => {
    if (state?.comparedTestCaseList === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.comparedTestCaseList = [];
      return;
    }

    state.comparedTestCaseList = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set expanded row keys
   */
  setExpandedRowKeys: action((state, payload) => {
    if (state?.expandedRowKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.expandedRowKeys = [];
      return;
    }

    state.expandedRowKeys = payload;
  }),

  /**
   * Action: Call api to get test case version list
   */
  getTestCaseVersionList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test case version list: Invalid Params');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const key = payload.key;
      delete payload.key;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.TESTCASE
      }/${key}${ENDPOINTS.TESTCASE_VERSION}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseVesitonList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get test case version by Id
   */
  getTestCaseVersionById: thunk(async (action, payload, helpers) => {
    const { key } = payload;
    const globalTenant = helpers.getStoreState()?.global?.globalTenant;
    const globalProject = helpers.getStoreState()?.global?.globalProject;

    try {
      if (!globalProject?.projectKey || !globalTenant?.tenantKey) {
        throw new Error('Get test case version list: Invalid Params');
      }

      action.setLoadingItem(true);

      // const url = `${ENDPOINTS.TESTCASE_VERSION}/tenant-${tenantId}/project-${projectId}/${id}`;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TESTCASE_VERSION}/${key}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTestCaseVersion(res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get compared test case list
   */
  getComparedTestCaseList: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.tenantId || !payload?.projectId) {
        throw new Error('Get test case version list: Invalid Params');
      }

      if (!(Array.isArray(payload?.ids) && payload?.ids.length)) {
        throw new Error('Get compared test case list: Invalid array');
      }

      action.setLoadingList(true);

      const { ids } = payload;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const promises = await Promise.all(
        [...ids].map(async id => {
          const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}/testcase/${id}/testcase-version`;
          const res = await Http.get(url).then(res => res.data);

          return res?.data;
        })
      );

      action.setComparedTestCaseList(promises);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to create test case version
   */
  createTestCaseVersion: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create test case version: Invalid Params');
      }

      if (!payload?.testCaseId) {
        throw new Error('Create test case version: There are no test case Id');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_VERSION}`;
      const res = await Http.post(url, { ...newPayload });

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testRepo.messageCreatingTestCaseVersion'),
          description: i18next.t('akaat:message.createdSuccessfully')
        });
      }

      return res;
    } catch (err) {
      return err;
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to roll back version test case
   */
  rollbackTestCaseVersion: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Rollback test case version: Invalid Params');
      }

      if (!payload?.testCaseKey) {
        throw new Error('Rollback test case version: There are no test case key');
      }
      action.setLoadingItem(true);

      const testCaseKey = payload.testCaseKey;
      delete payload.testCaseKey;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE}/${testCaseKey}${ENDPOINTS.TESTCASE_ROLLBACK_VERSION}`;
      const res = await Http.put(url, payload).then(res => res.data);
      notification.success({
        message: i18next.t('akaat:testRepo.messageRollbackTestCaseVersion'),
        description: i18next.t('akaat:message.rollbackSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
