import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import env from '../../../../env';
import { COMPONENT_TYPE, ENDPOINTS, WORK_ITEM_TEST_RESULT_ID } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { filterTestResultFields, removeDuplicate } from '../../../../common/utils';

const INVALID_PAYLOAD = 'Invalid Payload';

export const model = {
  processList: [],
  processType: [],
  processId: null,
  processItem: null,
  templateInfo: null,
  currentFieldsList: [],
  currentWorkFlow: null,
  currentProcess: {},
  fieldDetail: null,
  loadingFieldDetail: false,
  loadingFields: false,
  loadingProcessList: false,
  loadingProcessItem: false,

  setCurrentFieldsList: action((state, payload) => {
    state.currentFieldsList = payload.filter(field => !field.invisible);
  }),

  setProcessType: action((state, payload) => {
    state.processType = payload;
  }),

  setCurrentProcess: action((state, payload) => {
    state.currentProcess = payload;
  }),

  setProcessItem: action((state, payload) => {
    state.processItem = payload;
  }),

  setLoadingProcessItem: action((state, payload) => {
    if (state?.loadingProcessItem === undefined) {
      return;
    }
    state.loadingProcessItem = payload;
  }),

  setCurrentWorkFlow: action((state, payload) => {
    state.currentWorkFlow = payload;
  }),

  setProcessId: action((state, payload) => {
    state.processId = payload;
  }),

  setTemplateInfo: action((state, payload) => {
    state.templateInfo = payload;
  }),

  setProcessList: action((state, payload) => {
    state.processList = payload;
  }),

  setFieldDetail: action((state, payload) => {
    state.fieldDetail = payload;
  }),

  setLoadingProcessList: action((state, payload) => {
    if (state?.loadingProcessList === undefined) {
      return;
    }
    state.loadingProcessList = payload;
  }),

  setLoadingFields: action((state, payload) => {
    if (state?.loadingFields === undefined) {
      return;
    }
    state.loadingFields = payload;
  }),

  setLoadingFieldDetail: action((state, payload) => {
    if (state?.loadingFieldDetail === undefined) {
      return;
    }
    state.loadingFieldDetail = payload;
  }),

  getProcessList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      action.setLoadingProcessList(true);

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.PROCESS}/${payload.process}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.PROCESS}/${payload.process}`;
      }

      const res = await Http.get(url).then(res => res.data);

      action.setProcessList(res?.data.template);
      action.setProcessId(res?.data._id);
    } catch (error) {
      handleError(error);
    } finally {
      action.setLoadingProcessList(false);
    }
  }),

  getProcessType: thunk(async (action, payload, helpers) => {
    try {
      const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

      const tenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const projectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingProcessList(true);

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${tenantKey}${ENDPOINTS.PROCESS}`;

      if (projectKey && projectKey === projectPath) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${tenantKey}/${projectKey}${ENDPOINTS.PROCESS}`;
      }

      const res = await Http.get(url).then(res => res.data);

      if (typeof res?.data === 'object') {
        action.setCurrentProcess(res.data);
      }

      action.setProcessType(res?.data);

      await helpers.getStoreActions()?.global?.setGlobalProcessList(res?.data?.template);

      return res?.data;
    } catch (error) {
      handleError(error);
    } finally {
      action.setLoadingProcessList(false);
    }
  }),

  getProcessById: thunk(async (action, payload, helpers) => {
    try {
      const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

      const tenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const projectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingProcessItem(true);

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${tenantKey}${ENDPOINTS.PROCESS}/${payload}`;

      if (projectKey && projectKey === projectPath) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${tenantKey}/${projectKey}${ENDPOINTS.PROCESS}/${payload}`;
      }

      const res = await Http.get(url).then(res => res.data);

      action.setProcessItem(res?.data);
      action.setCurrentProcess(res?.data);

      return res?.data;
    } catch (error) {
      handleError(error);
    } finally {
      action.setLoadingProcessItem(false);
    }
  }),

  getTemplate: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;
      const newPayload = { ...payload };
      const { id, noSetTemplateInfo } = newPayload;
      delete newPayload.noSetTemplateInfo;

      action.setLoadingFields(true);

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.TEMPLATE}/${id}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.TEMPLATE}/${id}`;
      }

      const res = await Http.get(url).then(res => res.data);

      let newData = { ...res?.data };
      let newFiels = Array.isArray(newData?.fields) && newData?.fields.length ? [...newData?.fields] : [];

      if (id === WORK_ITEM_TEST_RESULT_ID) {
        newFiels = filterTestResultFields(newFiels);
      }

      newData = {
        ...newData,
        fields: newFiels
      };

      if (!noSetTemplateInfo) {
        action.setTemplateInfo(newData);
        action.setCurrentFieldsList(newData.fields);
        helpers.getStoreActions().workflow.setEditingWorkflow(newData.workFlow);
      }

      return newData;
    } catch (error) {
      handleError(error);
    } finally {
      action.setLoadingFields(false);
    }
  }),

  getFieldDetail: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingFieldDetail(true);

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/fields/${payload.id}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}/fields/${payload.id}`;
      }

      const res = await Http.get(url).then(res => res.data);

      action.setFieldDetail(res.data);
      return Promise.resolve();
    } catch (error) {
      handleError(error);
      return Promise.reject();
    } finally {
      action.setLoadingFieldDetail(false);
    }
  }),

  createFieldDetail: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }
      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingFieldDetail(true);

      let url2 = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.TEMPLATE}/${payload.id}/field`;

      if (globalProjectKey) {
        url2 = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.TEMPLATE}/${payload.id}/field`;
      }

      await Http.post(url2, payload);

      action.getTemplate({ id: payload.id });

      return Promise.resolve();
    } catch (error) {
      handleError(error);
      return Promise.reject();
    } finally {
      action.setLoadingFieldDetail(false);
    }
  }),

  editFieldDetail: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingFieldDetail(true);

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.TEMPLATE}/${payload.id}/field/${payload.fieldId}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.TEMPLATE}/${payload.id}/field/${payload.fieldId}`;
      }
      await Http.put(url, payload).then(res => res.data);

      action.getTemplate({ id: payload.id });
      return Promise.resolve();
    } catch (error) {
      handleError(error);
      return Promise.reject();
    } finally {
      action.setLoadingFieldDetail(false);
    }
  }),

  deleteFieldDetail: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      if (!payload.ticketId) {
        throw new Error('Invalid TicketId');
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      let url2 = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.TEMPLATE}/${payload.ticketId}/field/${payload.fieldId}`;

      if (globalProjectKey) {
        url2 = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.TEMPLATE}/${payload.ticketId}/field/${payload.fieldId}`;
      }

      await Http.delete(url2);

      action.getTemplate({ id: payload.ticketId });

      return Promise.resolve();
    } catch (error) {
      handleError(error);
      return Promise.reject();
    } finally {
      action.setLoadingFieldDetail(false);
    }
  }),

  createProcessItem: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      const body1 = {
        name: payload.name,
        description: payload.description,
        icon: payload.icon,
        isType: payload.isType,
        extendFromId: payload.extendFromId === '' ? null : payload.extendFromId
      };
      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}`;
      }

      const res = await Http.post(url, body1).then(res => res.data);

      action.getProcessType();
      return res?.data;
    } catch (error) {
      handleError(error);
    }
  }),

  deleteProcessItem: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }
      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}/${payload.workTicketId}`;

      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}/${payload.workTicketId}`;
      }

      const res = await Http.delete(url).then(res => res.data);

      action.getProcessType();

      return res;
    } catch (error) {
      handleError(error);
    }
  }),

  editProcessItem: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== undefined && typeof payload === 'object')) {
        throw new Error(INVALID_PAYLOAD);
      }

      const globalTenantKey = helpers.getStoreState()?.global?.globalTenant?.tenantKey;
      const globalProjectKey = helpers.getStoreState()?.global?.globalProject?.projectKey;

      action.setLoadingProcessItem(true);

      const body = {
        name: payload.name,
        description: payload.description,
        icon: payload.icon,
        isType: payload.isType,
        parentId: payload.parentId === '' ? null : payload.parentId
      };

      if (payload.isRevertToDefault) {
        body.isRevertToDefault = payload.isRevertToDefault;
      }
      if (payload.fieldConfig) {
        body.fieldConfig = payload.fieldConfig;
      }
      if (payload.fieldExecuteConfig) {
        body.fieldExecuteConfig = payload.fieldExecuteConfig;
      }

      let url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}/${payload.workTicketId}`;
      if (globalProjectKey) {
        url = `${ENDPOINTS.WORK_ITEM_SYSTEM}/${globalTenantKey}/${globalProjectKey}${ENDPOINTS.PROCESS}/${payload.process}${ENDPOINTS.TEMPLATE}/${payload.workTicketId}`;
      }
      const res = await Http.put(url, body).then(res => res.data);

      action.getProcessType();

      const ticketType = await action.getTemplate({ id: payload.workTicketId });

      helpers.getStoreActions()['global'].setTicketListData({
        workTicketId: payload.workTicketId,
        data: ticketType
      });

      return res;
    } catch (error) {
      handleError(error);
    } finally {
      action.setLoadingProcessItem(false);
    }
  }),

  getFieldList: thunk(async (action, payload, helpers) => {
    const { visibleDetailRelation, workTicketId } = { ...payload };

    let processList = helpers.getStoreState()?.global?.globalProcessList;
    processList = Array.isArray(processList) && processList.length ? [...processList] : [];

    const fieldListData = helpers.getStoreState()?.global?.fieldList || {};

    if (!payload[workTicketId]) {
      return [];
    }

    let workTicketFieldList = payload[workTicketId];
    workTicketFieldList =
      Array.isArray(workTicketFieldList) && workTicketFieldList.length ? [...workTicketFieldList] : [];

    const newFieldList = [];
    for (let index = 0; index < workTicketFieldList.length; index++) {
      const field = workTicketFieldList[index];

      if (field?.componentType === COMPONENT_TYPE.RELATION && visibleDetailRelation) {
        if (fieldListData?.[field?.lookup?.workTicketId]) {
          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;
          const list = convertSubRelationField(field, fieldListData?.[field?.lookup?.workTicketId], workTicketName);

          newFieldList.push(...list);
        } else if (fieldListData && !Object.keys(fieldListData).includes(field?.lookup?.workTicketId)) {
          const res = await helpers.getStoreActions()?.global?.getTicketTypeById(field?.lookup?.workTicketId);
          fieldListData[field?.lookup?.workTicketId] = res?.fields;

          const workTicketName = processList.find(p => p.workTicketId === field?.lookup?.workTicketId)?.name;
          const list = convertSubRelationField(field, res?.fields, workTicketName);

          newFieldList.push(...list);
        } else {
        }
      }

      newFieldList.push({
        ...field,
        workTicketId
      });
    }

    let list = newFieldList.filter(field => field && field.componentType !== COMPONENT_TYPE.TEST_STEPS);

    if (visibleDetailRelation) {
      list = list.filter(field => field.componentType !== COMPONENT_TYPE.RELATION && !field.invisible);
    }

    list = removeDuplicate(list, 'refName');

    return list;
  })
};
/**
 * Handle return sub field relation
 */
const convertSubRelationField = (rootField, list = [], workTicketName) => {
  const relationField = [];

  list.forEach(fieldRelation => {
    relationField.push({
      ...fieldRelation,
      refName: `${rootField?.refName}.${fieldRelation.refName}`,
      name: i18next.exists(`akaat:workItem.${fieldRelation.name}`)
        ? `${workTicketName || rootField?.name} - ${i18next.t(`akaat:workItem.${fieldRelation.name}`)}`
        : `${workTicketName || rootField?.name} - ${fieldRelation.name}`,
      workTicketId: rootField?.lookup?.workTicketId
    });
  });

  return relationField;
};
