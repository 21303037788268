import { action, thunk } from 'easy-peasy';

export default {
  /**
   * State
   */
  importInfo: {},
  importPromiseQueue: [],
  isImporting: false,

  /**
   * Action: Set import info list
   */
  setImportInfo: action((state, payload) => {
    if (!(state?.importInfo !== undefined)) {
      return;
    }

    if (!payload) {
      state.importInfo = {};
      return;
    }

    if (!payload.key || !payload.info) {
      state.importInfo[payload.key] = {};
      return;
    }

    state.importInfo[payload.key] = payload.info;
  }),

  /**
   * Action: Set import promise queue
   */
  setImportPromiseQueue: action((state, payload) => {
    if (!(state?.importPromiseQueue !== undefined)) {
      return;
    }

    if (!payload) {
      state.importPromiseQueue = [];
      return;
    }

    state.importPromiseQueue = payload;
  }),

  /**
   * Action: Set is importing
   */
  setIsImporting: action((state, payload) => {
    if (state?.isImporting === undefined) {
      return;
    }

    state.isImporting = payload;
  }),

  /**
   * Action: Run import promises sequentially
   */
  runImportPromisesSequentially: thunk(async (action, payload, helpers) => {
    try {
      // Nếu đang chạy thì không làm gì thêm
      if (helpers.getStoreState()?.global?.isImporting) {
        return;
      }

      helpers.getStoreActions()?.global?.setIsImporting(true);

      while (helpers.getStoreState()?.global?.importPromiseQueue.length > 0) {
        const nextPromise = helpers.getStoreState()?.global?.importPromiseQueue.shift(); // Lấy promise đầu tiên trong hàng đợi
        await nextPromise(); // Chạy promise và đợi nó hoàn thành
      }
    } catch (error) {
      console.error(error);
    } finally {
      helpers.getStoreActions()?.global?.setIsImporting(false);
    }
  })
};
