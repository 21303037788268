import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const ShowMoreButton = ({ hasData, page, total, limit, onShowMore, loadingList }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <div className="text-center mt-2">
      {hasData && page < Math.ceil(total / limit) && (
        <span
          className="text-primary text-hover-dark-primary cursor-pointer text-center"
          onClick={e => {
            e.preventDefault();
            onShowMore();
          }}
        >
          {loadingList && <Loading3QuartersOutlined spin />} {t('common.showMore')}
        </span>
      )}
    </div>
  );
};

export default ShowMoreButton;
