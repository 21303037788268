import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import objectPath from 'object-path';
import { Row, Tag } from 'antd';

import {
  PAGE_SIZE,
  PRIORITIES,
  COMPONENT_TYPE,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_TAG,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  ORDER_BY_KEY_DESC,
  OPERATION_VALUE_OR,
  OPERATION_VALUE_REGEX,
  NUMBER_PATTERN
} from '../../../constants';
import {
  jsonParse,
  removeDuplicate,
  getObjectByValue,
  checkIsNotEmptyArray,
  convertUserForSubmitData,
  convertMinutesToShortTime,
  convertRawHtmlToPlainText,
  checkValidMomentDateString,
  convertEstimatedTimeToMinutes
} from '../../utils';
import { UserAvatar, StatusLabel } from '../../../components';

const useField = () => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const projectUserList = useStoreState(state => state.global.projectUserList);

  /**
   * Add keyword to filter
   */
  const addKeywordToFilter = ({ field, keyword }) => {
    let newFilter = {};

    if (keyword && Array.isArray(field?.data?.suggestionBy) && field?.data?.suggestionBy.length) {
      newFilter = {
        ...field?.data?.filter,
        [OPERATION_VALUE_OR]: []
      };

      field?.data?.suggestionBy.forEach(k => {
        newFilter[OPERATION_VALUE_OR].push({
          [k]: {
            [OPERATION_VALUE_REGEX]: `.*${keyword}.*`,
            $options: 'i'
          }
        });
      });
    } else {
      newFilter = { ...field?.data?.filter };
    }

    return newFilter;
  };

  /**
   * Handle set suggestion
   */
  const handleSetSuggestion = ({
    field,
    suggestionParams,
    newParams,
    setSuggestionParams,
    newRows,
    res,
    suggestionData,
    setSuggestionData
  }) => {
    setSuggestionParams({
      [field?.refName]: { ...suggestionParams?.[field?.refName], ...newParams }
    });

    let oldRows = suggestionData?.[field?.refName]?.rows;
    oldRows = Array.isArray(oldRows) && oldRows.length ? [...oldRows] : [];

    setSuggestionData({
      [field?.refName]: {
        rows: newParams.page === 1 ? [...newRows] : [...oldRows, ...newRows],
        count: !isNaN(res?.count) ? res?.count : 0
      }
    });
  };

  /**
   * Get form data
   */
  const getSuggestionData = async ({
    field,
    page,
    keyword,
    isLoadType,
    setSuggestionData,
    suggestionData,
    setSuggestionParams,
    suggestionParams,
    suggestionKeyword
  }) => {
    if (!field?.data) {
      return;
    }

    switch (field?.componentType) {
      case COMPONENT_TYPE.SUGGESTION:
      case COMPONENT_TYPE.RELATION: {
        const select = ['_id', 'key', field?.data?.fieldLabel, field?.data?.displayField];
        const newParams = {
          referenceField: field?.refName,
          url: field?.data?.url,
          filter: { ...field?.data?.filter },
          group: field?.data?.fieldValue,
          limit: PAGE_SIZE,
          page: 1,
          order: ORDER_BY_KEY_DESC,
          select: removeDuplicate(select, '').join(' '),
          isLoadType
        };

        // For scroll
        if (isLoadType === 'SCROLL') {
          newParams.page = page;
          newParams.filter = addKeywordToFilter({ field, keyword: suggestionKeyword?.[field?.refName] });
        }

        // For search text
        else if (isLoadType === 'SEARCH') {
          // Reset page before search
          if (suggestionParams?.[field?.refName]?.isLoadType === 'SCROLL') {
            newParams.page = 1;
          }

          newParams.filter = addKeywordToFilter({ field, keyword });
        } else {
        }

        const res = await getSuggestion(newParams);
        const newRows = Array.isArray(res?.rows) && res?.rows.length ? [...res?.rows] : [];

        // Load complete
        if (!newRows.length) {
          newParams.isLoadedAll = true;
        }

        handleSetSuggestion({
          field,
          suggestionParams,
          newParams,
          setSuggestionParams,
          newRows,
          res,
          suggestionData,
          setSuggestionData
        });

        break;
      }

      default:
        break;
    }
  };

  /**
   * Get tag label utils
   */
  const getTagLabelUtils = ({ val }) => {
    const plainText = val || '';
    const label =
      val && typeof val === 'string'
        ? val.split(',').map((item, idx) => (
            <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
              {item}
            </Tag>
          ))
        : '';

    return { label, plainText };
  };

  /**
   * Get status label utils
   */
  const getStatusLabelUtils = ({ val, workFlow }) => {
    let listStates = workFlow?.listStates;
    listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];
    const foundStatus = val?.id ? listStates.find(s => s?.id === val?.id) : null;
    const newStatus = foundStatus || workFlow?.defaultState;

    const plainText = newStatus?.name;
    const label = <StatusLabel status={newStatus} />;

    return { label, plainText };
  };

  /**
   * Get priority label utils
   */
  const getPriorityLabelUtils = ({ val }) => {
    const newVal = getObjectByValue(val, PRIORITIES);
    const plainText = newVal?.label;
    const label = (
      <Row title={newVal?.label || ''} align="middle" wrap={false}>
        {newVal?.icon}
        <span className="text-truncate">{newVal?.label}</span>
      </Row>
    );

    return { label, plainText };
  };

  /**
   * Get option label utils
   */
  const getOptionLabelUtils = ({ val, fieldToDisplay }) => {
    if (val) {
      return { label: '', plainText: '' };
    }

    const options = Array.isArray(fieldToDisplay?.data) && fieldToDisplay?.data.length ? [...fieldToDisplay?.data] : [];
    const newVal = getObjectByValue(val, options);

    const plainText = newVal?.label || '';
    const label = newVal?.label || '';

    return { label, plainText };
  };

  /**
   * Get picklist label utils
   */
  const getPicklistLabelUtils = ({ val, fieldToDisplay }) => {
    if (Array.isArray(val) && val.length) {
      return { label: '', plainText: '' };
    }

    const options = Array.isArray(fieldToDisplay?.data) && fieldToDisplay?.data.length ? [...fieldToDisplay?.data] : [];
    const newVal = options.filter(item => val.includes(item?.value));

    const plainText = newVal.map(item => item?.label).join(', ');
    const label = newVal.length
      ? newVal.map((item, idx) => (
          <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
            {item?.label}
          </Tag>
        ))
      : null;

    return { label, plainText };
  };

  /**
   * Get suggestion or relation label utils
   */
  const getSuggestionOrRelationLabelUtils = ({ val, fieldToDisplay }) => {
    const plainText = val?.[fieldToDisplay?.data?.displayField] || '';
    const label = val?.[fieldToDisplay?.data?.displayField] || '';
    return { label, plainText };
  };

  /**
   * Get default label for relation label
   */
  const getDefaultLabelForRelationLabel = ({ currentPlainText, currentLabel }) => {
    const plainText = currentPlainText?.name || currentPlainText?.key || currentPlainText || '';
    const label = currentLabel?.name || currentLabel?.key || currentLabel || '';
    return { label, plainText };
  };

  /**
   * Default value for label
   */
  const defaultValueForLabel = ({ plainText, label }) => {
    return {
      plainText: plainText?.name || plainText?.key || plainText || '',
      label: label?.name || label?.key || label || ''
    };
  };

  /**
   * Get relation label batch1
   */
  const getRelationLabelBatch1 = ({ val, fieldToDisplay }) => {
    let plainText = '';
    let label = '';

    switch (fieldToDisplay?.componentType) {
      case COMPONENT_TYPE.HTML: {
        const newPlainText = convertRawHtmlToPlainText(val);
        plainText = newPlainText;
        label = newPlainText;
        break;
      }

      case COMPONENT_TYPE.DATE: {
        const newVal = checkValidMomentDateString(val) ? moment(val).format(SHORT_DATE_FORMAT) : '';
        plainText = newVal;
        label = newVal;
        break;
      }

      case COMPONENT_TYPE.DATE_TIME: {
        const newVal = checkValidMomentDateString(val) ? moment(val).format(FULL_DATE_FORMAT) : '';
        plainText = newVal;
        label = newVal;
        break;
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        const newVal = convertMinutesToShortTime(val) || t('common.unestimated');
        plainText = newVal;
        label = newVal;
        break;
      }

      case COMPONENT_TYPE.USER: {
        const newVal = val?.username ? <UserAvatar user={val} className="text-truncate" /> : '';
        plainText = val?.username;
        label = newVal;
        break;
      }

      default: {
        plainText = val;
        label = val;
      }
    }

    const result = defaultValueForLabel({ plainText, label });
    plainText = result?.plainText;
    label = result?.label;

    return { label, plainText };
  };

  /**
   * Get relation label batch2
   */
  const getRelationLabelBatch2 = ({ val, fieldToDisplay, workFlow }) => {
    let plainText = '';
    let label = '';

    switch (fieldToDisplay?.componentType) {
      case COMPONENT_TYPE.STATUS: {
        const result = getStatusLabelUtils({ val, workFlow });
        plainText = result?.plainText;
        label = result?.label;
        break;
      }

      case COMPONENT_TYPE.PRIORITY: {
        const result = getPriorityLabelUtils({ val });
        plainText = result?.plainText;
        label = result?.label;
        break;
      }

      case COMPONENT_TYPE.OPTION: {
        const result = getOptionLabelUtils({ val, fieldToDisplay });
        plainText = result?.plainText;
        label = result?.label;
        break;
      }

      case COMPONENT_TYPE.PICKLIST: {
        const result = getPicklistLabelUtils({ val, fieldToDisplay });
        plainText = result?.plainText;
        label = result?.label;
        break;
      }

      case COMPONENT_TYPE.SUGGESTION:
      case COMPONENT_TYPE.RELATION: {
        const result = getSuggestionOrRelationLabelUtils({ val, fieldToDisplay });
        plainText = result?.plainText;
        label = result?.label;
        break;
      }

      default: {
        plainText = val;
        label = val;
      }
    }

    const result = defaultValueForLabel({ plainText, label });
    plainText = result?.plainText;
    label = result?.label;

    return { label, plainText };
  };

  /**
   * Render relation label
   */
  const getRelationLabel = ({ relationItem, fieldToDisplay, workFlow }) => {
    let plainText = '';
    let label = '';

    if (!relationItem?.[SYSTEM_FIELD_KEY]) {
      return { label: '', plainText: '' };
    }

    const val = objectPath.get(relationItem, fieldToDisplay?.refName);

    if (fieldToDisplay?.refName === SYSTEM_FIELD_TAG) {
      const result = getTagLabelUtils({ val });
      plainText = result?.plainText;
      label = result?.label;
    } else {
      switch (fieldToDisplay?.componentType) {
        case COMPONENT_TYPE.HTML:
        case COMPONENT_TYPE.DATE:
        case COMPONENT_TYPE.DATE_TIME:
        case COMPONENT_TYPE.TIME_TRACKING:
        case COMPONENT_TYPE.USER: {
          const result = getRelationLabelBatch1({ val, fieldToDisplay }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
          plainText = result?.plainText;
          label = result?.label;
          break;
        }

        default: {
          const result = getRelationLabelBatch2({ val, fieldToDisplay, workFlow }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
          plainText = result?.plainText;
          label = result?.label;
        }
      }
    }

    const defaultLabel = getDefaultLabelForRelationLabel({ currentPlainText: plainText, currentLabel: label });
    plainText = defaultLabel?.plainText;
    label = defaultLabel?.label;

    return { label, plainText };
  };

  /**
   * Convert submit data for ref name
   */
  const convertSubmitDataForRefName = ({ field, val }) => {
    const newSubmitData = {};

    switch (field?.refName) {
      case SYSTEM_FIELD_TAG: {
        if (checkIsNotEmptyArray(val)) {
          newSubmitData[field?.refName] = val.join(',');
        }

        break;
      }

      default: {
      }
    }

    return newSubmitData;
  };

  /**
   * Convert submit data for component type
   */
  const convertSubmitDataForComponentType = ({ field, val }) => {
    const newSubmitData = {};

    switch (field?.componentType) {
      case COMPONENT_TYPE.SUGGESTION: {
        newSubmitData[field?.refName] = jsonParse(val) || null;
        break;
      }

      case COMPONENT_TYPE.USER: {
        if (checkIsNotEmptyArray(projectUserList)) {
          const user = projectUserList.find(u => u?.username === val);
          newSubmitData[field?.refName] = convertUserForSubmitData(user);
        }

        break;
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        newSubmitData[field?.refName] = convertEstimatedTimeToMinutes(val);
        break;
      }

      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        newSubmitData[field?.refName] = val && moment(val).isValid() ? moment(val).format() : '';
        break;
      }

      case COMPONENT_TYPE.NUMBER: {
        newSubmitData[field?.refName] = new RegExp(NUMBER_PATTERN).test(val) ? +val : '';
        break;
      }

      case COMPONENT_TYPE.OPTION: {
        newSubmitData[field?.refName] = val || '';
        break;
      }

      case COMPONENT_TYPE.RELATION: {
        newSubmitData[field?.refName] = val || null;
        break;
      }

      default: {
        newSubmitData[field?.refName] = val;
        break;
      }
    }

    return newSubmitData;
  };

  /**
   * Convert form values to submit data
   */
  const convertFormValuesToSubmitData = ({ formValues = {}, fieldList }) => {
    if (!checkIsNotEmptyArray(fieldList)) {
      return {};
    }

    let newSubmitData = {};

    Object.keys(formValues).forEach(key => {
      const field = fieldList.find(item => item?.refName === key);

      if (!field?.refName) {
        return;
      }

      if (field?.refName === SYSTEM_FIELD_TAG) {
        const newSubmitData2 = convertSubmitDataForRefName({ field, val: formValues[key] });
        newSubmitData = { ...newSubmitData, ...newSubmitData2 };
      } else {
        const newSubmitData3 = convertSubmitDataForComponentType({ field, val: formValues[key] });
        newSubmitData = { ...newSubmitData, ...newSubmitData3 };
      }
    });

    return newSubmitData;
  };

  return {
    getSuggestionData,
    getRelationLabel,
    convertSubmitDataForRefName,
    convertSubmitDataForComponentType,
    convertFormValuesToSubmitData
  };
};

export default useField;
