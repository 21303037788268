import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Button, Dropdown, Empty, Tooltip, Tree } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined, FolderOutlined } from '@ant-design/icons';

import { OPERATION_VALUE_OR, OPERATION_VALUE_REGEX } from '../../../constants';
import {
  checkIsNotEmptyArray,
  debounce,
  findItemAndParentsOnTree,
  getFieldName,
  getValueNestedObject
} from '../../../common/utils';

let typingTimerOfSearch = 0;

export const FieldFolder = ({
  field,
  currentValues,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  const repository = useStoreState(state => state.repositoryTree.repository);
  const loadingTreeData = useStoreState(state => state.repositoryTree.loadingRepository);
  const getTestPlanTreeData = useStoreActions(action => action.repositoryTree.getRepositoryList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [tree, setTree] = useState([]);

  /**
   * Has current values
   */
  const hasCurrentValues = useMemo(() => {
    return Array.isArray(currentValues) && currentValues.length > 0;
  }, [currentValues]);

  /**
   * Get test plan data
   */
  useEffect(() => {
    if (!(Array.isArray(repository) && repository.length) || loadingTreeData) {
      getTestPlanTreeData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!field) {
      return;
    }

    getSuggestion({
      referenceField: field?.refName,
      url: field?.data.url,
      page: null,
      limit: null,
      order: null,
      group: field?.data.fieldValue
    });
  }, []);

  /**
   * Sort folders
   */
  const sortFolders = ({ children, hasChildren }) => {
    return hasChildren
      ? children.sort((a, b) => {
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          return 0;
        })
      : []; // Sort by createdAt: ASC
  };

  /**
   * Convert to tree
   */
  const convertToTree = (list, treeLevel, treeType, parentKey, parentKeys = []) => {
    if (!(Array.isArray(list) && list.length)) {
      return [];
    }

    treeLevel++;

    const newParentKeys = parentKey ? [...parentKeys, parentKey] : [...parentKeys];

    return checkIsNotEmptyArray(list)
      ? list.map(item => {
          const hasChildren = checkIsNotEmptyArray(item?.subs);
          const newChildren = sortFolders({ children: item?.subs, hasChildren });
          const key = item?._id;

          return {
            ...item,
            key,
            id: item?._id,
            parentKey,
            parentKeys: newParentKeys,
            treeLevel,
            treeType,
            title: (
              <span title={item?.name}>
                <FolderOutlined /> {item?.name}
              </span>
            ),
            isLeaf: !hasChildren,
            children: hasChildren ? convertToTree(newChildren, treeLevel, treeType, key, newParentKeys) : []
          };
        })
      : [];
  };

  /**
   * Convert treeData to tree
   */
  useEffect(() => {
    if (!(Array.isArray(repository) && repository.length)) {
      return;
    }

    const data = convertToTree(repository, 0, null, []);
    setTree(data); // Set tree to store
  }, [repository]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (!field?.refName || !field?.data?.url || !checkIsNotEmptyArray(suggestion?.[field?.refName]?.data)) {
      setOptions([]);
      return;
    }

    const newOptionList = suggestion[field.refName].data.map(item => {
      return {
        label: getValueNestedObject(item, field.data.fieldLabel),
        value: getValueNestedObject(item, field.data.fieldValue),
        path: item.path
      };
    });

    setOptions(newOptionList);
  }, [field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (!field?.refName || !hasCurrentValues || !checkIsNotEmptyArray(options)) {
      setCheckedKeys([]);
      setSelectedKeys([]);

      return [];
    }

    let newCurrentValues = [];

    currentValues.forEach(cur => {
      if (cur?.refName === field?.refName && typeof cur?.value === 'string') {
        const newValue = cur.value?.split('|') || [];
        newCurrentValues = newCurrentValues.concat(newValue);
      }
    });

    const newSelectedOptions = [];
    const checkedKeys = [];

    newCurrentValues.forEach(value => {
      const node = findItemAndParentsOnTree(tree, value, 'path');

      newSelectedOptions.push(node?.item);
      checkedKeys.push(node?.item?.key);
    });

    setCheckedKeys(checkedKeys);
    setSelectedKeys(checkedKeys);

    return newSelectedOptions;
  }, [field, options, hasCurrentValues, currentValues]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setSelectedOptions(currentSelectedOptions);
    }

    if (visible && field?.refName && field?.data?.url && !suggestion?.[field.refName]?.loadedCount) {
      getSuggestion({
        referenceField: field.refName,
        url: field.data.url,
        page: null,
        limit: null,
        order: null,
        group: field.data.fieldValue
      });
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (selectedKeysValue, info) => {
    if (loadingSuggestion || !info?.node) {
      return;
    }

    setSelectedKeys(selectedKeysValue);
    setCheckedKeys(selectedKeysValue);

    const newSelectedValues = selectedKeysValue.map(selectedKey => {
      const node = findItemAndParentsOnTree(tree, selectedKey, 'key');
      return node.item;
    });

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) {
      return;
    }

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const queryObj = { [OPERATION_VALUE_OR]: [] };

        if (field.data?.suggestionBy && checkIsNotEmptyArray(field.data.suggestionBy)) {
          field.data.suggestionBy.forEach(sub => {
            queryObj[OPERATION_VALUE_OR].push({
              [sub]: { [OPERATION_VALUE_REGEX]: val, $options: 'i' }
            });
          });
        }

        getSuggestion({
          referenceField: field.refName,
          url: field.data?.url,
          page: null,
          limit: null,
          order: null,
          filter: val === '' ? {} : queryObj,
          group: field.data.fieldValue
        });
      }),
      300
    );
  };

  const handleApply = selectedOptions => {
    const paths = selectedOptions.map(selected => selected?.path).join('|');
    onApply(paths);
  };

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const items = [];

    if (!checkIsNotEmptyArray(options)) {
      items.push({
        key: 'empty',
        label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />,
        className: 'bg-transparent'
      });
    } else {
      items.push({
        key: 'tree',
        label: (
          <div className="c-directory-tree" style={{ maxHeight: '280px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Tree
              className="mt-2"
              checkable
              onCheck={handleChangeSelected}
              checkedKeys={checkedKeys}
              onSelect={handleChangeSelected}
              selectedKeys={selectedKeys}
              treeData={tree}
            />
          </div>
        ),
        className: 'bg-transparent'
      });
    }

    items.push(
      { type: 'divider' },
      {
        key: 'box-footer',
        label: (
          <>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
                handleSearch('');
                setSelectedKeys([]);
                setCheckedKeys([]);
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                !selectedOptions.length && handleSearch('');
                handleApply(selectedOptions);
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </>
        ),
        className: 'bg-transparent text-right cursor-default'
      }
    );

    return items;
  }, [options, checkedKeys, selectedKeys, tree, selectedOptions]);

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    const hasCurrentSelectedOptions = Array.isArray(currentSelectedOptions) && currentSelectedOptions.length > 0;

    return hasCurrentSelectedOptions
      ? `${fieldName}: ${currentSelectedOptions.map(item => item?.name).join(', ')}`
      : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, currentSelectedOptions]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{ items: menus, selectable: false }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style"
        overlayStyle={{ width: 250 }}
        disabled={disabled}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button
          title={btnFieldTitle}
          className={`btn-field field-folder btn-toggle-dropdown-with-checkbox-list ${className}`}
          {...rest}
        >
          {checkIsNotEmptyArray(currentSelectedOptions) ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {currentSelectedOptions.map(item => item?.name).join(', ')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
