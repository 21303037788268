import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';

import {
  SS_NEW_RECORDS,
  SS_EDITING_CELL,
  SS_NEW_TEST_STEPS,
  SS_LAST_SAVED_VALUE,
  SS_LOADED_ALL_SUGGESTION_INFO,
  SS_LOADED_USER_LIST_BY_ROLE_INFO
} from '../../../constants';
import { checkIsNotEmptyObject, getCtrlShiftAlt, reactSessionStorage } from '../../../common/utils';
import DeleteItemHelper from './delete-item-helper';
import CloneItemHelper from './clone-item-helper';
import UpdateTicketStatusModalHelper from './update-ticket-status-modal-helper';

export const EditableCellComponentHelpers = ({
  tableForm,
  hasUpdateTicketStatusModalHelper,
  disableScrollToFocusedElement,
  onDeleteItem,
  onCloneItem,
  onReload
}) => {
  // For suggestion store
  const allSuggestion = useStoreState(state => state.global.allSuggestion);
  const setAllSuggestion = useStoreActions(action => action.global.setAllSuggestion);

  // For user list by role store
  const userListByRole = useStoreState(state => state.global.userListByRole);

  /**
   * Check all suggestion
   */
  useEffect(() => {
    if (!checkIsNotEmptyObject(allSuggestion)) {
      if (reactSessionStorage.getObject(SS_LOADED_ALL_SUGGESTION_INFO, null)) {
        reactSessionStorage.remove(SS_LOADED_ALL_SUGGESTION_INFO);
      }
    }
  }, [allSuggestion]);

  /**
   * Check user list by role
   */
  useEffect(() => {
    if (!checkIsNotEmptyObject(userListByRole)) {
      if (reactSessionStorage.getObject(SS_LOADED_USER_LIST_BY_ROLE_INFO, null)) {
        reactSessionStorage.remove(SS_LOADED_USER_LIST_BY_ROLE_INFO);
      }
    }
  }, [userListByRole]);

  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) {
      return;
    }

    const code = e.code;
    const ctrl = getCtrlShiftAlt(e, 'Control');

    if (ctrl && code === 'Pause') {
      console.clear();
    } else if (code === 'Pause') {
    } else {
    }
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  /**
   * Unmount
   */
  useEffect(() => {
    reactSessionStorage.remove(SS_EDITING_CELL);
    reactSessionStorage.remove(SS_NEW_RECORDS);
    reactSessionStorage.remove(SS_NEW_TEST_STEPS);

    return () => {
      reactSessionStorage.remove(SS_LAST_SAVED_VALUE);
      reactSessionStorage.remove(SS_LOADED_USER_LIST_BY_ROLE_INFO);
      reactSessionStorage.remove(SS_EDITING_CELL);
      reactSessionStorage.remove(SS_NEW_RECORDS);
      reactSessionStorage.remove(SS_NEW_TEST_STEPS);
      document.body.classList.remove('is-editing-cell');
      setAllSuggestion({});
    };
  }, [setAllSuggestion]);

  return (
    <div className="c-editable-cell-component-helpers">
      <DeleteItemHelper onDeleteItem={onDeleteItem} />
      <CloneItemHelper onCloneItem={onCloneItem} />

      {/* For update status of: Testcase, work ticket */}
      {hasUpdateTicketStatusModalHelper && <UpdateTicketStatusModalHelper tableForm={tableForm} />}

      <div
        className="trigger-reload-table-data-helper"
        onClick={() => {
          const triggerReload = document.querySelector('.trigger-reload-table-data-helper.need-to-reload');
          triggerReload?.classList.remove('need-to-reload');

          typeof onReload === 'function' && onReload();
        }}
      />

      {disableScrollToFocusedElement && <div className="disable-scroll-to-focused-element" />}
    </div>
  );
};
