import React, { useEffect, useRef, useState } from 'react';
import monaco from 'monaco-editor';
import { v4 as uuidv4 } from 'uuid';
import { useStoreActions, useStoreState } from 'easy-peasy';

import { SocketInstance } from '../../../socket';
import {
  ENDPOINTS,
  FIELD_TEST_STEP_RAW,
  ORDER_BY_CREATED_AT_DESC,
  PAGE_SIZE,
  SYSTEM_FIELD_NAME
} from '../../../constants';
import {
  convertProtocolCompletionItemListToMonacoCompletionList,
  convertProtocolDiagnosticToMonacoMarkerData
} from './protocol-to-monaco-converter';
import { convertMonacoPositionToProtocolPosition } from './monaco-to-protocol-converter';

const VALIDATE_STEP = 'validate-step';

/** @type {monaco.languages.SemanticTokensLegend} */
const legend = {
  tokenTypes: [
    'bubble-term',
    'bubble-identifier',
    'bubble-raw-string',
    'bubble-escape-character',
    'bubble-number',
    'bubble-boolean',
    'bubble-operator',
    'bubble-comment',
    'bubble-error'
  ],
  tokenModifiers: ['repo-identifier', 'data-identifier', 'out-identififer', 'identifier']
};

monaco.editor.defineTheme('myCustomTheme', {
  base: 'vs',
  inherit: true,
  colors: {},
  rules: [
    { token: 'bubble-term', foreground: 'C0911B' },
    { token: 'bubble-identifier', foreground: '6320EE' },
    { token: 'bubble-raw-string', foreground: '0438c7' },
    { token: 'bubble-escape-character', foreground: 'c25e15' },
    { token: 'bubble-error', foreground: 'f64747' },
    { token: 'bubble-number', foreground: '0F6292' },
    { token: 'bubble-boolean', foreground: 'FF8D29' },
    { token: 'bubble-operator', foreground: '948979' },
    { token: 'bubble-comment', foreground: 'B5C0D0' },
    { token: 'bubble-identifier.repo-identifier', foreground: '77D970' },
    { token: 'bubble-identifier.out-identififer', foreground: '9C19E0' },
    { token: 'bubble-identifier.identifier', foreground: '0079FF' }
  ]
});

// // Define your custom language grammar
// const customLanguage = {
//   tokenizer: {
//     root: [
//       [/[a-zA-Z]+/, 'custom-keyword'],
//       [/\d+/, 'custom-number']
//     ]
//   }
// };

// Register the custom language with Monaco Editor
monaco.languages.register({ id: 'customLanguage' });

monaco.languages.setLanguageConfiguration('customLanguage', {
  autoClosingPairs: [
    { open: '`', close: '`' },
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: "'", close: "'" },
    { open: '"', close: '"' },
    { open: '(', close: ')' }
  ],
  onEnterRules: [
    {
      beforeText: new RegExp('{'),
      afterText: new RegExp('}'),
      action: {
        indentAction: monaco.languages.IndentAction.IndentOutdent
      }
    }
  ],
  comments: {
    lineComment: '//'
  }
});

// Register the language grammar
// monaco.languages.setMonarchTokensProvider('customLanguage', customLanguage);

const MonacoEditor = ({ fromModule, currentTestStepRaw, valueEditor, isReadOnly, setValueEditor, setErrorEditor }) => {
  const editorRef = useRef('container');
  const randomUUID = useRef(uuidv4());
  const randomNameUUID = useRef(`name-${uuidv4()}`);

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For test case store
  const setTestStepRawInfo = useStoreActions(action => action.testcase.setTestStepRawInfo);

  // For agent pool store
  const dataAgentPool = useStoreState(state => state.engineAgentPool.data);
  const getAgentPoolList = useStoreActions(action => action.engineAgentPool.getAgentPoolList);

  // For agent store
  const dataAgent = useStoreState(state => state.engineAgent.data);
  const getAgentList = useStoreActions(action => action.engineAgent.getAgentList);

  // For State
  const [infoEditor, SetInfoEditor] = useState({ ...currentTestStepRaw });
  const [socketReq, setSocketReq] = useState();
  const [socketRes, setSocketRes] = useState();
  const [dataSocket, setDataSocket] = useState(null);
  const [model, setModel] = useState();
  const [valuePosition, setValuePosition] = useState([]);
  const [dataColor, setDataColor] = useState([]);

  useEffect(() => {
    if (fromModule !== 'TESTCASE_DETAIL') {
      return;
    }

    getAgentPoolList({
      page: 1,
      limit: PAGE_SIZE,
      order: ORDER_BY_CREATED_AT_DESC,
      orderType: 'object'
    });
  }, []);

  /*
    select agent pool first
  */
  useEffect(() => {
    if (fromModule !== 'TESTCASE_DETAIL' || !Array.isArray(dataAgentPool) || dataAgentPool?.length === 0) {
      return;
    }

    getAgentList({
      page: 1,
      limit: PAGE_SIZE,
      order: ORDER_BY_CREATED_AT_DESC,
      orderType: 'object',
      aql: `agentPoolId == ${dataAgentPool?.[0]?.id}`
    });
  }, [dataAgentPool]);

  useEffect(() => {
    const editor = monaco.editor.create(editorRef.current, {
      value: valueEditor,
      readOnly: isReadOnly,
      minimap: {
        enabled: false
      },
      language: 'customLanguage',
      theme: 'myCustomTheme',
      'semanticHighlighting.enabled': true
    });
    setModel(editor);

    editor.onDidChangeModelContent(() => {
      const content = editor.getValue();
      setDataSocket(content);
      setValueEditor(content);
      setErrorEditor(infoEditor?.testStepErrors);
    });

    return () => {
      editor.dispose();
    };
  }, [isReadOnly]);

  /**
   * Set when add manual and quick edit test case
   */
  useEffect(() => {
    setTestStepRawInfo({ testStepRaw: dataSocket, testStepErrors: infoEditor.testStepErrors });
  }, [dataSocket, infoEditor, setTestStepRawInfo]);

  useEffect(() => {
    if (!model) {
      return;
    }

    const modelMarker = model.getModel();
    monaco.editor.setModelMarkers(modelMarker, 'owner', []);

    // Highlight syntax errors
    monaco.editor.setModelMarkers(
      modelMarker,
      'owner',
      (infoEditor?.testStepErrors || []).map(diagnostic => convertProtocolDiagnosticToMonacoMarkerData(diagnostic))
    );
  }, [model, infoEditor]);

  /**
   * Suggestions
   */

  useEffect(() => {
    if (!socketRes) {
      return;
    }
    const provider = monaco.languages.registerCompletionItemProvider('customLanguage', {
      provideCompletionItems: async (model, position, context, token) => {
        try {
          const valuePosition = convertMonacoPositionToProtocolPosition(position);
          setValuePosition(valuePosition);
          const wordUntil = model.getWordUntilPosition(position);
          const defaultRange = new monaco.Range(
            position.lineNumber,
            wordUntil.startColumn,
            position.lineNumber,
            wordUntil.endColumn
          );
          const result = await new Promise((resolve, reject) => {
            socketRes.on('topic_suggestion', arg => {
              if (Array.isArray(arg.suggestions)) {
                resolve(arg.suggestions);
              }
            });
          });

          const { suggestions } = convertProtocolCompletionItemListToMonacoCompletionList(result, defaultRange);

          return {
            suggestions: suggestions || []
          };
        } catch (error) {
          console.error('Error socket:', error);
          return []; // Trả về một mảng rỗng trong trường hợp có lỗi
        }
      }
    });

    return () => {
      provider.dispose();
    };
  }, [socketRes]);

  /**
   * Sematic Tokens
   */
  useEffect(() => {
    const providerSemantic = monaco.languages.registerDocumentSemanticTokensProvider('customLanguage', {
      getLegend: function () {
        return legend;
      },
      provideDocumentSemanticTokens: () => {
        return {
          data: new Uint32Array(dataColor),
          resultId: null
        };
      },
      releaseDocumentSemanticTokens: function (resultId) {}
    });
    return () => {
      providerSemantic.dispose();
    };
  }, [dataColor]);

  /**
   * Validate Step and Sematic Tokens
   */
  useEffect(() => {
    if (!dataSocket || !dataSocket.length || !socketReq) {
      return;
    }
    const timeoutValidate = setTimeout(() => {
      socketReq.emit(VALIDATE_STEP, {
        to: dataAgent?.[0]?.id,
        event: 'validate',
        payload: {
          stepRaw: dataSocket,
          metadata: {
            id: infoEditor?._id || randomUUID.current,
            name: infoEditor?.name || randomNameUUID.current,
            tenantKey: infoEditor?.tenantKey,
            projectKey: infoEditor?.projectKey
          }
        }
      });
    }, 200);

    const timeoutSematic = setTimeout(() => {
      socketReq.emit('topic_semanticToken', {
        to: dataAgent?.[0]?.id,
        event: 'topic_semanticToken',
        payload: {
          metadata: {
            id: infoEditor?._id || randomUUID.current
          }
        }
      });
    }, 250);

    return () => {
      clearTimeout(timeoutValidate);
      clearTimeout(timeoutSematic);
    };
  }, [dataSocket]);

  useEffect(() => {
    if (!socketReq) {
      return;
    }

    socketReq.emit('topic_suggestion', {
      to: dataAgent?.[0]?.id,
      event: 'topic_suggestion',
      payload: {
        metadata: {
          id: infoEditor?._id || randomUUID.current
        },
        position: valuePosition
      }
    });
  }, [valuePosition]);

  /**
   * Initial socket
   */
  useEffect(() => {
    const socketInstance = new SocketInstance(ENDPOINTS.SOCKET_NAMESPACE, null, {
      authorization: 'internal'
    }).socket();

    setSocketReq(socketInstance);
    socketInstance.connect();
    socketInstance.emit('join-room', randomUUID.current);

    socketInstance.emit(VALIDATE_STEP, {
      to: dataAgent?.[0]?.id,
      event: 'validate',
      payload: {
        stepRaw: currentTestStepRaw?.[FIELD_TEST_STEP_RAW] || '',
        metadata: {
          id: currentTestStepRaw?._id || randomUUID.current,
          name: currentTestStepRaw?.[SYSTEM_FIELD_NAME] || randomNameUUID.current,
          tenantKey: globalTenant?.tenantKey,
          projectKey: globalProject?.projectKey
        }
      }
    });

    socketInstance.emit('topic_semanticToken', {
      to: dataAgent?.[0]?.id,
      event: 'topic_semanticToken',
      payload: {
        metadata: {
          id: currentTestStepRaw?._id || randomUUID.current
        }
      }
    });
    return () => {
      socketInstance.disconnect();
    };
  }, []);

  /**
   * Socket get Errors
   */
  useEffect(() => {
    const socketInstanceRes = new SocketInstance(ENDPOINTS.TESTMAN_NAMESPACE, null, {
      authorization: 'internal'
    }).socket();

    setSocketRes(socketInstanceRes);
    socketInstanceRes.connect();

    socketInstanceRes.emit('join-room', infoEditor?._id || randomUUID.current);

    socketInstanceRes.on(VALIDATE_STEP, arg => {
      SetInfoEditor({
        ...infoEditor,
        testStepErrors: arg?.values?.testStepErrors
      });
    });

    socketInstanceRes.on('topic_semanticToken', arg => {
      setDataColor(arg?.tokens?.data);
    });
    return () => {
      socketInstanceRes.disconnect();
    };
  }, []);

  return <div ref={editorRef} style={{ height: '400px' }} />;
};

export default MonacoEditor;
