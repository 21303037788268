import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { SYSTEM_FIELD_SCRIPT_PATH, SYSTEM_FIELD_TEST_CASE, WORK_ITEM_TEST_RUN_ID } from '../../constants';
import { trackEventAzureWithDuration } from '../../common/utils';
import { EditableInput } from '../editable-input';

const BoxAutomation = ({
  workTicketId,
  editingItem,
  form,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For statistics and measurement
  const appInsights = useAppInsightsContext();

  return (
    <>
      <div
        id="anchorAutomation"
        className={`c-detail-components type-automation collapse-item mb-4 ${className}`}
        {...rest}
      >
        <h5 className="btn-toggle ant-typography mb-0">
          <span id="toggle-automation-button" onClick={() => onChangeCollapse('anchorAutomation')}>
            <>{collapseKeys.includes('anchorAutomation') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
            <span>{t('anchor.anchorAutomation')}</span>
          </span>
        </h5>

        <div className={`collapse-body ${collapseKeys.includes('anchorAutomation') ? 'show' : ''}`}>
          <div className="pt-2">
            <Form.Item
              label={
                <span id={`label_${SYSTEM_FIELD_SCRIPT_PATH}-field`} title={t('workItem.scriptPath')}>
                  {t('workItem.scriptPath')}
                </span>
              }
              className="label-truncate mb-0"
            >
              <EditableInput
                restValueText={{ id: `value_${SYSTEM_FIELD_SCRIPT_PATH}-field` }}
                restEditButton={{ id: `edit-button_${SYSTEM_FIELD_SCRIPT_PATH}-field` }}
                restSaveButton={{ id: `save-button_${SYSTEM_FIELD_SCRIPT_PATH}-field` }}
                restCloseButton={{ id: `close-button_${SYSTEM_FIELD_SCRIPT_PATH}-field` }}
                form={form}
                defaultVal={
                  workTicketId === WORK_ITEM_TEST_RUN_ID
                    ? editingItem?.[SYSTEM_FIELD_SCRIPT_PATH] ||
                      editingItem?.[SYSTEM_FIELD_TEST_CASE]?.[SYSTEM_FIELD_SCRIPT_PATH]
                    : editingItem?.[SYSTEM_FIELD_SCRIPT_PATH]
                }
                restFormItem={{ name: SYSTEM_FIELD_SCRIPT_PATH }}
                placeholder={t('workItem.enterScriptPath')}
                isReadOnly={isReadOnly}
                onSave={val => {
                  trackEventAzureWithDuration(appInsights, 'create_script_path');
                  onSubmit({ [SYSTEM_FIELD_SCRIPT_PATH]: val || '' });
                }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxAutomation;
