import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Form, Modal, Button, Spin, Select, Divider, Row } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { SYSTEM_FIELD_ASSIGN_TO } from '../../constants';
import { convertUserForSubmitData, filterOptionForUserField } from '../../common/utils';
import { useUnassignedUser } from '../../common/hooks';
import { UserAvatar } from '../../components';

export const AssigneeModal = ({
  fromModule,
  visible,
  selectedRows,
  loading,
  className = '',
  onAssign,
  onCancel,
  ...rest
}) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  /**
   * On submit
   */
  const onSubmit = async values => {
    if (!values || loading) {
      return;
    }

    let formData = null;
    const userList = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const user = userList.find(user => user.username === values.assignTo);
    const assignTo = values.assignTo && values.assignTo !== UNASSIGNED.value ? convertUserForSubmitData(user) : null;

    if (fromModule === 'TREE_ITEM') {
      formData = { [SYSTEM_FIELD_ASSIGN_TO]: assignTo };
    } else {
      const newSelectedRows = Array.isArray(selectedRows) && selectedRows.length ? [...selectedRows] : [];

      formData = newSelectedRows.map(item => {
        return {
          key: item.key,
          [SYSTEM_FIELD_ASSIGN_TO]: assignTo
        };
      });
    }

    onAssign(formData);
  };

  return (
    <Modal
      open={visible}
      width={700}
      maskClosable={false}
      keyboard={false}
      footer={null}
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
      className={`c-assignee-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <Form form={form} labelCol={{ sm: 8 }} wrapperCol={{ sm: 14 }} onFinish={onSubmit}>
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">{t('common.assignment')} </h3>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
              <Form.Item
                label={t('common.assignAllSelectedTo')}
                name="assignTo"
                rules={[{ required: true, message: t('message.required') }]}
              >
                <Select
                  options={
                    Array.isArray(projectUserList) && projectUserList.length
                      ? [
                          UNASSIGNED,
                          ...projectUserList.map(item => {
                            return {
                              label: <UserAvatar user={item} />,
                              value: item.username
                            };
                          })
                        ]
                      : [UNASSIGNED]
                  }
                  filterOption={filterOptionForUserField}
                  optionFilterProp="value"
                  showSearch
                  allowClear
                  placeholder={t('common.searchUser')}
                  className="w-100"
                />
              </Form.Item>
            </Spin>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button type="text" onClick={onCancel}>
              {t('common.cancel')}
            </Button>

            <Button htmlType="submit" type="primary">
              {t('common.ok')}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
