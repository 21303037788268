import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

export const PromptOnUnload = ({ when, message }) => {
  // For language
  const [t] = useTranslation('akaat');

  useEffect(() => {
    if (!when) {
      return;
    }

    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = ''; // Chrome requires setting this.
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [when]);

  return <Prompt when={when} message={message || t('common.exitScreenWarning')} />;
};
