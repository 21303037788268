import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Col, Popover, Row } from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';

import { AVATAR_BACKGROUND_COLORS } from '../../constants';
import { convertUserForSubmitData, getTotalUnicodeIndexOfString } from '../../common/utils';

import './style.scss';

export const UserAvatar = ({
  user,
  className = '',
  showAvatar = true,
  showName = true,
  onlyShowUsername = true,
  showInfoDropdown = true,
  inactive,
  restAvatar,
  restName,
  restInfoDropdown,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Get avatar background color
   */
  const getAvatarBackgroundColor = ({ newUser, user }) => {
    let bg = '#dfdfdf';

    if (newUser?.avatar) {
      bg = '#dfdfdf';
    } else if (user?.username || user?.email) {
      bg =
        AVATAR_BACKGROUND_COLORS[
          getTotalUnicodeIndexOfString(user?.username || user?.email) % AVATAR_BACKGROUND_COLORS.length
        ];
    } else {
    }

    return bg;
  };

  /**
   * Render avatar
   */
  const renderAvatar = ({ showAvatar, user, newUser, size, restAvatar }) => {
    if (!showAvatar) {
      return;
    }

    let letter = '';

    if (newUser?.username && !/\p{Extended_Pictographic}/u.test(newUser?.username)) {
      letter = newUser?.username.trim().charAt(0).toUpperCase();
    } else if (newUser?.email && !/\p{Extended_Pictographic}/u.test(newUser?.email)) {
      letter = newUser?.email.trim().charAt(0).toUpperCase();
    } else {
      letter = 'G'; // Guest
    }

    return (
      <Avatar
        src={newUser?.avatar || null}
        size={size}
        style={{
          flex: `0 0 ${size}px`,
          fontSize: size * 0.6,
          backgroundColor: getAvatarBackgroundColor({ newUser, user })
        }}
        className={`${showName ? 'mr-1' : ''}`}
        {...restAvatar}
      >
        {letter}
      </Avatar>
    );
  };

  /**
   * Render name
   */
  const renderName = ({ showName, onlyShowUsername, user, newUser, inactive, restName }) => {
    if (!showName) {
      return;
    }

    return (
      <span
        {...restName}
        className={`txt-name text-truncate ${inactive ? 'text-gray' : ''} ${restName?.className || ''}`}
      >
        {onlyShowUsername ? user?.username || user?.email : newUser?.name || user?.username || user?.email}
        {inactive ? ` - ${t('common.inactive')}` : null}
      </span>
    );
  };

  /**
   * Render avatar and name
   */
  const renderAvatarAndName = () => {
    if (!user) {
      return null;
    }

    const size = restAvatar?.size || 18;
    const newUser = convertUserForSubmitData(user);

    return (
      <>
        <Popover
          content={
            <Row className="p-3" onClick={e => e.stopPropagation()}>
              <Col style={{ flex: '0 0 50px', maxWidth: 50 }}>
                <UserAvatar user={user} showName={false} showInfoDropdown={false} restAvatar={{ size: 40 }} />
              </Col>

              <Col style={{ flex: '1 1 calc(100% - 50px)', maxWidth: 'calc(100% - 50px)' }}>
                <div className={`font-size-16 font-weight-medium mb-1 ${inactive ? 'text-gray' : ''}`}>
                  {newUser?.name}
                  {inactive && <span className="font-size-14 font-weight-normal">{` - ${t('common.inactive')}`}</span>}
                </div>

                <Row title={`${t('user.username')}: ${user?.username}`} align="middle" wrap={false} className="mb-1">
                  <UserOutlined className="mr-1" /> {user?.username}
                </Row>

                <Row title={`${t('user.email')}: ${user?.email}`} align="middle" wrap={false}>
                  <MailOutlined className="mr-1" /> <a href={`mailto:${user?.email}`}>{user?.email}</a>
                </Row>
              </Col>
            </Row>
          }
          placement="bottom"
          mouseEnterDelay={showInfoDropdown ? 1 : 86400}
          overlayStyle={{ minWidth: 300 }}
          destroyTooltipOnHide={true}
          overlayClassName="c-user-avatar-popover"
          zIndex={9999}
          {...restInfoDropdown}
        >
          <div className="box-avatar text-truncate">
            {renderAvatar({ showAvatar, user, newUser, size, restAvatar })}
            {renderName({ showName, onlyShowUsername, user, newUser, inactive, restName })}
          </div>
        </Popover>
      </>
    );
  };

  return (
    <div className={`c-user-avatar ${className}`} {...rest}>
      {renderAvatarAndName()}
    </div>
  );
};
