import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Empty, Tooltip } from 'antd';

import { EllipsisV } from '../../assets/svg-icons';
import { SortableComponent } from './sortable-component';

import './style.scss';

export const ColumnChooserV2 = ({
  fromModule = 'TABLE_HEADER',
  icon = <EllipsisV />,
  columns = [],
  defaultActiveColumnKeys = [],
  className = '',
  dropdownHeaderTitle,
  hideDropdownHeaderTitle,
  restDropdown,
  restButton,
  restButtonTooltip,
  onChangeActiveColumns,
  disabledSort,
  disabled,
  noFixedKeyField,
  isDisabledFirstDeleteButton,
  onRevertToConfigFolder,
  functionsInside,
  onBeforeVisibleChange,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [activeColumnKeys, setActiveColumnKeys] = useState([]);
  const [activeColumns, setActiveColumns] = useState([]);
  const [remainingColumns, setRemainingColumns] = useState([]);
  const [visible, setVisible] = useState(false);

  /**
   * Set functions inside
   * For: Call child function from parent component
   */
  useEffect(() => {
    if (functionsInside) {
      functionsInside.current = {
        handleVisibleChange
      };
    }
  }, [functionsInside]);

  /**
   * Handle visible change
   */
  const handleVisibleChange = (visible, moreInfo) => {
    if (functionsInside && typeof onBeforeVisibleChange === 'function' && !moreInfo?.isConfirmedBeforeChange) {
      onBeforeVisibleChange(visible);
      return;
    }

    setVisible(visible);
  };

  /**
   * Watching change of defaultActiveColumnKeys
   */
  useEffect(() => {
    if (!(Array.isArray(defaultActiveColumnKeys) && defaultActiveColumnKeys.length)) {
      return;
    }

    setActiveColumnKeys(defaultActiveColumnKeys);
  }, [defaultActiveColumnKeys]);

  /**
   * Emit event
   */
  useEffect(() => {
    if (!(Array.isArray(columns) && columns.length) || !Array.isArray(activeColumnKeys)) {
      return;
    }

    setInitialColumns(activeColumnKeys, columns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, activeColumnKeys, defaultActiveColumnKeys]);

  /**
   * Handle set initial field
   */
  const setInitialColumns = (activeColumnKeys = [], columns = []) => {
    const newActiveColumns = activeColumnKeys
      .map(item => columns.find(c => c?.dataIndex === item))
      .filter(f => f?.dataIndex);

    setActiveColumns(newActiveColumns);

    const newRemainingColumns = columns.filter(
      column => !newActiveColumns.some(c => c?.dataIndex === column?.dataIndex)
    );

    setRemainingColumns(newRemainingColumns);
  };

  /**
   * Columns dropdown
   *
   * @return {object} - Element
   */
  const renderColumnsDropdown = columns => {
    const columnList = [...columns].filter(item => !item?.hideOnColumnChooser);

    if (!(Array.isArray(columnList) && columnList.length)) {
      return (
        <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
          <li className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child" style={{ pointerEvents: 'none' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </li>
        </ul>
      );
    }

    return (
      <SortableComponent
        fromModule={fromModule}
        remainingColumns={remainingColumns}
        columns={activeColumns}
        dropdownHeaderTitle={dropdownHeaderTitle}
        hideDropdownHeaderTitle={hideDropdownHeaderTitle}
        isDisabledFirstDeleteButton={isDisabledFirstDeleteButton}
        noFixedKeyField={noFixedKeyField}
        disabledSort={disabledSort}
        onApply={val => {
          if (!Array.isArray(val)) {
            return;
          }

          onChangeActiveColumns(val.map(v => v.dataIndex));
          setVisible(false);
        }}
        onCancel={() => {
          setInitialColumns(defaultActiveColumnKeys, columns);
          setVisible(false);
        }}
        setActiveColumns={val => {
          const newActiveColumnKeys = val.map(c => c?.dataIndex);
          setActiveColumns(val);
          setActiveColumnKeys(newActiveColumnKeys);
        }}
        onRevertToConfigFolder={() => {
          setVisible(false);
          onRevertToConfigFolder();
        }}
      />
    );
  };

  return (
    <div className={`c-column-chooser-2 v2 ${className}`} {...rest}>
      <Dropdown
        open={visible}
        menu={{
          items: [{ key: 'menu', label: renderColumnsDropdown(columns), className: 'p-0' }],
          selectable: false
        }}
        disabled={disabled}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="c-column-chooser-dropdown-2 ant-dropdown-menu-p-0"
        onOpenChange={val => {
          if (!val) {
            setInitialColumns(defaultActiveColumnKeys, columns);
          }

          handleVisibleChange(val);
        }}
        {...restDropdown}
      >
        <Tooltip
          title={t('process.configureGridViewForCurrentBrowserSession')}
          placement="topRight"
          destroyTooltipOnHide={true}
          {...restButtonTooltip}
        >
          <Button
            type="link"
            icon={icon}
            className="btn-column-chooser text-hover-primary line-height-1 w-auto h-auto border-0 ml-2"
            disabled={disabled}
            {...restButton}
          />
        </Tooltip>
      </Dropdown>
    </div>
  );
};
