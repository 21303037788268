import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Checkbox, Empty, Tooltip } from 'antd';

import { EllipsisV } from '../../assets/svg-icons';

import './style.scss';

export const ColumnChooser = ({
  columns = [],
  defaultActiveColumnKeys = [],
  className = '',
  restDropdown,
  restButton,
  onChangeActiveColumns,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [activeColumnKeys, setActiveColumnKeys] = useState([]);

  /**
   * Watching change of default active column keys
   */
  useEffect(() => {
    if (!(Array.isArray(defaultActiveColumnKeys) && defaultActiveColumnKeys.length)) {
      return;
    }

    setActiveColumnKeys(defaultActiveColumnKeys);
  }, [defaultActiveColumnKeys]);

  /**
   * Emit event
   */
  useEffect(() => {
    if (!(Array.isArray(columns) && columns.length) || !Array.isArray(activeColumnKeys)) {
      return;
    }

    const newActiveColumns = activeColumnKeys
      .map(key => columns.find(c => c?.dataIndex === key))
      .filter(c => c?.dataIndex);

    onChangeActiveColumns(newActiveColumns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, activeColumnKeys]);

  /**
   * Get new active column keys 1
   */
  const getNewActiveColumnKeys1 = ({ defaultActiveColumnKeyList, currentActiveColumnKeyList, item }) => {
    const newList = defaultActiveColumnKeyList
      .map(key => {
        if ([...currentActiveColumnKeyList, item?.dataIndex].includes(key)) {
          return key;
        } else {
          return;
        }
      })
      .filter(key => key);

    return newList;
  };

  /**
   * Get new active column keys 2
   */
  const getNewActiveColumnKeys2 = ({ columns, currentActiveColumnKeyList, item }) => {
    let newList = [];

    const columnList = Array.isArray(columns) && columns.length ? [...columns] : [];
    const fixedLeftColumn = columnList.find(item => item?.fixed === 'left');
    const fixedRightColumn = columnList.find(item => item?.fixed === 'right');

    if (fixedLeftColumn || fixedRightColumn) {
      if (fixedLeftColumn) {
        newList = [
          ...currentActiveColumnKeyList.filter(key => key !== fixedLeftColumn?.dataIndex),
          item?.dataIndex,
          fixedLeftColumn?.dataIndex
        ];
      }

      if (fixedRightColumn) {
        newList = [
          ...currentActiveColumnKeyList.filter(key => key !== fixedRightColumn?.dataIndex),
          item?.dataIndex,
          fixedRightColumn?.dataIndex
        ];
      }
    } else {
      newList = [...currentActiveColumnKeyList, item?.dataIndex];
    }

    return newList;
  };

  /**
   * On change column key
   */
  const onChangeColumnKey = (isChecked, item, activeColumnKeyList) => {
    if (!item?.dataIndex) {
      return;
    }

    const defaultActiveColumnKeyList =
      Array.isArray(defaultActiveColumnKeys) && defaultActiveColumnKeys.length ? [...defaultActiveColumnKeys] : [];

    const currentActiveColumnKeyList =
      Array.isArray(activeColumnKeyList) && activeColumnKeyList.length ? [...activeColumnKeyList] : [];

    let newActiveColumnKeys = [];

    if (isChecked && defaultActiveColumnKeyList.includes(item?.dataIndex)) {
      newActiveColumnKeys = getNewActiveColumnKeys1({ defaultActiveColumnKeyList, currentActiveColumnKeyList, item });
    } else if (isChecked && !defaultActiveColumnKeyList.includes(item?.dataIndex)) {
      newActiveColumnKeys = getNewActiveColumnKeys2({ columns, currentActiveColumnKeyList, item });
    } else {
      newActiveColumnKeys = [...currentActiveColumnKeyList].filter(key => key !== item?.dataIndex); // Remove
    }

    setActiveColumnKeys(newActiveColumnKeys);
  };

  /**
   * Columns dropdown
   *
   * @return {object} - Element
   */
  const renderColumnsDropdown = (activeColumnKeyList, columns) => {
    const columnList = [...columns].filter(item => !item?.hideOnColumnChooser);

    if (!(Array.isArray(columnList) && columnList.length)) {
      return (
        <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
          <li className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child" style={{ pointerEvents: 'none' }}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
          </li>
        </ul>
      );
    }

    return (
      <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical">
        <li
          className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child has-padding"
          onClick={e => e.stopPropagation()}
        >
          <div
            className="text-primary cursor-pointer over"
            onClick={() => setActiveColumnKeys(defaultActiveColumnKeys)}
          >
            {t('common.restoreDefaults')}
          </div>
        </li>

        <li className="ant-dropdown-menu-item-divider my-0" onClick={e => e.stopPropagation()}></li>

        {columnList.map(item => (
          <li
            key={item?.dataIndex}
            title={item?.title}
            className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
            onClick={e => e.stopPropagation()}
          >
            <Checkbox
              checked={activeColumnKeyList.includes(item?.dataIndex)}
              className="w-100"
              onClick={e => e.stopPropagation()}
              onChange={e => onChangeColumnKey(e?.target?.checked, item, activeColumnKeyList)}
            >
              {item?.title}
            </Checkbox>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={`c-column-chooser v1 ${className}`} {...rest}>
      <Dropdown
        menu={{
          items: [{ key: 'menu', label: renderColumnsDropdown(activeColumnKeys, columns), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="c-column-chooser-dropdown ant-dropdown-menu-p-0"
        {...restDropdown}
      >
        <Tooltip placement="topRight" title={t('common.showHideColumns')} destroyTooltipOnHide={true}>
          <Button type="link" icon={<EllipsisV />} className="btn-column-chooser ml-2" {...restButton}></Button>
        </Tooltip>
      </Dropdown>
    </div>
  );
};
