import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { JIRA_PLATFORM_ID } from '../../constants';
import { checkValidRGBObject, getBgColorStatusJira, getColorStatusJira } from '../../common/utils';

import './style.scss';

export const StatusLabel = ({ status = {}, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Style
   */
  const style = useMemo(() => {
    const newStyle = {};

    // For external system: Jira
    if (status?.externalSystem === JIRA_PLATFORM_ID) {
      newStyle.color = getColorStatusJira(status?.statusCategory?.colorName);
      newStyle.background = getBgColorStatusJira(status?.statusCategory?.colorName);
    }

    // For internal system
    else if (checkValidRGBObject(status?.color)) {
      newStyle.color = `rgb(${status.color?.r}, ${status.color?.g}, ${status.color?.b})`;
      newStyle.background = `rgb(${status.background?.r}, ${status.background?.g}, ${status.background?.b})`;
    }

    // For default
    else {
      newStyle.color = null;
      newStyle.background = null;
    }

    return newStyle;
  }, [status]);

  if (!status) {
    return;
  }

  return (
    <div className={`c-status-label ${className}`} {...rest}>
      <span title={t(status?.name) || ''} style={style} className="status-label text-truncate">
        {t(status?.name) || ''}
      </span>
    </div>
  );
};
