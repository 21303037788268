import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ConfigProvider } from 'antd';
import i18next from 'i18next';
import moment from 'moment';

// Language
import enUS from 'antd/lib/locale/en_US';
import frFR from 'antd/lib/locale/fr_FR';
import jaJP from 'antd/lib/locale/ja_JP';
import viVN from 'antd/lib/locale/vi_VN';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/vi';

import env from '../env';
import { LS_LANGUAGE } from '../constants';
import { reactLocalStorage } from '../common/utils';
import { PrivateRoutes } from '../components/route/private-route';

// For home pages
const FirstPage = lazy(() => import('./home/first-page'));
const Homepage = lazy(() => import('./home/homepage'));
const TenantOverview = lazy(() => import('./home/tenant-overview'));
const JiraIntegration = lazy(() => import('./home/jira-integration'));
const AzureIntegration = lazy(() => import('./home/azure-integration'));
const Process = lazy(() => import('./home/process'));
const User = lazy(() => import('./home/user'));
const Project = lazy(() => import('./home/project'));
const Subscription = lazy(() => import('./home/subscription'));

// For project setting pages
const ProjectGeneralSettings = lazy(() => import('./project-settings/general-settings'));
const ProjectPermissionSettings = lazy(() => import('./project-settings/permission-settings'));
const ProjectTeamSettings = lazy(() => import('./project-settings/team-settings'));
const ProjectNotificationSettings = lazy(() => import('./project-settings/notification-settings'));
const ProjectIntegrationSettings = lazy(() => import('./project-settings/integration-settings'));
const TestConfigSettings = lazy(() => import('./project-settings/test-config-settings'));
const TemplateSettings = lazy(() => import('./project-settings/template-settings'));
const AgentPoolsSettings = lazy(() => import('./engine/agent-pools/agent-pools-settings'));
const ExtensionsSettings = lazy(() => import('./project-settings/extensions'));

// For project manager pages
const Dashboard = lazy(() => import('./manager/dashboard'));
const TestRepo = lazy(() => import('./manager/test-repository'));
const TestPlan = lazy(() => import('./manager/test-plan'));
const WorkItem = lazy(() => import('./manager/work-item'));
const TestData = lazy(() => import('./manager/test-data'));
const MyReports = lazy(() => import('./manager/my-reports'));
const Traceability = lazy(() => import('./manager/traceability'));

// For project engine pages
const SummaryEngine = lazy(() => import('./engine/summary'));
const PlanningScheduleEngine = lazy(() => import('./engine/planning/page-schedule'));
const PlanningCICDEngine = lazy(() => import('./engine/planning/page-cicd'));
const PlanningJobRunnerEngine = lazy(() => import('./engine/planning/page-job-runner'));
const TestDataEngine = lazy(() => import('./engine/test-data'));
const TestResultEngine = lazy(() => import('./engine/test-data/test-results/index'));
const CommonReportsEngine = lazy(() => import('./engine/report/page-common-reports'));
const MyReportsEngine = lazy(() => import('./engine/report/page-my-reports'));
const AgentPoolsEngine = lazy(() => import('./engine/agent-pools'));
const ViewAllTaskLogs = lazy(() => import('./engine/planning/page-job-runner/view-all-logs'));

// For common pages
const PreviewFile = lazy(() => import('./preview-file'));
const Maintenance = lazy(() => import('./maintenance'));
const Error403 = lazy(() => import('./error-403'));
const Error404 = lazy(() => import('./error-404'));

export default () => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX]; // IMPORTANT ===> FOR TENANT KEY
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX]; // IMPORTANT ===> FOR PROJECT KEY

  const localLanguage = reactLocalStorage.get(LS_LANGUAGE);

  // For global language store
  const setGlobalLanguage = useStoreActions(action => action.global.setGlobalLanguage);
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // Component state
  const [locale, setLocale] = useState(null);

  /**
   * Set global language by "lang" in localStorage
   */
  useEffect(() => {
    setGlobalLanguage(localLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Watching change of globalLanguage in STORE
   * Set language to localStorage
   * Language for i18next, for ant design locate, for moment
   */
  useEffect(() => {
    if (!globalLanguage) {
      return;
    }

    if (globalLanguage === 'en') {
      setLocale(enUS);
      moment.locale('en');
    } else if (globalLanguage === 'fr') {
      setLocale(frFR);
      moment.locale('fr');
    } else if (globalLanguage === 'ja') {
      setLocale(jaJP);
      moment.locale('ja');
    } else if (globalLanguage === 'vi') {
      setLocale(viVN);
      moment.locale('vi');
    } else {
    }

    i18next.changeLanguage(globalLanguage);
    reactLocalStorage.set(LS_LANGUAGE, globalLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalLanguage]);

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        {/* ====================> For home pages <==================== */}
        <PrivateRoutes exact path={`/${env.REACT_APP_PREFIX_PATH}`} forPage="firstPagePage" component={FirstPage} />
        <PrivateRoutes exact path={`/${env.REACT_APP_PREFIX_PATH}:tenantKey`} forPage="homepage" component={Homepage} />
        <PrivateRoutes
          exact
          path={`/${env.REACT_APP_PREFIX_PATH}:tenantKey/:projectKey/manager`}
          forPage="projectSummary"
          component={Dashboard}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/tenant-overview`}
          component={TenantOverview}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/jira-integration`}
          component={JiraIntegration}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/azure-integration`}
          component={AzureIntegration}
        />
        {projectPath === '_settings' && (
          <PrivateRoutes
            path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/process-tenant`}
            component={Process}
          />
        )}
        <PrivateRoutes path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/user`} component={User} />
        <PrivateRoutes path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/project`} component={Project} />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/_settings/subscription`}
          component={Subscription}
        />

        {/* ====================> For project setting pages <==================== */}
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/general`}
          component={ProjectGeneralSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/members`}
          component={ProjectPermissionSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/teams`}
          component={ProjectTeamSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/notifications`}
          component={ProjectNotificationSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/integration`}
          component={ProjectIntegrationSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/test-configuration`}
          component={TestConfigSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/import_export-template`}
          component={TemplateSettings}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/process`}
          component={Process}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/agent-pools`}
          component={AgentPoolsSettings}
        />

        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/_settings/extensions`}
          component={ExtensionsSettings}
        />
        {/* ====================> For project manager pages <==================== */}
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/dashboard`}
          component={Dashboard}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo`}
          component={TestRepo}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan`}
          component={TestPlan}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item`}
          component={WorkItem}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/test-data`}
          component={TestData}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports`}
          component={MyReports}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/traceability`}
          component={Traceability}
        />

        {/* ====================> For project engine pages <==================== */}
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/summary`}
          component={SummaryEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/schedule`}
          component={PlanningScheduleEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/cicd`}
          component={PlanningCICDEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/planning/job-runner`}
          component={PlanningJobRunnerEngine}
        />

        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/test-data`}
          component={TestDataEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/test-results`}
          component={TestResultEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/common-reports`}
          component={CommonReportsEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/test-analysis/my-reports`}
          component={MyReportsEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/agent-pools`}
          component={AgentPoolsEngine}
        />
        <PrivateRoutes
          path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/enigne/job-runner/:jobRunnerId/task-log/:taskId`}
          component={ViewAllTaskLogs}
        />

        {/* ====================> For common pages <==================== */}
        <Route path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/files`} component={PreviewFile} />
        <Route path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/maintenance`} component={Maintenance} />
        <Route path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/403`} component={Error403} />
        <Route path={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/404`} component={Error404} />
        <Redirect to={`/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/404`} />
      </Switch>
    </ConfigProvider>
  );
};
