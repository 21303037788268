import { Form, Select } from 'antd';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { WORK_ITEM_DEFECT_ID, WORK_ITEM_TYPE, DEFAULT_WORK_ITEM_ICONS } from '../../constants';
import { getObjectByValue } from '../../common/utils';
import { BugSearching } from '../../assets/svg-icons';

const WorkTicketTypeList = () => {
  const [t] = useTranslation('akaat');

  return (
    <>
      <Form.Item
        hidden
        label={t('process.workItemType')}
        name="workItemType"
        rules={[{ required: true, message: t('message.required') }]}
      >
        <Select allowClear placeholder={t('common.pleaseSelect')} defaultValue={WORK_ITEM_DEFECT_ID}>
          {WORK_ITEM_TYPE.map(item => {
            const iconWorkTicket = getObjectByValue(item?.icon, DEFAULT_WORK_ITEM_ICONS);

            return (
              <Select.Option value={item?.id} key={item?.refName} title={item?.name} id={item?.id}>
                <span className="mr-1">{iconWorkTicket?.icon ? iconWorkTicket?.icon : <BugSearching />}</span>
                {item?.name}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
};

export default WorkTicketTypeList;
