import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import env from '../../env';
import {
  LS_PRODUCTION_KEY,
  HOME_MENU,
  PROJECT_MANAGER_MENU,
  PROJECT_SETTING_MENU,
  PROJECT_ENGINE_MENU,
  PRODUCT_KEY_ON_URL_MANAGER,
  PRODUCT_KEY_ON_URL_ENGINE,
  ROLE_APP_ACCESS_MANAGER,
  ROLE_APP_ACCESS_TESTENGINE
} from '../../constants';
import { reactLocalStorage, getParentMenuByPathname, buildBreadcrumbListByMenus } from '../../common/utils';
import { BugSearching } from '../../assets/svg-icons';

import './style.scss';

let PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU];

export const BasicBreadcrumb = ({ className = '', routes = [], addedRoutes = [], ...rest }) => {
  const productionKeyLocal = reactLocalStorage.get(LS_PRODUCTION_KEY);
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalProcessList = useStoreState(state => state.global.globalProcessList);
  const globalProductionKey = useStoreState(state => state.global.globalProductionKey);

  // Component state
  const [menuList, setMenuList] = useState([]);
  const [workItemList, setWorkItemList] = useState([]);

  /**
   * Has menu list
   */
  const hasMenuList = useMemo(() => {
    return Array.isArray(menuList) && menuList.length > 0;
  }, [menuList]);

  /**
   * Has work item list
   */
  const hasWorkItemList = useMemo(() => {
    return Array.isArray(workItemList) && workItemList.length > 0;
  }, [workItemList]);

  /**
   * Set work item list
   */
  useEffect(() => {
    if (!(Array.isArray(globalProcessList) && globalProcessList.length)) {
      return;
    }

    const list = globalProcessList.map(item => {
      return {
        id: `${item?._id}`,
        parentId: null,
        groupId: 'workItems',
        path: `/${
          env.REACT_APP_PREFIX_PATH
        }${tenantPath}/${projectPath}/manager/work-item/${item?.workTicket?.name?.toLowerCase()}`,
        name: `${item?.name}`,
        icon: <BugSearching />
      };
    });

    setWorkItemList(list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, globalProcessList]);

  /**
   * Set menu list
   */
  useEffect(() => {
    if (!(Array.isArray(routes) && routes.length)) {
      const belongToHomePages = getParentMenuByPathname(location.pathname, HOME_MENU);
      const belongToProjectSettingPage = getParentMenuByPathname(location.pathname, [...PROJECT_SETTING_MENU]);

      if (Array.isArray(workItemList) && workItemList.length) {
        PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU, ...workItemList];
      }

      const projectMenus = [...PROJECT_MANAGER_MENU_EXTEND, ...PROJECT_ENGINE_MENU];

      const newMenuList = belongToHomePages
        ? HOME_MENU
        : belongToProjectSettingPage
        ? [...PROJECT_SETTING_MENU]
        : projectMenus;

      if (JSON.stringify(newMenuList) !== JSON.stringify(menuList)) {
        setMenuList(newMenuList);
      }

      return;
    }

    setMenuList(routes);
  }, [routes, location, menuList, workItemList]);

  /**
   * Links
   */
  const homeLink = useMemo(() => {
    return {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}`,
      name: 'menu.home',
      icon: <HomeOutlined />,
      isHome: true
    };
  }, [tenantPath]);

  const tenantLink = useMemo(() => {
    return {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}`,
      name: globalTenant?.name || 'Tenant',
      keepLink: true,
      noTranslate: true
    };
  }, [tenantPath, globalTenant]);

  const managerDashboardLink = useMemo(() => {
    return {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/dashboard`,
      name: globalProject?.name || 'Project',
      keepLink: true,
      noTranslate: true
    };
  }, [globalProject, tenantPath, projectPath]);

  const testEngineDashboardLink = useMemo(() => {
    return {
      path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/engine/summary`,
      name: globalProject?.name || 'Project',
      keepLink: true,
      noTranslate: true
    };
  }, [globalProject, tenantPath, projectPath]);

  /**
   * Get default breadcrumbs for project setting pages
   */
  const getDefaultBreadcrumbsForProjectSettingPages = ({
    globalUserInfo,
    productionKeyLocal,
    tenantLink,
    managerDashboardLink,
    testEngineDashboardLink
  }) => {
    const newDefaultBreadcrumbs = [];
    const hasManagerRole =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_MANAGER);

    const hasTestengineRole =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_TESTENGINE);

    newDefaultBreadcrumbs.push(tenantLink);

    if (productionKeyLocal === PRODUCT_KEY_ON_URL_MANAGER && hasManagerRole) {
      newDefaultBreadcrumbs.push(managerDashboardLink);
    } else if (productionKeyLocal === PRODUCT_KEY_ON_URL_ENGINE && hasTestengineRole) {
      newDefaultBreadcrumbs.push(testEngineDashboardLink);
    } else if (!productionKeyLocal && hasTestengineRole) {
      newDefaultBreadcrumbs.push(testEngineDashboardLink);
    } else {
    }

    return newDefaultBreadcrumbs;
  };

  /**
   * Default breadcrumbs
   */
  const defaultBreadcrumbs = useMemo(() => {
    if (!hasMenuList) {
      return [];
    }

    if (hasWorkItemList) {
      PROJECT_MANAGER_MENU_EXTEND = [...PROJECT_MANAGER_MENU, ...workItemList];
    }

    let newDefaultBreadcrumbs = [];

    const belongToHomePages = getParentMenuByPathname(location.pathname, HOME_MENU);
    const belongToProjectSettingPages = getParentMenuByPathname(location.pathname, PROJECT_SETTING_MENU);
    const belongToProjectPages = getParentMenuByPathname(location.pathname, [
      ...PROJECT_MANAGER_MENU_EXTEND,
      ...PROJECT_ENGINE_MENU
    ]);

    newDefaultBreadcrumbs = [homeLink];

    // Home link
    if (belongToHomePages) {
      newDefaultBreadcrumbs.push(tenantLink);
    }

    // Project setting link
    else if (belongToProjectSettingPages) {
      const newBreadcrumbs = getDefaultBreadcrumbsForProjectSettingPages({
        globalUserInfo,
        productionKeyLocal,
        tenantLink,
        managerDashboardLink,
        testEngineDashboardLink
      });

      newDefaultBreadcrumbs = [...newDefaultBreadcrumbs, ...newBreadcrumbs];
    }

    // Project link (Management)
    else if (belongToProjectPages && globalProductionKey === PRODUCT_KEY_ON_URL_MANAGER) {
      newDefaultBreadcrumbs.push(managerDashboardLink);
    }

    // Project link (Test Engine)
    else if (belongToProjectPages && globalProductionKey === PRODUCT_KEY_ON_URL_ENGINE) {
      newDefaultBreadcrumbs.push(testEngineDashboardLink);
    } else {
    }

    return newDefaultBreadcrumbs;
  }, [
    location,
    productionKeyLocal,
    globalUserInfo,
    globalTenant,
    globalProject,
    globalProductionKey,
    menuList,
    workItemList,
    hasMenuList,
    hasWorkItemList,
    homeLink,
    tenantLink,
    managerDashboardLink,
    testEngineDashboardLink
  ]);

  /**
   * On click to route link
   */
  const onClickToRouteLink = (e, path) => {
    if (
      window.location.pathname.includes('/_settings') &&
      (/\/manager\/dashboard$/.test(path) || /\/engine\/summary$/.test(path))
    ) {
      e?.stopPropagation();
      window.location.href = path;
    }
  };

  return (
    <Breadcrumb className={`c-basic-breadcrumb ${className}`} {...rest}>
      {[...defaultBreadcrumbs, ...buildBreadcrumbListByMenus(location.pathname, menuList), ...addedRoutes].map(
        (item, index, arr) => {
          const isLast = index === arr.length - 1;

          const RouteText = () => (
            <>
              {item.isHome ? item.icon : ''} {item.noTranslate ? item.name : t(`${item.name}`)}
            </>
          );

          const RouteLink = ({ className = '' }) => (
            <Link
              to={{ pathname: item.path || '/' }}
              className={className}
              onClick={e => onClickToRouteLink(e, item.path)}
            >
              <RouteText />
            </Link>
          );

          const showRouteText = item.noLink || isLast;
          const keepLink = isLast && item.keepLink;

          return (
            <Breadcrumb.Item key={item.path}>
              {keepLink ? <RouteLink className="keep-link" /> : showRouteText ? <RouteText /> : <RouteLink />}
            </Breadcrumb.Item>
          );
        }
      )}
    </Breadcrumb>
  );
};
