import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  editingSuite: {},
  loadingItem: false,

  /**
   * Action: Set editing suite
   */
  setEditingSuite: action((state, payload) => {
    if (state?.editingSuite === undefined) {
      return;
    }

    state.editingSuite = payload;
  }),

  /**
   * Action: Set loading item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get suite by Id
   */
  getSuiteById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Get suite by id: There are no suite ID');
      }

      action.setLoadingItem(true);

      const { _id, noSetDataToStore } = payload;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${_id}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setEditingSuite(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create suite
   */
  createSuite: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create suite: Invalid Payload');
      }

      const newPayload = { ...payload };
      const { noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}`;
      const res = await Http.post(url, payload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:common.successful'),
          description: i18next.t('akaat:testPlan.messageCreatingTestSuite')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update suite
   */
  updateSuite: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Update suite: There are no suite ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id, noShowSuccessMessage } = newPayload;
      delete newPayload._id;
      delete newPayload.noShowSuccessMessage;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageUpdatingTestSuite'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete suite
   */
  deleteSuite: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Delete suite: There are no suite ID');
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageDeletingTestSuite'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get nested test suites by path
   */
  getNestedTestSuitesByPath: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id || !payload?.path) {
        throw new Error('Get nested test suites by path: No test suite ID/test suite path');
      }

      const { _id, path } = payload;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_PLAN_TEST_SUITE}/all-element/${_id}/?${encodeURI(`path=${path}`)}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to change status suite
   */
  changeStatusSuite: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Change status suite: There are no suite ID');
      }
      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to re-Cycle test suite
   */
  reCycleBySuite: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Re-Cycle test suite: Invalid Payload');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${_id}/reCycle`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      notification.success({
        description: i18next.t('akaat:message.reCycleSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to clone format test suite
   */
  cloneFormatTestSuite: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalTenant?.tenantKey || !globalProject?.projectKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) {
        throw new Error('Clone test suite: No Id');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${payload}/clone-tree`;
      const res = await Http.get(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageCloning', { name: i18next.t('akaat:testPlan.testSuite') }),
        description: i18next.t('akaat:message.clonedSuccessfully')
      });
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to execute Auto Test Run
   */
  executeAutoTestRun: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Re-Cycle test suite: Invalid Payload');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_SUITE}/${_id}/execute`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      notification.success({
        description: i18next.t('akaat:message.runAutomationTestRun')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
