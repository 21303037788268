import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';
import qs from 'qs';

import { ENDPOINTS } from '../../constants';
import { sleep } from '../../common/utils';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  globalProject: null,
  editingProject: null,
  myProjectList: null, // All of projects of current user,
  filteredMyProjectList: null,
  isGettedMyProjectList: false,
  loadingMyProjectList: false,
  loadingProjectItem: false,
  myProjectsMoreInfo: null,

  /**
   * Action: Set current global project
   */
  setGlobalProject: action((state, payload) => {
    if (!(state?.globalProject !== undefined)) {
      return;
    }

    const newData = JSON.stringify(payload);
    const oldData = JSON.stringify(state.globalProject);

    if (payload && newData !== oldData) {
      state.globalProject = payload;
    }
  }),

  /**
   * Action: Set editing project
   */
  setEditingProject: action((state, payload) => {
    if (state?.editingProject === undefined) {
      return;
    }

    state.editingProject = payload;
  }),

  /**
   * Action: Set my project list
   */
  setMyProjectList: action((state, payload) => {
    if (!(state?.myProjectList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.myProjectList = [];
      state.filteredMyProjectList = [];
      return;
    }

    state.myProjectList = payload;
    state.filteredMyProjectList = payload;
  }),

  /**
   * Action: Set filtered my project list
   */
  setFilteredMyProjectList: action((state, payload) => {
    if (!(state?.filteredMyProjectList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.filteredMyProjectList = [];
      return;
    }

    state.filteredMyProjectList = payload;
  }),

  /**
   * Action: Set is getted my project list
   */
  setIsGettedMyProjectList: action((state, payload) => {
    if (!(state?.isGettedMyProjectList !== undefined)) {
      return;
    }

    state.isGettedMyProjectList = payload;
  }),

  /**
   * Action: Set loading my project list
   */
  setLoadingMyProjectList: action((state, payload) => {
    if (!(state?.loadingMyProjectList !== undefined)) {
      return;
    }

    state.loadingMyProjectList = payload;
  }),

  /**
   * Action: Set loading project item
   */
  setLoadingProjectItem: action((state, payload) => {
    if (!(state?.loadingProjectItem !== undefined)) {
      return;
    }

    state.loadingProjectItem = payload;
  }),

  /**
   * Action: Set my projects more info
   */
  setMyProjectsMoreInfo: action((state, payload) => {
    if (!(state?.myProjectsMoreInfo !== undefined)) {
      return;
    }

    state.myProjectsMoreInfo = payload;
  }),

  /**
   * Action: Call api to get my project list
   */
  getMyProjectList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingMyProjectList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MY_PROJECTS}/${globalTenant?.tenantKey}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMyProjectList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingMyProjectList(false);
      action.setIsGettedMyProjectList(true);
    }
  }),

  /**
   * Action: Call api to get project by project key
   */
  getProjectByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Get project by key: No Project Key');
      }

      action.setLoadingProjectItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${payload}${ENDPOINTS.PROJECT}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingProject(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectItem(false);
    }
  }),

  /**
   * Action: Call api to create project
   */
  createProject: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create project: Invalid Payload');
      }

      action.setLoadingProjectItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT}${ENDPOINTS.PROJECT}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:project.messageCreatingProject'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      await sleep(1000);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectItem(false);
    }
  }),

  /**
   * Action: Call api to update project
   */
  updateProject: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.projectKey) {
        throw new Error('Update project: No Project Key x');
      }

      action.setLoadingProjectItem(true);

      const body = { ...payload };
      const { projectKey } = payload;
      delete body.projectKey;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${projectKey}${ENDPOINTS.PROJECT}`;
      const res = await Http.put(url, body).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:project.messageUpdatingProject'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      if (res?.data) {
        action.setEditingProject(res.data);
      }

      await sleep(1000);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectItem(false);
    }
  }),

  /**
   * Action: Call api to delete project
   */
  deleteProject: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Delete project: No Project Key');
      }

      action.setLoadingProjectItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${payload}${ENDPOINTS.PROJECT}`;
      const res = await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:project.messageDeletingProject'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      await sleep(1000);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectItem(false);
    }
  }),

  /**
   * Action: Call api to get my projects more info
   */
  getMyProjectsMoreInfo: thunk(async (action, payload, helpers) => {
    if (!(Array.isArray(payload) && payload.length)) {
      throw new Error('Get my projects more info: Invalid payload');
    }

    try {
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MY_PROJECTS}/${globalTenant?.tenantKey}/more-info?${qs.stringify({
        projectKey: payload
      })}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMyProjectsMoreInfo(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  })
};
