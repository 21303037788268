import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Button, Dropdown, Empty, Tooltip, Spin, Tree } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import {
  OPERATION_VALUE_OR,
  OPERATION_VALUE_REGEX,
  TEST_PLAN_TREE_TYPE_CYCLE,
  TEST_PLAN_TREE_TYPE_RELEASE,
  TEST_PLAN_TREE_TYPE_SUITE
} from '../../../constants';
import { debounce, findItemAndParentsOnTree, getFieldName, getValueNestedObject } from '../../../common/utils';
import { TestPlanningIcon } from '../../test-planning-icon';

let typingTimerOfSearch = 0;

export const FieldTestPlanning = ({
  field,
  currentValues,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  const treeData = useStoreState(state => state.testPlanTree.treeData);
  const loadingTreeData = useStoreState(state => state.testPlanTree.loadingTreeData);
  const getTestPlanTreeData = useStoreActions(action => action.testPlanTree.getTestPlanTreeData);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [tree, setTree] = useState([]);

  /**
   * Get test plan data
   */
  useEffect(() => {
    if (!(Array.isArray(treeData) && treeData.length) || loadingTreeData) {
      getTestPlanTreeData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!field) {
      return;
    }

    getSuggestion({
      referenceField: field?.refName,
      url: field?.data.url,
      page: null,
      limit: null,
      order: null,
      group: field?.data.fieldValue
    });
  }, []);

  /**
   * Sort folders
   */
  const sortFolders = ({ children, hasChildren }) => {
    return hasChildren
      ? children.sort((a, b) => {
          if (moment(a.createdAt) > moment(b.createdAt)) {
            return 1;
          }
          if (moment(a.createdAt) < moment(b.createdAt)) {
            return -1;
          }
          return 0;
        })
      : []; // Sort by createdAt: ASC
  };

  /**
   * Convert sub tochildren
   */
  const convertSubsToChildren = item => {
    let children = [];

    if (Array.isArray(item?.cycles) && item?.cycles.length) {
      children = item?.cycles;
    } else if (Array.isArray(item?.testSuites) && item?.testSuites.length) {
      children = item?.testSuites;
    } else if (Array.isArray(item?.subs) && item?.subs.length) {
      children = item?.subs;
    } else {
    }

    return children;
  };

  /**
   * Convert to tree
   */
  const convertToTree = (list, treeLevel, parentKey, parentKeys = []) => {
    if (!(Array.isArray(list) && list.length)) {
      return;
    }

    treeLevel++;

    const treeType =
      treeLevel === 1
        ? TEST_PLAN_TREE_TYPE_RELEASE
        : treeLevel === 2
        ? TEST_PLAN_TREE_TYPE_CYCLE
        : TEST_PLAN_TREE_TYPE_SUITE;

    return list.map(item => {
      const newItem = { ...item };
      const children = convertSubsToChildren(item);
      const hasChildren = Array.isArray(children) && children.length > 0;
      const newChildren = sortFolders({ children, hasChildren });
      const key = `${item._id}`;
      const newParentKeys = parentKey ? [...parentKeys, parentKey] : [...parentKeys];

      // Replace cycles, testSuites, subs by children
      delete newItem.cycles;
      delete newItem.testSuites;
      delete newItem.subs;

      return {
        ...newItem,
        key,
        parentKey,
        parentKeys: newParentKeys,
        title: (
          <span title={item.name}>
            <TestPlanningIcon planningItem={item} planningItemType={treeType} /> {item.name}
          </span>
        ),
        treeLevel,
        treeType,
        isLeaf: !hasChildren,
        children: convertToTree(newChildren, treeLevel, key, newParentKeys)
      };
    });
  };

  /**
   * Convert treeData to tree
   */
  useEffect(() => {
    if (!(Array.isArray(treeData) && treeData.length)) {
      return;
    }

    const data = convertToTree(treeData, 0, null, []);
    setTree(data); // Set tree to store
  }, [treeData]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (
      !field?.refName ||
      !field?.data?.url ||
      !(Array.isArray(suggestion?.[field?.refName]?.data) && suggestion?.[field?.refName]?.data.length)
    ) {
      setOptions([]);
      return;
    }

    const newOptionList = suggestion[field.refName].data.map(item => {
      return {
        label: getValueNestedObject(item, field.data.fieldLabel),
        value: getValueNestedObject(item, field.data.fieldValue),
        path: item.path
      };
    });

    setOptions(newOptionList);
  }, [field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    const hasCurrentValues = Array.isArray(currentValues) && currentValues.length > 0;
    const hasOptions = Array.isArray(options) && options.length > 0;

    if (!field?.refName || !hasCurrentValues || !hasOptions) {
      return [];
    }

    let newCurrentValues = [];

    currentValues.forEach(cur => {
      if (cur?.refName === field?.refName && typeof cur?.value === 'string') {
        const newValue = cur.value?.split('|') || [];
        newCurrentValues = newCurrentValues.concat(newValue);
      }
    });

    const newSelectedOptions = [];

    const checkedKeys = [];

    newCurrentValues.forEach(value => {
      const node = findItemAndParentsOnTree(tree, value, 'path');

      newSelectedOptions.push(node?.item);
      checkedKeys.push(node?.item?.key);
    });

    setCheckedKeys(checkedKeys);
    setSelectedKeys(checkedKeys);

    return newSelectedOptions;
  }, [field, options, currentValues]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setSelectedOptions(currentSelectedOptions);
    }

    if (visible && field?.refName && field?.data?.url && !suggestion?.[field.refName]?.loadedCount) {
      getSuggestion({
        referenceField: field.refName,
        url: field.data.url,
        page: null,
        limit: null,
        order: null,
        group: field.data.fieldValue
      });
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (selectedKeysValue, info) => {
    if (loadingSuggestion || !info?.node) {
      return;
    }

    const newSelectedKeysValues =
      Array.isArray(info?.checkedNodes) && info?.checkedNodes.length
        ? info?.checkedNodes.filter(node => !selectedKeysValue.some(key => key === node.parentKey)).map(node => node.id)
        : [];

    setSelectedKeys(selectedKeysValue);
    setCheckedKeys(selectedKeysValue);

    const newSelectedValues = newSelectedKeysValues.map(selectedKey => {
      const node = findItemAndParentsOnTree(tree, selectedKey, 'key');
      return node.item;
    });

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) {
      return;
    }

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const queryObj = { [OPERATION_VALUE_OR]: [] };

        if (field.data?.suggestionBy && Array.isArray(field.data.suggestionBy) && field.data.suggestionBy.length) {
          field.data.suggestionBy.forEach(sub => {
            queryObj[OPERATION_VALUE_OR].push({
              [sub]: { [OPERATION_VALUE_REGEX]: val, $options: 'i' }
            });
          });
        }

        getSuggestion({
          referenceField: field.refName,
          url: field.data?.url,
          page: null,
          limit: null,
          order: null,
          filter: val === '' ? {} : queryObj,
          group: field.data.fieldValue
        });
      }),
      300
    );
  };

  const handleApply = selectedOptions => {
    const paths = selectedOptions.map(selected => selected?.path).join('|');
    onApply(paths);
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    return (
      <div className="ant-dropdown-menu c-field-test-planning-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingSuggestion}>
          {!(Array.isArray(options) && options.length > 0) && (
            <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                onClick={e => e.stopPropagation()}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
              </li>
            </ul>
          )}

          <div className="c-directory-tree">
            <Tree
              className="pt-2"
              checkable
              onCheck={handleChangeSelected}
              checkedKeys={checkedKeys}
              onSelect={handleChangeSelected}
              selectedKeys={selectedKeys}
              treeData={tree}
            />
          </div>

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>
          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedKeys([]);
                setCheckedKeys([]);
                setSelectedOptions([]);
                onClear();
                handleSearch('');
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                !selectedOptions.length && handleSearch('');
                handleApply(selectedOptions);
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    const hasCurrentSelectedOptions = Array.isArray(currentSelectedOptions) && currentSelectedOptions.length > 0;

    return hasCurrentSelectedOptions
      ? `${fieldName}: ${currentSelectedOptions.map(item => item?.name).join(', ')}`
      : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, currentSelectedOptions]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        disabled={disabled}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button
          title={btnFieldTitle}
          className={`btn-field field-test-planning btn-toggle-dropdown-with-checkbox-list ${className}`}
          {...rest}
        >
          {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {currentSelectedOptions.map(item => item?.name).join(', ')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
