import { useEffect } from 'react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import env from '../../../env';

const useAppInsight = (type, name) => {
  // tracking data appInsights
  const appInsights = useAppInsightsContext();
  const metricData = {
    average: 10,
    name: 'network_traffic',
    sampleCount: 1
  };
  const additionalProperties = { 'Component Name': `${type}-${name}` };

  useEffect(() => {
    const pageView = {
      name: `${type}-${name}`,
      uri: window.location.href
    };

    // production
    if (env.REACT_APP_ENV === 'production') {
      appInsights.trackMetric(metricData, additionalProperties);
      appInsights.trackPageView(pageView);
    }
  }, [type, name]);
};

export default useAppInsight;
