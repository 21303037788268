import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import objectPath from 'object-path';
import { Form } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { useStoreState } from 'easy-peasy';

import {
  FIELD_SYSTEM,
  COMPONENT_TYPE,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  SYSTEM_FIELD_PATH,
  ESTIMATED_TIME_PATTERN,
  SYSTEM_FIELD_EXECUTED_AT,
  SYSTEM_FIELD_ESTIMATEDTIME,
  SYSTEM_FIELD_END_DATE_PLAN,
  SYSTEM_FIELD_START_DATE_PLAN
} from '../../constants';
import {
  checkValidMomentDateString,
  disabledOutOfRangeDate,
  findItemAndParentsOnTree,
  convertMinutesToShortTime,
  convertEstimatedTimeToMinutes,
  getFieldName
} from '../../common/utils';
import { EditableInput } from '../editable-input';
import { SafeInnerHtml } from '../safe-inner-html';

const LANG_MESSAGE_REQUIRED = 'message.required';

const BoxDates = ({
  workTicketId,
  fieldList,
  editingItem,
  form,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For test plan tree store
  const testPlanTree = useStoreState(state => state.testPlanTree.tree);

  /**
   * Compute fields
   */
  const fields = useMemo(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return [];
    }

    let newFields = [];

    const systemFields = fieldList.filter(item => item?.type === FIELD_SYSTEM);
    const otherFields = fieldList.filter(item => item?.type !== FIELD_SYSTEM);

    newFields = [...systemFields, ...otherFields];

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_EXECUTED_AT)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_EXECUTED_AT);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_ESTIMATEDTIME)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_ESTIMATEDTIME);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    return newFields;
  }, [fieldList]);

  /**
   * Get disabled date for date plan field
   */
  const getDisabledDateForDatePlanField = ({ currentDate, refName }) => {
    const startDateRow = editingItem?.[SYSTEM_FIELD_START_DATE_PLAN];
    const endDateRow = editingItem?.[SYSTEM_FIELD_END_DATE_PLAN];

    const treeItem = findItemAndParentsOnTree(testPlanTree, editingItem?.[SYSTEM_FIELD_PATH], SYSTEM_FIELD_PATH);
    const startDateTreeItem = treeItem?.item?.startDate;
    const endDateTreeItem = treeItem?.item?.endDate;

    let newValidRangeDate = {};

    // For start date plan
    if (refName === SYSTEM_FIELD_START_DATE_PLAN) {
      const endDateList = [endDateRow, endDateTreeItem]
        .filter(item => checkValidMomentDateString(item))
        .map(item => moment(item));
      const minEndDate = moment.min(endDateList);

      newValidRangeDate = {
        startDate: startDateTreeItem,
        endDate: moment.utc(minEndDate).format()
      };
    }

    // For start date plan
    else if (refName === SYSTEM_FIELD_END_DATE_PLAN) {
      const startDateList = [startDateRow, startDateTreeItem]
        .filter(item => checkValidMomentDateString(item))
        .map(item => moment(item));
      const maxStartDate = moment.max(startDateList);

      newValidRangeDate = {
        startDate: moment.utc(maxStartDate).format(),
        endDate: endDateTreeItem
      };
    } else {
    }

    return disabledOutOfRangeDate({
      currentDate,
      startDate: newValidRangeDate?.startDate,
      endDate: newValidRangeDate?.endDate
    });
  };

  /**
   * Render available insert fields
   */
  const renderAvailableInsertFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const val = objectPath.get(editingItem, field?.refName);

    switch (field?.componentType) {
      case COMPONENT_TYPE.TIME_TRACKING: {
        const rules = [
          { required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
          { pattern: ESTIMATED_TIME_PATTERN, message: t('message.invalidFormat') }
        ];

        return (
          <Form.Item
            key={field?.refName}
            label={label}
            required={field?.mandatory}
            tooltip={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
            className="label-truncate mb-0"
          >
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              form={form}
              defaultVal={convertMinutesToShortTime(val)}
              restFormItem={{ name: field?.refName, rules, validateTrigger: ['onBlur'] }}
              isReadOnly={isReadOnly}
              placeholder={t('workItem.enterEstimatedTime')}
              onChange={e => form.setFieldsValue({ [field?.refName]: e?.target?.value })}
              onSave={val => onSubmit({ [field?.refName]: convertEstimatedTimeToMinutes(val) || 0 })}
            />
          </Form.Item>
        );
      }

      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        const type = field?.componentType === COMPONENT_TYPE.DATE_TIME ? 'DATETIMEPICKER' : 'DATEPICKER';
        const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];
        const restField = {
          allowClear: !field?.mandatory
        };

        if (field?.refName === SYSTEM_FIELD_START_DATE_PLAN || field?.refName === SYSTEM_FIELD_END_DATE_PLAN) {
          restField.disabledDate = currentDate =>
            getDisabledDateForDatePlanField({ currentDate, refName: field?.refName });
        }

        return (
          <Form.Item key={field?.refName} label={label} required={field?.mandatory} className="label-truncate mb-0">
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              type={type}
              form={form}
              defaultVal={checkValidMomentDateString(val) ? moment(val) : null}
              restFormItem={{ name: field?.refName, rules }}
              restField={restField}
              isReadOnly={isReadOnly}
              iShowTitle={true}
              placeholder={`${
                field?.componentType === COMPONENT_TYPE.DATE_TIME ? t('common.enterDateTime') : t('common.enterDate')
              }`}
              onSave={val => onSubmit({ [field?.refName]: moment(val).format() })}
            />
          </Form.Item>
        );
      }

      default: {
        return null;
      }
    }
  };

  /**
   * Render readonly fields for component type
   */
  const renderReadonlyFieldsForComponentType = ({ field, val, label }) => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        const format = field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
        const title = checkValidMomentDateString(val) ? moment(val).format(format) : '';

        return (
          <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
            <div id={`value_${field?.refName}-field`} title={title}>
              {title || <span className="text-gray">N/A</span>}
            </div>
          </Form.Item>
        );
      }

      default: {
        return null;
      }
    }
  };

  /**
   * Render readonly fields
   */
  const renderReadonlyFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const val = objectPath.get(editingItem, field?.refName);

    switch (field?.refName) {
      case SYSTEM_FIELD_ESTIMATEDTIME: {
        const title = /^[0-9]*$/.test(val) ? convertMinutesToShortTime(val) : '';

        return (
          <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
            <div id={`value_${field?.refName}-field`} title={title}>
              {title || <span className="text-gray">N/A</span>}
            </div>
          </Form.Item>
        );
      }

      default: {
        return renderReadonlyFieldsForComponentType({ field, val, label });
      }
    }
  };

  return (
    <>
      <div id="anchorDates" className={`c-detail-components type-date collapse-item mb-4 ${className}`} {...rest}>
        <h5 id="toggle-dates-button" className="btn-toggle ant-typography mb-0">
          <span onClick={() => onChangeCollapse('anchorDates')}>
            <>{collapseKeys.includes('anchorDates') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
            <span>{t('anchor.anchorDates')}</span>
          </span>
        </h5>

        <div className={`collapse-body ${collapseKeys.includes('anchorDates') ? 'show' : ''}`}>
          <div className="pt-2 pb-2">
            {Array.isArray(fields) &&
              fields.length > 0 &&
              fields.map(field => (field?.isInsert ? renderAvailableInsertFields(field) : renderReadonlyFields(field)))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BoxDates;
