import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../../constants';
import { Http, handleError } from '../../../../../core';
import { parseRsql, buildQueryForParamsUrl } from '../../../../../common/utils';

export const model = {
  /**
   * State
   */
  loadingTestResultExportedData: false,

  /**
   * Action: Set loading test result exported data
   */
  setLoadingTestResultExportedData: action((state, payload) => {
    if (state?.loadingTestResultExportedData === undefined) {
      return;
    }

    state.loadingTestResultExportedData = payload;
  }),

  /**
   * Action: Call api to get test result exported data
   */
  getTestResultExportedData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (!(Array.isArray(payload?.fieldList) && payload.fieldList.length)) {
        throw new Error('Fields export empty');
      }

      if ((payload?.aql && !parseRsql(payload.aql)) || !payload?.fieldList) {
        throw new Error('Invalid aql'); // aql is invalid
      }

      action.setLoadingTestResultExportedData(true);

      const { fieldList, order, aql } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_EXPORT_TEST_RESULT
      }?${buildQueryForParamsUrl({
        order,
        aql,
        orderType: 'object'
      })}`;

      const config = { responseType: 'blob' };
      const res = await Http.post(url, fieldList, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestResultExportedData(false);
    }
  })
};
