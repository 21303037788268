import { action, thunk } from 'easy-peasy';
import queryString from 'query-string';
import qs from 'qs';

import {
  ENDPOINTS,
  CHART_COMMON,
  COMMON_REPORT_LIST,
  CHART_PLAN_VS_ACTUAL_KEY_PLAN,
  CHART_PLAN_VS_ACTUAL_KEY_ACTUAL
} from '../../../../constants';
import { handleError, Http } from '../../../../core';
import { jsonParse, buildQueryForFilterMongo, checkIsNotEmptyObject } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  testCaseExecutionProgressData: [],
  trendChartByStatusData: {},
  trendChartByUserData: [],
  mostExecutedTestcasesData: [],
  mostFailedTestcasesData: [],
  plannedVsExecutedData: {},
  accumulatedTestExecutionProgressData: {},
  accumulatedTestResultStatus: [],
  accumulatedBugSubmission: [],
  testSuccessfulCoverage: [],
  query: {},

  // Loading for each chart
  loadingReport: false,
  loadingReportMemberDaily: false,
  loadingReportBurndown: false,
  loadingReportMostExecuteTestCase: false,
  loadingReportMostFailTestCase: false,
  loadingReportPlanned: false,
  loadingReportTrendStatus: false,
  loadingReportTrendUser: false,

  loadingDashboardList: false,
  loadingDashboardItem: false,
  data: [],
  total: 0,
  dashboard: {},
  dashboardChart: [],
  testCaseDailyChart: [],
  dataBurndownChart: [],
  totalTestRunsBurndownChart: 0,

  /**
   * Action: Set data testing burndown chart
   *
   */
  setDataBurndownChart: action((state, payload) => {
    if (state?.dataBurndownChart === undefined) {
      return;
    }

    if (!payload && !Object.keys(payload).length) {
      state.dataBurndownChart = [];
      return;
    }

    state.dataBurndownChart = payload;
  }),

  /**
   * Action: Set data testing burndown chart
   *
   */
  setTotalTestRunBurndownChart: action((state, payload) => {
    state.totalTestRunsBurndownChart = payload;
  }),

  /**
   * Action: Set test case for daily chart
   *
   */
  setTestCaseDailyChart: action((state, payload) => {
    if (state?.testCaseDailyChart === undefined) {
      return;
    }

    if (!payload && !Object.keys(payload).length) {
      state.testCaseDailyChart = [];
      return;
    }

    state.testCaseDailyChart = payload;
  }),

  /**
   * Action: Set dashboard data
   */
  setDashboardData: action((state, payload) => {
    if (state?.dashboard === undefined) {
      return;
    }

    state.dashboard = payload;
  }),

  /**
   * Action: Set dashboard chart data
   */
  setDashboardChart: action((state, payload) => {
    if (state?.dashboardChart === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.dashboardChart = [];
      return;
    }

    state.dashboardChart = payload.filter(item => {
      if (item?.chartType === CHART_COMMON && !COMMON_REPORT_LIST?.some(v => v?.value === item?.chartId)) {
        return false;
      }

      return true;
    });
  }),

  /**
   * Action: Set test case execution progress data
   */
  setTestCaseExecutionProgressData: action((state, payload) => {
    if (state?.testCaseExecutionProgressData === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.testCaseExecutionProgressData = [];
      return;
    }

    const cloneTestCaseExecutionProgress = jsonParse(JSON.stringify(payload));

    state.testCaseExecutionProgressData = cloneTestCaseExecutionProgress
      .filter(item => item._id)
      .map((item, index) => {
        return {
          id: index + 1,
          plan: {
            id: item._id,
            name: item.groupBy
          },
          data: item.data
        };
      });
  }),

  /**
   * Action: Set trend chart by status data
   */
  setTrendChartByStatusData: action((state, payload) => {
    if (state?.trendChartByStatusData === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.trendChartByStatusData = {};
      return;
    }

    state.trendChartByStatusData = payload;
  }),

  /**
   * Action: Set trend chart by user data
   */
  setTrendChartByUserData: action((state, payload) => {
    if (state?.trendChartByUserData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.trendChartByUserData = [];
      return;
    }

    state.trendChartByUserData = payload.map(item => {
      return {
        ...item.user,
        count: item.count
      };
    });
  }),

  /**
   * Action: Set most executed test cases data
   */
  setMostExecutedTestCasesData: action((state, payload) => {
    if (state?.mostExecutedTestcasesData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostExecutedTestcasesData = [];
      return;
    }

    state.mostExecutedTestcasesData = payload;
  }),

  /**
   * Action: Set most failed test cases data
   */
  setMostFailedTestCasesData: action((state, payload) => {
    if (state?.mostFailedTestcasesData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostFailedTestcasesData = [];
      return;
    }

    state.mostFailedTestcasesData = payload;
  }),

  /**
   * Action: Set planned vs executed data
   */
  setPlannedVsExecutedData: action((state, payload) => {
    if (state?.plannedVsExecutedData === undefined) {
      return;
    }

    if (
      !(
        payload?.hasOwnProperty(CHART_PLAN_VS_ACTUAL_KEY_PLAN) ||
        payload?.hasOwnProperty(CHART_PLAN_VS_ACTUAL_KEY_ACTUAL)
      )
    ) {
      state.plannedVsExecutedData = {};
      return;
    }

    state.plannedVsExecutedData = payload;
  }),

  /**
   * Action: Set accumulate test execution progress
   */
  setAccumulatedTestExecutionProgress: action((state, payload) => {
    if (state?.accumulatedTestExecutionProgressData === undefined) {
      return;
    }

    if (
      !(
        payload?.hasOwnProperty(CHART_PLAN_VS_ACTUAL_KEY_PLAN) ||
        payload?.hasOwnProperty(CHART_PLAN_VS_ACTUAL_KEY_ACTUAL)
      )
    ) {
      state.accumulatedTestExecutionProgressData = {};
      return;
    }

    state.accumulatedTestExecutionProgressData = payload;
  }),

  /**
   * Action: Set accumulated test result status
   */
  setAccumulatedTestResultStatus: action((state, payload) => {
    if (state?.accumulatedTestResultStatus === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.accumulatedTestResultStatus = [];
      return;
    }

    state.accumulatedTestResultStatus = payload;
  }),

  /**
   * Action: Set accumulated bug submission
   */
  setAccumulatedBugSubmission: action((state, payload) => {
    if (state?.accumulatedBugSubmission === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.accumulatedBugSubmission = [];
      return;
    }

    state.accumulatedBugSubmission = payload;
  }),

  /**
   * Action: Set test successful coverage
   */
  setTestSuccessfulCoverage: action((state, payload) => {
    if (state?.testSuccessfulCoverage === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.testSuccessfulCoverage = [];
      return;
    }

    state.testSuccessfulCoverage = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set loading report
   */
  setLoadingReport: action((state, payload) => {
    if (state?.loadingReport === undefined) {
      return;
    }

    state.loadingReport = payload;
  }),

  /**
   * Action: Set loading member daily report
   */

  setLoadingReportMemberDaily: action((state, payload) => {
    if (state?.loadingReportMemberDaily === undefined) {
      return;
    }

    state.loadingReportMemberDaily = payload;
  }),

  /**
   * Action: Set loading burndown report
   */
  setLoadingReportBurndown: action((state, payload) => {
    if (state?.loadingReportBurndown === undefined) {
      return;
    }

    state.loadingReportBurndown = payload;
  }),

  /**
   * Action: Set loading most executed test case report
   */
  setLoadingReportMostExecuteTestCase: action((state, payload) => {
    if (state?.loadingReportMostExecuteTestCase === undefined) {
      return;
    }

    state.loadingReportMostExecuteTestCase = payload;
  }),

  /**
   * Action: Set loading most fail test case report
   */
  setLoadingReportMostFailTestCase: action((state, payload) => {
    if (state?.loadingReportMostFailTestCase === undefined) {
      return;
    }

    state.loadingReportMostFailTestCase = payload;
  }),

  /**
   * Action: Set loading planned report
   */
  setLoadingReportPlanned: action((state, payload) => {
    if (state?.loadingReportPlanned === undefined) {
      return;
    }

    state.loadingReportPlanned = payload;
  }),

  /**
   * Action: Set loading trend status report
   */
  setLoadingReportTrendStatus: action((state, payload) => {
    if (state?.loadingReportTrendStatus === undefined) {
      return;
    }

    state.loadingReportTrendStatus = payload;
  }),

  /**
   * Action: Set loading trend user report
   */
  setLoadingReportTrendUser: action((state, payload) => {
    if (state?.loadingReportTrendUser === undefined) {
      return;
    }

    state.loadingReportTrendUser = payload;
  }),

  /**
   * Action: Set loading dashboard list
   */
  setLoadingDashboardList: action((state, payload) => {
    if (state?.loadingDashboardList === undefined) {
      return;
    }

    state.loadingDashboardList = payload;
  }),

  /**
   * Action: Set loading dashboard item
   */
  setLoadingDashboardItem: action((state, payload) => {
    if (state?.loadingDashboardItem === undefined) {
      return;
    }

    state.loadingDashboardItem = payload;
  }),

  /**
   * Action: Call api to Get dashboard list
   */
  getDashboardData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get dashboard data: Invalid Params');
      }

      let isSetQuery = true;

      if (!payload?.isSetQuery) {
        isSetQuery = false;
      }

      action.setLoadingDashboardList(true);

      if (isSetQuery) {
        action.setQuery(payload);
      }

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}`;
      const res = await Http.get(url).then(res => res.data);

      // action.setDashboardData(res?.data);
      // action.setDashboardChart(res?.data?.charts);

      const charts = Array.isArray(res?.data?.charts) && res?.data?.charts.length ? [...res?.data?.charts] : [];
      const newData = {
        ...res?.data,
        charts: charts.filter(c => c?.chartId !== 'chart-testing-burndown') // To do burndown chart
      };

      action.setDashboardData(newData);
      action.setDashboardChart(newData?.charts);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to delete dashboard by id
   */
  deleteChartById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Delete chart by id: Invalid Params');
      }

      if (!payload) {
        throw new Error('Delete chart by id: No ID');
      }

      action.setLoadingDashboardList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}${ENDPOINTS.CHART}/${payload}`;
      const res = await Http.delete(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to delete dashboard by id
   */
  createDashboard: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Create dashboard: Invalid Params');
      }

      if (!payload) {
        throw new Error('Create dashboard: No Body');
      }
      action.setLoadingDashboardItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}${ENDPOINTS.CHART}`;
      const res = await Http.post(url, payload).then(res => res.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardItem(false);
    }
  }),

  /**
   * Action: Call api to Get test case execution progress list
   */
  getTestCaseExecutionProgressList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test case execution progress list: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_TEST_CASE_EXECUTION_PROGRESS
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseExecutionProgressData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to Get trend chart by status
   */
  getTrendChartByStatus: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart by status: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_TREND_CHART_BY_STATUS}?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTrendChartByStatusData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to Get trend chart by user
   */
  getTrendChartByUser: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart by user: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_TREND_CHART_BY_USER}?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTrendChartByUserData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to Get chart by most executed testcase
   */
  getTrendChartMostExecuted: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most executed: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_MOST_EXECUTED_TEST_CASES}?${buildQueryForFilterMongo(
        payload
      )}&sortExecutingTime=${payload?.sortExecutingTime}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostExecutedTestCasesData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to Get chart by most failed testcase
   */
  getTrendChartMostFailed: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most failed: Invalid Payload');
      }

      const sortDesOrAcs = payload?.order === 'DESC' ? -1 : 1;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_MOST_FAILED_TEST_CASES}?${buildQueryForFilterMongo(
        payload
      )}&sortExecutingTime=${sortDesOrAcs}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostFailedTestCasesData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to Get chart by plan and executed
   */
  getChartByPlanAndExecuted: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get chart by plan and executed: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_PLANNED_VS_EXECUTED}?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setPlannedVsExecutedData(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get accumulate test execution progress
   */
  getAccumulatedTestExecutionProgress: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get accumulate test execution progress: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_CHART_ACCUMULATED_TEST_EXECUTION_PROGRESS}?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAccumulatedTestExecutionProgress(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get accumulated test result status
   */
  getAccumulatedTestResultStatus: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get accumulated test result status: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_CHART_ACCUMULATED_TEST_RESULT_STATUS}?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAccumulatedTestResultStatus(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get accumulated bug submission
   */
  getAccumulatedBugSubmission: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get accumulated bug submission: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_CHART_ACCUMULATED_BUG_SUBMISSION}?${qs.stringify(payload)}`;
      // const res = await Http.get(url).then(res => res.data);

      const res = {
        status: 200,
        message: 'Success',
        data: {
          dataPlanning: [
            {
              _id: '66eab1c03b9be41608e6df8a',
              date: '2024-08-29T07:00:00.000Z'
            },
            {
              _id: '66eab1c03b9be41608e6df8a',
              date: '2024-08-29T08:00:00.000Z'
            },
            {
              _id: '66eab9333b9be41608e6dfae',
              date: '2024-08-30T07:00:00.000Z'
            },
            {
              _id: '66eab9333b9be41608e6dfae',
              date: '2024-08-30T09:00:00.000Z'
            },
            {
              _id: '66eab9333b9be41608e6dfaf',
              date: '2024-08-31T07:00:00.000Z'
            },
            {
              _id: '66eab9333b9be41608e6dfb0',
              date: '2024-09-04T07:00:00.000Z'
            },
            {
              _id: '66daafb36e466fba3fe4e86c',
              date: '2024-09-07T07:00:00.000Z'
            },
            {
              _id: '66ea59ad3b9be41608e6df69',
              date: '2024-09-08T07:00:00.000Z'
            },
            {
              _id: '66ea59ad3b9be41608e6df69',
              date: '2024-09-09T07:00:00.000Z'
            },
            {
              _id: '66ea59ad3b9be41608e6df69',
              date: '2024-09-09T07:00:00.000Z'
            },
            {
              _id: '66ea59ad3b9be41608e6df69',
              date: '2024-09-10T07:00:00.000Z'
            },
            {
              _id: '66ea59ad3b9be41608e6df69',
              date: '2024-09-12T07:00:00.000Z'
            }
          ],
          dataActual: [
            {
              _id: {
                statusId: 'system.wi.state.2b45',
                name: 'Open'
              },
              dates: [
                { date: '2024-08-27T10:59:12.493Z' },
                { date: '2024-08-27T11:59:12.493Z' },
                { date: '2024-08-28T10:59:12.493Z' },
                { date: '2024-08-28T11:59:12.493Z' },
                { date: '2024-08-28T12:59:12.493Z' },
                { date: '2024-08-28T13:59:12.493Z' },
                { date: '2024-08-28T14:59:12.493Z' },
                { date: '2024-08-29T05:00:52.198Z' },
                { date: '2024-08-29T06:00:52.198Z' },
                { date: '2024-08-29T10:00:52.198Z' },
                { date: '2024-08-29T06:42:41.970Z' },
                { date: '2024-08-29T07:42:41.970Z' },
                { date: '2024-08-29T08:42:41.970Z' },
                { date: '2024-08-30T06:42:43.469Z' },
                { date: '2024-08-30T07:42:43.469Z' },
                { date: '2024-08-30T08:42:43.469Z' },
                { date: '2024-08-30T09:42:43.469Z' },
                { date: '2024-08-30T10:42:43.469Z' },
                { date: '2024-08-31T06:42:44.726Z' },
                { date: '2024-08-31T07:42:44.726Z' },
                { date: '2024-08-31T08:42:44.726Z' },
                { date: '2024-08-31T09:42:44.726Z' },
                { date: '2024-08-31T10:42:44.726Z' },
                { date: '2024-09-01T06:42:48.677Z' },
                { date: '2024-09-01T07:42:48.677Z' },
                { date: '2024-09-01T08:42:48.677Z' },
                { date: '2024-09-01T09:42:48.677Z' },
                { date: '2024-09-01T10:42:48.677Z' },
                { date: '2024-09-02T06:42:48.677Z' },
                { date: '2024-09-02T06:42:50.677Z' },
                { date: '2024-09-02T06:42:51.677Z' },
                { date: '2024-09-02T06:42:52.677Z' },
                { date: '2024-09-03T06:42:48.635Z' },
                { date: '2024-09-03T06:42:48.676Z' },
                { date: '2024-09-03T06:42:48.675Z' },
                { date: '2024-09-03T06:42:48.632Z' },
                { date: '2024-09-03T06:42:48.687Z' },
                { date: '2024-09-04T06:42:48.671Z' },
                { date: '2024-09-04T06:42:48.672Z' },
                { date: '2024-09-04T06:42:48.673Z' },
                { date: '2024-09-04T06:42:48.674Z' },
                { date: '2024-09-05T06:42:48.675Z' },
                { date: '2024-09-05T06:42:48.676Z' },
                { date: '2024-09-05T06:42:48.677Z' },
                { date: '2024-09-05T06:42:48.678Z' },
                { date: '2024-09-06T06:42:48.676Z' },
                { date: '2024-09-06T06:42:48.674Z' },
                { date: '2024-09-06T06:42:48.673Z' },
                { date: '2024-09-07T06:42:48.677Z' },
                { date: '2024-09-07T06:42:48.677Z' },
                { date: '2024-09-08T06:42:48.677Z' },
                { date: '2024-09-08T06:42:48.677Z' },
                { date: '2024-09-09T06:42:48.677Z' },
                { date: '2024-09-10T06:42:48.677Z' },
                { date: '2024-09-10T06:42:48.677Z' },
                { date: '2024-09-11T06:42:48.677Z' },
                { date: '2024-09-11T06:42:48.677Z' },
                { date: '2024-09-12T06:42:48.677Z' },
                { date: '2024-09-13T06:42:48.677Z' },
                { date: '2024-09-14T06:42:48.677Z' },
                { date: '2024-09-15T06:42:48.677Z' },
                { date: '2024-09-16T06:42:48.677Z' },
                { date: '2024-09-17T06:42:48.677Z' },
                { date: '2024-09-18T06:42:48.677Z' },
                { date: '2024-09-19T06:42:48.677Z' },
                { date: '2024-09-20T06:42:48.677Z' },
                { date: '2024-09-21T06:42:48.677Z' }
              ]
            },
            {
              _id: {
                statusId: 'system.wi.state.20dc',
                name: 'Closed-Valid'
              },
              dates: [
                { date: '2024-08-29T08:41:17.699Z' },
                { date: '2024-08-30T10:00:33.998Z' },
                { date: '2024-08-30T11:00:41.943Z' },
                { date: '2024-08-31T04:14:53.156Z' },
                { date: '2024-09-02T03:14:53.156Z' },
                { date: '2024-09-04T03:14:53.156Z' },
                { date: '2024-09-05T03:14:53.156Z' },
                { date: '2024-09-05T10:14:53.156Z' },
                { date: '2024-09-06T06:14:53.156Z' },
                { date: '2024-09-07T03:14:53.156Z' },
                { date: '2024-09-07T06:14:53.156Z' },
                { date: '2024-09-09T03:14:53.156Z' },
                { date: '2024-09-09T03:14:53.156Z' },
                { date: '2024-09-10T03:14:53.156Z' },
                { date: '2024-09-12T03:14:53.156Z' },
                { date: '2024-09-12T04:14:53.156Z' },
                { date: '2024-09-12T05:14:53.156Z' },
                { date: '2024-09-22T05:14:53.156Z' }
              ]
            },
            {
              _id: {
                statusId: 'system.wi.state.0baa',
                name: 'Closed-Invalid'
              },
              dates: [{ date: '2024-08-30T03:52:20.465Z' }, { date: '2024-09-03T09:59:03.610Z' }]
            }
          ]
        }
      };

      const resx = {
        status: 200,
        message: 'Success',
        data: {
          dataPlanning: [
            {
              date: '2024-08-27',
              count: 0
            },
            {
              date: '2024-08-28',
              count: 0
            },
            {
              date: '2024-08-29',
              count: 2
            },
            {
              date: '2024-08-30',
              count: 4
            },
            {
              date: '2024-08-31',
              count: 5
            },
            {
              date: '2024-09-01',
              count: 5
            },
            {
              date: '2024-09-02',
              count: 5
            },
            {
              date: '2024-09-03',
              count: 5
            },
            {
              date: '2024-09-04',
              count: 6
            },
            {
              date: '2024-09-05',
              count: 6
            },
            {
              date: '2024-09-06',
              count: 6
            },
            {
              date: '2024-09-07',
              count: 8
            },
            {
              date: '2024-09-08',
              count: 9
            },
            {
              date: '2024-09-09',
              count: 10
            },
            {
              date: '2024-09-10',
              count: 10
            }
          ],
          dataActual: [
            {
              date: '2024-08-27',
              status: {}
            },
            {
              date: '2024-08-28',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 0
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 1
                },
                'system.wi.state.0baa': {
                  count: 0
                }
              }
            },
            {
              date: '2024-08-29',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 1
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 3
                },
                'system.wi.state.0baa': {
                  count: 0
                }
              }
            },
            {
              date: '2024-08-30',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 3
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 3
                },
                'system.wi.state.0baa': {
                  count: 1
                }
              }
            },
            {
              date: '2024-08-31',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 4
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 5
                },
                'system.wi.state.0baa': {
                  count: 1
                }
              }
            },
            {
              date: '2024-09-01',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 5
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 5
                },
                'system.wi.state.0baa': {
                  count: 1
                }
              }
            },
            {
              date: '2024-09-02',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 6
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 4
                },
                'system.wi.state.0baa': {
                  count: 1
                }
              }
            },
            {
              date: '2024-09-03',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 6
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 5
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-04',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 7
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 5
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-05',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 8
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 4
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-06',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 10
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 3
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-07',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 11
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 2
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-08',
              status: {
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 12
                },
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 2
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-09',
              status: {
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 1
                },
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 13
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            },
            {
              date: '2024-09-10',
              status: {
                'system.wi.state.2b45': {
                  name: 'Open',
                  count: 1
                },
                'system.wi.state.20dc': {
                  name: 'Closed',
                  count: 15
                },
                'system.wi.state.0baa': {
                  count: 2
                }
              }
            }
          ]
        }
      };

      action.setAccumulatedBugSubmission(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to get test successful coverage
   */
  getTestSuccessfulCoverage: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test successful coverage: Invalid Payload');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_CHART_TEST_SUCCESSFUL_COVERAGE}?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      let res2 = null;

      if (payload?.isShowPercentage) {
        res2 = {
          status: 200,
          message: 'Success',
          data: [
            {
              data: [
                {
                  label: { display: 'Manual', value: 'manual' },
                  passPercent: 33.33,
                  failPercent: 66.67
                },
                {
                  label: { display: 'Automation', value: 'automation' },
                  passPercent: 100,
                  failPercent: 0
                }
              ],
              totalData: 10,
              totalPassPercent: 20,
              name: 'subCreateManual',
              startDate: '2024-01-23T17:00:00.000Z',
              endDate: '2025-03-31T16:59:59.999Z'
            },
            {
              data: [
                {
                  label: {
                    display: 'Manual',
                    value: 'manual'
                  },
                  passPercent: 0,
                  failPercent: 100
                }
              ],
              totalData: 4,
              totalPassPercent: 0,
              name: 'tqhuy',
              startDate: '2024-04-04T17:00:00.001Z',
              endDate: '2025-05-31T16:59:59.999Z'
            },
            {
              data: [
                {
                  label: {
                    display: 'Manual',
                    value: 'manual'
                  },
                  passPercent: 50,
                  failPercent: 50
                },
                {
                  label: {
                    display: 'Automation',
                    value: 'automation'
                  },
                  passPercent: 100,
                  failPercent: 0
                }
              ],
              totalData: 7,
              totalPassPercent: 28.57,
              name: 'fpt-hue-x1 fpt-hue-x1 fpt-hue-x1 ',
              startDate: '2024-09-11T17:00:00.000Z',
              endDate: '2024-09-20T16:59:59.999Z'
            }
          ]
        };
      } else {
        const name1 = 'Round 1 lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ';
        const name2 = 'Round 1 lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ';
        const name3 = 'Round 1 lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem ';

        res2 = {
          status: 200,
          message: 'Success',
          data: [
            {
              label: {
                display: 'All',
                value: 'all'
              },
              data: [
                {
                  x: name1,
                  y: 20,
                  startDate: '2024-04-04T17:00:00.002Z',
                  endDate: '2025-05-31T16:59:59.999Z'
                },
                { x: 'Round 2', y: 28, startDate: '2024-04-04T17:00:00.003Z', endDate: '2025-05-31T16:59:59.998Z' },
                { x: 'Round 3', y: 29, startDate: '2024-04-04T17:00:00.004Z', endDate: '2025-05-31T16:59:59.997Z' },
                { x: 'Round 4', y: 36, startDate: '2024-04-04T17:00:00.005Z', endDate: '2025-05-31T16:59:59.996Z' },
                { x: 'Round 5', y: 40, startDate: '2024-04-04T17:00:00.006Z', endDate: '2025-05-31T16:59:59.995Z' }
              ]
            },
            {
              label: {
                display: 'Manual',
                value: 'manual'
              },
              data: [
                { x: name2, y: 15 },
                { x: 'Round 2', y: 20 },
                { x: 'Round 3', y: 22 },
                { x: 'Round 4', y: 26 },
                { x: 'Round 5', y: 30 }
              ]
            },
            {
              label: {
                display: 'Automation',
                value: 'automation'
              },
              data: [
                { x: name3, y: 5 },
                { x: 'Round 2', y: 8 },
                { x: 'Round 3', y: 7 },
                { x: 'Round 4', y: 10 },
                { x: 'Round 5', y: 12 }
              ]
            }
          ]
        };
      }

      action.setTestSuccessfulCoverage(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to update chart list
   */
  updateChartList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update chart list: Invalid projectKey, tenantKey');
      }

      if (!(Array.isArray(payload) && payload.length)) {
        throw new Error('Update chart list: Invalid payload');
      }

      action.setLoadingDashboardList(true);

      const newPayload = payload.map(el => {
        return {
          name: el.name,
          chartId: el.chartId,
          chartType: el.chartType,
          status: el.status
        };
      });
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.DASHBOARD}/chart`;

      await Http.put(url, newPayload).then(res => res.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingDashboardList(false);
    }
  }),

  /**
   * Action: Call api to get test run list
   */
  getTestCaseFromRunList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test case from run list: Invalid Payload');
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const newPayload = {
        user: payload.user,
        today: payload.dateSelected,
        tenantKey: globalTenant?.tenantKey,
        projectKey: globalProject?.projectKey
      };

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_DAILY_MEMBER}?${qs.stringify(newPayload)}`;

      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseDailyChart(res?.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  }),

  // Action Testing burndown chart

  getDataBurndownChart: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get data burndown chart: Invalid Payload');
      }
      if (!payload?.filter?.path || !payload?.filter?.from || !payload?.filter?.to) {
        // throw new Error('Get data burndown chart: Invalid Payload');
        return;
      }

      if (!payload?.notSetQuery) {
        action.setLoadingReport(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.REPORTING}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }/report/chart${ENDPOINTS.REPORT_TESTING_BURNDOWN}?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      await action.setDataBurndownChart(res?.data?.dataChart);
      action.setTotalTestRunBurndownChart(res?.data?.totalTestPlan);
    } catch (err) {
      handleError(err);
    } finally {
      if (!payload?.notSetQuery) {
        action.setLoadingReport(false);
      }
    }
  })
};
