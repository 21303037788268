import React from 'react';
import { useTranslation } from 'react-i18next';

const BoxFilterBtns = ({ projectFilters, productCountInfo, onChangeFilter }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <ul className="box-filter-btns">
      <li className="my-1 mr-3">
        <button
          className={`${projectFilters?.status === 'ALL' ? 'active' : ''}`}
          onClick={() => onChangeFilter({ ...projectFilters, status: 'ALL' })}
        >
          {t('home.allProjects')}
          <span className="txt-count">{productCountInfo?.all || 0}</span>
        </button>
      </li>

      <li className="my-1 mr-3">
        <button
          className={`${projectFilters?.status === 'ACTIVE' ? 'active' : ''}`}
          onClick={() => onChangeFilter({ ...projectFilters, status: 'ACTIVE' })}
        >
          {t('common.active')}
          <span className="txt-count">{productCountInfo?.active || 0}</span>
        </button>
      </li>

      <li className="my-1 mr-3">
        <button
          className={`${projectFilters?.status === 'INACTIVE' ? 'active' : ''}`}
          onClick={() => onChangeFilter({ ...projectFilters, status: 'INACTIVE' })}
        >
          {t('common.inactive')}
          <span className="txt-count">{productCountInfo?.inactive || 0}</span>
        </button>
      </li>
    </ul>
  );
};

export default BoxFilterBtns;
