import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Tooltip, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import {
  DATA_TYPE,
  COMPONENT_TYPE,
  OPERATION_MAPPING,
  SYSTEM_FIELD_VERSION,
  SYSTEM_FIELD_TEST_CONFIG,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_STATUS,
  JIRA_PLATFORM_ID,
  TESTMAN_PLATFORM_ID,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TEST_RESULT_ID,
  WORK_ITEM_TESTCASE_ID,
  OPERATION_VALUE_REGEX,
  OPERATION_VALUE_IN,
  OPERATION_VALUE_AND,
  OPERATION_VALUE_IN_RANGE,
  OPERATION_VALUE_NOT_IN_RANGE,
  OPERATION_VALUE_LTE,
  OPERATION_VALUE_GTE,
  OPERATION_VALUE_GT,
  OPERATION_VALUE_LT,
  OPERATION_VALUE_NOT
} from '../../constants';
import {
  parseCodeQSKeepType,
  getKeyByValueInObject,
  convertEstimatedTimeToMinutes,
  checkIsNotEmptyObject,
  checkValidMomentDateString,
  checkIsNotEmptyArray,
  getFieldName
} from '../../common/utils';
import { useIntegrationSystem } from '../../common/hooks';
import { ClearFilter } from '../../assets/svg-icons';
import { MoreDropdown } from './more-dropdown';
import { FieldStringNumber } from './field-string-number';
import { FieldDate } from './field-date';
import { FieldSelect } from './field-select';
import { FieldStatus } from './field-status';
import { FieldSuggestion } from './field-suggestion';
import { FieldUser } from './field-user';
import { FieldTestPlanning } from './field-test-planning';
import { FieldFolder } from './field-folder';
import { FieldPriority } from './field-priority';
import { FieldRelation } from './field-relation';

import './style.scss';

export const BasicManagerSearch = ({
  workTicketId,
  fieldList,
  fieldConfig = {},
  filterProp,
  allowChangeQueryParamsUrl = true,
  noPageParam,
  loading,
  disabledFilter,
  className = '',
  onChangeFilter,
  onSearch,
  functionsInside,
  onBeforeSearchChange,
  ...rest
}) => {
  const [searchForm] = Form.useForm();

  // For query params on url
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    filter: StringParam
  });

  // For language
  const [t] = useTranslation('akaat');

  // For hooks
  useIntegrationSystem();

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const setSuggestion = useStoreActions(action => action.global.setSuggestion);

  // For integration store
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);
  const getWorkflowStatusList = useStoreActions(action => action.jiraIntegration.getWorkflowStatusList);
  const getPriorityList = useStoreActions(action => action.jiraIntegration.getPriorityList);
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For test data list
  const loadingFieldList = useStoreState(state => state.global.loadingFieldList);

  // Component state
  const [isSetCurrentValues, setIsSetCurrentValues] = useState(false);
  const [currentValues, setCurrentValues] = useState([]); // Example: [{ referenceField: 'status', value: ['PASS'] }]
  const [currentMoreFieldRefNames, setCurrentMoreFieldRefNames] = useState([]);
  const [filter, setFilter] = useState('');
  const [fieldData, setFieldData] = useState([]);
  const [defaultShow, setDefaultShow] = useState([]);
  const [defaultSystem, setDefaultSystem] = useState();

  /**
   * Has field data
   */
  const hasFieldData = useMemo(() => {
    return Array.isArray(fieldData) && fieldData.length > 0;
  }, [fieldData]);

  /**
   * Has current more field ref names
   */
  const hasCurrentMoreFieldRefNames = useMemo(() => {
    return Array.isArray(currentMoreFieldRefNames) && currentMoreFieldRefNames.length > 0;
  }, [currentMoreFieldRefNames]);

  /**
   * Is test ticket
   */
  const isTestTicket = useMemo(() => {
    return (
      workTicketId === WORK_ITEM_TESTCASE_ID ||
      workTicketId === WORK_ITEM_TEST_RUN_ID ||
      workTicketId === WORK_ITEM_TEST_RESULT_ID
    );
  }, [workTicketId]);

  /**
   * Set functions inside
   * For: Call child function from parent component
   */
  useEffect(() => {
    if (functionsInside) {
      functionsInside.current = {
        handleSearchChange
      };
    }
  }, [functionsInside]);

  /**
   * Handle search change
   */
  const handleSearchChange = (filter, moreInfo) => {
    if (disabledFilter) return;

    if (functionsInside && typeof onBeforeSearchChange === 'function' && !moreInfo?.isConfirmedBeforeChange) {
      onBeforeSearchChange(filter);
      return;
    }

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter });
    } else if (typeof onSearch === 'function') {
      onSearch({ filterString: filter });
    } else {
    }

    typeof onChangeFilter === 'function' && onChangeFilter(filter);
  };

  /**
   * Set default system
   */
  useEffect(() => {
    if (!(Array.isArray(integrationSystemList) && integrationSystemList.length)) {
      return;
    }

    const newDefaultSystem = integrationSystemList?.find(item => item?.id === TESTMAN_PLATFORM_ID);

    if (!newDefaultSystem?.id) {
      return;
    }

    if (integrationSystemList?.some(item => item?.id === JIRA_PLATFORM_ID)) {
      getWorkflowStatusList();
      getPriorityList();
      getAssignableUserList({
        username: ''
      });
    }

    setDefaultSystem(newDefaultSystem?.id);
  }, [integrationSystemList]);

  /**
   * Get data for jira
   */
  useEffect(() => {
    if (!integrationSystemList?.some(item => item?.id === JIRA_PLATFORM_ID)) {
      return;
    }

    getWorkflowStatusList();
    getPriorityList();
    getAssignableUserList({
      username: ''
    });
  }, [integrationSystemList]);

  /**
   * Get field util
   */
  const getFieldUtil = field => {
    let finalFiled = null;
    const newField = { ...field };

    if (field?.componentType === COMPONENT_TYPE.RELATION && field?.refName === SYSTEM_FIELD_LATEST_RESULT) {
      newField.componentType = COMPONENT_TYPE.STATUS;
      newField.dataType = DATA_TYPE.STATUS;
    }

    if (field?.componentType === SYSTEM_FIELD_STATUS && field?.workTicketId) {
      newField.data = {
        ...newField.data,
        ...ticketListData[field?.workTicketId]?.workFlow,
        workTicketId: field?.workTicketId
      };
    }

    if (
      field?.data?.fieldValue &&
      (field?.dataType === DATA_TYPE.USER ||
        field?.dataType === DATA_TYPE.STATUS ||
        field?.refName === SYSTEM_FIELD_TEST_CONFIG)
    ) {
      let newRefName = `${field?.refName}.${field?.data?.fieldValue}`;

      if (field?.refName === SYSTEM_FIELD_LATEST_RESULT) {
        newField.componentType = COMPONENT_TYPE.STATUS;
        newField.dataType = DATA_TYPE.STATUS;
        newRefName = `${field?.refName}.${field?.data?.fieldValue}`;
      }

      finalFiled = { ...newField, refName: newRefName, originRefName: field?.refName };
    } else {
      finalFiled = { ...newField, originRefName: field?.refName };
    }

    return finalFiled;
  };

  /**
   * Set field list
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return;
    }

    const allField = [];
    let defaultList = [];

    fieldList.forEach(field => {
      if (field?.invisible) {
        return;
      }

      const newField = getFieldUtil(field);
      allField.push(newField);
    });

    if (!Array.isArray(fieldConfig?.defaultShow) || !fieldConfig?.defaultShow?.length) {
      return;
    }

    defaultList = fieldConfig.defaultShow
      .map(field => {
        return {
          ...allField.find(d => d?.originRefName === field?.refName || d?.refName === field?.refName),
          isDefaultSearch: true
        };
      })
      .filter(field => field?.refName);

    setDefaultShow(defaultList);
    setFieldData(allField);
  }, [fieldList, fieldConfig]);

  /**
   * Handle set current values utils
   */
  const handleSetCurrentValuesUtilCheckString = async ({ found, filterValue }) => {
    const condition1 = [
      COMPONENT_TYPE.STRING,
      COMPONENT_TYPE.URL,
      COMPONENT_TYPE.NUMBER,
      COMPONENT_TYPE.HTML,
      COMPONENT_TYPE.TIME_TRACKING
    ].includes(found?.componentType);

    const condition2 = checkIsNotEmptyObject(filterValue);

    return condition1 && condition2;
  };
  const handleSetCurrentValuesUtilCheckDateDateTime = async found => {
    return found?.componentType === COMPONENT_TYPE.DATE || found?.componentType === COMPONENT_TYPE.DATE_TIME;
  };
  const handleSetCurrentValuesUtilCheckPicklist = async ({ found, filterValue }) => {
    const condition1 = [
      COMPONENT_TYPE.PICKLIST,
      COMPONENT_TYPE.SUGGESTION,
      COMPONENT_TYPE.RELATION,
      COMPONENT_TYPE.PRIORITY,
      COMPONENT_TYPE.OPTION,
      COMPONENT_TYPE.STATUS,
      COMPONENT_TYPE.USER
    ].includes(found?.componentType);

    const condition2 = Array.isArray(filterValue[OPERATION_VALUE_IN]) && filterValue[OPERATION_VALUE_IN].length;

    return condition1 && condition2;
  };
  const getCurrentValuesForOperationAnd = ({ filterValue, fieldList, emitCurrentValues }) => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return;
    }

    filterValue.forEach(value => {
      const field = fieldList.find(f => f?.refName === Object.keys(value)[0]);

      if (!field) {
        return;
      }

      const operationLabel = getKeyByValueInObject(OPERATION_VALUE_AND, OPERATION_MAPPING);

      emitCurrentValues({
        refName: field?.refName,
        value,
        componentType: field?.componentType,
        operation: operationLabel
      });
    });
  };
  const getCurrentValuesForString = ({ key, found, filterValue, emitCurrentValues }) => {
    const newValue = /\.\*(.*)\.\*/.exec(Object.values(filterValue)[0])?.[1] ?? Object.values(filterValue)[0];
    const operationValue = Object.keys(filterValue)[0];
    const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

    emitCurrentValues({
      refName: key,
      value: typeof newValue === 'object' ? Object.values(newValue) : newValue,
      componentType: found?.componentType,
      operation: operationLabel
    });
  };
  const getLteGteLtGtValues = filterValue => {
    const lteValue = Object.keys(filterValue)?.includes(OPERATION_VALUE_LTE)
      ? filterValue?.[OPERATION_VALUE_LTE]
      : null;
    const gteValue = Object.keys(filterValue)?.includes(OPERATION_VALUE_GTE)
      ? filterValue?.[OPERATION_VALUE_GTE]
      : null;
    const ltValue = filterValue?.[OPERATION_VALUE_NOT]
      ? Object.keys(filterValue?.[OPERATION_VALUE_NOT])?.includes(OPERATION_VALUE_LT)
        ? filterValue?.[OPERATION_VALUE_NOT]?.[OPERATION_VALUE_LT]
        : null
      : null;
    const gtValue = filterValue?.[OPERATION_VALUE_NOT]
      ? Object.keys(filterValue?.[OPERATION_VALUE_NOT])?.includes(OPERATION_VALUE_GT)
        ? filterValue?.[OPERATION_VALUE_NOT]?.[OPERATION_VALUE_GT]
        : null
      : null;

    return {
      lteValue,
      gteValue,
      ltValue,
      gtValue
    };
  };
  const getCurrentValuesForDateDatetime = ({ key, found, filterValue, emitCurrentValues }) => {
    const vals = getLteGteLtGtValues(filterValue);
    const lteValue = vals?.lteValue;
    const gteValue = vals?.gteValue;
    const ltValue = vals?.ltValue;
    const gtValue = vals?.gtValue;

    const singleDateVal = Object.values(filterValue)[0];

    // For in range
    if (checkValidMomentDateString([lteValue, gteValue])) {
      emitCurrentValues({
        refName: key,
        value: [moment(gteValue).local(), moment(lteValue).local()],
        componentType: found?.componentType,
        operation: OPERATION_VALUE_IN_RANGE
      });
    }

    // For not in range
    if (checkValidMomentDateString([ltValue, gtValue])) {
      emitCurrentValues({
        refName: key,
        value: [moment(gtValue).local(), moment(ltValue).local()],
        componentType: found?.componentType,
        operation: OPERATION_VALUE_NOT_IN_RANGE
      });
    }

    // For other: Example: =, <, >, <=, >=
    else if (checkValidMomentDateString(singleDateVal)) {
      const operationValue = Object.keys(filterValue)[0];
      const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

      emitCurrentValues({
        refName: key,
        value: moment(singleDateVal).local(),
        componentType: found.componentType,
        operation: operationLabel
      });
    } else {
    }
  };
  const getCurrentValuesForPicklist = async ({ key, found, filterValue, emitCurrentValues }) => {
    const operationLabel = getKeyByValueInObject(OPERATION_VALUE_IN, OPERATION_MAPPING);

    emitCurrentValues({
      refName: key,
      value: filterValue[OPERATION_VALUE_IN],
      componentType: found.componentType,
      operation: operationLabel
    });

    if (found.data?.url && found.originRefName !== SYSTEM_FIELD_LATEST_RESULT) {
      await getSuggestion({
        refName: key,
        url: found.data.url,
        page: null,
        limit: null,
        order: null,
        group: found.data.fieldValue,
        ...(found.componentType === COMPONENT_TYPE.RELATION
          ? { filter: { 'workTicketType.id': found.lookup.workTicketId } }
          : {})
      });
    }
  };

  /**
   * No action when handle set current values
   */
  const noActionWhenHandleSetCurrentValues = ({ params, hasFieldData }) => !params || !hasFieldData;

  /**
   * Handle set current values
   */
  const handleSetCurrentValues = async params => {
    if (noActionWhenHandleSetCurrentValues({ params, hasFieldData })) {
      return;
    }

    setFilter(params.filter);

    // Parse value filter keep type
    const filterObj = parseCodeQSKeepType(params.filter);
    const newCurrentValues = [];

    for (let index = 0; index < Object.keys(filterObj).length; index++) {
      let key = Object.keys(filterObj)[index];
      const filterValue = filterObj[key];

      if (key === OPERATION_MAPPING.and) {
        getCurrentValuesForOperationAnd({
          filterValue,
          fieldList,
          emitCurrentValues: val => newCurrentValues.push(val)
        });

        continue;
      }

      let found = fieldData.find(fi => fi.refName === key);

      if (found === undefined) {
        key = SYSTEM_FIELD_LATEST_RESULT;
        found = fieldData.find(fi => fi.refName === key);
      }

      if (!found) {
        return null;
      }

      if (JSON.stringify(filterValue) == JSON.stringify(OPERATION_MAPPING.isNull)) {
        newCurrentValues.push({
          refName: key,
          value: null,
          componentType: found.componentType,
          operation: 'isNull'
        });
      }

      // For string, number
      else if (handleSetCurrentValuesUtilCheckString({ found, filterValue })) {
        getCurrentValuesForString({ key, found, filterValue, emitCurrentValues: val => newCurrentValues.push(val) });
      }

      // For date, datetime
      else if (handleSetCurrentValuesUtilCheckDateDateTime(found)) {
        getCurrentValuesForDateDatetime({
          key,
          found,
          filterValue,
          emitCurrentValues: val => newCurrentValues.push(val)
        });
      }

      // For picklist
      else if (handleSetCurrentValuesUtilCheckPicklist({ found, filterValue })) {
        await getCurrentValuesForPicklist({
          key,
          found,
          filterValue,
          emitCurrentValues: val => newCurrentValues.push(val)
        });
      } else {
      }
    }

    setCurrentValues(newCurrentValues);
    handleSetCurrentMoreFieldRefNames(filterObj);
  };

  const getCurrentMoreFieldRefNames = ({
    key,
    found,
    filterObj,
    newFieldData,
    newCurrentMoreFieldRefNames,
    emitCurrentMoreFieldRefNames
  }) => {
    if (key === OPERATION_MAPPING.and) {
      filterObj[key].map(obj => {
        const folderField = newFieldData.find(field => field?.refName === Object.keys(obj)[0]);

        if (!defaultShow?.some(s => s?.refName == Object.keys(obj)[0]) && folderField?.refName) {
          emitCurrentMoreFieldRefNames(folderField?.refName);
        }
      });
    } else if (found && !newCurrentMoreFieldRefNames.includes(found?.refName)) {
      emitCurrentMoreFieldRefNames(found?.refName);
    } else {
    }
  };

  /**
   * Handle set current more field ref names
   */
  const handleSetCurrentMoreFieldRefNames = filterObj => {
    const newCurrentMoreFieldRefNames = hasCurrentMoreFieldRefNames ? [...currentMoreFieldRefNames] : [];
    const newFieldData = hasFieldData ? [...fieldData] : [];

    if (filterObj) {
      Object.keys(filterObj).forEach(key => {
        const found = newFieldData.find(
          item => !defaultShow?.some(s => s?.refName === item?.refName) && item?.refName === key
        );

        getCurrentMoreFieldRefNames({
          key,
          found,
          filterObj,
          newFieldData,
          newCurrentMoreFieldRefNames,
          emitCurrentMoreFieldRefNames: val => newCurrentMoreFieldRefNames.push(val)
        });
      });
    }

    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
  };

  /**
   * Watching change of queryParams on url
   */
  useEffect(() => {
    if (!queryParams || !hasFieldData) {
      return;
    }

    const newQuery = {};
    const newQueryParams = allowChangeQueryParamsUrl ? queryParams : { filter: filterProp };

    if (newQueryParams?.filter) {
      newQuery.filter = newQueryParams?.filter;
    }

    if (!isSetCurrentValues) {
      handleSetCurrentValues(newQuery);
      setIsSetCurrentValues(true);

      return;
    } else if (
      // ==========> For change query params on url
      newQuery?.filter !== filter
    ) {
      handleSetCurrentValues(newQuery);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldData,
    searchForm,
    queryParams,
    filterProp,
    filter,
    isSetCurrentValues,
    setQueryParams,
    allowChangeQueryParamsUrl,
    hasFieldData,
    hasCurrentMoreFieldRefNames
  ]);

  /**
   * Convert to filter string utils
   */
  const checkInvalidValueIsNull = ({ operation, value }) => {
    return operation !== 'isNull' && (value === null || value === undefined);
  };
  const getFilterStringForDate = ({ value }) => {
    let newValue = value;

    if (Array.isArray(value) && value.length === 2 && value.every(sub => sub && moment(sub).isValid())) {
      newValue = [moment.utc(value[0].startOf('day')).format(), moment.utc(value[1].endOf('day')).format()];
    } else if (value && moment(value).isValid()) {
      newValue = moment.utc(value).format();
    } else {
    }

    return newValue;
  };
  const getFilterStringForDatetime = ({ value }) => {
    let newValue = value;

    if (Array.isArray(value) && value.every(sub => sub && moment(sub).isValid())) {
      newValue = value.map(val => moment.utc(val).format('YYYY-MM-DDTHH:mm[Z]'));
    } else if (value && moment(value).isValid()) {
      newValue = moment.utc(value).format('YYYY-MM-DDTHH:mm[Z]');
    } else {
    }

    return newValue;
  };
  const getFilterStringForDateOrDatetime = ({ item, value }) => {
    let newValue = value;

    if (item?.operation === OPERATION_VALUE_IN_RANGE && Array.isArray(item?.value) && item?.value.length) {
      newValue = {
        [OPERATION_VALUE_GTE]: value[0], // >=
        [OPERATION_VALUE_LTE]: value[1] // <=
      };
    } else if (item?.operation === OPERATION_VALUE_NOT_IN_RANGE && Array.isArray(item?.value) && item?.value.length) {
      newValue = {
        [OPERATION_VALUE_NOT]: {
          [OPERATION_VALUE_GT]: value[0], // <
          [OPERATION_VALUE_LT]: value[1] // >
        }
      };
    } else if (item?.operation === 'isNull') {
      newValue = OPERATION_MAPPING.isNull;
    } else {
      newValue = {
        [OPERATION_MAPPING[item?.operation]]: value
      };
    }

    return newValue;
  };
  const getFilterStringForString = ({ item, operation, value }) => {
    let newValue = value;

    if (item?.operation === '~') {
      const val =
        item?.componentType === COMPONENT_TYPE.FOLDER || item?.componentType === COMPONENT_TYPE.TEST_PLANNING
          ? value
          : `.*${value}.*`;
      newValue = { [OPERATION_VALUE_REGEX]: val, $options: 'i' };
    } else if (item?.operation === 'isNull') {
      newValue = operation;
    } else {
      newValue = { [operation]: value };
    }

    return newValue;
  };
  const getFilterStringForOtherCase = ({ item, operation, value, queryObj }) => {
    let newValue = value;

    if (operation === OPERATION_MAPPING.and) {
      if (queryObj[operation]) {
        queryObj[operation] = [...queryObj[operation], value];
      } else {
        queryObj[operation] = [value];
      }
    } else if (item?.operation === 'isNull') {
      newValue = operation;
    } else if (item?.operation === '~') {
      newValue = { [OPERATION_VALUE_REGEX]: `.*${value}.*`, $options: 'i' };
    } else if (item?.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      newValue = { [operation]: convertEstimatedTimeToMinutes(value) };
    } else {
      newValue = { [operation]: value };
    }

    return newValue;
  };

  /**
   * Convert to filter string
   */
  const convertToFilterString = values => {
    if (!checkIsNotEmptyArray(values)) {
      return;
    }

    const queryObj = {};

    for (let index = 0; index < values.length; index++) {
      const item = values[index];

      const operation = OPERATION_MAPPING[item?.operation];
      let value = item?.value;

      if (!operation) {
        queryObj[item?.refName] = value;
        break;
      }

      if (checkInvalidValueIsNull({ operation: item?.operation, value })) {
        break;
      }

      if (item?.componentType === COMPONENT_TYPE.DATE) {
        value = getFilterStringForDate({ value });
      }

      if (item?.componentType === COMPONENT_TYPE.DATE_TIME) {
        value = getFilterStringForDatetime({ value });
      }

      if ([COMPONENT_TYPE.DATE, COMPONENT_TYPE.DATE_TIME].includes(item?.componentType)) {
        value = getFilterStringForDateOrDatetime({ item, value });
        queryObj[item?.refName] = value;
        continue;
      }

      if ([COMPONENT_TYPE.STRING, COMPONENT_TYPE.FOLDER, COMPONENT_TYPE.TEST_PLANNING].includes(item?.componentType)) {
        value = getFilterStringForString({ item, operation, value });
        queryObj[item?.refName] = value;
        continue;
      }

      // for field nest operator under operator(example: {$in:[{$regex}]})
      value = getFilterStringForOtherCase({ item, operation, value, queryObj });
      queryObj[item?.refName] = value;
    }

    const newFilter = qs.stringify(queryObj, { encode: false }) || undefined;

    return newFilter;
  };

  /**
   * On apply
   */
  const onApply = ({ field, value, operation }) => {
    if (!field?.refName) {
      return;
    }
    let values = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];

    const handleRefName = refName => {
      if (refName === 'latestResult') {
        return 'latestResult.status.id'; //check in db(test-runs table to see status id)
      }
      return refName;
    };

    const newVal = {
      refName: handleRefName(field?.refName),
      componentType: field?.componentType,
      value,
      operation
    };

    values = [...values].filter(item => item?.refName !== field?.refName); // Remove first
    values = [...values, newVal]; // Then, add new
    const newFilterString = convertToFilterString(values);

    handleSearchChange(newFilterString);
  };

  /**
   * Handle clear for more fields dropdown
   */
  const handleClearForMoreFieldsDropdown = (clearedRefNames, hasSetCurrentMoreFieldRefNames) => {
    if (!(Array.isArray(clearedRefNames) && clearedRefNames.length)) {
      return;
    }

    // Set current more field ref names
    if (hasSetCurrentMoreFieldRefNames) {
      const newCurrentMoreFieldRefNames = hasCurrentMoreFieldRefNames
        ? [...currentMoreFieldRefNames].filter(refName => !clearedRefNames.includes(refName))
        : [];

      setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
    }

    // Change filter
    const values =
      Array.isArray(currentValues) && currentValues.length
        ? [...currentValues].filter(item => !clearedRefNames.includes(item?.refName))
        : [];

    const newFilterString = convertToFilterString(values);

    handleSearchChange(newFilterString);
  };

  /**
   * Handle ok for more fields dropdown
   */
  const handleOkForMoreFieldsDropdown = selectedFields => {
    const originCurrentMoreFieldRefNames = hasCurrentMoreFieldRefNames ? [...currentMoreFieldRefNames] : [];

    // Set current more field ref names
    const newCurrentMoreFieldRefNames =
      Array.isArray(selectedFields) && selectedFields.length ? [...selectedFields] : [];
    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);

    // On clear
    const clearedRefNames = originCurrentMoreFieldRefNames.filter(
      refName => !newCurrentMoreFieldRefNames.includes(refName)
    );
    handleClearForMoreFieldsDropdown(clearedRefNames, false);
  };

  /**
   * On remove from more fields
   * Click to: "Remove this condition"
   */
  const onRemoveThisConditionFromMoreFields = field => {
    if (!field?.refName) {
      return;
    }

    const newCurrentMoreFieldRefNames = hasCurrentMoreFieldRefNames
      ? [...currentMoreFieldRefNames].filter(item => item !== field?.refName)
      : [];

    onClearValueOfAField(field);
    setCurrentMoreFieldRefNames(newCurrentMoreFieldRefNames);
  };

  /**
   * On clear all filters
   */
  const onClearAllFilters = () => {
    handleSearchChange(undefined);
  };

  /**
   * On clear value of a field
   */
  const onClearValueOfAField = field => {
    if (!field?.refName) {
      return;
    }

    onApply({ field, value: undefined });
  };

  /**
   * Render field item for component type batch 1
   */
  const renderFieldItemForComponentTypeBatch1 = field => {
    if (
      [
        COMPONENT_TYPE.STRING,
        COMPONENT_TYPE.URL,
        COMPONENT_TYPE.NUMBER,
        COMPONENT_TYPE.HTML,
        COMPONENT_TYPE.TIME_TRACKING
      ].includes(field?.componentType)
    ) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if ([COMPONENT_TYPE.DATE, COMPONENT_TYPE.DATE_TIME].includes(field?.componentType)) {
      return (
        <FieldDate
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if ([COMPONENT_TYPE.PICKLIST, COMPONENT_TYPE.OPTION].includes(field?.componentType)) {
      return (
        <FieldSelect
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.PRIORITY) {
      return (
        <FieldPriority
          isTestTicket={isTestTicket}
          defaultSystem={defaultSystem}
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.TEST_PLANNING) {
      return (
        <FieldTestPlanning
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: '~' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.FOLDER) {
      return (
        <FieldFolder
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: '~' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.STATUS) {
      return (
        <FieldStatus
          isTestTicket={isTestTicket}
          field={field}
          defaultSystem={defaultSystem}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }
  };

  /**
   * Render field item for component type batch 2
   */
  const renderFieldItemForComponentTypeBatch2 = field => {
    if (field?.componentType === COMPONENT_TYPE.USER) {
      return (
        <FieldUser
          isTestTicket={isTestTicket}
          defaultSystem={defaultSystem}
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.SUGGESTION) {
      return (
        <FieldSuggestion
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }

    if (field?.componentType === COMPONENT_TYPE.RELATION) {
      return (
        <FieldRelation
          field={field}
          currentValues={currentValues}
          disabled={disabledFilter}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    }
  };

  /**
   * Render field item
   */
  const renderFieldItem = field => {
    if (!field?.refName) {
      return null;
    }

    if (field?.refName === SYSTEM_FIELD_VERSION) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValueOfAField(field)}
          onRemove={() => onRemoveThisConditionFromMoreFields(field)}
        />
      );
    } else {
      return (
        // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
        <>
          {renderFieldItemForComponentTypeBatch1(field)}
          {renderFieldItemForComponentTypeBatch2(field)}
        </>
      );
    }
  };

  /**
   * Render more button
   */
  const renderMoreButton = () => {
    const optionsMoreData = hasFieldData
      ? fieldData
          .filter(item => !defaultShow.some(show => show.refName === item?.refName))
          .map(item => {
            return {
              label: getFieldName(item, 'originRefName'),
              value: item?.refName
            };
          })
      : [];

    return (
      <MoreDropdown
        currentValues={currentMoreFieldRefNames}
        originOptions={optionsMoreData}
        disabled={disabledFilter}
        onOk={handleOkForMoreFieldsDropdown}
        onClear={val => handleClearForMoreFieldsDropdown(val, true)}
      />
    );
  };

  /**
   * Render right button
   */
  const RightButton = () => {
    return (
      <Row align="middle">
        <Tooltip title={t('search.clearAllFilters')} placement="topRight" destroyTooltipOnHide={true}>
          <Button
            type="link"
            className="border-transparent text-hover-dark-primary line-height-1 my-1 ml-2 px-0"
            disabled={disabledFilter}
            onClick={onClearAllFilters}
          >
            <ClearFilter className="font-size-18" />
          </Button>
        </Tooltip>
      </Row>
    );
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setSuggestion([]);
    };
  }, [setSuggestion]);

  return (
    <div className={`c-basic-manager-search ${className}`} {...rest}>
      <Row>
        <Col flex="1 1 auto" className="col-fields">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingFieldList}>
            <div>
              <Row gutter="20">
                <Col flex="1 1 auto">
                  {/* For default fields */}
                  {Array.isArray(defaultShow) && defaultShow.length > 0 && (
                    <Row align="middle">
                      {defaultShow
                        .filter(item => !currentMoreFieldRefNames.includes(item?.refName))
                        .map(item => (
                          <div key={item?.refName} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}

                      <div className="my-1 mr-2">{renderMoreButton()}</div>
                    </Row>
                  )}

                  {/* For more fields */}
                  {hasFieldData && hasCurrentMoreFieldRefNames && (
                    <Row align="middle">
                      {fieldData
                        .filter(item => currentMoreFieldRefNames.includes(item?.refName))
                        .map(item => (
                          <div key={item?.refName} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}
                    </Row>
                  )}
                </Col>

                <Col>
                  <RightButton />
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
