import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { handleError, Http } from '../../../../core';

export const model = {
  /**
   * State
   */
  currentIndex: 0,
  testExecutionList: [],
  changedTestResultStatusIds: {}, // Status của các test execution mà vừa được thay đổi ở Status dropdown trên modal
  invalidTestExecutionList: [],
  fullScreenModal: false,

  /**
   * Action: Set current index
   */
  setCurrentIndex: action((state, payload) => {
    if (state?.currentIndex === undefined) {
      return;
    }

    state.currentIndex = payload;
  }),

  /**
   * Action: Set test execution list
   */
  setTestExecutionList: action((state, payload) => {
    if (state?.testExecutionList === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.testExecutionList = [];
      return;
    }

    state.testExecutionList = payload;
  }),

  /**
   * Action: Set changed test result status ids
   */
  setChangedTestResultStatusIds: action((state, payload) => {
    if (state?.changedTestResultStatusIds === undefined) {
      return;
    }

    if (!payload) {
      state.changedTestResultStatusIds = [];
      return;
    }

    state.changedTestResultStatusIds = payload;
  }),

  /**
   * Action: Set invalid test execution list
   */
  setInvalidTestExecutionList: action((state, payload) => {
    if (state?.invalidTestExecutionList === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.invalidTestExecutionList = [];
      return;
    }

    state.invalidTestExecutionList = payload;
  }),

  /**
   * Action: Set full screen modal
   */
  setFullScreenModal: action((state, payload) => {
    if (state?.fullScreenModal === undefined) {
      return;
    }

    state.fullScreenModal = payload;
  }),

  /**
   * Action: Call api to get test run by key
   */
  getTestRunByKeyForExecution: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Get test run by key for execution: No test run key');
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_TEST_RUN}/${payload?.key}/execute`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
    }
  })
};
