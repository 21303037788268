import React, { useEffect, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { arrayMoveImmutable } from 'array-move';
import { Form, Popconfirm } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { FIELD_SYSTEM, SYSTEM_FIELD_ASSIGN_TO, SYSTEM_FIELD_STATUS, SYSTEM_FIELD_EXECUTED_BY } from '../../constants';
import {
  getFieldName,
  filterOptionForUserField,
  convertUserForSubmitData,
  getUserListByRoleFromStore
} from '../../common/utils';
import { useUnassignedUser } from '../../common/hooks';
import { UserAvatar, EditableSelectOption } from '../../components';

const LANG_MESSAGE_REQUIRED = 'message.required';

const BoxPeople = ({
  workTicketId,
  fieldList,
  editingItem,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const getUserListByRole = useStoreActions(action => action.global.getUserListByRole);
  const userListByRole = useStoreState(state => state.global.userListByRole);

  // Component state
  const [isGetUserListByRoleWhenMounted, setIsGetUserListByRoleWhenMounted] = useState(false);

  /**
   * Compute fields
   */
  const fields = useMemo(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return [];
    }

    let newFields = [];

    const systemFields = fieldList.filter(item => item?.type === FIELD_SYSTEM);
    const otherFields = fieldList.filter(item => item?.type !== FIELD_SYSTEM);

    newFields = [...systemFields, ...otherFields];

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_EXECUTED_BY)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_EXECUTED_BY);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_ASSIGN_TO)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_ASSIGN_TO);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    return newFields;
  }, [fieldList]);

  /**
   * Get role keys
   */
  const getRoleKeys = ({ editingItem, workTicketId, ticketListData }) => {
    if (!editingItem || !workTicketId || !ticketListData?.[workTicketId]?.id) {
      return;
    }

    let listStates = ticketListData?.[workTicketId]?.workFlow?.listStates;
    listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

    const currentStatus = listStates.find(item => item?.id === editingItem?.[SYSTEM_FIELD_STATUS]?.id);
    const roleKeys =
      Array.isArray(currentStatus?.assignmentRoles) && currentStatus?.assignmentRoles.length
        ? [...currentStatus?.assignmentRoles]
        : [];

    return roleKeys;
  };

  /**
   * Get user list by role
   */
  useEffect(() => {
    if (!editingItem || !workTicketId || !ticketListData?.[workTicketId]?.id) {
      return;
    }

    if (!isGetUserListByRoleWhenMounted) {
      const roleKeys = getRoleKeys({ editingItem, workTicketId, ticketListData });
      getUserListByRole({ roleKeys });
      setIsGetUserListByRoleWhenMounted(true);
    }
  }, [isGetUserListByRoleWhenMounted, editingItem, workTicketId, ticketListData, getUserListByRole]);

  /**
   * Handle submit
   */
  const handleSubmit = (refName, val, user) => {
    if (!refName) {
      return;
    }

    const currentVal = objectPath.get(editingItem, refName);

    if (val === currentVal?.username) {
      return;
    }

    const acceptUnassignedValue = refName === SYSTEM_FIELD_ASSIGN_TO && val && val !== UNASSIGNED.value;
    const newVal = acceptUnassignedValue || val ? convertUserForSubmitData(user) : {};

    onSubmit({ [refName]: newVal });
  };

  /**
   * Render assign to field
   */
  const renderAssignToField = ({ field, val, label, currentUser, userList, globalUserInfo, handleSubmit }) => {
    const showAssignToMeCondition1 = !isReadOnly && field?.refName === SYSTEM_FIELD_ASSIGN_TO;
    const showAssignToMeCondition2 =
      userList.some(u => u?.value === globalUserInfo?.username) && val?.username !== globalUserInfo?.username;

    return (
      <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
        <EditableSelectOption
          restValueText={{ id: `value_${field?.refName}-field` }}
          restEditButton={{ id: `edit-button_${field?.refName}-field` }}
          defaultVal={val?.username || UNASSIGNED.value}
          options={[UNASSIGNED, ...currentUser, ...userList]}
          restField={{
            filterOption: filterOptionForUserField,
            showSearch: true,
            allowClear: !field?.mandatory,
            dropdownMatchSelectWidth: false
          }}
          placeholder={t('common.pleaseSelect')}
          isReadOnly={isReadOnly}
          onSelect={(val, option) => handleSubmit(field?.refName, val, option?.user)}
        />

        {showAssignToMeCondition1 && showAssignToMeCondition2 && (
          <Popconfirm
            title={t('common.assignToMe')}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => handleSubmit(field?.refName, globalUserInfo?.username, globalUserInfo)}
          >
            <span id="assign-to-me-button" className="text-primary cursor-pointer over">
              {t('common.assignToMe')}
            </span>
          </Popconfirm>
        )}
      </Form.Item>
    );
  };

  /**
   * Render available insert fields
   */
  const renderAvailableInsertFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];
    const val = objectPath.get(editingItem, field?.refName);

    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject = projectUsers.some(u => u?.username === val?.username);

    const currentUser = val?.username
      ? [
          {
            label: <UserAvatar user={val} inactive={!isUserInProject} />,
            value: val?.username,
            disabled: !isUserInProject,
            user: val
          }
        ]
      : [];

    const roleKeys = getRoleKeys({ editingItem, workTicketId, ticketListData });
    const newUserListByRole = getUserListByRoleFromStore({ roleKeys, userListByRole });
    let userList = field?.refName === SYSTEM_FIELD_ASSIGN_TO ? newUserListByRole : projectUserList;

    userList =
      Array.isArray(userList) && userList.length
        ? [...userList]
            .filter(u => u.username !== val?.username)
            .map(u => ({ label: <UserAvatar user={u} />, value: u.username, user: u }))
        : [];

    switch (field?.refName) {
      case SYSTEM_FIELD_ASSIGN_TO: {
        return renderAssignToField({ field, val, label, currentUser, userList, globalUserInfo, handleSubmit });
      }

      default: {
        return (
          <Form.Item key={field?.refName} label={label} required={field?.mandatory} className="label-truncate mb-0">
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              defaultVal={val?.username || UNASSIGNED.value}
              options={[...currentUser, ...userList]}
              restFormItem={{ name: field?.refName, rules }}
              restField={{
                filterOption: filterOptionForUserField,
                showSearch: true,
                allowClear: !field?.mandatory,
                dropdownMatchSelectWidth: false
              }}
              placeholder={t('common.pleaseSelect')}
              isReadOnly={isReadOnly}
              onClear={() => handleSubmit(field?.refName, null, null)}
              onSelect={(val, option) => handleSubmit(field?.refName, val, option?.user)}
            />
          </Form.Item>
        );
      }
    }
  };

  /**
   * Render readonly fields
   */
  const renderReadonlyFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const val = objectPath.get(editingItem, field?.refName);

    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject = projectUsers.some(u => u?.username === val?.username);

    return (
      <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
        <div id={`value_${field?.refName}-field`}>
          {val?.username ? (
            <UserAvatar user={val} inactive={!isUserInProject} />
          ) : (
            <span className="text-gray">N/A</span>
          )}
        </div>
      </Form.Item>
    );
  };

  return (
    <div id="anchorPeople" className={`c-detail-components type-people collapse-item mb-4 ${className}`} {...rest}>
      <h5 className="btn-toggle ant-typography mb-0">
        <span id="toggle-people-button" onClick={() => onChangeCollapse('anchorPeople')}>
          <>{collapseKeys.includes('anchorPeople') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
          <span>{t('anchor.anchorPeople')}</span>
        </span>
      </h5>

      <div className={`collapse-body ${collapseKeys.includes('anchorPeople') ? 'show' : ''}`}>
        <div className="pt-2 pb-2">
          {Array.isArray(fields) &&
            fields.length > 0 &&
            fields.map(field => (field?.isInsert ? renderAvailableInsertFields(field) : renderReadonlyFields(field)))}
        </div>
      </div>
    </div>
  );
};

export default BoxPeople;
