import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _debounce from 'lodash/debounce';
import { Form, Button, Input, DatePicker, Tooltip, Row, Modal, Dropdown, Empty, Select, Menu } from 'antd';
import {
  SaveOutlined,
  DownOutlined,
  SearchOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons';

import {
  PAGE_SIZE,
  PRIORITIES,
  URL_PATTERN,
  DEFAULT_ORDER,
  NUMBER_PATTERN,
  COMPONENT_TYPE,
  JIRA_PLATFORM_ID,
  SYSTEM_FIELD_TAG,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  ORDER_BY_KEY_DESC,
  SYSTEM_FIELD_STATUS,
  ESTIMATED_TIME_PATTERN,
  SYSTEM_FIELD_ASSIGN_TO,
  WORK_ITEM_TEST_RESULT_ID,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_END_DATE_PLAN,
  SYSTEM_FIELD_START_DATE_PLAN,
  OPERATION_VALUE_REGEX,
  OPERATION_VALUE_OR,
  WORKFLOW_STATUS_TYPE_OPEN
} from '../../constants';
import {
  checkValidField,
  removeDuplicate,
  getObjectByValue,
  filterOptionForUserField,
  convertUserForSubmitData,
  convertMinutesToShortTime,
  convertEstimatedTimeToMinutes,
  getFieldName
} from '../../common/utils';
import { useUnassignedUser } from '../../common/hooks';
import { ArrowsRepeat, Play } from '../../assets/svg-icons';
import { UserAvatar, StatusLabel, SafeInnerHtml } from '../../components';

import './style.scss';

const LANG_MESSAGE_REQUIRED = 'message.required';
const LANG_COMMON_ENTER_VALUE = 'common.enterValue';
const LANG_COMMON_PLEASE_SELECT = 'common.pleaseSelect';
const STYLE_SUB_MENU_ITEM = 'bg-white cursor-default p-0';

const DEFAULT_FORM_DATA = {
  newVal: null,
  isClearValue: false
};

export const BulkChangeTickets = ({
  fieldList,
  fieldConfig,
  workTicketId,
  buttonTooltip,
  disabled,
  className = '',
  onVisibleBulkChangeDropdown,
  onSave,
  ...rest
}) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For global
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const getAllSuggestion = useStoreActions(action => action.global.getAllSuggestion);
  const allSuggestion = useStoreState(state => state.global.allSuggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // For jira integration
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);
  const getWorkflowStatusList = useStoreActions(action => action.jiraIntegration.getWorkflowStatusList);
  const workflowStatusList = useStoreState(state => state.jiraIntegration.workflowStatusList);
  const getPriorityList = useStoreActions(action => action.jiraIntegration.getPriorityList);
  const jiraPriorityList = useStoreState(state => state.jiraIntegration.priorityList);
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);
  const assignableUserList = useStoreState(state => state.jiraIntegration.assignableUserList);
  const loadingAssignableUserList = useStoreState(state => state.jiraIntegration.loadingAssignableUserList);

  // Component State
  const [currentFieldList, setCurrentFieldList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Get default field list
   */
  const getDefaultFieldList = ({ fieldList, fieldConfig }) => {
    if (
      !(Array.isArray(fieldList) && fieldList.length) ||
      !(Array.isArray(fieldConfig?.defaultShow) && fieldConfig?.defaultShow.length)
    ) {
      return [];
    }

    const externalFields = fieldList.filter(f => f?.externalSystem);
    const newCurrentFieldList = [];

    fieldConfig?.defaultShow.forEach(item => {
      const foundField = fieldList.find(f => f?.refName === item?.refName && !f?.externalSystem);

      if (foundField) {
        newCurrentFieldList.push(foundField);
      }
    });

    return [...externalFields, ...newCurrentFieldList];
  };

  /**
   * Set current field list
   */
  useEffect(() => {
    if (
      !(Array.isArray(fieldList) && fieldList.length) ||
      !(Array.isArray(fieldConfig?.defaultShow) && fieldConfig?.defaultShow.length)
    ) {
      return;
    }

    setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
  }, [fieldList, fieldConfig]);

  /**
   * Handle get data for jira
   */
  const handleGetDataForJira = () => {
    const integrationSystems =
      Array.isArray(integrationSystemList) && integrationSystemList.length ? [...integrationSystemList] : [];
    const jiraExternalSystem = integrationSystems?.find(item => item?.id === JIRA_PLATFORM_ID);

    if (jiraExternalSystem) {
      getWorkflowStatusList();
      getPriorityList();
      getAssignableUserList({
        username: ''
      });
    }
  };

  /**
   * Handle open change
   */
  const handleOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setKeyword('');
      setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
      handleGetDataForJira();
    }

    typeof onVisibleBulkChangeDropdown === 'function' && onVisibleBulkChangeDropdown(visible);
  };

  /**
   * Handle get suggestion
   */
  const handleGetSuggestion = ({ field, keyword }) => {
    if (!field?.data?.url) {
      return;
    }

    const newParams = {
      referenceField: field?.refName,
      url: field?.data?.url,
      filter: { ...field?.data?.filter },
      group: field?.data?.fieldValue,
      limit: PAGE_SIZE,
      page: 1,
      order: DEFAULT_ORDER
    };

    if (keyword) {
      newParams.keyword = keyword;
      newParams.filter = {
        ...newParams.filter,
        [OPERATION_VALUE_OR]: []
      };

      let suggestionBy = field?.data?.suggestionBy;
      suggestionBy = Array.isArray(suggestionBy) && suggestionBy.length ? [...suggestionBy] : [];

      if (!suggestionBy.includes('name')) {
        suggestionBy.push('name');
      }

      suggestionBy.forEach(k => {
        newParams.filter[OPERATION_VALUE_OR].push({
          [k]: {
            [OPERATION_VALUE_REGEX]: `.*${keyword}.*`,
            $options: 'i'
          }
        });
      });
    }

    if (field?.componentType === COMPONENT_TYPE.RELATION) {
      const select = [
        '_id',
        'key',
        'name',
        field?.data?.fieldLabel,
        field?.data?.fieldValue,
        field?.data?.displayField
      ];

      newParams.select = removeDuplicate(select, '').join(' ');
      newParams.order = ORDER_BY_KEY_DESC;
    }

    getAllSuggestion(newParams);
  };

  /**
   * On search suggestion
   */
  const onSearchSuggestion = useCallback(
    _debounce(({ field, keyword }) => handleGetSuggestion({ field, keyword }), 300),
    []
  );

  /**
   * On search jira users
   */
  const onSearchJiraUsers = useCallback(
    _debounce(keyword => getAssignableUserList({ username: keyword }), 300),
    []
  );

  /**
   * Get form data for jira user
   */
  const getFormDataForJiraUser = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    let newValue = null;

    if (val === UNASSIGNED.value) {
      newValue = null;
    } else {
      const newUserList = Array.isArray(assignableUserList) && assignableUserList.length ? [...assignableUserList] : [];
      const user = newUserList.find(u => u?.id === val);
      newValue = user?.id ? convertUserForSubmitData(user) : null;
    }

    newVal = newValue || { username: '' };
    isClearValue = !newValue;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for user jira status
   */
  const getFormDataForJiraStatus = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for user jira priority
   */
  const getFormDataForJiraPriority = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    const jiraPriorities = Array.isArray(jiraPriorityList) && jiraPriorityList.length ? [...jiraPriorityList] : [];

    if (field?.mandatory && !jiraPriorities.some(p => p?.value === val)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for jira
   */
  const getFormDataForJira = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    switch (field?.componentType) {
      case COMPONENT_TYPE.USER: {
        const result = getFormDataForJiraUser({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.STATUS: {
        const result = getFormDataForJiraStatus({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.PRIORITY: {
        const result = getFormDataForJiraPriority({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      default: {
        break;
      }
    }

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for latest result
   */
  const getFormDataForLatestResult = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for tag
   */
  const getFormDataForTag = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !(Array.isArray(val) && val.length)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = Array.isArray(val) && val.length ? [...val].join(',') : '';
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for date/datetime
   */
  const getFormDataForDateDatetime = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !(val && moment(val).isValid())) {
      return DEFAULT_FORM_DATA;
    }

    const newValue = val && moment(val).isValid() ? moment(val).format() : null;
    newVal = newValue;
    isClearValue = !newValue;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for time tracking
   */
  const getFormDataForTimeTracking = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !new RegExp(ESTIMATED_TIME_PATTERN).test(val)) {
      return DEFAULT_FORM_DATA;
    }

    const minutes = convertEstimatedTimeToMinutes(val);
    newVal = minutes;
    isClearValue = !minutes;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for user
   */
  const getFormDataForUser = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    let newValue = null;

    if (val === UNASSIGNED.value) {
      newValue = {};
    } else {
      const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
      const user = projectUsers.find(u => u?.username === val);
      newValue = user?.username ? convertUserForSubmitData(user) : null;
    }

    newVal = newValue || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for priority
   */
  const getFormDataForPriority = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !getObjectByValue(val, PRIORITIES)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for option
   */
  const getFormDataForOption = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];

    if (field?.mandatory && !getObjectByValue(val, options)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for picklist
   */
  const getFormDataForPicklist = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !(Array.isArray(val) && val.length)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = Array.isArray(val) && val.length ? [...val] : null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for url
   */
  const getFormDataForUrl = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !new RegExp(URL_PATTERN).test(val)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for number
   */
  const getFormDataForNumber = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !new RegExp(NUMBER_PATTERN).test(val)) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal && !new RegExp(NUMBER_PATTERN).test(newVal);

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for html string status
   */
  const getFormDataForHtmlStringStatus = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for suggestion
   */
  const getFormDataForSuggestion = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    let rows = allSuggestion?.[field?.refName]?.rows;
    rows = Array.isArray(rows) && rows.length ? [...rows] : [];
    const found = rows.find(r => r?.[field?.data?.fieldValue] === val);

    newVal = found || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for relation
   */
  const getFormDataForRelation = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    if (field?.mandatory && !val) {
      return DEFAULT_FORM_DATA;
    }

    newVal = val || null;
    isClearValue = !newVal;

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for component type batch2
   */
  const getFormDataForComponentTypeBatch2 = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    switch (field?.componentType) {
      case COMPONENT_TYPE.URL: {
        const result = getFormDataForUrl({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.NUMBER: {
        const result = getFormDataForNumber({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.HTML:
      case COMPONENT_TYPE.STRING:
      case COMPONENT_TYPE.STATUS: {
        const result = getFormDataForHtmlStringStatus({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.SUGGESTION: {
        const result = getFormDataForSuggestion({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.RELATION: {
        const result = getFormDataForRelation({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      default: {
        break;
      }
    }

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for component type batch1 (internal system)
   */
  const getFormDataForComponentTypeBatch1 = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    switch (field?.componentType) {
      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        const result = getFormDataForDateDatetime({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        const result = getFormDataForTimeTracking({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.USER: {
        const result = getFormDataForUser({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.PRIORITY: {
        const result = getFormDataForPriority({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.OPTION: {
        const result = getFormDataForOption({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case COMPONENT_TYPE.PICKLIST: {
        const result = getFormDataForPicklist({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      default: {
        const result = getFormDataForComponentTypeBatch2({ val, field }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }
    }

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * Get form data for internal system
   */
  const getFormDataForInternalSystem = ({ val, field }) => {
    let newVal = null;
    let isClearValue = false;

    switch (field?.refName) {
      case SYSTEM_FIELD_LATEST_RESULT:
      case `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`: {
        const result = getFormDataForLatestResult({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      case SYSTEM_FIELD_TAG: {
        const result = getFormDataForTag({ val, field });
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }

      default: {
        const result = getFormDataForComponentTypeBatch1({ val, field }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
        newVal = result?.newVal;
        isClearValue = result?.isClearValue;
        break;
      }
    }

    return {
      newVal,
      isClearValue
    };
  };

  /**
   * No action when get form data
   */
  const noActionWhenGetFormData = async ({ field, form }) => {
    const validField = await checkValidField({ form, formItemName: field?.refName });

    return !field?.refName || !validField;
  };

  /**
   * Get form data
   */
  const getFormData = async ({ val, field }) => {
    if (await noActionWhenGetFormData({ field, form })) {
      return;
    }

    let newVal = null;
    let isClearValue = false;

    // For external system: jira
    if (field?.externalSystem === JIRA_PLATFORM_ID) {
      const result = getFormDataForJira({ val, field });
      newVal = result?.newVal;
      isClearValue = result?.isClearValue;
    }

    // For internal system
    else {
      const result = getFormDataForInternalSystem({ val, field });
      newVal = result?.newVal;
      isClearValue = result?.isClearValue;
    }

    const newFormData = {
      formData: {
        [field?.refName]: newVal
      },
      field,
      isClearValue
    };

    return newFormData;
  };

  /**
   * Handle save
   */
  const handleSave = async ({ val, field }) => {
    const newData = await getFormData({ val, field });
    const isClearValue = newData?.isClearValue;
    delete newData?.isClearValue;

    if (!newData?.formData && !newData?.field) {
      return;
    }

    setVisibleDropdown(false);

    if ([SYSTEM_FIELD_LATEST_RESULT, `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`].includes(field?.refName)) {
      await onSave(newData);
    } else {
      Modal.confirm({
        title: t('common.confirmSave'),
        content: isClearValue ? t('message.areYouSureClearValue') : t('message.areYouSureSave'),
        autoFocusButton: null,
        maskClosable: true,
        okText: t('common.save'),
        okButtonProps: { type: 'primary', icon: <SaveOutlined /> },
        cancelText: t('common.cancel'),
        onOk: async () => {
          await onSave(newData);
        },
        onCancel: () => {}
      });
    }

    document.querySelector('.c-bulk-change-tickets .trigger-reset-form')?.click();
  };

  /**
   * Render apply button
   */
  const renderApplyButton = field => {
    return (
      <Button
        type="primary"
        className="ml-2"
        onClick={async e => {
          e.stopPropagation();
          handleSave({ val: form.getFieldValue(field?.refName), field });
        }}
      >
        {t('common.apply')}
      </Button>
    );
  };

  /**
   * Get sub menus for user jira
   */
  const getSubMenusForUserJira = field => {
    const newUserList = Array.isArray(assignableUserList) && assignableUserList.length ? [...assignableUserList] : [];

    let options = [...newUserList].map(u => ({ label: <UserAvatar user={u} />, value: u?.id }));
    options = [UNASSIGNED, ...options];

    return [
      {
        key: `${field?.externalSystem}-sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                options={options}
                filterOption={false}
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t('common.searchEmail')}
                className="w-200"
                loading={loadingAssignableUserList}
                onKeyDown={e => e?.stopPropagation()}
                onSearch={onSearchJiraUsers}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for status jira
   */
  const getSubMenusForStatusJira = field => {
    const listStates = Array.isArray(workflowStatusList) && workflowStatusList.length ? [...workflowStatusList] : [];

    return listStates.map(s => {
      return {
        key: `${field?.externalSystem}-sub-menus-${field?.refName}-${s?.id}`,
        label: <StatusLabel status={s} />,
        title: s?.name,
        onClick: () => handleSave({ val: s?.id, field })
      };
    });
  };

  /**
   * Get sub menus for priority jira
   */
  const getSubMenusForPriorityJira = field => {
    const jiraPriorities = Array.isArray(jiraPriorityList) && jiraPriorityList.length ? [...jiraPriorityList] : [];

    return jiraPriorities.map(item => {
      return {
        key: `${field?.externalSystem}-sub-menus-${field?.refName}-${item?.value}`,
        label: item?.label,
        title: item?.label,
        icon: item?.icon,
        onClick: () => handleSave({ val: item?.value, field })
      };
    });
  };

  /**
   * Get sub menus for latest result
   */
  const getSubMenusForLatestResult = ({ field }) => {
    let listStates = ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow?.listStates;
    listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

    return listStates.map(s => {
      return {
        key: `sub-menus-${field?.refName}-${s?.id}`,
        label: <StatusLabel status={s} />,
        title: s?.name,
        disabled: s?.type === WORKFLOW_STATUS_TYPE_OPEN,
        style: { opacity: s?.type === WORKFLOW_STATUS_TYPE_OPEN ? 0.5 : null },
        onClick: () => handleSave({ val: s?.id, field })
      };
    });
  };

  /**
   * Get sub menus for tag
   */
  const getSubMenusForTag = ({ field }) => {
    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                mode="tags"
                options={[]}
                optionFilterProp="label"
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t(LANG_COMMON_ENTER_VALUE)}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for html
   */
  const getSubMenusForHtml = ({ field }) => {
    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Input.TextArea
                rows={6}
                autoComplete="off"
                placeholder={t(LANG_COMMON_ENTER_VALUE)}
                allowClear={!field?.mandatory}
                style={{ width: 300 }}
                className="textarea-resize-none"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for date date time
   */
  const getSubMenusForDateDateTime = ({ field }) => {
    const format = field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
    const showTime = field?.componentType === COMPONENT_TYPE.DATE ? false : { format: 'HH:mm' };

    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <DatePicker
                format={format}
                showTime={showTime}
                placeholder={format}
                showSearch
                allowClear={!field?.mandatory}
                disabledDate={field?.disabledDate}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for time tracking
   */
  const getSubMenusForTimeTracking = ({ field }) => {
    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[
                { required: field?.mandatory, whitespace: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
                { pattern: ESTIMATED_TIME_PATTERN, message: t('message.invalidFormat') }
              ]}
              validateTrigger={['onBlur']}
              className="mb-0"
            >
              <Input
                autoComplete="off"
                placeholder={t(LANG_COMMON_ENTER_VALUE)}
                allowClear={!field?.mandatory}
                suffix={
                  <Tooltip
                    title={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
                    placement="top"
                    destroyTooltipOnHide={true}
                    overlayClassName="disabled-outside-click"
                  >
                    <InfoCircleOutlined className="text-gray ml-2" />
                  </Tooltip>
                }
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
                onBlur={async e => {
                  await form.validateFields([field?.refName]);
                  const et = convertMinutesToShortTime(convertEstimatedTimeToMinutes(e?.target?.value));
                  form.setFieldsValue({ [field?.refName]: et || null });
                }}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for user
   */
  const getSubMenusForUser = ({ field }) => {
    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    let options = [...projectUsers].map(u => ({ label: <UserAvatar user={u} />, value: u?.username }));

    if (field?.refName === SYSTEM_FIELD_ASSIGN_TO) {
      options = [UNASSIGNED, ...options];
    }

    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                options={options}
                filterOption={filterOptionForUserField}
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t(LANG_COMMON_PLEASE_SELECT)}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for status
   */
  const getSubMenusForStatus = ({ field, workTicketId }) => {
    let listStates = ticketListData?.[workTicketId]?.workFlow?.listStates;
    listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

    return listStates.map(s => {
      return {
        key: `sub-menus-${field?.refName}-${s?.id}`,
        label: <StatusLabel status={s} />,
        title: s?.name,
        onClick: () => handleSave({ val: s?.id, field })
      };
    });
  };

  /**
   * Get sub menus for priority
   */
  const getSubMenusForPriority = ({ field }) => {
    return PRIORITIES.map(item => {
      return {
        key: `sub-menus-${field?.refName}-${item?.value}`,
        label: item?.label,
        title: item?.label,
        icon: item?.icon,
        onClick: () => handleSave({ val: item?.value, field })
      };
    });
  };

  /**
   * Get sub menus for option
   */
  const getSubMenusForOption = ({ field }) => {
    const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];

    return [
      {
        key: `sub-menus-${field?.refName}}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                options={options}
                optionFilterProp="label"
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t(LANG_COMMON_PLEASE_SELECT)}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for picklist
   */
  const getSubMenusForPicklist = ({ field }) => {
    const options = Array.isArray(field?.data) && field?.data.length ? [...field?.data] : [];

    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                options={options}
                mode="multiple"
                optionFilterProp="label"
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t(LANG_COMMON_PLEASE_SELECT)}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              >
                {Array.isArray(options) && options.length
                  ? options.map(item => (
                      <Select.Option key={item.value} label={item.label} value={item.value}>
                        {item.label}
                      </Select.Option>
                    ))
                  : null}
              </Select>
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for string number url
   */
  const getSubMenusForStringNumberUrl = ({ field }) => {
    const patternRule = {};

    if (field?.componentType === COMPONENT_TYPE.NUMBER) {
      patternRule.pattern = NUMBER_PATTERN;
      patternRule.message = t('message.fieldMustBeANumber');
    } else if (field?.componentType === COMPONENT_TYPE.URL) {
      patternRule.pattern = URL_PATTERN;
      patternRule.message = t('message.invalidFormat');
    } else {
    }

    return [
      {
        key: `sub-menus-${field?.refName}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[
                { required: field?.mandatory, whitespace: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
                patternRule
              ]}
              className="mb-0"
            >
              <Input
                autoComplete="off"
                placeholder={t(LANG_COMMON_ENTER_VALUE)}
                allowClear={!field?.mandatory}
                className="w-200"
                onKeyDown={e => e?.stopPropagation()}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for suggestion or relation
   */
  const getSubMenusForSuggestionOrRelation = ({ field }) => {
    let rows = allSuggestion?.[field?.refName]?.rows;
    rows = Array.isArray(rows) && rows.length ? [...rows] : [];

    const options = [...rows].map(item => {
      return {
        label: item?.[field?.data?.fieldLabel],
        value: item?.[field?.data?.fieldValue]
      };
    });

    return [
      {
        key: `sub-menus-${field?.refName}}`,
        label: (
          <Row wrap={false} className="p-3" onClick={e => e?.stopPropagation()}>
            <Form.Item
              name={field?.refName}
              rules={[{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }]}
              className="mb-0"
            >
              <Select
                options={options}
                filterOption={false}
                showSearch
                allowClear={!field?.mandatory}
                placeholder={t('common.searchName')}
                className="w-200"
                loading={loadingSuggestion}
                onKeyDown={e => e?.stopPropagation()}
                onDropdownVisibleChange={visible => {
                  if (visible) {
                    onSearchSuggestion({ field, keyword: '' });
                  }
                }}
                onKeyUp={e => onSearchSuggestion({ field, keyword: e?.target?.value })}
              />
            </Form.Item>
            {renderApplyButton(field)}
          </Row>
        ),
        className: STYLE_SUB_MENU_ITEM
      }
    ];
  };

  /**
   * Get sub menus for component type batch2
   */
  const getSubMenusForComponentTypeBatch2 = ({ field }) => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.PICKLIST: {
        return getSubMenusForPicklist({ field });
      }

      case COMPONENT_TYPE.STRING:
      case COMPONENT_TYPE.NUMBER:
      case COMPONENT_TYPE.URL: {
        return getSubMenusForStringNumberUrl({ field });
      }

      case COMPONENT_TYPE.SUGGESTION:
      case COMPONENT_TYPE.RELATION: {
        return getSubMenusForSuggestionOrRelation({ field });
      }

      default: {
        return [];
      }
    }
  };

  /**
   * Get sub menus for component type batch1
   */
  const getSubMenusForComponentTypeBatch1 = ({ field, workTicketId }) => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.HTML: {
        return getSubMenusForHtml({ field });
      }

      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        return getSubMenusForDateDateTime({ field });
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        return getSubMenusForTimeTracking({ field });
      }

      case COMPONENT_TYPE.USER: {
        return getSubMenusForUser({ field });
      }

      case COMPONENT_TYPE.STATUS: {
        return getSubMenusForStatus({ field, workTicketId });
      }

      case COMPONENT_TYPE.PRIORITY: {
        return getSubMenusForPriority({ field });
      }

      case COMPONENT_TYPE.OPTION: {
        return getSubMenusForOption({ field });
      }

      default: {
        return getSubMenusForComponentTypeBatch2({ field }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  /**
   * Get sub menus for jira
   */
  const getSubMenusForJira = field => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.USER: {
        return getSubMenusForUserJira(field);
      }

      case COMPONENT_TYPE.STATUS: {
        return getSubMenusForStatusJira(field);
      }

      case COMPONENT_TYPE.PRIORITY: {
        return getSubMenusForPriorityJira(field);
      }

      default: {
        return [];
      }
    }
  };

  /**
   * Get sub menus for internal system
   */
  const getSubMenusForInternalSystem = ({ field, workTicketId }) => {
    switch (field?.refName) {
      case SYSTEM_FIELD_LATEST_RESULT:
      case `${SYSTEM_FIELD_LATEST_RESULT}.${SYSTEM_FIELD_STATUS}`: {
        return getSubMenusForLatestResult({ field });
      }

      case SYSTEM_FIELD_TAG: {
        return getSubMenusForTag({ field });
      }

      default: {
        return getSubMenusForComponentTypeBatch1({ field, workTicketId }); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  /**
   * Get sub menus
   */
  const getSubMenus = ({ field, workTicketId }) => {
    if (!field?.refName || !workTicketId) {
      return [];
    }

    // For external system: jira
    if (field?.externalSystem === JIRA_PLATFORM_ID) {
      return getSubMenusForJira(field);
    }

    // For internal system
    else {
      return getSubMenusForInternalSystem({ field, workTicketId });
    }
  };

  /**
   * Get menu items
   */
  const getMenuItems = () => {
    if (!(Array.isArray(currentFieldList) && currentFieldList.length) || !workTicketId) {
      return [
        {
          key: 'empty',
          label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-3" />
        }
      ];
    }

    const items = [...currentFieldList].map(field => {
      const title = field?.exactName ? field?.exactName : getFieldName(field);
      const showPlanDateQuestionIcon =
        (field?.refName === SYSTEM_FIELD_START_DATE_PLAN || field?.refName === SYSTEM_FIELD_END_DATE_PLAN) &&
        field?.hasDisabledPlanDate;

      return {
        key:
          field?.externalSystem === JIRA_PLATFORM_ID
            ? `${field?.externalSystem}-${field?.refName}`
            : `${field?.refName}`,
        label: (
          <Row align="middle" wrap={false} className="text-truncate">
            <div title={title} className="text-truncate">
              {field?.externalSystem && (
                <span title={field?.externalSystem} className={`external-system-label ${field?.externalSystem} mr-1`}>
                  <span className="text-truncate">{field?.externalSystem}</span>
                </span>
              )}

              {title}
            </div>

            {showPlanDateQuestionIcon && (
              <div className="box-ic pl-1" style={{ flex: '1 1 18px' }}>
                <Tooltip
                  placement="top"
                  title={t('message.cannotChangePlanDate', { fieldName: title })}
                  destroyTooltipOnHide={true}
                >
                  <QuestionCircleOutlined className="text-gray-2 mr-1" />
                </Tooltip>
              </div>
            )}
          </Row>
        ),
        popupClassName: 'c-bulk-change-tickets-submenu style-like-as-dropdown-menu',
        disabled: field?.hasDisabledPlanDate,
        children: getSubMenus({ field, workTicketId })
      };
    });

    return items;
  };

  /**
   * On show all fields
   */
  const onShowAllFields = () => {
    const fields = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];
    const defaultFields = getDefaultFieldList({ fieldList, fieldConfig });
    const allFields = [...defaultFields];

    fields.forEach(f => {
      if (!defaultFields.some(sub => sub?.refName === f?.refName) && !f?.externalSystem) {
        allFields.push(f);
      }
    });

    setCurrentFieldList(allFields);
  };

  /**
   * On filter fields
   */
  const onFilterFields = useCallback(
    _debounce(keyword => {
      setKeyword(keyword);

      if (!keyword) {
        setCurrentFieldList(getDefaultFieldList({ fieldList, fieldConfig }));
        return;
      }

      keyword = `${typeof keyword === 'string' && keyword ? keyword : ''}`;

      const fields = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];

      const newCurrentFieldList = fields.filter(f => {
        const name = `${typeof f?.name === 'string' && f?.name ? f?.name : ''}`;
        const refName = `${typeof f?.refName === 'string' && f?.refName ? f?.refName : ''}`;

        return (
          name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
          refName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        );
      });

      setCurrentFieldList(newCurrentFieldList);
    }, 300),
    [fieldList, fieldConfig]
  );

  /**
   * Get is view more button
   */
  const getIsViewMoreButton = ({ fieldList, currentFieldList, keyword }) => {
    const condition1 = Array.isArray(fieldList) && fieldList.length > 0;
    const condition2 =
      Array.isArray(currentFieldList) && currentFieldList.length > 0 && currentFieldList.length < fieldList.length;
    const condition3 = !keyword;

    return condition1 && condition2 && condition3;
  };

  /**
   * Render dropdown
   */
  const renderDropdown = () => {
    return (
      <>
        <Menu
          items={[
            {
              key: 'search',
              label: (
                <Input
                  placeholder={t('common.searchField')}
                  size="small"
                  allowClear
                  suffix={<SearchOutlined />}
                  className="w-100"
                  onClick={e => e.stopPropagation()}
                  onChange={e => onFilterFields(e?.target?.value)}
                />
              ),
              className: 'bg-white cursor-default'
            }
          ]}
          selectable={false}
          className="style-like-as-dropdown-menu"
        />

        <Menu
          items={getMenuItems()}
          selectable={false}
          triggerSubMenuAction="click"
          className="style-like-as-dropdown-menu"
        />

        {getIsViewMoreButton({ fieldList, currentFieldList, keyword }) && (
          <Menu
            items={[
              {
                key: 'moreField',
                label: (
                  <>
                    {t('common.viewMoreFields', { count: fieldList.length - currentFieldList.length })}{' '}
                    <DownOutlined className="font-size-10" />
                  </>
                ),
                className: 'text-primary',
                onClick: onShowAllFields
              }
            ]}
            selectable={false}
            className="style-like-as-dropdown-menu"
          />
        )}
      </>
    );
  };

  return (
    <div className={`c-bulk-change-tickets ${className}`} {...rest}>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false,
          triggerSubMenuAction: 'click',
          className: 'c-bulk-change-tickets-menu'
        }}
        dropdownRender={node => (
          <Form form={form} layout="vertical">
            {node}
          </Form>
        )}
        placement="bottomLeft"
        trigger={['click']}
        destroyPopupOnHide={true}
        overlayClassName="c-bulk-change-tickets-dropdown"
        disabled={disabled}
        onOpenChange={handleOpenChange}
      >
        <Tooltip placement="top" destroyTooltipOnHide={true} {...buttonTooltip}>
          <Button id="button-bulk-change-tickets" icon={<ArrowsRepeat />} disabled={disabled} className="no-animation">
            {t('common.bulkChange')} <CaretDownOutlined className="font-size-12 ml-1" />
          </Button>
        </Tooltip>
      </Dropdown>

      <div className="trigger-reset-form" onClick={() => form.resetFields()} />
    </div>
  );
};
