import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';

import { jsonParse } from '../../../common/utils';
import { useFile } from '../../../common/hooks';
import { UpdateTicketStatusModal } from '../../update-ticket-status-modal';

const UpdateTicketStatusModalHelper = ({ tableForm }) => {
  // For upload
  const { getUploadPath } = useFile();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [formItemName, setFormItemName] = useState(null);
  const [workTicketId, setWorkTicketId] = useState(null);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [visibleUpdateTicketStatusModal, setVisibleUpdateTicketStatusModal] = useState(false);

  /**
   * On open
   */
  const onOpen = () => {
    const helper = document.querySelector('.c-update-ticket-status-modal-helper');

    if (!helper) {
      return;
    }

    setFormItemName(helper.dataset.formItemName);
    setWorkTicketId(helper.dataset.workTicketId);
    setCurrentValue(helper.dataset.currentValue);
    setCurrentStep(jsonParse(helper.dataset.currentStep || {}));

    setVisibleUpdateTicketStatusModal(true);
  };

  /**
   * On close
   */
  const onClose = () => {
    setTimeout(() => {
      setFormItemName(null);
      setWorkTicketId(null);
      setCurrentValue(null);
      setCurrentStep(null);
      setVisibleUpdateTicketStatusModal(false);

      onRemoveAttributes();
    }, 500);
  };

  /**
   * On remove attributes
   */
  const onRemoveAttributes = () => {
    const helper = document.querySelector('.c-update-ticket-status-modal-helper');

    helper?.setAttribute('data-x', '');
    helper?.setAttribute('data-y', '');
    helper?.setAttribute('data-form-item-name', '');
    helper?.setAttribute('data-work-ticket-id', '');
    helper?.setAttribute('data-current-value', '');
    helper?.setAttribute('data-current-step', '');

    const textElm = helper?.querySelector('.text');
    if (textElm) {
      textElm.innerHTML = '';
    }
  };

  /**
   * On change status
   */
  const onChangeStatus = formData => {
    const textElm = document.querySelector('.c-update-ticket-status-modal-helper .text');

    if (!textElm) {
      return;
    }

    textElm.innerHTML = JSON.stringify(formData);

    const trigger = document.querySelector(`.trigger-change-status-element[data-form-item-name="${formItemName}"]`);
    trigger?.click();
  };

  /**
   * On cancel
   */
  const onCancel = () => {
    tableForm.setFieldsValue({ [formItemName]: currentValue || null });

    onRemoveAttributes();
    setVisibleUpdateTicketStatusModal(false);
  };

  return (
    <>
      <div className="c-update-ticket-status-modal-helper">
        <div className="trigger-open" onClick={onOpen} />
        <div className="trigger-close" onClick={onClose} />
        <div className="text d-none" />
      </div>

      {visibleUpdateTicketStatusModal && (
        <UpdateTicketStatusModal
          fromModule="EDITABLE_CELL"
          visible={visibleUpdateTicketStatusModal}
          workflow={ticketListData?.[workTicketId]?.workFlow}
          currentStep={currentStep}
          uploadPath={getUploadPath(workTicketId)}
          className="disabled-outside-click"
          onChangeStatus={onChangeStatus}
          onCancel={onCancel}
        />
      )}
    </>
  );
};

export default UpdateTicketStatusModalHelper;
