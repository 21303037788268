import React, { useEffect, useRef, useState } from 'react';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Input, Button, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { VIEW_SEARCH_BASIC, VIEW_SEARCH_ADVANCED } from '../../constants';
import { parseRsql, getConditionsByAql, debounce } from '../../common/utils';

import './style.scss';

let typingTimerOfSearch = 0;

export const BasicAdvancedAqlSearchV2 = ({
  searchByKeyword = 'name,description',
  hideAdvanced,
  allowChangeQueryParamsUrl,
  loading,
  className = '',
  onSearch,
  ...rest
}) => {
  const refInput = useRef(null);
  const [searchForm] = Form.useForm();

  // For query params on url
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    aql: StringParam,
    searchType: StringParam
  });

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isSetFormValuesWhenMounted, setIsSetFormValuesWhenMounted] = useState(false);
  const [searchType, setSearchType] = useState(null);
  const [aql, setAql] = useState('');

  /**
   * onSetFormValuesWhenChangeSearchType
   */
  const onSetFormValuesWhenChangeSearchType = params => {
    if (!params) {
      return;
    }

    if (params.searchType === VIEW_SEARCH_ADVANCED) {
      // For advanced
      setSearchType(VIEW_SEARCH_ADVANCED);
      setAql(params.aql);

      searchForm.setFieldsValue({ aql: params.aql });
    } else if (params.searchType === VIEW_SEARCH_BASIC) {
      // For basic
      const conditions = getConditionsByAql(params.aql);
      const conditionList =
        Array.isArray(conditions?.conditionList) && conditions.conditionList.length ? conditions.conditionList : [];
      const attributesForSearchByKeyword = searchByKeyword.split(','); // Example: ['name', 'description']

      setSearchType(VIEW_SEARCH_BASIC);
      setAql(params.aql);

      searchForm.setFieldsValue({
        keyword: conditionList.find(item => attributesForSearchByKeyword.includes(item.selector))?.value
      });
    } else {
    }
  };

  /**
   * Check valid search type
   */
  const checkValidSearchType = searchType => {
    return searchType === VIEW_SEARCH_BASIC || searchType === VIEW_SEARCH_ADVANCED;
  };

  /**
   * Watching change of queryParams on url
   * Watching change of searchType
   */
  useEffect(() => {
    if (!queryParams) {
      return;
    }

    const newQuery = {
      searchType: VIEW_SEARCH_BASIC
    };

    if (checkValidSearchType(queryParams?.searchType)) {
      newQuery.searchType = queryParams?.searchType;
    }

    if (queryParams?.aql) {
      newQuery.aql = queryParams?.aql;
    }

    if (!isSetFormValuesWhenMounted) {
      // ==========> For mounted, F5 browser
      if (!checkValidSearchType(queryParams?.searchType) && !hideAdvanced) {
        // Push default query params to url
        setQueryParams({
          searchType: VIEW_SEARCH_BASIC
        });
      } else {
        onSetFormValuesWhenChangeSearchType(newQuery);
      }

      setIsSetFormValuesWhenMounted(true);

      return;
    } else if (
      // ==========> For change query params on url
      newQuery?.searchType !== searchType ||
      newQuery?.aql !== aql
    ) {
      onSetFormValuesWhenChangeSearchType(newQuery);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aql, searchType, searchForm, queryParams, hideAdvanced, isSetFormValuesWhenMounted, setQueryParams]);

  /**
   * Validate valid aql
   *
   * @param {object} rule - Rule of validation
   * @param {string} value - Value of input
   */
  const validateValidAql = async (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    value = value.trim();

    if (!parseRsql(value)) {
      return Promise.reject(t('message.invalidFormat'));
    }

    return Promise.resolve();
  };

  /**
   * Render switch basic button
   */
  const renderSwitchBasicButton = () => {
    return (
      <Tooltip
        title={searchType === VIEW_SEARCH_BASIC ? t('search.switchToAdvancedSearch') : t('search.switchToBasicSearch')}
        placement="topRight"
        destroyTooltipOnHide={true}
      >
        <Button
          type="link"
          className="border-transparent over my-1 px-0"
          onClick={() => {
            setQueryParams({ searchType: searchType === VIEW_SEARCH_BASIC ? VIEW_SEARCH_ADVANCED : VIEW_SEARCH_BASIC });
            setTimeout(() => refInput?.current && refInput.current.focus(), 200);
          }}
        >
          {searchType === VIEW_SEARCH_BASIC ? t('search.advancedSearch') : t('search.basicSearch')}
        </Button>
      </Tooltip>
    );
  };

  /**
   * On submit and search
   */
  const onSubmit = values => {
    if (loading) {
      return;
    }

    // Get aql
    let _aql = '';

    if (searchType === VIEW_SEARCH_ADVANCED) {
      _aql = values._aql ? values._aql.trim() : '';
    } else if (values.keyword) {
      const keyword = values.keyword.trim();
      const attributesForSearchByKeyword = searchByKeyword.split(','); // Example: ['name', 'description']

      _aql = attributesForSearchByKeyword.map(key => `${key} =like= "${keyword}"`).join(' or ');
    } else {
    }

    if (allowChangeQueryParamsUrl) {
      setQueryParams({
        page: 1,
        aql: _aql || undefined
      });
    } else if (typeof onSearch === 'function') {
      onSearch({ aql: _aql });
    } else {
    }
  };

  /**
   * On search by key word
   */
  const onSearchByKeyWord = (val = '') => {
    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        if (val === '') {
          onSubmit({ keyword: '' });
          return;
        }

        onSubmit({ keyword: val });
      }),
      300
    );
  };

  return (
    <div className={`c-basic-advanced-aql-search-v2 mb-2 ${className}`} {...rest}>
      <Form form={searchForm} onFinish={onSubmit}>
        <Row gutter="20">
          <Col flex="1 1 auto">
            {/* Search by keyword input */}
            {searchType === VIEW_SEARCH_BASIC && (
              <Form.Item name="keyword" className="m-0">
                <Input
                  ref={refInput}
                  placeholder={t('common.searchByKeyword')}
                  prefix={<SearchOutlined className="text-gray font-size-20" />}
                  bordered={false}
                  className="bg-transparent box-shadow-none px-0 my-1"
                  onPressEnter={() => searchForm.submit()}
                  onChange={e => onSearchByKeyWord(e?.target?.value)}
                />
              </Form.Item>
            )}

            {/* Aql input */}
            {searchType === VIEW_SEARCH_ADVANCED && (
              <Form.Item name="aql" rules={[{ validator: validateValidAql }]} className="m-0">
                <Input
                  ref={refInput}
                  placeholder={t('common.enterAql')}
                  prefix={<SearchOutlined className="text-gray font-size-20" />}
                  bordered={false}
                  className="bg-transparent box-shadow-none px-0 my-1"
                  onPressEnter={() => searchForm.submit()}
                />
              </Form.Item>
            )}
          </Col>

          {!hideAdvanced && <Col>{renderSwitchBasicButton()}</Col>}
        </Row>
      </Form>
    </div>
  );
};
