import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import {
  COMPONENT_TYPE,
  OPERATION_VALUE_OR,
  OPERATION_VALUE_REGEX,
  ORDER_BY_KEY_DESC,
  PRIORITIES,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_PRIORITY
} from '../../../constants';
import { debounce, getFieldName, getObjectByValue } from '../../../common/utils';

let typingTimerOfSearch = 0;

export const FieldRelation = ({
  field,
  currentValues,
  allowSearch = true,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  const BLANK = {
    label: t('common.blank'),
    value: ''
  };

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);
  const projectUserListData = useStoreState(state => state.global.projectUserList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [formData, setFormData] = useState([]);
  const [options, setOptions] = useState([BLANK]);
  const [formOption, setFormOption] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const displayFieldRelation = field.data.displayField || SYSTEM_FIELD_KEY;
  const [loading, setLoading] = useState(false);

  /**
   * Project user list
   */
  const projectUserList = useMemo(() => {
    return Array.isArray(projectUserListData) && projectUserListData.length ? [...projectUserListData] : [];
  }, [projectUserListData]);

  /**
   * Set option list
   */

  useEffect(() => {
    if (
      !field?.refName ||
      !field?.data?.url ||
      !(Array.isArray(suggestion?.[field?.refName]?.data) && suggestion?.[field?.refName]?.data.length)
    ) {
      setOptions([BLANK]);
      return;
    }

    const newOptionList = suggestion[field.refName].data.map(item => {
      return {
        label: objectPath.get(item, displayFieldRelation),
        value: objectPath.get(item, field.data.fieldValue),
        key: objectPath.get(item, SYSTEM_FIELD_KEY)
      };
    });
    setOptions([BLANK, ...newOptionList]);
  }, [t, field, suggestion]);

  /**
   * Compute current selected options from currentValues
   */

  const currentSelectedOptions = useMemo(() => {
    if (
      !field?.refName ||
      !(Array.isArray(currentValues) && currentValues.length)
      // ||
      // !(Array.isArray(options) && options.length)
    ) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.refName === field.refName);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(
      item => currentValue.includes(item.value) || currentValue.includes(Number(item.value))
    );

    return newSelectedOptions?.length ? newSelectedOptions : currentValue;
  }, [field, options, currentValues]);

  /**
   * Handle get suggestion when mounted
   */
  const handleGetSuggestionWhenMounted = async () => {
    const param = {
      referenceField: field.refName,
      url: field.data.url,
      limit: 10,
      page: 1,
      order: ORDER_BY_KEY_DESC,
      group: field.data.fieldValue,
      select: `${field.data.fieldValue} ${SYSTEM_FIELD_KEY} ${displayFieldRelation}`
    };
    if (field.componentType === COMPONENT_TYPE.RELATION) {
      param.filter = {
        'workTicketType.id': field.lookup.workTicketId
      };
    }

    const res = await getSuggestion(param);

    if (res) {
      const newOptionList = res?.rows.map(item => {
        return {
          label: objectPath.get(item, displayFieldRelation),
          value: objectPath.get(item, field.data.fieldValue),
          key: objectPath.get(item, SYSTEM_FIELD_KEY)
        };
      });

      const newSelectedOptions = newOptionList.filter(
        item => currentValues.includes(item.value) || currentValues.includes(Number(item.value))
      );
      setSelectedOptions(newSelectedOptions);
      setFormOption({
        limit: 10,
        page: 1
      });
      setFormData({
        rows: res?.rows || [],
        count: res?.count || 0
      });
    }
  };

  /**
   * On visible change
   */
  const onVisibleChange = async visible => {
    setVisibleDropdown(visible);

    if (visible) {
      handleGetSuggestionWhenMounted();
    } else {
      handleSearch('');
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (loadingSuggestion) {
      return;
    }

    let newSelectedValues =
      Array.isArray(selectedOptions) && selectedOptions.length
        ? [...selectedOptions].filter(item => item.value !== currentOption.value)
        : [];

    if (checked) {
      newSelectedValues = [...newSelectedValues, currentOption];
    }

    setSelectedOptions(newSelectedValues);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) {
      return;
    }

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(async () => {
        // Reset
        setFormOption({
          limit: 10,
          page: 1
        });
        setFormData({
          ...formData,
          rows: []
        });

        const queryObj = { [OPERATION_VALUE_OR]: [] };

        if (field.data?.suggestionBy && Array.isArray(field.data.suggestionBy) && field.data.suggestionBy.length) {
          field.data.suggestionBy.forEach(sub => {
            queryObj[OPERATION_VALUE_OR].push({
              [sub]: { [OPERATION_VALUE_REGEX]: val, $options: 'i' }
            });
          });
        }

        const res = await getSuggestion({
          referenceField: field.refName,
          url: field.data?.url,
          page: 0,
          limit: 10,
          order: ORDER_BY_KEY_DESC,
          group: field.data.fieldValue,
          select: `${field.data.fieldValue} ${SYSTEM_FIELD_KEY} ${displayFieldRelation}`,
          filter: {
            'workTicketType.id': field.lookup.workTicketId,
            ...(queryObj[OPERATION_VALUE_OR].length ? queryObj : {})
          }
        });

        if (res) {
          setFormOption({
            limit: 10,
            page: 1
          });
          setFormData({
            rows: res?.rows || [],
            count: res?.count || 0
          });

          document
            .querySelector('.c-field-relation-dropdown .ant-dropdown-menu')
            ?.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }),
      300
    );
  };

  /**
   * Render text utils
   */
  const renderTextUtilRenderPriotity = dataRow => {
    const priority = getObjectByValue(objectPath.get(dataRow, 'label'), PRIORITIES);

    if (dataRow?.label === BLANK.label) {
      return BLANK.label;
    }

    return (
      <span title={priority?.label} className="text-truncate">
        {dataRow?.key} - {priority?.icon} {priority?.label}
      </span>
    );
  };
  const renderTextUtilRenderOtherCase = dataRow => {
    const typeUser = ['assignTo', 'createdBy', 'updatedBy', 'executedBy'];

    if (typeUser.includes(field?.data?.displayField)) {
      const isUserInProject = projectUserList.some(u => u?.username === dataRow?.label?.username);

      if (dataRow?.label === BLANK.label) {
        return BLANK.label;
      }

      return (
        <>
          {dataRow?.key}{' '}
          {dataRow?.label
            ? `- ${dataRow?.label?.username} ${!isUserInProject ? `(${t('common.inactive')})` : ''}`
            : null}
        </>
      );
    } else {
      return dataRow?.label === BLANK.label ? (
        BLANK.label
      ) : (
        <>
          {dataRow?.key} {dataRow?.label ? `- ${dataRow?.label}` : null}
        </>
      );
    }
  };

  /**
   * Render text
   */
  const renderText = dataRow => {
    switch (field?.data?.displayField) {
      case SYSTEM_FIELD_PRIORITY: {
        return renderTextUtilRenderPriotity(dataRow);
      }

      default: {
        return renderTextUtilRenderOtherCase(dataRow);
      }
    }
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    const selectedValue =
      Array.isArray(selectedOptions) && selectedOptions.length ? selectedOptions.map(item => item.value) : [];

    return (
      <div className="ant-dropdown-menu c-field-relation-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingSuggestion}>
          {allowSearch && (
            <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
              <Input
                placeholder={t('common.search')}
                suffix={<SearchOutlined />}
                autoFocus
                autoComplete="off"
                allowClear
                size="small"
                onChange={e => handleSearch(e?.target?.value)}
              />
            </div>
          )}

          {!(Array.isArray(options) && options.length > 0) && (
            <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
              <li
                className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                onClick={e => e.stopPropagation()}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
              </li>
            </ul>
          )}

          {Array.isArray(options) && options.length > 0 && (
            <ul
              className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
              onClick={e => e.stopPropagation()}
              onScroll={async e => {
                const { target } = e;
                if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
                  // if not load all;
                  if (formData?.rows?.length < formData?.count || formData.length == 0) {
                    setLoading(true);
                    const currentPage = formOption?.page;
                    const newPage = currentPage + 1;
                    const params = {
                      referenceField: field?.refName,
                      url: field?.data.url,
                      limit: 10,
                      page: newPage,
                      group: field?.data.fieldValue,
                      order: ORDER_BY_KEY_DESC,
                      select: `${field.data.fieldValue} ${SYSTEM_FIELD_KEY} ${displayFieldRelation}`,
                      filter: field?.data.filter || null
                    };
                    const res = await getSuggestion(params);

                    const newData = {
                      rows: [...formData?.rows, ...res?.rows],
                      count: res?.count
                    };

                    const newOptions = newData.rows.map(item => {
                      return {
                        label: objectPath.get(item, displayFieldRelation),
                        value: objectPath.get(item, field.data.fieldValue),
                        key: objectPath.get(item, SYSTEM_FIELD_KEY)
                      };
                    });

                    setOptions([BLANK, ...newOptions]);
                    setFormData(newData);

                    setFormOption(prev => ({ ...prev, page: newPage }));
                    setLoading(false);
                  }
                }
              }}
            >
              {options.map(item => (
                <li
                  key={item.value}
                  title={typeof item?.label === 'string' ? item?.label : item?.label?.username}
                  className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox
                    checked={selectedValue.includes(item.value)}
                    className="w-100"
                    onChange={e => handleChangeSelected(e?.target?.checked, item)}
                  >
                    {renderText(item)}
                  </Checkbox>
                </li>
              ))}
            </ul>
          )}

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
                handleSearch('');
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                !selectedOptions.length && handleSearch('');
                const newSelectedOptions = selectedOptions.map(selected => selected.value);
                onApply(newSelectedOptions);
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    const hasCurrentSelectedOptions = Array.isArray(currentSelectedOptions) && currentSelectedOptions.length > 0;

    return hasCurrentSelectedOptions
      ? `${fieldName}: ${currentSelectedOptions.map(item => item?.label).join(', ')}`
      : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, currentSelectedOptions]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        disabled={disabled}
        onOpenChange={onVisibleChange}
        {...restDropdown}
      >
        <Button
          title={btnFieldTitle}
          className={`btn-field field-relation btn-toggle-dropdown-with-checkbox-list ${className}`}
          {...rest}
        >
          {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {currentSelectedOptions.map(item => item?.label || item.key).join(', ')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
