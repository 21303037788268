import React from 'react';
import { useStoreState } from 'easy-peasy';
import { ArrowRightOutlined } from '@ant-design/icons';

import { HISTORY_TYPE_LIST, HISTORY_TYPE_CREATE } from '../../constants';
import { useActivityHistoryRendering } from '../../common/hooks';
import { UserAvatar } from '..';

const PanelContent = ({ item, fieldList, workTicketId }) => {
  // For activity history
  const { getRendererActivityHistory } = useActivityHistoryRendering();

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  /**
   * Render history type create
   */
  const renderHistoryTypeCreate = item => {
    const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
    const isUserInProject = projectUsers.some(u => u?.username === item?.createdBy?.username);

    return (
      <div className="panel-content">
        <UserAvatar user={item?.createdBy} inactive={!isUserInProject} />
        <div className="mx-1">{HISTORY_TYPE_LIST[item?.type]} the </div>
        <div>{item?.objectiveTypeName ? item?.objectiveTypeName : ''}</div>
      </div>
    );
  };

  /**
   * Render old value
   */
  const renderOldValue = item => {
    return (
      <ul className="list-style-none p-0 m-0">
        {item?.oldValue ? (
          Object.keys(item?.oldValue)
            .filter(key => key !== 'noReload')
            .map((key, index) => {
              const value = item?.oldValue[key];

              if (!value || (Array.isArray(value) && !value.length)) {
                return (
                  <li key={`${key}-${index}`} className="text-gray">
                    None
                  </li>
                );
              }

              return getRendererActivityHistory({ key, index, value, fieldList, workTicketId });
            })
        ) : (
          <li className="text-gray">None</li>
        )}
      </ul>
    );
  };

  /**
   * Render new value
   */
  const renderNewValue = item => {
    return (
      <ul className="list-style-none p-0 m-0">
        {item?.newValue ? (
          Object.keys(item?.newValue)
            .filter(key => key !== 'noReload')
            .map((key, index) => {
              const value = item?.newValue[key];

              if (!value || (Array.isArray(value) && !value.length)) {
                return (
                  <li key={`${key}-${index}`} className="text-gray">
                    None
                  </li>
                );
              }

              return getRendererActivityHistory({ key, index, value, fieldList, workTicketId });
            })
        ) : (
          <li className="text-gray">None</li>
        )}
      </ul>
    );
  };

  /**
   * Render panel content
   */
  const renderPanelContent = item => {
    if (!item) {
      return;
    }

    switch (item?.type) {
      case HISTORY_TYPE_CREATE: {
        return renderHistoryTypeCreate(item);
      }

      default:
        return (
          <>
            <div className="panel-value">
              {renderOldValue(item)}

              <ArrowRightOutlined className="px-2" />

              {renderNewValue(item)}
            </div>
          </>
        );
    }
  };

  return renderPanelContent(item);
};

export default PanelContent;
