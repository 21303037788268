import { PureComponent } from 'react';

export default class PanViewer extends PureComponent {
  static defaultProps = {
    enablePan: true,
    onPan: () => undefined,
    onReset: () => undefined,
    pandx: 0,
    pandy: 0,
    zoom: 0,
    rotation: 0
  };

  panWrapper;
  panContainer;

  getInitialState = () => {
    const { pandx, pandy, zoom } = this.props;
    const defaultDragData = {
      dx: pandx,
      dy: pandy,
      x: 0,
      y: 0
    };

    return {
      comesFromDragging: false,
      dragData: defaultDragData,
      dragging: false,
      matrixData: [
        zoom,
        0,
        0,
        zoom,
        pandx,
        pandy // [zoom, skew, skew, zoom, dx, dy]
      ],
      mouseDown: false
    };
  };

  state = this.getInitialState();

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { matrixData } = this.state;

    if (matrixData[0] !== nextProps.zoom) {
      const newMatrixData = [...this.state.matrixData];
      newMatrixData[0] = nextProps.zoom || newMatrixData[0];
      newMatrixData[3] = nextProps.zoom || newMatrixData[3];

      this.setState({ matrixData: newMatrixData });
    }
  }

  reset = () => {
    const matrixData = [0.4, 0, 0, 0.4, 0, 0];

    this.setState({ matrixData });

    if (this.props.onReset) {
      this.props.onReset(0, 0, 1);
    }
  };

  onClick = e => {
    if (this.state.comesFromDragging) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  onTouchStart = e => {
    const { pageX, pageY } = e.touches[0];

    this.panStart(pageX, pageY, e);
  };

  onTouchEnd = () => {
    this.onMouseUp();
  };

  onTouchMove = e => {
    this.updateMousePosition(e.touches[0].pageX, e.touches[0].pageY);
  };

  onMouseDown = e => {
    this.panStart(e.pageX, e.pageY, e);
  };

  panStart = (pageX, pageY, event) => {
    if (!this.props.enablePan) {
      return;
    }

    const { matrixData } = this.state;
    const offsetX = matrixData[4];
    const offsetY = matrixData[5];
    const newDragData = {
      dx: offsetX,
      dy: offsetY,
      x: pageX,
      y: pageY
    };

    this.setState({
      dragData: newDragData,
      mouseDown: true
    });

    if (this.panWrapper) {
      this.panWrapper.style.cursor = 'move';
    }

    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    event.preventDefault();
  };

  onMouseUp = () => {
    this.panEnd();
  };

  panEnd = () => {
    this.setState({
      comesFromDragging: this.state.dragging,
      dragging: false,
      mouseDown: false
    });

    if (this.panWrapper) {
      this.panWrapper.style.cursor = '';
    }

    if (this.props.onPan) {
      this.props.onPan(this.state.matrixData[4], this.state.matrixData[5]);
    }
  };

  preventDefault(e) {
    e = e || window.event;

    if (e.preventDefault) {
      e.preventDefault();
    }

    e.returnValue = false;
  }

  onMouseMove = e => {
    this.updateMousePosition(e.pageX, e.pageY);
  };

  onWheel = e => {
    Math.sign(e.deltaY) < 0
      ? this.props.setZoom((this.props.zoom || 0) + 0.1)
      : (this.props.zoom || 0) > 1 && this.props.setZoom((this.props.zoom || 0) - 0.1);
  };

  onMouseEnter = () => {
    document.addEventListener('wheel', this.preventDefault, { passive: false });
  };

  onMouseLeave = () => {
    document.removeEventListener('wheel', this.preventDefault, false);
  };

  updateMousePosition = (pageX, pageY) => {
    if (!this.state.mouseDown) {
      return;
    }

    const matrixData = this.getNewMatrixData(pageX, pageY);

    this.setState({
      dragging: true,
      matrixData
    });

    if (this.panContainer) {
      this.panContainer.style.transform = this.state.matrixData
        ? `matrix(${this.state.matrixData?.toString()})`
        : 'none';
    }
  };

  getNewMatrixData = (x, y) => {
    const { dragData, matrixData } = this.state;
    const deltaX = dragData.x - x;
    const deltaY = dragData.y - y;

    matrixData[4] = dragData.dx - deltaX;
    matrixData[5] = dragData.dy - deltaY;

    return matrixData;
  };

  componentWillUnmount() {
    document.removeEventListener('wheel', this.preventDefault, false);
  }

  render() {
    return (
      <div
        ref={ref => (this.panWrapper = ref)}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onTouchStart={this.onTouchStart}
        onTouchMove={this.onTouchMove}
        onTouchEnd={this.onTouchEnd}
        onMouseMove={this.onMouseMove}
        onWheel={this.onWheel}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onClick}
        style={{
          height: this.props.height,
          userSelect: 'none',
          width: this.props.width
        }}
        className={`pan-container ${this.props.className || ''}`}
      >
        <div
          ref={ref => (ref ? (this.panContainer = ref) : null)}
          style={{
            transform: this.state.matrixData ? `matrix(${this.state.matrixData?.toString()})` : 'none'
          }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
