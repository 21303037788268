import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Form, Spin, Modal, Button, Select, Divider, Row, Input } from 'antd';
import { CloseOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { SYSTEM_FIELD_ASSIGN_TO } from '../../constants';
import {
  removeTokenToRawHtml,
  convertUserForSubmitData,
  filterOptionForUserField,
  getUserListByRoleFromStore
} from '../../common/utils';
import { UserAvatar, BasicEditor } from '..';

export const UpdateTicketStatusModal = ({
  fromModule,
  visible,
  workflow,
  currentStep,
  uploadPath,
  isReadOnly,
  loading = false,
  className = '',
  onChangeStatus,
  onEditorAttachFiles,
  onCancel,
  ...rest
}) => {
  const refAssigneeSelect = useRef(null);
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For user list by role store
  const getUserListByRole = useStoreActions(action => action.global.getUserListByRole);
  const userListByRole = useStoreState(state => state.global.userListByRole);
  const loadingUserListByRole = useStoreState(state => state.global.loadingUserListByRole);

  // Component state
  const [isOpenAssigneeSelection, setIsOpenAssigneeSelection] = useState(false);
  const [visibleEditor, setVisibleEditor] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  /**
   * Set focus
   */
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        refAssigneeSelect?.current && refAssigneeSelect.current.focus();
        setIsOpenAssigneeSelection(true);
      }, 450);
    }
  }, [visible]);

  /**
   * Current user list by role
   */
  const targetStatus = useMemo(() => {
    if (!currentStep || !(Array.isArray(workflow?.listStates) && workflow?.listStates.length)) {
      return;
    }

    const newTargetStatus = workflow.listStates.find(item => item.id === currentStep.to);

    return newTargetStatus;
  }, [workflow, currentStep]);

  /**
   * Current user list by role
   */
  const currentUserListByRole = useMemo(() => {
    const roleKeys = targetStatus?.assignmentRoles;
    const newUserListByRole = getUserListByRoleFromStore({ roleKeys, userListByRole });

    return newUserListByRole;
  }, [userListByRole, targetStatus]);

  /**
   * Get user list by role
   */
  useEffect(() => {
    if (!targetStatus?.id) {
      return;
    }

    getUserListByRole({ roleKeys: targetStatus?.assignmentRoles });
  }, [targetStatus, userListByRole, getUserListByRole]);

  /**
   * Set values to form
   */
  useEffect(() => {
    if (!globalUserInfo?.id || !(Array.isArray(currentUserListByRole) && currentUserListByRole.length)) {
      return;
    }

    if (currentUserListByRole.some(item => item?.username === globalUserInfo.username)) {
      form.setFieldsValue({ assignTo: globalUserInfo.username });
    }
  }, [globalUserInfo, currentUserListByRole]);

  /**
   * On submit
   */
  const onSubmit = async () => {
    const values = await form.validateFields();

    if (!values) {
      return;
    }

    const formData = {};

    if (values.assignTo) {
      const users =
        Array.isArray(currentUserListByRole) && currentUserListByRole.length ? [...currentUserListByRole] : [];
      const user = users.find(item => item?.username === values.assignTo);
      formData[SYSTEM_FIELD_ASSIGN_TO] = convertUserForSubmitData(user);
    }

    if (values.comment) {
      formData.comment = values.comment;
    }

    onChangeStatus(formData);
  };

  return (
    <Modal
      title={currentStep?.name || ''}
      open={visible}
      width={1160}
      maskClosable={true}
      keyboard={true}
      footer={null}
      forceRender // For fix: Instance created by `useForm` is not connected to any Form element.
      centered // For "modal-fixed-header"
      wrapClassName="modal-fixed-header" // Enable "centered" mode, wrap content by class "modal-body-with-scroll"
      className={`c-update-ticket-status-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
      onCancel={onCancel}
      {...rest}
    >
      <Form form={form} layout="vertical">
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">{currentStep?.name || ''}</h3>

            <Button
              id="close-button_update-ticket-status-modal"
              title={t('common.cancel')}
              type="text"
              icon={<CloseOutlined className="font-size-14" />}
              size="small"
              className="text-gray-2 text-hover-black border-0 bg-transparent w-auto h-auto p-0"
              onClick={onCancel}
            />
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
              <Form.Item
                label={t('workItem.assignTo')}
                name="assignTo"
                rules={[{ required: true, message: t('message.required') }]}
              >
                <Select
                  ref={refAssigneeSelect}
                  open={isOpenAssigneeSelection}
                  options={
                    Array.isArray(currentUserListByRole) && currentUserListByRole.length
                      ? currentUserListByRole.map(item => {
                          return {
                            label: <UserAvatar user={item} />,
                            value: item.username
                          };
                        })
                      : []
                  }
                  filterOption={filterOptionForUserField}
                  showSearch
                  allowClear
                  placeholder={t('common.searchUser')}
                  className="max-w-300"
                  loading={loadingUserListByRole}
                  onDropdownVisibleChange={setIsOpenAssigneeSelection}
                />
              </Form.Item>

              {visibleEditor && (
                <Form.Item label={t('comment.comment')} name="comment">
                  {fromModule === 'EDITABLE_CELL' ? (
                    <Input.TextArea rows={10} placeholder={t('comment.enterComment')} />
                  ) : (
                    <BasicEditor
                      restEditor={{ placeholder: t('comment.enterComment') }}
                      uploadPath={uploadPath}
                      onEditorAttachFiles={file => {
                        typeof onEditorAttachFiles === 'function' && onEditorAttachFiles(file);
                      }}
                      onEditorRawHtmlChange={val => {
                        form.setFieldsValue({ comment: removeTokenToRawHtml({ rawHtml: val }) });
                      }}
                    />
                  )}
                </Form.Item>
              )}
            </Spin>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button
              id="submit-button_update-ticket-status-modal"
              type="primary"
              loading={loading}
              disabled={isReadOnly}
              onClick={() => onSubmit()}
            >
              {currentStep?.name || ''}
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};
