import React, { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import { PROJECT_MANAGER_MENU } from '../constants';
import { getParentMenuByPathname } from '../common/utils';
import { LeftSidebar, MainHeader, MainFooter, RouterLoading, ImportProgress, ProgressBar } from '../components';

const { Content } = Layout;

const DefaultLayout = ({ children, className = '' }) => {
  const location = useLocation();

  return (
    <Suspense fallback={<RouterLoading />}>
      <Layout className={`l-default ${className}`}>
        <MainHeader />

        <Layout className="main-body">
          <LeftSidebar />

          <Layout>
            {getParentMenuByPathname(location.pathname, [...PROJECT_MANAGER_MENU]) ? <ProgressBar /> : null}

            <Content className="main-content">
              {/* <MaintenanceAlert className="mt-3 mx-3" /> */}
              {children}
            </Content>
            <MainFooter />
          </Layout>
        </Layout>
      </Layout>

      <ImportProgress />
    </Suspense>
  );
};

export default DefaultLayout;
