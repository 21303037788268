import { action } from 'easy-peasy';

export default {
  /**
   * State
   */
  globalProductionKey: null,

  /**
   * Action: Set current global production Key
   */
  setGlobalProductionKey: action((state, payload) => {
    if (!(state?.globalProductionKey !== undefined)) {
      return;
    }

    if (payload && state.globalProductionKey !== payload) {
      state.globalProductionKey = payload;
    }
  })
};
