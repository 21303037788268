import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Dropdown, Tooltip, DatePicker, Select, Input } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import {
  COMPONENT_TYPE,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  OPERATION_VALUE_IN_RANGE,
  OPERATION_VALUE_NOT_IN_RANGE
} from '../../../constants';
import { getFieldName } from '../../../common/utils';

export const FieldDate = ({
  field,
  currentValues,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentOperation, setCurrentOperation] = useState(null);

  /**
   * Check is range
   */
  const checkIsRange = operation => {
    return operation === OPERATION_VALUE_IN_RANGE || operation === OPERATION_VALUE_NOT_IN_RANGE;
  };

  /**
   * Has current values
   */
  const hasCurrentValues = useMemo(() => {
    return Array.isArray(currentValues) && currentValues.length > 0;
  }, [currentValues]);

  /**
   * Check invalid date range
   */
  const checkInvalidDateRange = ({ isRange, newField }) => {
    const validDates =
      Array.isArray(newField?.value) &&
      newField?.value.length &&
      newField?.value.every(sub => sub && moment(sub).isValid());

    return isRange && !validDates;
  };

  /**
   * Check invalid null date
   */
  const checkInvalidNullDate = ({ isRange, newField }) => {
    const validDate =
      (newField?.value && moment(newField?.value).isValid()) ||
      (newField?.operation === 'isNull' && newField?.value === null);

    return !isRange && !validDate;
  };

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.refName || !hasCurrentValues) {
      return null;
    }

    const newField = [...currentValues].find(item => item.refName === field?.refName);
    const isRange = checkIsRange(newField?.operation);

    if (checkInvalidDateRange({ isRange, newField })) {
      return null;
    } else if (checkInvalidNullDate({ isRange, newField })) {
      return null;
    } else {
    }

    if (isRange) {
      newField.value = [...newField.value].map(sub => moment(sub));
    } else {
      newField.value = moment(newField.value);
    }

    return newField;
  }, [field, hasCurrentValues, currentValues]);

  /**
   * Compute date format
   */
  const dateFormat = useMemo(() => {
    return field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
  }, [field]);

  /**
   * Set current operation
   */
  useEffect(() => {
    if (!field?.refName) {
      return;
    }

    const newCurrentOperation = currentField?.operation
      ? currentField?.operation
      : Array.isArray(field.supportedOperations) && field.supportedOperations.length
      ? field.supportedOperations[0]
      : null;

    setCurrentOperation(newCurrentOperation);
  }, [field, currentField]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setCurrentDate(currentField?.value || null);
    }
  };

  /**
   * Handle apply utils
   */
  const handleApplyUtilCheckValidRangeDate = ({ isRange, currentDate }) => {
    const validDates =
      Array.isArray(currentDate) && currentDate.length === 2 && currentDate.every(sub => sub && moment(sub).isValid());

    return isRange && validDates;
  };
  const handleApplyUtilCheckValidSingleDate = ({ isRange, currentDate }) => {
    return !isRange && currentDate && moment(currentDate.isValid());
  };

  /**
   * Handle apply
   */
  const handleApply = () => {
    let newDate = null;
    const isRange = checkIsRange(currentOperation);

    if (handleApplyUtilCheckValidRangeDate({ isRange, currentDate })) {
      newDate = [currentDate[0], currentDate[1]];
    } else if (handleApplyUtilCheckValidSingleDate({ isRange, currentDate })) {
      newDate = currentDate;
    } else {
    }

    onApply(newDate, currentOperation);
    setTimeout(() => setVisibleDropdown(false), 300);
  };

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const isOperationRangeDate = checkIsRange(currentOperation);

    const items = [
      {
        key: 'field',
        label: (
          <span className="ant-input-group-wrapper">
            <span className="ant-input-wrapper ant-input-group">
              <span className="ant-input-group-addon">
                <Select
                  value={currentOperation}
                  options={
                    Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
                      ? field.supportedOperations.map(sub => {
                          return {
                            label: sub,
                            value: sub
                          };
                        })
                      : []
                  }
                  dropdownMatchSelectWidth={false}
                  onSelect={val => {
                    setCurrentDate(null);
                    setCurrentOperation(val);
                  }}
                />
              </span>

              {currentOperation === 'isNull' ? (
                <Input disabled />
              ) : isOperationRangeDate ? (
                <DatePicker.RangePicker
                  value={Array.isArray(currentDate) && currentDate.length ? currentDate : []}
                  format={FULL_DATE_FORMAT}
                  showTime={{ format: 'HH:mm' }}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                />
              ) : field.componentType === COMPONENT_TYPE.DATE_TIME ? (
                <DatePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={{ format: 'HH:mm' }}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                  disabled={currentOperation === 'isNull'}
                />
              ) : (
                <DatePicker
                  value={currentDate}
                  format={SHORT_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={false}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                  disabled={currentOperation === 'isNull'}
                />
              )}
            </span>
          </span>
        ),
        className: 'bg-transparent'
      }
    ];

    items.push(
      { type: 'divider' },
      {
        key: 'box-footer',
        label: (
          <>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setCurrentDate(null);
                onClear();
              }}
            >
              {t('common.clear')}
            </Button>

            <Button type="primary" size="small" className="w-auto ml-2" onClick={handleApply}>
              {t('common.apply')}
            </Button>
          </>
        ),
        className: 'bg-transparent text-right cursor-default'
      }
    );

    return items;
  }, [currentOperation, field, currentDate, dateFormat]);

  /**
   * Btn field title
   */
  const dateText = useMemo(() => {
    const isRange = checkIsRange(currentField?.operation);
    const validRangeDate = currentField && isRange && Array.isArray(currentField?.value) && currentField?.value.length;

    return currentField?.operation === 'isNull'
      ? ''
      : validRangeDate
      ? `${moment(currentField?.value[0]).format(dateFormat)} ⇀ ${moment(currentField?.value[1]).format(dateFormat)}`
      : currentField
      ? moment(currentField?.value).format(dateFormat)
      : 'N/A';
  }, [currentField, dateFormat]);

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    return currentField ? `${fieldName}: ${currentField?.operation} ${dateText}` : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, dateText, currentField]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{ items: menus, selectable: false }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ minWidth: 290 }}
        disabled={disabled}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button title={btnFieldTitle} className={`btn-field field-date ${className}`} {...rest}>
          {currentField ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {currentField?.operation} {dateText}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
