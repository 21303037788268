import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  globalProcessTenantList: null,
  globalProcessList: null,
  globalCurrentProcess: null,
  loadingProcessList: false,

  /**
   * Action: Set global process list
   */
  setGlobalProcessList: action((state, payload) => {
    if (!(state?.globalProcessList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.globalProcessList = [];
      return;
    }

    state.globalProcessList = payload;
  }),

  /**
   * Action: Set global process tenant list
   */
  setGlobalProcessTenantList: action((state, payload) => {
    if (!(state?.globalProcessTenantList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.globalProcessTenantList = [];
      return;
    }

    state.globalProcessTenantList = payload;
  }),

  /**
   * Action: Set loading process list
   */
  setLoadingProcessList: action((state, payload) => {
    if (!(state?.loadingProcessList !== undefined)) {
      return;
    }

    state.loadingProcessList = payload;
  }),

  /**
   * Action: Set loading process
   */
  setGlobalCurrentProcess: action((state, payload) => {
    if (!(state?.globalCurrentProcess !== undefined)) {
      return;
    }

    if (!payload) {
      state.globalCurrentProcess = null;
      return;
    }

    state.globalCurrentProcess = payload;
  }),

  /**
   * Action: Call api to get process list
   */
  getProcessList: thunk(async (action, payload) => {
    try {
      if (!payload?.projectKey || !payload?.tenantKey) {
        throw new Error('Get process list: Invalid Params');
      }

      action.setLoadingProcessList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${payload.tenantKey}/${payload.projectKey}${ENDPOINTS.PROCESS}`;
      const res = await Http.get(url).then(res => res.data);

      action.setGlobalProcessList(res?.data?.template);
      action.setGlobalCurrentProcess(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProcessList(false);
    }
  }),

  /**
   * Action: Call api to get process tenant list
   */
  getProcessTenantList: thunk(async (action, payload, helpers) => {
    try {
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }
      action.setLoadingProcessList(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.SYSTEM}/${tenant?.tenantKey}${ENDPOINTS.PROCESS}`;
      const res = await Http.get(url).then(res => res.data);

      action.setGlobalProcessTenantList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProcessList(false);
    }
  })
};
