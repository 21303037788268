import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { sleep } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  previewData: [],
  loadingPreviewImportTestcaseExcel: false,

  /**
   * Action: Set preview data
   */
  setPreviewData: action((state, payload) => {
    if (state?.previewData === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.previewData = [];
      return;
    }

    state.previewData = payload.data;
  }),

  /**
   * Action: Set loading preview import testcase excel
   */
  setLoadingPreviewTestcaseExcel: action((state, payload) => {
    if (state?.loadingPreviewImportTestcaseExcel === undefined) {
      return;
    }

    state.loadingPreviewImportTestcaseExcel = payload;
  }),

  /**
   * Action: Call api to preview import testcase excel
   */
  previewImportTestcaseExcel: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Preview import testcase excel: Invalid Params');
      }

      action.setLoadingPreviewTestcaseExcel(true);

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.IMPORT_PREVIEW}/test-case`;
      const res = await Http.post(url, payload).then(res => res.data);

      if (res?.data) {
        action.setPreviewData(res.data);
        return res.data;
      }
      action.setLoadingPreviewTestcaseExcel(false);
    } catch (err) {
      if (err?.status === 400 && err.data?.message.includes('Sheet name is not exist')) {
        return err;
      }
      handleError(err);
    } finally {
      action.setLoadingPreviewTestcaseExcel(false);
    }
  }),

  /**
   * Action: Call api to import test case
   */
  importTestCase: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;
      const setImportInfo = helpers.getStoreActions()?.global?.setImportInfo;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Import test case: Invalid Params');
      }

      if (!(Array.isArray(payload?.data) && payload?.data.length)) {
        return;
      }

      const url = `${ENDPOINTS.BIG_DATA}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TESTCASE_IMPORT}`;
      const batchSize = 50;
      const totalRecords = payload?.data.length;
      const onlyOneBatch = totalRecords <= batchSize;

      for (let i = 0; i < totalRecords; i += batchSize) {
        // Chia từng batch 100 record và import
        const batchRecords = payload?.data.slice(i, i + batchSize);
        await Http.post(url, batchRecords).then(res => res.data);

        // Tính toán tiến trình (%)
        const progressPercent = Math.round(((i + batchSize) / totalRecords) * 100);

        // Cập nhật tiến trình trên giao diện
        setImportInfo({
          key: payload?.key,
          info: {
            workTicketIcon: payload?.workTicketIcon,
            title: payload?.title,
            fileName: payload?.fileName,
            percent: Math.min(progressPercent, 100)
          }
        });
      }

      // Dành cho chỉ có một vòng lặp (<= 100 records), cần delay để hiển thị tiến trình chạy được 100%
      if (onlyOneBatch) {
        await sleep(1000);
      }

      notification.success({
        message: i18next.t('akaat:testRepo.messageImportingTestCase'),
        description: i18next.t('akaat:message.importedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  })
};
