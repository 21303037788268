import React, { useMemo } from 'react';
import { useStoreActions } from 'easy-peasy';

import { SYSTEM_FIELD_LATEST_RESULT_STATUS } from '../../constants';
import Cell from './cell';

export const EditableCell = ({
  fromModule,
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  currentTreeItem,
  disableEditingCells,
  hasAllowAssignToPermission,
  allowQuickExecute,
  isReadOnly,
  restKeyField,
  restAttachmentsField,
  onSave,
  onChangeEditingCell,
  onReload
}) => {
  // For user list by role store
  const getUserListByRole = useStoreActions(action => action.global.getUserListByRole);

  // For suggestion
  const getAllSuggestion = useStoreActions(action => action.global.getAllSuggestion);

  /**
   * Form item name
   * Is editable field
   * Current read only
   */
  const formItemName = useMemo(() => `key-${row?.key}-${field?.refName}`, [row, field]);
  const isEditableField = useMemo(
    () => field?.refName === SYSTEM_FIELD_LATEST_RESULT_STATUS || (field?.isInsert && !field?.isRelationDisplayField),
    [field]
  );
  const currentReadOnly = useMemo(() => isReadOnly || !isEditableField, [isReadOnly, field]);

  /**
   * Render cell
   */
  const renderCell = () => {
    return (
      <Cell
        fromModule={fromModule}
        workTicketId={workTicketId}
        x={x}
        y={y}
        row={row}
        field={field}
        tableForm={tableForm}
        formItemName={formItemName}
        currentTreeItem={currentTreeItem}
        disableEditingCells={disableEditingCells}
        hasAllowAssignToPermission={hasAllowAssignToPermission}
        allowQuickExecute={allowQuickExecute}
        isReadOnly={disableEditingCells || currentReadOnly}
        isEditableField={!disableEditingCells && isEditableField}
        restKeyField={restKeyField}
        restAttachmentsField={restAttachmentsField}
        onGetUserListByRole={getUserListByRole}
        onGetSuggestion={getAllSuggestion}
        onSave={onSave}
        onChangeEditingCell={onChangeEditingCell}
        onReload={onReload}
      />
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCell();
  }

  return <div className="c-editable-cell">{renderCell()}</div>;
};
