import { useMemo } from 'react';
import { useStoreState } from 'easy-peasy';

import {
  ROLE_PROJECT_OWNER,
  ROLE_PROJECT_MANAGER,
  P_PROJECT_CHART_SETUP,
  ROLE_PROJECT_TEST_LEADER,
  P_TENANT_SETTING_PROJECT_SETUP,
  P_TEST_MANAGER_ALLOW_ASSIGN_TO,
  P_TEST_MANAGER_TRACEABILITY_SETUP,
  P_PROJECT_SETTING_TEST_CONFIG_SETUP,
  P_TENANT_SETTING_UPDATE_INFORMATION,
  P_PROJECT_MANAGER_WORK_TICKET_UPDATE,
  P_PROJECT_SETTING_UPDATE_INFORMATION,
  P_TEST_MANAGER_PLANNING_RELEASE_SETUP,
  P_TEST_MANAGER_REPOSITORY_FOLDER_SETUP,
  P_TEST_MANAGER_PLANNING_TEST_PLAN_SETUP,
  P_TEST_MANAGER_PLANNING_TEST_SUITE_SETUP,
  P_TEST_MANAGER_REPOSITORY_TESTCASE_SETUP,
  P_TEST_MANAGER_PLANNING_TEST_RESULT_DELETE,
  P_TEST_MANAGER_EXECUTION_ALLOW_EXECUTION_ALL,
  ROLE_PROJECT_TESTER
} from '../../../constants';
import { checkPermission } from '../../utils';

const usePermission = () => {
  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  /**
   * Is manager role
   */
  const isManagerRole = useMemo(() => {
    return (
      globalUserInfo?.currentProjectUser?.role?.roleKey === ROLE_PROJECT_OWNER ||
      globalUserInfo?.currentProjectUser?.role?.roleKey === ROLE_PROJECT_MANAGER ||
      globalUserInfo?.currentProjectUser?.role?.roleKey === ROLE_PROJECT_TEST_LEADER
    );
  }, [globalUserInfo]);

  /**
   * Is tester role
   */
  const isTesterRole = useMemo(() => {
    return globalUserInfo?.currentProjectUser?.role?.roleKey === ROLE_PROJECT_TESTER;
  }, [globalUserInfo]);

  /**
   * Has tenant setting update info permission
   */
  const hasTenantSettingUpdateInfoPermission = useMemo(() => {
    return checkPermission(P_TENANT_SETTING_UPDATE_INFORMATION, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has tenant setting project setup permission
   */
  const hasTenantSettingProjectSetupPermission = useMemo(() => {
    return checkPermission(P_TENANT_SETTING_PROJECT_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has project setting update info permission
   */
  const hasProjectSettingUpdateInfoPermission = useMemo(() => {
    return checkPermission(P_PROJECT_SETTING_UPDATE_INFORMATION, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has project setting test config setup permission
   */
  const hasProjectSettingTestConfigSetupPermission = useMemo(() => {
    return checkPermission(P_PROJECT_SETTING_TEST_CONFIG_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has allow assign to permission
   */
  const hasAllowAssignToPermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_ALLOW_ASSIGN_TO, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has repository folder permission
   */
  const hasRepositoryFolderPermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_REPOSITORY_FOLDER_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has repository testcase permission
   */
  const hasRepositoryTestcasePermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_REPOSITORY_TESTCASE_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has release permission
   */
  const hasReleasePermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_PLANNING_RELEASE_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has cycle permission
   */
  const hasCyclePermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_PLANNING_TEST_PLAN_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has test suite permission
   */
  const hasTestSuitePermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_PLANNING_TEST_SUITE_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has allow execution all permission
   */
  const hasAllowExecutionAllPermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_EXECUTION_ALLOW_EXECUTION_ALL, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has work ticket update permission
   */
  const hasWorkTicketUpdatePermission = useMemo(() => {
    return checkPermission(P_PROJECT_MANAGER_WORK_TICKET_UPDATE, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has test result permission
   */
  const hasTestResultPermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_PLANNING_TEST_RESULT_DELETE, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has chart setup permission
   */
  const hasChartSetupPermission = useMemo(() => {
    return checkPermission(P_PROJECT_CHART_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  /**
   * Has traceability setup permission
   */
  const hasTraceabilitySetupPermission = useMemo(() => {
    return checkPermission(P_TEST_MANAGER_TRACEABILITY_SETUP, globalUserInfo);
  }, [globalUserInfo]);

  return {
    isManagerRole,
    isTesterRole,
    hasTenantSettingUpdateInfoPermission,
    hasTenantSettingProjectSetupPermission,
    hasProjectSettingUpdateInfoPermission,
    hasProjectSettingTestConfigSetupPermission,
    hasAllowAssignToPermission,
    hasRepositoryFolderPermission,
    hasRepositoryTestcasePermission,
    hasReleasePermission,
    hasCyclePermission,
    hasTestSuitePermission,
    hasAllowExecutionAllPermission,
    hasWorkTicketUpdatePermission,
    hasTestResultPermission,
    hasChartSetupPermission,
    hasTraceabilitySetupPermission
  };
};

export default usePermission;
