import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Empty, Input, Checkbox } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';

import { debounce } from '../../../common/utils';

let typingTimerOfSearch = 0;

export const MoreDropdown = ({
  currentValues,
  optionData,
  className = '',
  restDropdown,
  onChangeSelected,
  onClear,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (!(Array.isArray(optionData) && optionData.length)) {
      setOptions([]);
      return;
    }

    setOptions(optionData);
  }, [optionData]);

  /**
   * Get selected options
   */
  const getSelectedOptions = (currentValues, options) => {
    if (!(Array.isArray(options) && options.length)) {
      return [];
    }

    const newCurrentValues = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];
    const newSelectedOptions = options.filter(item => newCurrentValues.includes(item.value));

    return newSelectedOptions;
  };

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      const newSelectedOptions = getSelectedOptions(currentValues, options);

      setSelectedOptions(newSelectedOptions);
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (!currentOption?.value) {
      return;
    }

    let newSelectedValues =
      Array.isArray(selectedOptions) && selectedOptions.length
        ? [...selectedOptions].filter(item => item.value !== currentOption.value)
        : [];

    if (checked) {
      newSelectedValues = [...newSelectedValues, currentOption];
    } else {
      onClear([currentOption.value]);
    }

    setVisibleDropdown(false);
    setSelectedOptions(newSelectedValues);
    onChangeSelected(newSelectedValues.map(item => item.value));
  };

  /**
   * Handle search when search keyword is empty
   */
  const handleSearchSearch = val => {
    if (!(Array.isArray(optionData) && optionData.length)) {
      return;
    }

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        if (!val) {
          setOptions(optionData);
          return;
        }

        const newOptionList = [...optionData].filter(
          item => typeof item.label === 'string' && item.label.toLowerCase().indexOf(val.toLowerCase()) !== -1
        );

        setOptions(newOptionList);
      }),
      300
    );
  };

  /**
   * Columns dropdown
   *
   * @return {object} - Element
   */
  const renderColumnsDropdown = () => {
    const selectedValues = Array.isArray(selectedOptions) ? selectedOptions.map(item => item.value) : [];
    const hasOptions = Array.isArray(options) && options.length > 0;

    return (
      <div className="ant-dropdown-menu c-more-dropdown p-0" onClick={e => e.stopPropagation()}>
        <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
          <Input
            placeholder={t('common.searchField')}
            suffix={<SearchOutlined />}
            allowClear
            size="small"
            onChange={e => handleSearchSearch(e?.target?.value)}
          />
        </div>

        {!hasOptions && (
          <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
            <li className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child" onClick={e => e.stopPropagation()}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
            </li>
          </ul>
        )}

        {hasOptions && (
          <ul
            className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
            onClick={e => e.stopPropagation()}
          >
            {/* Selected Item */}
            {options
              .filter(item => selectedValues.includes(item.value))
              .map(item => (
                <li
                  key={item.value}
                  title={item.label}
                  className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox
                    checked={selectedValues.includes(item.value)}
                    className="w-100"
                    onChange={e => handleChangeSelected(e?.target?.checked, item)}
                  >
                    {item.label}
                  </Checkbox>
                </li>
              ))}

            {/* None Selected Item */}
            {options
              .filter(item => !selectedValues.includes(item.value))
              .map(item => (
                <li
                  key={item.value}
                  title={item.label}
                  className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox
                    checked={selectedValues.includes(item.value)}
                    className="w-100"
                    onChange={e => handleChangeSelected(e?.target?.checked, item)}
                  >
                    {item.label}
                  </Checkbox>
                </li>
              ))}
          </ul>
        )}

        <li className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></li>

        <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
          <Button
            size="small"
            className="w-auto ml-2"
            onClick={() => {
              setVisibleDropdown(false);
              onClear(currentValues);
            }}
          >
            {t('common.clear')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderColumnsDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button className={`btn-toggle-dropdown-with-checkbox-list btn-field ${className}`} {...rest}>
          <span className="text-truncate">{t('common.more')}</span> <CaretDownOutlined className="ic-caret-down" />
        </Button>
      </Dropdown>
    </>
  );
};
