import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip, Popconfirm, Row } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { SS_NEW_TEST_STEPS, SYSTEM_FIELD_ORDER_ID, SYSTEM_FIELD_TEST_STEPS } from '../../../constants';
import { scrollToSelector, generateTestStepKey, reactSessionStorage } from '../../../common/utils';
import { PlusOutlined } from '../../../assets/svg-icons';

const BottomToolBtns = ({
  fromModule,
  form,
  parentKey,
  testStepList,
  handleSetTestStepList,
  handleEmitTestStepList,
  handleDeleteTestStepAttachments
}) => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * On add new test step
   */
  const onAddNewTestStep = () => {
    let newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];

    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    let newSessionTestStepList = newTestStepsInSession[parentKey];
    newSessionTestStepList =
      Array.isArray(newSessionTestStepList) && newSessionTestStepList.length ? [...newSessionTestStepList] : [];

    const key = generateTestStepKey(parentKey);
    const newStep = { key, isNew: true };

    newTestStepList = [...newTestStepList, newStep];
    newSessionTestStepList = [...newSessionTestStepList, newStep];

    reactSessionStorage.setObject(SS_NEW_TEST_STEPS, {
      ...newTestStepsInSession,
      [parentKey]: newSessionTestStepList
    });

    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    // setTimeout(() => document.querySelector(`#key-${key}-${FIELD_STEP}`)?.focus(), 100);
  };

  /**
   * On save all new test steps
   */
  const onSaveAllNewTestSteps = async () => {
    if (!form) {
      return;
    }

    try {
      let formItemNameList = Object.keys(form.getFieldsValue());
      formItemNameList = formItemNameList.filter(
        str => new RegExp(`^key-${parentKey}-${SYSTEM_FIELD_TEST_STEPS}-`, 'g').test(str) // Example: ^key-639-testSteps-
      );

      await form.validateFields(formItemNameList);

      let oldTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
      oldTestStepList = oldTestStepList.filter(s => !s?.isNew);

      const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
      let newTestStepList = newTestStepsInSession[parentKey];

      newTestStepList = Array.isArray(newTestStepList) && newTestStepList.length ? [...newTestStepList] : [];
      newTestStepList = [...oldTestStepList, ...newTestStepList].map((s, idx) => {
        const newItem = { ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 };
        delete newItem.isNew;
        return newItem;
      });

      delete newTestStepsInSession[parentKey];
      reactSessionStorage.setObject(SS_NEW_TEST_STEPS, newTestStepsInSession);

      handleSetTestStepList({ testSteps: newTestStepList, parentKey });
      handleEmitTestStepList(newTestStepList);
    } catch (_) {
      scrollToSelector({
        selectorString: `.children-table-on-expanded-row[data-parent-row-key="${parentKey}"] .ant-form-item-has-error`,
        delay: 0
      });
    }
  };

  /**
   * On discard changes
   */
  const onDiscardChanges = () => {
    let newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    const deletedTestSteps = newTestStepsInSession[parentKey];

    newTestStepList = newTestStepList.filter(s => !s?.isNew);
    delete newTestStepsInSession[parentKey];

    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    handleDeleteTestStepAttachments(deletedTestSteps);
    setTimeout(() => reactSessionStorage.setObject(SS_NEW_TEST_STEPS, newTestStepsInSession), 500);
  };

  return (
    <Row justify="space-between" align="middle" className="c-bottom-tool-btns pt-2 pb-2">
      <div className="btn-action-editable-cell-wrapper">
        <Button
          type="link"
          icon={<PlusOutlined className="font-size-12" />}
          className="btn-action-editable-cell text-hover-dark-primary border-0 h-auto w-auto p-0 mr-2"
          onClick={onAddNewTestStep}
        >
          {t('testRepo.addNewTestStep')}
        </Button>
      </div>

      {Array.isArray(testStepList) && testStepList.length > 0 && testStepList.some(item => item?.isNew) && (
        <Row justify="end" align="middle" wrap={false}>
          <Popconfirm
            title={t('common.confirmDiscardChanges')}
            placement="top"
            zIndex={1080}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={onDiscardChanges}
          >
            <Tooltip
              title={t('common.discardSavingNewItems')}
              placement="top"
              destroyTooltipOnHide={true}
              overlayStyle={{ pointerEvents: 'none' }}
            >
              <Button size="small" onClick={e => e?.stopPropagation()} className="btn-action-editable-cell my-1 ml-2">
                {t('common.discardChanges')}
              </Button>
            </Tooltip>
          </Popconfirm>

          {fromModule === 'TESTCASE_DETAIL' && (
            <div className="btn-action-editable-cell-wrapper">
              <Button
                icon={<SaveOutlined />}
                type="primary"
                size="small"
                className="btn-action-editable-cell my-1 ml-2"
                onClick={onSaveAllNewTestSteps}
              >
                {t('common.save')}
              </Button>
            </div>
          )}
        </Row>
      )}
    </Row>
  );
};

export default BottomToolBtns;
