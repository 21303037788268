import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import OutsideClickHandler from 'react-outside-click-handler';

import { SS_LAST_SAVED_VALUE, SYSTEM_FIELD_TEST_CASE_VERSION } from '../../../../constants';
import { checkValidField, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common/utils';
import { checkIsEditableCell, onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import ScriptPathFormItem from './script-path-form-item';

const ScriptPathField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [editingCell, setEditingCell] = useState(null);

  /**
   * On change editing cell
   */
  useEffect(() => {
    typeof onChangeEditingCell === 'function' && onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => {
    return (
      objectPath.get(row, field?.refName) || objectPath.get(row, `${SYSTEM_FIELD_TEST_CASE_VERSION}.${field?.refName}`)
    );
  }, [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') {
      return;
    }

    if (isEditing) {
      tableForm.setFieldsValue({ [formItemName]: getCurrentValue(defaultVal) });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultVal]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    setEditingCell({ x, y });
  };

  /**
   * Handle close
   */
  const handleClose = val => {
    setCurrentValue(val);
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async val => {
    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !val)) {
      handleClose(defaultValue);
      return;
    }

    if (val !== defaultValue) {
      handleSetLastSavedValueToSession({ [formItemName]: val });

      typeof onSave === 'function' && onSave({ formData: { [field?.refName]: val }, row, field });
    }

    handleClose(val);
  };

  /**
   * Input props
   */
  const inputProps = {
    autoFocus: !row?.isNew,
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    placeholder: placeholder || t('common.enterValue'),
    onChange: e => setCurrentValue(e?.target?.value),
    onBlur: e => handleSave(e?.target?.value),
    onClick: () => {
      if (row?.isNew) {
        return;
      }

      const triggerFocusElement = document.querySelector(
        `.trigger-focus-element[data-form-item-name="${formItemName}"]`
      );
      triggerFocusElement?.classList.remove('focused');
    },
    onPressEnter: e => {
      if (row?.isNew) {
        handleSave(e?.target?.value);
      }
    }
  };

  /**
   * Render cell text utils
   */
  const renderCellTextUtilEditable = ({ val }) => {
    return (
      <div title={val || ''} className="cell-text cursor-text" onClick={handleOpen}>
        <span className="cell-text-value min-h-22 text-truncate">{val || ''}</span>
      </div>
    );
  };

  const renderCellTextUtilRelationField1 = ({ val, row, field }) => {
    return (
      <RelationLink title={val || ''} row={row} field={field}>
        {val || ''}
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ val, row, field }) => {
    return (
      <div title={val || ''} className="cell-text cursor-default hide-after">
        <RelationLink row={row} field={field}>
          {val || ''}
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ val }) => {
    return <span title={val || ''}>{val || ''}</span>;
  };

  const renderCellTextUtilReadonly2 = ({ val }) => {
    return (
      <div title={val || ''} className="cell-text cursor-default hide-after">
        <span className="cell-text-value min-h-22 text-truncate">{val || ''}</span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val = getCurrentValue(currentValue);

    // Editable
    if (checkIsEditableCell({ isEditableField, isReadOnly, disableEditingCells })) {
      return renderCellTextUtilEditable({ val });
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ val, row, field });
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ val, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ val });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ val });
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <ScriptPathFormItem
        x={x}
        y={y}
        field={field}
        tableForm={tableForm}
        formItemName={formItemName}
        inputProps={inputProps}
        setEditingCell={setEditingCell}
        handleSave={handleSave}
        closeAndSetLastValue={closeAndSetLastValue}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && renderFormItem()}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper script-path-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new script-path-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default ScriptPathField;
