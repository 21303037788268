import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { parseRsql, buildQueryForParamsUrl, buildQueryForFilterMongo } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: null,
  total: null,
  query: {},
  editingAgent: {},
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set agent list
   */
  setAgentList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    // Reset
    if (payload === null) {
      state.data = null;
      state.total = null;
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set editing agent
   */
  setEditingAgent: action((state, payload) => {
    if (state?.editingAgent === undefined) {
      return;
    }

    state.editingAgent = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get agent list
   */
  getAgentList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent list: Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload.aql)) {
        throw new Error('Get agent list: Invalid aql');
      } // aql is invalid

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_AGENT
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAgentList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get agent list
   */
  getAgentListByFilter: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent list: Invalid Params');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_AGENT
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setAgentList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get agent by Id
   */
  getAgentById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent list: Invalid Params');
      }

      if (!payload) {
        throw new Error('Get agent list: There are no ID');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingAgent(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update agent pool
   */
  updateAgent: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent list: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Get agent list: There are no ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      delete newPayload.id;

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT}/${payload.id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:agentPool.messageUpdatingAgent'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete agent
   */
  deleteAgent: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get agent list: Invalid Params');
      }

      if (!payload) {
        throw new Error('Get agent list: Invalid Payload');
      }

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_AGENT}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:agentPool.messageDeletingAgent'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  })
};
