import React from 'react';

const DeleteItemHelper = ({ onDeleteItem }) => {
  return (
    <div
      className="c-delete-item-helper"
      onClick={e => {
        if (typeof onDeleteItem === 'function') {
          onDeleteItem(e?.target?.dataset?.key);
          setTimeout(() => document.querySelector('.c-delete-item-helper')?.setAttribute('data-key', ''), 300);
        } else {
        }
      }}
    />
  );
};

export default DeleteItemHelper;
