import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';
import { buildQueryForFilterMongo } from '../../common/utils';

export const model = {
  /**
   * State
   */
  loadingExportData: false,

  /**
   * Action: Set loading test result exported data
   */
  setLoadingExportData: action((state, payload) => {
    if (state?.loadingExportData === undefined) {
      return;
    }

    state.loadingExportData = payload;
  }),

  /**
   * Action: Call api to get test exported data excel
   */
  getExportedExcelData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get exported excel data: Invalid Params');
      }

      if (!(Array.isArray(payload?.fieldList) && payload.fieldList.length)) {
        throw new Error('Get exported excel data: Fields export empty');
      }

      if (!payload.dataSource) {
        throw new Error('Get exported excel data: Invalid datasource');
      }

      action.setLoadingExportData(true);

      const { fieldList, order, filter, dataSource } = payload;
      const filterOption = {
        filter,
        order
      };

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_EXPORT_EXCEL
      }/${dataSource}?${buildQueryForFilterMongo(filterOption)}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, fieldList, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingExportData(false);
    }
  }),

  /**
   * Action: Call api to get test exported data for csv
   */
  getExportedCSVData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get exported csv data: Invalid Params');
      }

      if (!(Array.isArray(payload?.fieldList) && payload.fieldList.length)) {
        throw new Error('Get exported csv data: Fields export empty');
      }

      if (!payload.dataSource) {
        throw new Error('Get exported csv data: Invalid datasource');
      }

      action.setLoadingExportData(true);

      const { fieldList, order, filter, dataSource } = payload;
      const filterOption = {
        filter,
        order
      };

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_EXPORT_CSV
      }/${dataSource}?${buildQueryForFilterMongo(filterOption)}`;

      const config = { responseType: 'blob' };
      const res = await Http.post(url, fieldList, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingExportData(false);
    }
  })
};
