import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
// import { StringParam, useQueryParams } from 'use-query-params';

import env from '../../env';
import {
  TENANT_KEY_TRIAL,
  HOME_MENU,
  PROJECT_ENGINE_MENU,
  PROJECT_MANAGER_MENU,
  PROJECT_SETTING_MENU,
  ROLE_APP_ACCESS_MANAGER,
  ROLE_APP_ACCESS_TESTENGINE,
  ROLE_TENANT_OWNER
} from '../../constants';
import { checkIsNotEmptyArray, getParentMenuByPathname } from '../../common/utils';
import { useKeycloak } from '../../common/hooks';

export const PrivateRoutes = ({ component: Component, children: Children, forPage, ...rest }) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For keycloak
  const { keycloak } = useKeycloak();

  // // For query params on url
  // const [queryParams] = useQueryParams({
  //   code: StringParam,
  //   state: StringParam,
  //   session_state: StringParam
  // });

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  /**
   * Check is no permission
   */
  const checkIsNoPermission = ({ currentMenu, userPermissions }) => {
    const condition1 = currentMenu?.permission && !userPermissions.includes(currentMenu?.permission);
    const condition2 =
      checkIsNotEmptyArray(currentMenu?.permissions) &&
      !userPermissions.some(p => currentMenu?.permissions.includes(p));

    return condition1 || condition2;
  };

  /**
   * Check is no access manager or engine
   */
  const checkIsNoAccessManagerOrEngine = ({ managerMenu, engineMenu, userPermissions }) => {
    return (
      (managerMenu && !userPermissions?.includes(ROLE_APP_ACCESS_MANAGER)) ||
      (engineMenu && !userPermissions?.includes(ROLE_APP_ACCESS_TESTENGINE))
    );
  };

  /**
   * Check is no access subscription
   */
  const checkIsNoAccessSubscription = ({ currentMenu, globalTenant }) => {
    return (
      (currentMenu?.id === 'subscription' && globalTenant?.tenantKey === TENANT_KEY_TRIAL) ||
      (currentMenu?.id === 'subscription' && globalTenant?.currentUserRole?.roleKey !== ROLE_TENANT_OWNER)
    );
  };

  return (
    <Route
      render={routeProps => {
        const currentMenu = getParentMenuByPathname(location.pathname, [
          ...HOME_MENU,
          ...PROJECT_ENGINE_MENU,
          ...PROJECT_MANAGER_MENU,
          ...PROJECT_SETTING_MENU
        ]);
        const managerMenu = getParentMenuByPathname(location.pathname, PROJECT_MANAGER_MENU);
        const engineMenu = getParentMenuByPathname(location.pathname, PROJECT_ENGINE_MENU);
        const userPermissions = checkIsNotEmptyArray(globalUserInfo?.permissions)
          ? [...globalUserInfo?.permissions]
          : [];

        // /**
        //  * After login on Xone
        //  */
        // if (queryParams?.code && queryParams?.state && queryParams?.session_state) {
        //   return;
        // }

        // Need to login
        if (!keycloak?.authenticated) {
          keycloak.login();
          return;
        }

        // Logged in, but not allow access
        if (!['firstPagePage', 'homepage'].includes(forPage)) {
          if (
            checkIsNoPermission({ currentMenu, userPermissions }) ||
            checkIsNoAccessManagerOrEngine({ managerMenu, engineMenu, userPermissions }) ||
            checkIsNoAccessSubscription({ currentMenu, globalTenant })
          ) {
            return <Redirect to={`/${env.REACT_APP_PREFIX_PATH}${tenantPath || '_'}/${projectPath || '_'}/403`} />;
          }
        }

        // Allow access, go to next
        if (Component) {
          return <Component {...routeProps} />;
        }

        // Allow access, go to next
        if (Children) {
          return <Children {...routeProps} />;
        }
      }}
      {...rest}
    />
  );
};
