import { AutoComplete, Input } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TESTMAN_PLATFORM_ID, JIRA_PLATFORM_ID, OPERATION_VALUE_REGEX } from '../../constants';
import { debounce } from '../../common/utils';

let typingTimerOfSearch = 0;

const placeholderText = {
  [JIRA_PLATFORM_ID]: 'relationTo.enterTheExistingIssueName',
  [TESTMAN_PLATFORM_ID]: 'relationTo.enterTheExistingDefect'
};

const ExistItem = ({ defect, form }) => {
  const [t] = useTranslation('akaat');

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const objectiveList = useStoreState(state => state.global.objectiveList);
  const loadingRelationTypeList = useStoreState(state => state.global.loadingRelationTypeList);
  const getObjectiveList = useStoreActions(action => action.global.getObjectiveList);

  // For jira store
  const searchJiraByJql = useStoreActions(action => action.jiraIntegration.searchByJql);
  const jiraIssueList = useStoreState(state => state.jiraIntegration.data);
  const loadingListJira = useStoreState(state => state.jiraIntegration.loadingList);

  const [workTicketList, setWorkTicketList] = useState([]);

  useEffect(() => {
    setWorkTicketList([]);
  }, [defect]);

  /**
   * Set jira defect item list
   */
  useEffect(() => {
    if (!Array.isArray(jiraIssueList)) {
      return;
    }

    const options = jiraIssueList.map(item => {
      return {
        title: `${item?.key}: ${item?.fields?.summary}`,
        label: <span className="text-truncate">{`${item?.key}: ${item?.fields?.summary}`}</span>,
        value: `${item?.key}: ${item?.fields?.summary}`,
        item
      };
    });

    setWorkTicketList(options);
  }, [jiraIssueList]);

  /**
   * Set objective list
   */
  useEffect(() => {
    if (!Array.isArray(objectiveList)) {
      return;
    }

    const options = objectiveList.map(item => {
      return {
        title: item.name,
        label: <span className="text-truncate">{item.name}</span>,
        value: item.name,
        item
      };
    });

    setWorkTicketList(options);
  }, [objectiveList]);

  /**
   * Handle search by keyword for typing
   */
  const handleSearchByKeywordForTyping = ({ defect, val }) => {
    if (defect?.defectSystem === JIRA_PLATFORM_ID) {
      getDataFromJira(val);
    } else if (defect?.defectSystem === TESTMAN_PLATFORM_ID) {
      getDataFromManager(`.*${val}.*`);
    } else {
    }
  };

  /**
   * Handle search by keyword
   */
  const handleSearchByKeyword = (val, actionType) => {
    if (loadingListJira || !defect || (!val._id && actionType?.key === 'Enter')) {
      return;
    }

    if (typeof val === 'string') {
      val = val.trim();
    }

    if (actionType === 'TYPING') {
      clearTimeout(typingTimerOfSearch);

      typingTimerOfSearch = setTimeout(
        debounce(() => {
          if (val === '') {
            setWorkTicketList([]);
            form.setFieldsValue({ item: '' });
            return;
          }

          handleSearchByKeywordForTyping({ defect, val });
        }),
        1000
      );
    } else {
      if (val === '') {
        setWorkTicketList([]);
        form.setFieldsValue({ item: '' });
      }

      form.setFieldsValue({ item: val });
    }
  };

  const getDataFromJira = val => {
    if (!val || !defect) {
      return;
    }

    let jiraType = '';

    if (defect?.jiraIssueType?.name) {
      jiraType = `AND type = '${defect.jiraIssueType.name}'`;
    }
    const jql = `text ~ '${val}' ${jiraType}`;

    searchJiraByJql({
      jql,
      projectKey: globalProject?.projectKey,
      tenantKey: globalTenant?.tenantKey
    });
  };

  const getDataFromManager = val => {
    if (!val || !defect) {
      return;
    }

    const query = {
      filter: {
        name: { [OPERATION_VALUE_REGEX]: val, $options: 'i' },
        'workTicketType.id': defect.workItemType
      }
    };

    getObjectiveList(query);
  };

  return (
    <>
      <AutoComplete
        options={workTicketList}
        allowClear
        className="has-enter-and-clear-button"
        onSelect={(value, option) => handleSearchByKeyword(option.item)}
        onSearch={val => handleSearchByKeyword(val, 'TYPING')}
      >
        <Input.Search
          placeholder={t(placeholderText[defect.defectSystem])}
          loading={loadingListJira || loadingRelationTypeList}
          onSearch={handleSearchByKeyword}
        />
      </AutoComplete>
    </>
  );
};

export default ExistItem;
