import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../../constants';
import { Http, handleError } from '../../../../../core';

export const model = {
  /**
   * State
   */
  testResultStatuses: [],
  testActivitiesData: {},
  testActivitiesDurationData: [],
  loadingTestResultStatuses: false,
  loadingTestActivitiesData: false,

  /**
   * Action: Set test result statuses
   */
  setTestResultStatuses: action((state, payload) => {
    if (state?.testResultStatuses === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.testResultStatuses = [];
      return;
    }

    state.testResultStatuses = payload;
  }),

  /**
   * Action: Set test activities data
   */
  setTestActivitiesData: action((state, payload) => {
    if (state?.testActivitiesData === undefined || !payload) {
      return;
    }

    state.testActivitiesData = payload;
  }),

  /**
   * Action: Set test activities duration data
   */
  setTestActivitiesDurationData: action((state, payload) => {
    if (state?.testActivitiesDurationData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.testActivitiesDurationData = [];
      return;
    }

    state.testActivitiesDurationData = payload;
  }),

  /**
   * Action: Set loading test result statuses
   */
  setLoadingTestResultStatuses: action((state, payload) => {
    if (state?.loadingTestResultStatuses === undefined) {
      return;
    }

    state.loadingTestResultStatuses = payload;
  }),

  /**
   * Action: Set loading test activities data
   */
  setLoadingTestActivitiesData: action((state, payload) => {
    if (state?.loadingTestActivitiesData === undefined) {
      return;
    }

    state.loadingTestActivitiesData = payload;
  }),

  /**
   * Action: Call api to get test result statuses
   */
  getTestResultStatuses: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test result statuses: Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test result statuses: Invalid Payload');
      }

      action.setLoadingTestResultStatuses(true);

      const filterQuery = payload?.filter ? `&filter=${JSON.stringify(payload.filter)} ` : '';

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/summary/count-by-type?type=status${filterQuery}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestResultStatuses(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestResultStatuses(false);
    }
  }),

  /**
   * Action: Call api to get test activities data
   */
  getTestActivitiesData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test activities data: Invalid Params');
      }

      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test activities data: Invalid Payload');
      }

      action.setLoadingTestActivitiesData(true);

      const { from, to, type, jobId, hasGetDuration } = payload;
      const jobIdQuery = jobId ? `&jobId=${jobId} ` : '';

      let res1 = null;
      let res2 = null;

      const url1 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/summary/test-activities?from=${from}&to=${to}&type=${type}${jobIdQuery}`;
      res1 = Http.get(url1).then(res => res.data);

      if (hasGetDuration) {
        const url2 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/summary/count-duration-by-time?from=${from}&to=${to}&type=${type}${jobIdQuery}`;
        res2 = Http.get(url2).then(res => res.data);
      }

      const promises = await Promise.all([res1, res2]);

      action.setTestActivitiesData(promises?.[0].data);
      action.setTestActivitiesDurationData(promises?.[1]?.data);

      return promises;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTestActivitiesData(false);
    }
  })
};
