import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { StringParam, useQueryParams } from 'use-query-params';
import moment from 'moment';
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Col, Row, Select, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { FULL_DATE_FORMAT, ENGINE_TEST_RESULT_STATUSES, COLORS } from '../../constants';
import { checkIsNotEmptyArray, checkValidMomentDateString, getTotalUnicodeIndexOfString } from '../../common/utils';

import './style.scss';

const RANGE_DATE_SELECTION_LIST = [
  { value: 'today', label: 'common.today' },
  { value: 'yesterday', label: 'common.yesterday' },
  { value: 'last7Days', label: 'common.last7Days' },
  { value: 'last30Days', label: 'common.last30Days' },
  { value: 'sinceStarted', label: 'common.sinceStarted' }
];

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

export const TestResultStatusChart = ({
  jobId,
  testResultStatuses,
  testResultDuration,
  hasGetDuration = false,
  loading = false,
  className = '',
  onGetChartData,
  ...rest
}) => {
  // For query params on url
  const [queryParams] = useQueryParams({
    treeKey: StringParam
  });

  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const globalProject = useStoreState(state => state.global.globalProject);

  // Component state
  const [isGetSummaryWhenMounted, setIsGetSummaryWhenMounted] = useState(false);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [rangeDate, setRangeDate] = useState([]);
  const [currentRangeDate, setCurrentRangeDate] = useState('last7Days');

  /**
   * Get chart data
   */
  useEffect(() => {
    if (!queryParams) {
      return;
    }

    const newRangeDates = getRangeDates(currentRangeDate);
    const params = {
      type: currentRangeDate === 'today' || currentRangeDate === 'yesterday' ? 'hour' : 'day',
      from: moment.utc(newRangeDates[0]).startOf('day').format(),
      to: moment.utc(newRangeDates[1]).endOf('day').format()
    };

    if (!queryParams?.treeKey && !isGetSummaryWhenMounted) {
      setRangeDate(newRangeDates);
      onGetChartData(params);
      setIsGetSummaryWhenMounted(true);
      return;
    }

    if (jobId && jobId !== currentJobId) {
      params.jobId = jobId;

      setRangeDate(newRangeDates);
      onGetChartData(params);
      setCurrentJobId(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, queryParams, currentJobId, isGetSummaryWhenMounted, onGetChartData, currentRangeDate]);

  /**
   * Compute total of chart options
   */
  const chartOptions = useMemo(() => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'x'
      },
      scales: {
        x: {
          stacked: true
        },
        y1: {
          display: true,
          position: 'left',
          title: {
            display: true,
            text: t('engineSummary.testResults')
          },
          type: 'linear',
          beginAtZero: true
        },
        y2: {
          display: hasGetDuration,
          position: 'right',
          title: {
            display: true,
            text: t('engineSummary.seconds')
          },
          type: 'linear',
          beginAtZero: true
        }
      },
      plugins: {
        legend: {
          display: checkIsNotEmptyArray(testResultStatuses?.status),
          position: 'top'
        }
      }
    };

    return options;
  }, [t, hasGetDuration, testResultStatuses]);

  /**
   * Check is empty chart data
   */
  const checkIsEmptyChartData = ({ testResultStatuses, hasGetDuration, testResultDuration }) => {
    return (
      !checkIsNotEmptyArray(testResultStatuses?.status) || (hasGetDuration && !checkIsNotEmptyArray(testResultDuration))
    );
  };

  /**
   * Compute total of chart data
   */
  const chartData = useMemo(() => {
    if (checkIsEmptyChartData({ testResultStatuses, hasGetDuration, testResultDuration })) {
      return { labels: [''], datasets: [{ label: '', data: [] }] };
    }

    const durationList = hasGetDuration ? testResultDuration : [];
    const allStatusKey = [...testResultStatuses.status].reduce((accumulator, current) => {
      Object.keys(current.status).forEach(key => {
        if (!accumulator.includes(key)) {
          accumulator.push(key);
        }
      });

      return accumulator;
    }, []);

    const barChartData = allStatusKey.map(key => {
      const found = ENGINE_TEST_RESULT_STATUSES.find(s => s?.value === key);

      return {
        type: 'bar',
        yAxisID: 'y1',
        label: found ? t(found?.label) : key,
        backgroundColor: found ? found?.color : COLORS[getTotalUnicodeIndexOfString(key) % COLORS.length],
        borderRadius: 6,
        stack: 'stack1',
        data: testResultStatuses.status.map(sub => sub?.status[key] || 0)
      };
    });

    const lineChartData = hasGetDuration
      ? [
          {
            type: 'line',
            yAxisID: 'y2',
            label: t('engineSummary.duration'),
            data: durationList.map(item => (+item?.duration / 1000).toFixed(1) || 0),
            borderColor: 'rgba(106, 145, 255, 0.9)',
            fill: false
          }
        ]
      : [];

    const data = {
      labels: testResultStatuses.status.map(item =>
        checkValidMomentDateString(item?.time) ? moment.utc(item?.time).local().format(FULL_DATE_FORMAT) : ''
      ),
      datasets: [...lineChartData, ...barChartData]
    };

    return data;
  }, [t, hasGetDuration, testResultStatuses, testResultDuration]);

  /**
   * Handle change range date selection
   */

  const handleChangeRangeDateSelection = val => {
    if (!val) {
      return;
    }
    const newRangeDates = getRangeDates(val);
    const params = {
      type: val === 'today' || val === 'yesterday' ? 'hour' : 'day',
      from: moment.utc(newRangeDates[0]).format(),
      to: moment.utc(newRangeDates[1]).format()
    };

    if (jobId) {
      params.jobId = jobId;
    }

    setCurrentRangeDate(val);
    setRangeDate(newRangeDates);
    onGetChartData(params);
  };

  /**
   * Get range date from type
   */
  const getRangeDates = type => {
    if (!type) {
      return;
    }

    let newRangeDates = [];

    const startDate = moment.utc().startOf('day');
    const endDate = moment.utc().endOf('day');

    if (type === 'today') {
      newRangeDates = [startDate, endDate];
    } else if (type === 'yesterday') {
      newRangeDates = [startDate.subtract(1, 'day'), endDate.subtract(1, 'day')];
    } else if (type === 'last7Days') {
      newRangeDates = [startDate.subtract(7, 'day'), endDate];
    } else if (type === 'last30Days') {
      newRangeDates = [startDate.subtract(30, 'day'), endDate];
    } else if (type === 'sinceStarted') {
      const startDate = checkValidMomentDateString(globalProject?.startDate)
        ? moment(globalProject?.startDate).local()
        : null;
      const endDate = checkValidMomentDateString(globalProject?.endDate)
        ? moment(globalProject?.endDate).local()
        : null;

      newRangeDates = [startDate, endDate];
    } else {
    }

    return newRangeDates;
  };
  return (
    <div className={`c-test-result-status-chart ${className}`} {...rest}>
      <Select
        defaultValue="last7Days"
        options={RANGE_DATE_SELECTION_LIST.map(item => {
          return {
            label: t(item.label),
            value: item.value
          };
        })}
        optionFilterProp="label"
        showSearch
        allowClear
        placeholder={t('engineSummary.selectRange')}
        className="range-date-selection"
        onChange={handleChangeRangeDateSelection}
      />

      <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loading}>
        <Row gutter={20}>
          <Col xs={24} xl={6}>
            <div className="txt-total">
              <div className="txt-num text-primary">
                {testResultStatuses?.countJobRunner > 0 ? testResultStatuses.countJobRunner : 0}
              </div>
              {t('engineSummary.totalJobRunners')}
            </div>

            <div className="txt-total">
              <div className="txt-num text-primary">
                {testResultStatuses?.countTestResult > 0 ? testResultStatuses.countTestResult : 0}
              </div>
              {t('engineSummary.totalTestResults')}
            </div>
          </Col>

          <Col xs={24} xl={18}>
            <div className="box-chart">
              {checkIsNotEmptyArray(chartData?.labels) && checkIsNotEmptyArray(chartData.datasets) && (
                <Bar options={chartOptions} data={chartData} width="700" height="300" />
              )}
            </div>

            {checkIsNotEmptyArray(rangeDate) && moment(rangeDate[0]).isValid() && moment(rangeDate[1]).isValid() && (
              <div className="text-center mt-2">
                {`${t('engineSummary.timeline')} (${t('engineSummary.startTime')} ${moment(rangeDate[0])
                  .startOf('day')
                  .format(FULL_DATE_FORMAT)} ${t('engineSummary.toEndTime')} ${moment(rangeDate[1])
                  .endOf('day')
                  .format(FULL_DATE_FORMAT)})`}
              </div>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
