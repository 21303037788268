import { action, thunk } from 'easy-peasy';
import moment from 'moment';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { reactSessionStorage } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  integrationItem: {},
  integrationSystemList: [],
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set integration item
   */
  setIntegrationItem: action((state, payload) => {
    if (state?.integrationItem === undefined) {
      return;
    }

    state.integrationItem = payload;
  }),

  /**
   * Action: Set integration system list
   */
  setIntegrationSystemList: action((state, payload) => {
    if (state?.integrationSystemList === undefined) {
      return;
    }

    if (!Array.isArray(payload) || !payload.length) {
      payload = [];
    }
    state.integrationSystemList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get connection list
   */
  getIntegrationItem: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get integration item: Invalid Params');
      }

      action.setLoadingItem(true);

      // Ngăn không cho call api liên tục nhiều lần trong một khoảng thời gian ngắn (Đang để 0.25 giây).
      const timer = reactSessionStorage.getObject('getIntegrationItemTimer', 0);
      const currentTime = +timer;

      if (moment().clone().unix() <= currentTime) {
        return;
      }

      reactSessionStorage.setObject('getIntegrationItemTimer', moment().clone().add(0.25, 'second').unix()); // Nếu vừa call api get một workTicketId, thì 0.25 giây sau mới được get tiếp workTicketId đó.

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/jira/detail`;
      const res = await Http.get(url).then(res => res.data);

      action.setIntegrationItem(res?.data);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get integration by Id
   */
  getIntegrationById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get integration by id: Invalid Params');
      }

      if (!payload) {
        throw new Error('Get integration by id: Invalid Payload');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.INTEGRATION}/${tenant?.tenantKey}/${project?.projectKey}/integration/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setIntegrationItem(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
