import { action, thunk } from 'easy-peasy';
import qs from 'qs';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  projectRoleList: null, // All of roles in current project
  loadingProjectRoleList: false,

  /**
   * Action: Set project role list
   */
  setProjectRoleList: action((state, payload) => {
    if (!(state?.projectRoleList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.projectRoleList = [];
    }

    state.projectRoleList = payload;
  }),

  /**
   * Action: Set loading project role list
   */
  setLoadingProjectRoleList: action((state, payload) => {
    if (!(state?.loadingProjectRoleList !== undefined)) {
      return;
    }

    state.loadingProjectRoleList = payload;
  }),

  /**
   * Action: Call api to get project role list
   */
  getProjectRoleList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingProjectRoleList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.PERMISSION}/${globalTenant?.tenantKey}${
        ENDPOINTS.PROJECT_ROLES
      }?${qs.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setProjectRoleList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectRoleList(false);
    }
  })
};
