import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  globalTenant: null,
  myTenantList: null,
  loadingTenantItem: false,

  /**
   * Action: Set global tenant
   */
  setGlobalTenant: action((state, payload) => {
    if (!(state?.globalTenant !== undefined)) {
      return;
    }

    const newData = JSON.stringify(payload);
    const oldData = JSON.stringify(state.globalTenant);

    if (payload && newData !== oldData) {
      state.globalTenant = payload;
    }
  }),

  /**
   * Action: Set loading tenant item
   */
  setLoadingTenantItem: action((state, payload) => {
    if (!(state?.loadingTenantItem !== undefined)) {
      return;
    }

    state.loadingTenantItem = payload;
  }),

  /**
   * Action: Set my tenant list
   */
  setMyTenantList: action((state, payload) => {
    if (!(state?.myTenantList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length && payload.some(item => item?.id))) {
      state.myTenantList = [];
      return;
    }

    state.myTenantList = payload;
  }),

  /**
   * Action: Call api to get tenant
   */
  getTenant: thunk(async (action, payload) => {
    try {
      if (!payload) {
        throw new Error('Get tenant: No Tenant Key');
      }

      action.setLoadingTenantItem(true);

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${payload}${ENDPOINTS.TENANT}`;
      const res = await Http.get(url).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantItem(false);
    }
  }),

  /**
   * Action: Call api to update tenant
   */
  updateTenant: thunk(async (action, payload) => {
    try {
      if (!payload?.tenantKey) {
        throw new Error('Update tenant: No Tenant Key');
      }

      action.setLoadingTenantItem(true);

      const body = { ...payload };
      const { tenantKey } = payload;
      delete body.tenantKey;

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${tenantKey}${ENDPOINTS.TENANT}`;
      const res = await Http.put(url, body).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:tenantOverview.messageUpdatingTenant'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantItem(false);
    }
  }),

  /**
   * Action: Call api to delete tenant
   */
  deleteTenant: thunk(async (action, payload) => {
    try {
      action.setLoadingTenantItem(true);

      const { tenantKey } = payload;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${tenantKey}${ENDPOINTS.TENANT}`;
      const res = await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:tenantOverview.messageDeletingTenant'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantItem(false);
    }
  })
};
