import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  INTEGRATION_DEFECT_SYSTEM,
  JIRA_PLATFORM_ID,
  PRODUCT_KEY_ON_URL_MANAGER,
  ROLE_APP_ACCESS_MANAGER,
  TESTMAN_PLATFORM_ID
} from '../../../constants';
import { useEffect, useState } from 'react';

const useIntegrationSystem = () => {
  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // For integration store
  const getIntegrationItem = useStoreActions(action => action.integration.getIntegrationItem);
  const setIntegrationSystemList = useStoreActions(action => action.integration.setIntegrationSystemList);
  const integrationItem = useStoreState(state => state.integration.integrationItem);
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);

  // Component state
  const [defaultSystem, setDefaultSystem] = useState();

  useEffect(() => {
    if (!Array.isArray(integrationSystemList) || !integrationSystemList.length) {
      return;
    }

    const newDefaultSystem = integrationSystemList?.find(integration => integration.id !== TESTMAN_PLATFORM_ID);

    if (!newDefaultSystem) {
      return;
    }

    setDefaultSystem(newDefaultSystem.id);
  }, [integrationSystemList]);

  /**
   * Set data integration system list
   */
  useEffect(() => {
    const listSystem = [];

    // push product manager to list
    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER)) {
      listSystem.push(INTEGRATION_DEFECT_SYSTEM[PRODUCT_KEY_ON_URL_MANAGER]);
    }

    // push integration jira when relation is link to
    integrationItem &&
      Object.keys(integrationItem).forEach(value => {
        if (INTEGRATION_DEFECT_SYSTEM[value] && value === JIRA_PLATFORM_ID && integrationItem[value]) {
          listSystem.push(INTEGRATION_DEFECT_SYSTEM[value]);
        }
      });

    setIntegrationSystemList(listSystem);

    // eslint-disable-next-line
  }, [integrationItem, globalUserInfo]);

  /**
   * Get integration system
   */
  useEffect(() => {
    if (!globalProject?.projectKey || !globalTenant.tenantKey || integrationItem?.id) {
      return;
    }

    getIntegrationItem({ projectKey: globalProject.projectKey, tenantKey: globalTenant.tenantKey });

    // eslint-disable-next-line
  }, [globalProject, globalTenant]);

  return [defaultSystem];
};

export default useIntegrationSystem;
