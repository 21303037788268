import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputNumber, Popover, Row, Tooltip } from 'antd';
import {
  UndoOutlined,
  RedoOutlined,
  CloseOutlined,
  ExpandOutlined,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@ant-design/icons';

import { Flip } from '../../assets/svg-icons';
import PanViewer from './pan-viewer';

import './style.scss';

export const ReactPanZoomImage = ({ src, className = '', restPanViewer, restImage, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For component state
  const [currentSrc, setCurrentSrc] = useState(null);
  const [dx, setDx] = useState(0);
  const [dy, setDy] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [flip, setFlip] = useState(false);
  const [visibleZoomPopover, setVisibleZoomPopover] = useState(false);

  /**
   * On reset all
   */
  const onResetAll = () => {
    setDx(0);
    setDy(0);
    setZoom(1);
    setRotation(0);
    setFlip(false);
  };

  /**
   * On rotate left
   */
  const onRotateLeft = () => {
    if (rotation === -3) {
      setRotation(0);
    } else {
      setRotation(rotation - 1);
    }
  };

  /**
   * On rotate right
   */
  const onRotateRight = () => {
    if (rotation === 3) {
      setRotation(0);
    } else {
      setRotation(rotation + 1);
    }
  };

  /**
   * On pan
   */
  const onPan = (dx, dy) => {
    setDx(dx);
    setDy(dy);
  };

  /**
   * On reset all
   */
  useEffect(() => {
    if (src !== currentSrc) {
      onResetAll();
      setCurrentSrc(src);
    }
  }, [src, currentSrc]);

  return (
    <div className={`c-react-pan-zoom-image ${className}`} {...rest}>
      <div className="box-action">
        <Tooltip
          title={t('common.zoomOut')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button
            shape="circle"
            icon={<ZoomOutOutlined />}
            onClick={() => {
              if (zoom >= 0.1) {
                setZoom(zoom - 0.3);
              }
            }}
          />
        </Tooltip>

        {!isNaN(zoom) && (
          <Popover
            open={visibleZoomPopover}
            title={
              <Row justify="space-between" align="middle">
                <span className="font-weight-medium">{t('common.zoom')}</span>

                <Button
                  title={t('common.close')}
                  icon={<CloseOutlined className="font-size-12" />}
                  type="text"
                  size="small"
                  className="text-gray text-hover-danger border-0 p-0 ml-2"
                  onClick={() => setVisibleZoomPopover(false)}
                />
              </Row>
            }
            content={
              <Row align="middle">
                <InputNumber
                  value={(zoom * 100).toFixed(0)}
                  min={10}
                  max={1000000000}
                  step={100}
                  addonAfter="%"
                  autoFocus
                  className="input-zoom"
                  onChange={val => {
                    if (/^[0-9]*$/.test(val) && val >= 10 && val <= 1000000000) {
                      setZoom(val / 100);
                    }
                  }}
                />

                <Button className="ml-2" onClick={onResetAll}>
                  {t('common.reset')}
                </Button>
              </Row>
            }
            trigger="click"
            zIndex={1080}
            destroyTooltipOnHide={true}
            overlayClassName="c-zoom-popover"
            onOpenChange={setVisibleZoomPopover}
          >
            <Tooltip
              title={t('common.clickToCustomZoom')}
              placement="top"
              destroyTooltipOnHide={true}
              color="#fff"
              overlayInnerStyle={{ color: '#000' }}
              overlayStyle={{ pointerEvents: 'none' }}
            >
              <span className="txt-zoom-percent cursor-pointer mx-1" onClick={() => setVisibleZoomPopover(true)}>
                {`${(zoom * 100).toFixed(0)}%`}
              </span>
            </Tooltip>
          </Popover>
        )}

        <Tooltip
          title={t('common.zoomIn')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button shape="circle" icon={<ZoomInOutlined />} onClick={() => setZoom(zoom + 0.3)} />
        </Tooltip>

        <Tooltip
          title={t('common.rotateLeft')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button shape="circle" icon={<UndoOutlined />} onClick={onRotateLeft} />
        </Tooltip>

        <Tooltip
          title={t('common.rotateRight')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button shape="circle" icon={<RedoOutlined />} onClick={onRotateRight} />
        </Tooltip>

        <Tooltip
          title={t('common.flip')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button shape="circle" icon={<Flip />} onClick={() => setFlip(!flip)} />
        </Tooltip>

        <Tooltip
          title={t('common.reset')}
          placement="top"
          destroyTooltipOnHide={true}
          color="#fff"
          overlayInnerStyle={{ color: '#000' }}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <Button shape="circle" icon={<ExpandOutlined />} onClick={onResetAll} />
        </Tooltip>
      </div>

      <PanViewer
        key={dx}
        zoom={zoom}
        pandx={dx}
        pandy={dy}
        rotation={rotation}
        setZoom={setZoom}
        onPan={onPan}
        {...restPanViewer}
      >
        <img src={src} style={{ transform: `rotate(${rotation * 90}deg) scaleX(${flip ? -1 : 1})` }} {...restImage} />
      </PanViewer>
    </div>
  );
};
