import { COMPONENT_TYPE, NUMBER_PATTERN, URL_PATTERN } from '../../constants';
import { checkIsNotEmptyArray, checkIsNotEmptyObject, checkValidMomentDateString } from '.';

/**
 * Checking batch 3
 */
const checkingBatch3 = ({ val, field }) => {
  let isValid = false;

  switch (field?.componentType) {
    case COMPONENT_TYPE.PICKLIST: {
      isValid = checkIsNotEmptyArray(val) && val.every(v => !!v);
      break;
    }

    case COMPONENT_TYPE.SUGGESTION: {
      isValid = checkIsNotEmptyObject(val);
      break;
    }

    case COMPONENT_TYPE.URL: {
      isValid = new RegExp(URL_PATTERN).test(val);
      break;
    }

    default: {
      isValid = !!val;
      break;
    }
  }

  return isValid;
};

/**
 * Checking batch 2
 */
const checkingBatch2 = ({ val, field }) => {
  let isValid = false;

  switch (field?.componentType) {
    case COMPONENT_TYPE.TIME_TRACKING: {
      isValid = /^[0-9]*$/.test(val) && val > 0;
      break;
    }

    case COMPONENT_TYPE.USER: {
      isValid = val?.username || val?.email || val?.id;
      break;
    }

    case COMPONENT_TYPE.STATUS: {
      isValid = val?.id;
      break;
    }

    case COMPONENT_TYPE.PRIORITY: {
      isValid = /^[0-9]*$/.test(val);
      break;
    }

    default: {
      isValid = checkingBatch3({ val, field });
      break;
    }
  }

  return isValid;
};

/**
 * Check valid required field for submit value
 */
const checkValidRequiredFieldForSubmitValue = ({ val, field }) => {
  if (!field?.refName) {
    return false;
  }

  let isValid = false;

  switch (field?.componentType) {
    case COMPONENT_TYPE.STRING:
    case COMPONENT_TYPE.HTML:
    case COMPONENT_TYPE.OPTION: {
      isValid = typeof val === 'string' && !!val;
      break;
    }

    case COMPONENT_TYPE.NUMBER: {
      isValid = new RegExp(NUMBER_PATTERN).test(val);
      break;
    }

    case COMPONENT_TYPE.DATE:
    case COMPONENT_TYPE.DATE_TIME: {
      isValid = checkValidMomentDateString(val);
      break;
    }

    default: {
      isValid = checkingBatch2({ val, field });
      break;
    }
  }

  return isValid;
};

export default checkValidRequiredFieldForSubmitValue;
