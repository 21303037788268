import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useMenu = () => {
  // For language
  const [t] = useTranslation('akaat');

  /**
   * Convert to sub menu
   */
  const convertToSubMenu = ({ list, restSubMenu }) => {
    if (!(Array.isArray(list) && list.length)) {
      return;
    }

    return list.map(item => {
      const title = item?.name ? t(item?.name) : null;

      const newItem = {
        key: item?.path,
        id: `menu-key-${item?.id}`,
        label:
          item?.isAbsolutePath || /^(http|https):\/\//g.test(item?.path) ? (
            <a href={item?.path} target={item?.target || '_self'}>
              {title}
            </a>
          ) : (
            <Link to={item?.path} target={item?.target || '_self'}>
              {title}
            </Link>
          ),
        title,
        icon: item?.icon,
        ...restSubMenu
      };

      if (Array.isArray(item?.children) && item?.children.length) {
        newItem.children = convertToSubMenu({ list: item?.children, restSubMenu });
      }

      return newItem;
    });
  };

  /**
   * Convert group menu to ant menu items
   */
  const convertGroupMenuToAntMenuItems = ({ groupMenu, restSubMenu }) => {
    if (!(Array.isArray(groupMenu) && groupMenu.length)) {
      return [];
    }

    const newMenus = [...groupMenu].map(item => {
      return {
        key: item?.id,
        id: `menu-key-${item?.id}`,
        type: 'group',
        label: item?.name ? t(item?.name) : null,
        className: !item?.name ? 'no-group' : '',
        children: convertToSubMenu({ list: item?.children, restSubMenu })
      };
    });

    return newMenus;
  };

  return { convertGroupMenuToAntMenuItems };
};

export default useMenu;
