import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import objectPath from 'object-path';
import { useStoreActions } from 'easy-peasy';

import {
  SYSTEM_FIELD_KEY,
  FROM_MODULE_TEST_PLAN,
  FROM_MODULE_TEST_REPO,
  FROM_MODULE_TEST_DATA,
  FROM_MODULE_WORK_TICKET,
  WORK_ITEM_TEST_RUN_ID,
  WORK_ITEM_TESTCASE_ID,
  WORK_ITEM_TEST_RESULT_ID
} from '../../../../constants';
import { getPrePathLinkToTicket } from '../../../../common/utils';
import { useEditableCell } from '../../../../common/hooks';
import RelationLink from '../../relation-link';

const KeyField = ({ workTicketId, fromModule, row, field, disableEditingCells, restKeyField, onReload }) => {
  const urlParams = useParams();

  // For language
  const [t] = useTranslation('akaat');

  // For hooks
  const { checkHasNewItems, showConfirmDiscardChangesModal } = useEditableCell();

  // For global store
  const setQuickEditingDrawer = useStoreActions(action => action.global.setQuickEditingDrawer);

  /**
   * Prefix path
   */
  const prefixPath = useMemo(() => {
    if (!fromModule) {
      return;
    }

    switch (fromModule) {
      case FROM_MODULE_TEST_PLAN:
        return getPrePathLinkToTicket({ workTicketId: WORK_ITEM_TEST_RUN_ID });

      case FROM_MODULE_TEST_REPO:
        return getPrePathLinkToTicket({
          workTicketId: WORK_ITEM_TESTCASE_ID,
          noGoToTestcaseVersion: !restKeyField?.hasGoToTestcaseVersion
        });

      case FROM_MODULE_TEST_DATA:
        return getPrePathLinkToTicket({ workTicketId: WORK_ITEM_TEST_RESULT_ID });

      case FROM_MODULE_WORK_TICKET:
        return getPrePathLinkToTicket({ workTicketId: urlParams?.refName });

      default:
        return;
    }
  }, [fromModule, urlParams]);

  /**
   * On ok discard changes
   */
  const onOkDiscardChanges = key => {
    typeof onReload === 'function' && onReload();
    setTimeout(() => setQuickEditingDrawer({ visible: true, key }), 800);
  };

  /**
   * On before open quick editing drawer
   */
  const onBeforeOpenQuickEditingDrawer = key => {
    if (!checkHasNewItems()) {
      setQuickEditingDrawer({ visible: true, key });
      return;
    }

    showConfirmDiscardChangesModal({ onOk: () => onOkDiscardChanges(key) });
  };

  /**
   * Render cell text utils
   */
  const checkIsNoLink = ({ field, restKeyField }) => {
    return field?.refName === SYSTEM_FIELD_KEY && restKeyField?.noLink;
  };

  const checkIsLink = ({ field, disableEditingCells, restKeyField }) => {
    const condition1 = field?.refName === SYSTEM_FIELD_KEY;
    const condition2 = disableEditingCells || (!disableEditingCells && restKeyField?.disableQuickEditing);
    return condition1 && condition2;
  };

  const checkIsLinkWithOpenDetail = ({ field, disableEditingCells, restKeyField }) => {
    return field?.refName === SYSTEM_FIELD_KEY && !disableEditingCells && !restKeyField?.disableQuickEditing;
  };

  const renderNoLink = ({ val }) => {
    return (
      <span title={val || ''} className="min-h-22 text-truncate">
        {val}
      </span>
    );
  };

  const renderLink = ({ val, link }) => {
    return (
      <div className="cell-text text-truncate hide-after">
        <Link
          to={link}
          title={val || ''}
          className="cell-text-value min-h-22 allow-click text-primary text-hover-dark-primary text-truncate cursor-pointer"
          onClick={e => e.stopPropagation()}
        >
          {val}
        </Link>
      </div>
    );
  };

  const renderLinkWithOpenDetail = ({ val, link }) => {
    return (
      <div className="cell-text hide-after">
        <span
          title={val || ''}
          className="cell-text-value min-h-22 allow-click text-primary text-hover-dark-primary text-truncate cursor-pointer"
          onClick={e => {
            e.stopPropagation();
            onBeforeOpenQuickEditingDrawer(val);
          }}
        >
          {val}
        </span>

        <Tooltip
          title={t('common.goToDetail')}
          placement="top"
          destroyTooltipOnHide={true}
          mouseEnterDelay={0.3}
          overlayStyle={{ pointerEvents: 'none' }}
        >
          <div className="btn-go-to-detail-wrapper btn-action-editable-cell-wrapper">
            <Link
              to={link}
              className="btn-go-to-detail btn-action-editable-cell allow-click ant-btn ant-btn-sm"
              onClick={e => e.stopPropagation()}
            >
              {t('common.open')}
            </Link>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ val }) => {
    return (
      <RelationLink title={val || ''} row={row} field={field}>
        {val || ''}
      </RelationLink>
    );
  };

  const renderCellTextUtilReadonly2 = ({ val }) => {
    return (
      <div title={val || ''} className="cell-text text-truncate">
        <RelationLink row={row} field={field}>
          {val || ''}
        </RelationLink>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const val = objectPath.get(row, field?.refName);
    let link = `${prefixPath}${val}`;

    if (!restKeyField?.noAddQueryParamsToKeyLink) {
      link = `${prefixPath}${val}${window.location.search}`;
    }

    // For new row
    if (row?.isNew) {
      return null;
    }

    // For key field
    else if (checkIsNoLink({ field, restKeyField })) {
      return renderNoLink({ val });
    }

    // For key field
    else if (checkIsLink({ field, disableEditingCells, restKeyField })) {
      return renderLink({ val, link });
    }

    // For key field
    else if (checkIsLinkWithOpenDetail({ field, disableEditingCells, restKeyField })) {
      return renderLinkWithOpenDetail({ val, link });
    }

    // Relation field
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ val });
    }

    // Relation field
    else {
      return renderCellTextUtilReadonly2({ val });
    }
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return <div className="field-wrapper key-field">{renderCellText()}</div>;
};

export default KeyField;
