import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { Button, Collapse, Spin, Tooltip } from 'antd';
import { ReloadOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { FULL_DATE_FORMAT, HISTORY_TYPE_LIST, HISTORY_TYPE_CREATE } from '../../constants';
import { checkValidMomentDateString, getFieldName } from '../../common/utils';
import { UserAvatar } from '../../components';
import PanelContent from './panel-content';

import './style.scss';

const { Panel } = Collapse;

export const BasicHistory = ({
  workTicketId,
  data,
  total,
  page,
  limit,
  fieldList = [],
  loadingList,
  className = '',
  onShowMore,
  onReload,
  ...rest
}) => {
  // For language
  const [t, i18n] = useTranslation('akaat');

  // For project user list store
  const projectUserListData = useStoreState(state => state.global.projectUserList);

  // Component state
  const [activeCollapseKeys, setActiveCollapseKeys] = useState([]);

  /**
   * Has project user list
   */
  const projectUserList = useMemo(() => {
    return Array.isArray(projectUserListData) && projectUserListData.length ? [...projectUserListData] : [];
  }, [projectUserListData]);

  /**
   * Render panel header util render item
   */
  const renderPanelHeaderUtilRenderItem = ({ key, item }) => {
    if (key === 'properties') {
      return Object.keys(item?.newValue?.properties)
        .map(key => {
          const fieldData = fieldList.find(f => f?.refName === `properties.${key}`);

          if (!fieldData) {
            return;
          }

          return getFieldName(fieldData);
        })
        .join(', ');
    } else {
      return i18n.exists(`akaat:workItem.${key}`) ? t(`workItem.${key}`) : key;
    }
  };

  /**
   * Render panel header
   */
  const renderPanelHeader = item => {
    if (!item) {
      return;
    }

    const isUserInProject = projectUserList.some(u => u?.username === item.createdBy?.username);

    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <UserAvatar user={item.createdBy} inactive={!isUserInProject} />
        <div className="mx-1">-</div>
        <div className="mr-1">{HISTORY_TYPE_LIST[item.type]}</div>
        <div className="font-weight-bold mr-1">
          {item?.type === HISTORY_TYPE_CREATE
            ? ''
            : item.newValue &&
              Object.keys(item.newValue)
                .filter(key => key !== 'noReload')
                .map(key => renderPanelHeaderUtilRenderItem({ key, item }))
                .join(', ')}
        </div>
        <div title={checkValidMomentDateString(item.createdAt) ? moment(item.createdAt).format(FULL_DATE_FORMAT) : ''}>
          {checkValidMomentDateString(item.createdAt) ? moment(item.createdAt).fromNow() : ''}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`c-basic-history ${className}`} {...rest}>
        <div className="position-relative">
          <Tooltip placement="bottom" title={t('common.reload')} destroyTooltipOnHide={true}>
            <Button
              id="reload-history-button"
              type="link"
              icon={<ReloadOutlined />}
              className="btn-reload-on-detail-page"
              onClick={onReload}
            ></Button>
          </Tooltip>
        </div>

        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingList}>
          <div className="box-history-list min-h-22">
            {Array.isArray(data) && data.length > 0 && (
              <Collapse
                activeKey={activeCollapseKeys}
                destroyInactivePanel={true}
                bordered={false}
                onChange={setActiveCollapseKeys}
              >
                {data
                  .filter(item => {
                    const isNewValueNone = !item.newValue || (Array.isArray(item.newValue) && !item.newValue.length);
                    const isOldValueNone = !item.oldValue || (Array.isArray(item.oldValue) && !item.oldValue.length);
                    return !(isNewValueNone && isOldValueNone);
                  })
                  .map(item => (
                    <Panel key={item._id} id={`history-${item._id}`} header={renderPanelHeader(item)}>
                      <PanelContent item={item} fieldList={fieldList} workTicketId={workTicketId} />
                    </Panel>
                  ))}
              </Collapse>
            )}
          </div>
        </Spin>

        {Array.isArray(data) && data.length > 0 && page < Math.ceil(total / limit) && (
          <div className="text-center mt-2">
            <span
              className="text-primary text-hover-dark-primary cursor-pointer"
              onClick={e => {
                e.preventDefault();
                onShowMore();
              }}
            >
              {loadingList && <Loading3QuartersOutlined spin />} {t('common.showMore')}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
