import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Layout, Dropdown, Row } from 'antd';
import { UserOutlined, LoginOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';

import env from '../../env';
import {
  ROLE_APP_ACCESS_TESTENGINE,
  ROLE_APP_ACCESS_MANAGER,
  PRODUCT_KEY_ON_URL_ENGINE,
  PRODUCT_KEY_ON_URL_MANAGER
} from '../../constants';
import { useKeycloak } from '../../common/hooks';
import { UserAvatar } from '../../components';
import BoxProjectActions from './box-project-actions';

import './style.scss';

const isShowLabelBeta = false;

export const MainHeader = () => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];
  const thirdPath = location.pathname.split('/')[env.REACT_APP_PREFIX_PATH ? 4 : 3];

  // For keycloak
  const { keycloak } = useKeycloak();

  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProductionKey = useStoreState(state => state.global.globalProductionKey);
  const globalProject = useStoreState(state => state.global.globalProject);

  /**
   * Product menus
   */
  const productMenus = useMemo(() => {
    const items = [];

    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_MANAGER)) {
      items.push({
        key: PRODUCT_KEY_ON_URL_MANAGER,
        label: (
          <Link
            to={{
              pathname: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/${PRODUCT_KEY_ON_URL_MANAGER}/dashboard`
            }}
          >
            {t('header.management')}
          </Link>
        )
      });
    }

    if (Array.isArray(globalUserInfo?.permissions) && globalUserInfo.permissions.includes(ROLE_APP_ACCESS_TESTENGINE)) {
      items.push({
        key: PRODUCT_KEY_ON_URL_ENGINE,
        label: (
          <Link
            to={{
              pathname: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/${PRODUCT_KEY_ON_URL_ENGINE}/summary`
            }}
          >
            {t('header.runTimeEngine')}
          </Link>
        )
      });
    }

    return items;
  }, [globalUserInfo, tenantPath, projectPath]);

  /**
   * Account menus
   */
  const accountMenus = useMemo(() => {
    const items = [];

    if (globalUserInfo?.id) {
      items.push({
        key: 'profile',
        label: (
          <a href={`${env.REACT_APP_AKAAT_URL}/profile`} target="_blank" rel="noreferrer">
            <UserOutlined /> {t('header.myProfile')}
          </a>
        )
      });
    }

    if (keycloak?.authenticated) {
      items.push({
        key: 'logout',
        label: (
          <a rel="noreferrer" onClick={() => keycloak?.logout()}>
            <LogoutOutlined /> {t('header.logout')}
          </a>
        )
      });
    }

    if (!keycloak?.authenticated) {
      items.push({
        key: 'login',
        label: (
          <a rel="noreferrer" onClick={() => keycloak?.login()}>
            <LoginOutlined /> {t('header.login')}
          </a>
        )
      });
    }

    return items;
  }, [globalUserInfo, keycloak?.authenticated]);

  /**
   * Show box product menu
   */
  const showBoxProductMenu = useMemo(() => {
    const noProjectSettings = projectPath && projectPath !== '_settings';
    const hasProductMenus = Array.isArray(productMenus) && productMenus.length > 0;

    return (
      noProjectSettings && projectPath === globalProject?.projectKey && thirdPath !== '_settings' && hasProductMenus
    );
  }, [projectPath, globalProject, productMenus]);

  return (
    <>
      <Layout.Header className={`c-main-header ${isShowLabelBeta ? 'beta-version' : ''}`}>
        {isShowLabelBeta && (
          <div className="label-beta" style={{ display: 'none' }}>
            <span>BETA</span>
          </div>
        )}

        <Row align="middle">
          <div className="logo pl-3 pr-3" style={{ width: 220 }}>
            <a href={`/${env.REACT_APP_PREFIX_PATH}${globalTenant?.tenantKey || ''}`}>
              <img src={`/${env.REACT_APP_PREFIX_PATH}images/logo.png`} alt="AkaAT Logo" width="156" height="40" />
            </a>
          </div>

          {showBoxProductMenu && (
            <ul className="box-product-menu">
              {productMenus.map(item => {
                return (
                  <li
                    key={item?.key}
                    id={`product-header-menu-${item?.key}`}
                    className={`${item?.key === globalProductionKey ? 'active' : ''}`}
                  >
                    {item?.label}
                  </li>
                );
              })}
            </ul>
          )}
        </Row>

        <Row wrap={false} align="middle" justify="space-between" style={{ flex: '1 1 auto', overflowX: 'auto' }}>
          <div style={{ flex: '1 1 auto' }} className="box-action">
            {tenantPath && !projectPath && <BoxProjectActions />}
          </div>

          {/* <LanguageDropdown restDropdown={{ placement: 'bottom' }} className="ml-3" /> */}

          <Row wrap={false} align="middle" justify="end">
            <Dropdown
              menu={{ items: accountMenus }}
              trigger={['click']}
              destroyPopupOnHide={true}
              placement="bottomRight"
              className="btn-dropdown-account ml-4"
            >
              <Row wrap={false} align="middle" justify="end" className="ant-dropdown-link cursor-pointer">
                <UserAvatar
                  user={globalUserInfo}
                  restAvatar={{ size: 23 }}
                  restInfoDropdown={{ placement: 'bottomRight' }}
                />
                <DownOutlined className="ic-arrow" />
              </Row>
            </Dropdown>
          </Row>
        </Row>
      </Layout.Header>
    </>
  );
};
