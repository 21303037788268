import { createStore } from 'easy-peasy';

// For global
import globalLanguage from './global-language';
import globalEditableCell from './global-editable-cell';
import globalFile from './global-file';
import globalImport from './global-import';
import globalTenant from './global-tenant';
import globalProject from './global-project';
import globalProductionKey from './global-production-key';
import globalUserInfo from './global-user-info';
import globalSystemUsers from './global-system-users';
import globalTenantRoles from './global-tenant-roles';
import globalTenantUsers from './global-tenant-users';
import globalProjectUsers from './global-project-users';
import globalProjectRoles from './global-project-roles';
import globalProjectTeams from './global-project-teams';
import globalTeamsUser from './global-team-users';
import globalTenantProjects from './global-tenant-projects';
import searchFieldList from './search-field-list';
import searchSuggestionList from './search-suggestion-list';
import globalFieldList from './global-field-list';
import jiraIntegration from './jira-integration';
import globalTemplate from './global-template';
import globalProcess from './global-process';
import globalRelationType from './relation-type';
import globalWorkTicketObjective from './work-ticket-objective';
import globalWorkTicketRelation from './work-ticket-relation';
import globalTicketType from './global-ticket-type';
// import modelAuthenticationCustom from '../keycloak/fsoft/authentication-store-fsoft';

// For home page
import { model as modelIntegration } from '../../pages/project-settings/integration-settings/models/model';
import { model as modelJiraIntegration } from '../../pages/project-settings/integration-settings/models/model-jira-integration';
import { model as modelAzureIntegration } from '../../pages/project-settings/integration-settings/models/model-azure-settings';
import { model as modelProcess } from '../../pages/home/process/models/model';
import { model as modelWorkflow } from '../../pages/home/process/models/model-workflow';
import { model as modelNotification } from '../../pages/project-settings/notification-settings/models/model';
import { model as modelImportTemplate } from '../../pages/project-settings/template-settings/models/model-import-template';
import { model as modelExportTemplate } from '../../pages/project-settings/template-settings/models/model-export-template';

// For project manager page
import { model as modelManagerExportFile } from './manager-export-file';
import { model as modelCommonReport } from '../../pages/manager/dashboard/models/model';
import { model as modelMyReport } from '../../pages/manager/my-reports/models/model';
import { model as modelTestPlanCycle } from '../../pages/manager/test-plan/models/model-test-plan-cycle';
import { model as modelTestPlanRelease } from '../../pages/manager/test-plan/models/model-test-plan-release';
import { model as modelTestPlanSuite } from '../../pages/manager/test-plan/models/model-test-plan-suite';
import { model as modelCompareTestSuite } from '../../pages/manager/test-plan/models/model-test-suite-compare';
import { model as modelTestPlanTree } from '../../pages/manager/test-plan/models/model-test-plan-tree';
import { model as modelTestRun } from '../../pages/manager/test-plan/models/model-test-run';
import { model as modelTestRunComment } from '../../pages/manager/test-plan/models/model-test-run-comment';
import { model as modelTestRunHistory } from '../../pages/manager/test-plan/models/model-test-run-history';
import { model as modelTestExecutionModal } from '../../pages/manager/test-plan/models/model-test-execution-modal';
import { model as modelImportTestRun } from '../../pages/manager/test-plan/models/model-import-test-run';
import { model as modelExportTestRun } from '../../pages/manager/test-plan/models/model-export-test-run';
import { model as modelTestResult } from '../../pages/manager/test-data/models/model-test-result';
import { model as modelTestResultComment } from '../../pages/manager/test-data/models/model-test-result-comment';
import { model as modelTestResultHistory } from '../../pages/manager/test-data/models/model-test-result-history';
import { model as modelExportTestCase } from '../../pages/manager/test-repository/models/model-export-test-case';
import { model as modelImportTestCase } from '../../pages/manager/test-repository/models/model-import-test-case';
import { model as modelTestCaseComment } from '../../pages/manager/test-repository/models/model-testcase-comment';
import { model as modelTestCaseHistory } from '../../pages/manager/test-repository/models/model-testcase-history';
import { model as modelTestCaseVersion } from '../../pages/manager/test-repository/models/model-test-case-version';
import { model as modelTestCase } from '../../pages/manager/test-repository/models/model';
import { model as modelRepositoryTree } from '../../pages/manager/test-repository/models/model-repository-tree';
import { model as modelManagerWorkItem } from '../../pages/manager/work-item/models/model-work-ticket';
import { model as modelManagerWorkTicketComment } from '../../pages/manager/work-item/models/model-work-ticket-comment';
import { model as modelManagerWorkTicketHistory } from '../../pages/manager/work-item/models/model-work-ticket-history';
import { model as modelImportWorkTicket } from '../../pages/manager/work-item/models/model-import-work-ticket';
import { model as modelExportWorkTicket } from '../../pages/manager/work-item/models/model-export-work-ticket';
import { model as modelSubscription } from '../../pages/home/subscription/models/model-subscription';
import { model as modelTraceability } from '../../pages/manager/traceability/models/model';
import { model as modelExtension } from '../../pages/project-settings/extensions/models/model-extension';

// For project engine pages
import { model as modelEngineAgent } from '../../pages/engine/agent-pools/models/model-agent';
import { model as modelEngineAgentPool } from '../../pages/engine/agent-pools/models/model-agent-pool';
import { model as modelEngineJobsByAgentPool } from '../../pages/engine/agent-pools/models/model-jobs-by-agent-pool';
import { model as modelSchedule } from '../../pages/engine/planning/models/model';
import { model as modelEngineJobSummary } from '../../pages/engine/planning/models/model-job-summary';
import { model as modelEngineJobRunner } from '../../pages/engine/planning/page-job-runner/models/model';
import { model as modelEngineJobRunnerTree } from '../../pages/engine/planning/page-job-runner/models/model-job-runner-tree';
import { model as modelEngineCommonReport } from '../../pages/engine/report/page-common-reports/models/model';
import { model as modelEngineMyReport } from '../../pages/engine/report/page-my-reports/models/model';
import { model as modelEngineSummary } from '../../pages/engine/summary/models/model';
import { model as modelTestConfig } from '../../pages/project-settings/test-config-settings/models/model-test-config';
import { model as modelTestConfigTree } from '../../pages/project-settings/test-config-settings/models/model-test-config-tree';
import { model as modelTestConfigVariable } from '../../pages/project-settings/test-config-settings/models/model-test-config-variable';
import { model as modelEngineTestData } from '../../pages/engine/test-data/models/model';
import { model as modelEngineTestDataExport } from '../../pages/engine/test-data/models/model-export';
import { model as modelEngineTestResult } from '../../pages/engine/test-data/test-results/models/model';
import { model as modelEngineExportTestResult } from '../../pages/engine/test-data/test-results/models/model-export-test-result';
import { model as modelEngineTestResultComment } from '../../pages/engine/test-data/test-results/models/model-test-result-comment';
import { model as modelEngineTestResultSummary } from '../../pages/engine/test-data/test-results/models/model-test-result-summary';
import { model as modelEngineExportTestResultJobRunner } from '../../pages/engine/planning/page-job-runner/models/model-export-job-runner-test-result';

/**
 * Create store
 */
const store = createStore({
  global: {
    ...globalLanguage,
    ...globalEditableCell,
    ...globalFile,
    ...globalImport,
    ...globalTenant,
    ...globalProject,
    ...globalProductionKey,
    ...globalUserInfo,
    ...globalSystemUsers, // All of users in the system
    ...globalTenantRoles, // All of roles in current tenant
    ...globalTenantUsers, // All of users in current tenant
    ...globalProjectUsers, // All of users in current project
    ...globalProjectRoles, // All of roles in current project
    ...globalProjectTeams, // All of teams in current project
    ...globalTeamsUser, // All of users in current teams
    ...globalTenantProjects, // All of projects in current tenant
    ...searchFieldList,
    ...searchSuggestionList,
    ...globalFieldList,
    ...jiraIntegration,
    ...globalTemplate,
    ...globalProcess,
    ...globalRelationType,
    ...globalWorkTicketObjective,
    ...globalWorkTicketRelation,
    ...globalTicketType
  }
});

/**
 * Add other models
 */
// store.addModel('authenticationCustom', modelAuthenticationCustom); // For authentication fsoft

// For home page
store.addModel('integration', modelIntegration);
store.addModel('jiraIntegration', modelJiraIntegration);
store.addModel('azureIntegration', modelAzureIntegration);
store.addModel('notification', modelNotification);
store.addModel('importTemplate', modelImportTemplate);
store.addModel('exportTemplate', modelExportTemplate);
store.addModel('process', modelProcess);
store.addModel('workflow', modelWorkflow);

// For project manager page
store.addModel('testcase', modelTestCase);
store.addModel('repositoryTree', modelRepositoryTree);
store.addModel('importTestCase', modelImportTestCase);
store.addModel('exportTestCase', modelExportTestCase);
store.addModel('testcaseVersion', modelTestCaseVersion);
store.addModel('testCaseComment', modelTestCaseComment);
store.addModel('testcaseHistory', modelTestCaseHistory);
store.addModel('testPlanTree', modelTestPlanTree);
store.addModel('testPlanRelease', modelTestPlanRelease);
store.addModel('testPlanCycle', modelTestPlanCycle);
store.addModel('testPlanSuite', modelTestPlanSuite);
store.addModel('compareTestSuite', modelCompareTestSuite);
store.addModel('testRun', modelTestRun);
store.addModel('testRunComment', modelTestRunComment);
store.addModel('testRunHistory', modelTestRunHistory);
store.addModel('testExecutionModal', modelTestExecutionModal);
store.addModel('importTestRun', modelImportTestRun);
store.addModel('exportTestRun', modelExportTestRun);
store.addModel('testResult', modelTestResult);
store.addModel('testResultComment', modelTestResultComment);
store.addModel('testResultHistory', modelTestResultHistory);
store.addModel('commonReport', modelCommonReport);
store.addModel('myReport', modelMyReport);
store.addModel('managerWorkItem', modelManagerWorkItem);
store.addModel('managerWorkTicketComment', modelManagerWorkTicketComment);
store.addModel('managerWorkTicketHistory', modelManagerWorkTicketHistory);
store.addModel('importWorkTicket', modelImportWorkTicket);
store.addModel('exportWorkTicket', modelExportWorkTicket);
store.addModel('managerExportFile', modelManagerExportFile);
store.addModel('subscription', modelSubscription);
store.addModel('testConfig', modelTestConfig);
store.addModel('testConfigTree', modelTestConfigTree);
store.addModel('testConfigVariable', modelTestConfigVariable);
store.addModel('traceability', modelTraceability);
store.addModel('extensions', modelExtension);

// For project engine pages
store.addModel('engineSummary', modelEngineSummary);
store.addModel('scheduleJob', modelSchedule);
store.addModel('engineJobSummary', modelEngineJobSummary);
store.addModel('engineJobRunner', modelEngineJobRunner);
store.addModel('engineJobRunnerTree', modelEngineJobRunnerTree);
store.addModel('engineTestResult', modelEngineTestResult);
store.addModel('engineTestResultSummary', modelEngineTestResultSummary);
store.addModel('engineTestResultComment', modelEngineTestResultComment);
store.addModel('engineExportTestResult', modelEngineExportTestResult);
store.addModel('engineCommonReport', modelEngineCommonReport);
store.addModel('engineMyReport', modelEngineMyReport);
store.addModel('engineAgentPool', modelEngineAgentPool);
store.addModel('engineJobsByAgentPool', modelEngineJobsByAgentPool);
store.addModel('engineAgent', modelEngineAgent);
store.addModel('engineTestData', modelEngineTestData);
store.addModel('engineTestDataExport', modelEngineTestDataExport);
store.addModel('engineExportTestResultJobRunner', modelEngineExportTestResultJobRunner);

export default store;
