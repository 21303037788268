import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';

const STYLE_LEFT_50_SUBTRACT_30 = 'calc(50% - 30px)';
const STYLE_LEFT_50_PLUS_30 = 'calc(50% + 30px)';
const STYLE_LEFT_50_SUBTRACT_8 = 'calc(50% - 8px)';
const STYLE_LEFT_50_PLUS_8 = 'calc(50% + 8px)';

const HANDLE_LIST = [
  // ==========> target-top
  {
    id: 'target-top-center',
    type: 'target',
    position: 'top'
  },
  {
    id: 'target-top-left',
    type: 'target',
    position: 'top',
    style: { left: STYLE_LEFT_50_SUBTRACT_30 }
  },
  {
    id: 'target-top-right',
    type: 'target',
    position: 'top',
    style: { left: STYLE_LEFT_50_PLUS_30 }
  },
  // target-bottom
  {
    id: 'target-bottom-center',
    type: 'target',
    position: 'bottom'
  },
  {
    id: 'target-bottom-left',
    type: 'target',
    position: 'bottom',
    style: { left: STYLE_LEFT_50_SUBTRACT_30 }
  },
  {
    id: 'target-bottom-right',
    type: 'target',
    position: 'bottom',
    style: { left: STYLE_LEFT_50_PLUS_30 }
  },
  // target-right
  {
    id: 'target-right-center',
    type: 'target',
    position: 'right'
  },
  {
    id: 'target-right-top',
    type: 'target',
    position: 'right',
    style: { top: STYLE_LEFT_50_SUBTRACT_8 }
  },
  {
    id: 'target-right-bottom',
    type: 'target',
    position: 'right',
    style: { top: STYLE_LEFT_50_PLUS_8 }
  },
  // target-left
  {
    id: 'target-left-center',
    type: 'target',
    position: 'left'
  },
  {
    id: 'target-left-top',
    type: 'target',
    position: 'left',
    style: { top: STYLE_LEFT_50_SUBTRACT_8 }
  },
  {
    id: 'target-left-bottom',
    type: 'target',
    position: 'left',
    style: { top: STYLE_LEFT_50_PLUS_8 }
  },
  // ==========> source-top
  {
    id: 'source-top-center',
    type: 'source',
    position: 'top'
  },
  {
    id: 'source-top-left',
    type: 'source',
    position: 'top',
    style: { left: STYLE_LEFT_50_SUBTRACT_30 }
  },
  {
    id: 'source-top-right',
    type: 'source',
    position: 'top',
    style: { left: STYLE_LEFT_50_PLUS_30 }
  },
  // source-bottom
  {
    id: 'source-bottom-center',
    type: 'source',
    position: 'bottom'
  },
  {
    id: 'source-bottom-left',
    type: 'source',
    position: 'bottom',
    style: { left: STYLE_LEFT_50_SUBTRACT_30 }
  },
  {
    id: 'source-bottom-right',
    type: 'source',
    position: 'bottom',
    style: { left: STYLE_LEFT_50_PLUS_30 }
  },
  // source-right
  {
    id: 'source-right-center',
    type: 'source',
    position: 'right'
  },
  {
    id: 'source-right-top',
    type: 'source',
    position: 'right',
    style: { top: STYLE_LEFT_50_SUBTRACT_8 }
  },
  {
    id: 'source-right-bottom',
    type: 'source',
    position: 'right',
    style: { top: STYLE_LEFT_50_PLUS_8 }
  },
  // source-left
  {
    id: 'source-left-center',
    type: 'source',
    position: 'left'
  },
  {
    id: 'source-left-top',
    type: 'source',
    position: 'left',
    style: { top: STYLE_LEFT_50_SUBTRACT_8 }
  },
  {
    id: 'source-left-bottom',
    type: 'source',
    position: 'left',
    style: { top: STYLE_LEFT_50_PLUS_8 }
  }
];

export const CustomNode = memo(props => {
  return (
    <>
      <div>{props?.data?.name}</div>

      {HANDLE_LIST.map(item => {
        return (
          <Handle
            key={item.id}
            id={item.id}
            type={item.type}
            position={item.position}
            style={item.style ? item.style : null}
          />
        );
      })}
    </>
  );
});
