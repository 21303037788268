import React from 'react';

import {
  COMPONENT_TYPE,
  JIRA_PLATFORM_ID,
  FIELD_STEP_RESULT,
  SYSTEM_FIELD_STATUS,
  SYSTEM_FIELD_VERSION,
  WORK_ITEM_TESTCASE_ID,
  WORK_ITEM_TEST_RUN_ID,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_END_DATE_PLAN,
  SYSTEM_FIELD_START_DATE_PLAN,
  SYSTEM_FIELD_AUTOMATION_STATUS,
  SYSTEM_FIELD_TEST_CASE_VERSION,
  SYSTEM_FIELD_LATEST_RESULT_STATUS
} from '../../constants';
import {
  checkIsKeyFieldRefName,
  checkIsAssignToFieldRefName,
  checkIsTagFieldRefName,
  checkIsScriptPathFieldRefName,
  checkIsTestPlanningFolderFieldRefName
} from '../../common/utils';
import KeyField from './ref-name/key-field';
import TestcaseVersionField from './ref-name/testcase-version-field';
import TestRunStatusField from './ref-name/test-run-status-field';
import LatestResultStatusField from './ref-name/latest-result-status-field';
import StepResultStatusField from './ref-name/step-result-status-field';
import AssignToField from './ref-name/assign-to-field';
import TagField from './ref-name/tag-field';
import ScriptPathField from './ref-name/script-path-field';
import ReleaseCycleTestSuiteField from './ref-name/release-cycle-test-suite-field';
import AutomationStatusField from './ref-name/automation-status-field';
import PlanDateField from './ref-name/plan-date-field';

import NumberField from './component-type/number-field';
import DateField from './component-type/date-field';
import DatetimeField from './component-type/datetime-field';
import HtmlField from './component-type/html-field';
import TimeTrackingField from './component-type/time-tracking-field';
import UserField from './component-type/user-field';
import StatusField from './component-type/status-field';
import PriorityField from './component-type/priority-field';
import OptionField from './component-type/option-field';
import PicklistField from './component-type/picklist-field';
import SuggestionField from './component-type/suggestion-field';
import UrlField from './component-type/url-field';
import StringField from './component-type/string-field';
import TestPlanningField from './component-type/test-planning-field';
import FolderField from './component-type/folder-field';
import AttachmentsField from './component-type/attachments-field';

import JiraCell from './external-system-jira/jira-cell';

const Cell = ({
  fromModule,
  workTicketId,
  row,
  field,
  currentTreeItem,
  hasAllowAssignToPermission,
  allowQuickExecute,
  restKeyField,
  restAttachmentsField,
  onGetUserListByRole,
  onGetSuggestion,
  onReload,
  ...rest
}) => {
  /**
   * Render for component type batch 2
   */
  const renderForComponentTypeBatch2 = ({
    workTicketId,
    row,
    field,
    restAttachmentsField,
    onGetSuggestion,
    ...rest
  }) => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.PICKLIST: {
        return <PicklistField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.SUGGESTION: {
        return (
          <SuggestionField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            onGetSuggestion={onGetSuggestion}
          />
        );
      }

      case COMPONENT_TYPE.URL: {
        return <UrlField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.STRING: {
        return <StringField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.TEST_PLANNING: {
        return (
          <TestPlanningField
            workTicketId={workTicketId}
            row={row}
            field={field}
            disableEditingCells={rest?.disableEditingCells}
          />
        );
      }

      case COMPONENT_TYPE.FOLDER: {
        return (
          <FolderField
            workTicketId={workTicketId}
            row={row}
            field={field}
            disableEditingCells={rest?.disableEditingCells}
          />
        );
      }

      case COMPONENT_TYPE.ATTACHMENTS: {
        return (
          <AttachmentsField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            restAttachmentsField={restAttachmentsField}
          />
        );
      }

      default: {
        return <div style={{ visibility: 'hidden' }}>N/A</div>;
      }
    }
  };

  /**
   * Render for component type batch 1
   */
  const renderForComponentTypeBatch1 = ({
    workTicketId,
    row,
    field,
    restAttachmentsField,
    onGetSuggestion,
    ...rest
  }) => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.NUMBER: {
        return <NumberField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.HTML: {
        return <HtmlField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.DATE: {
        return <DateField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.DATE_TIME: {
        return <DatetimeField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        return <TimeTrackingField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.USER: {
        return <UserField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.STATUS: {
        return <StatusField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.PRIORITY: {
        return <PriorityField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case COMPONENT_TYPE.OPTION: {
        return <OptionField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      default: {
        return renderForComponentTypeBatch2({
          workTicketId,
          row,
          field,
          restAttachmentsField,
          onGetSuggestion,
          ...rest
        });
      }
    }
  };

  /**
   * Check belong to jira fields
   */
  const checkBelongToJiraFields = ({ row, field }) => {
    const condition1 = row?.externalSystem === JIRA_PLATFORM_ID;
    const condition2 =
      field?.refName === SYSTEM_FIELD_ASSIGN_TO ||
      field?.componentType === COMPONENT_TYPE.USER ||
      field?.componentType === COMPONENT_TYPE.STATUS ||
      field?.componentType === COMPONENT_TYPE.PRIORITY;

    return condition1 && condition2;
  };

  /**
  /* Render cell batch 3
   */
  const renderCellBatch3 = () => {
    // For internal
    switch (field?.refName) {
      case checkIsTestPlanningFolderFieldRefName(field?.refName): {
        return (
          <ReleaseCycleTestSuiteField
            workTicketId={workTicketId}
            row={row}
            field={field}
            disableEditingCells={rest?.disableEditingCells}
          />
        );
      }

      case new RegExp(
        `(${SYSTEM_FIELD_AUTOMATION_STATUS}|${SYSTEM_FIELD_TEST_CASE_VERSION}\.${SYSTEM_FIELD_AUTOMATION_STATUS})$`,
        'g'
      ).test(field?.refName)
        ? field?.refName
        : false: {
        return (
          <AutomationStatusField
            workTicketId={workTicketId}
            row={row}
            field={field}
            disableEditingCells={rest?.disableEditingCells}
          />
        );
      }

      case new RegExp(`(${SYSTEM_FIELD_START_DATE_PLAN}|${SYSTEM_FIELD_END_DATE_PLAN})$`, 'g').test(field?.refName)
        ? field?.refName
        : false: {
        return (
          <PlanDateField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            currentTreeItem={currentTreeItem}
          />
        );
      }

      default: {
        return renderForComponentTypeBatch1({
          workTicketId,
          row,
          field,
          restAttachmentsField,
          onGetSuggestion,
          ...rest
        });
      }
    }
  };

  /**
  /* Render cell batch 2
   */
  const renderCellBatch2 = () => {
    // For internal
    switch (field?.refName) {
      case SYSTEM_FIELD_LATEST_RESULT_STATUS: {
        return (
          <LatestResultStatusField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            isReadOnly={!allowQuickExecute ? true : rest?.isReadOnly}
          />
        );
      }

      case FIELD_STEP_RESULT: {
        return (
          <StepResultStatusField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            isReadOnly={!allowQuickExecute ? true : rest?.isReadOnly}
          />
        );
      }

      case checkIsAssignToFieldRefName(field?.refName): {
        return (
          <AssignToField
            {...rest}
            workTicketId={workTicketId}
            row={row}
            field={field}
            isReadOnly={!hasAllowAssignToPermission ? true : rest?.isReadOnly}
            onGetUserListByRole={onGetUserListByRole}
          />
        );
      }

      case checkIsTagFieldRefName(field?.refName): {
        return <TagField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      case checkIsScriptPathFieldRefName(field?.refName): {
        return <ScriptPathField {...rest} workTicketId={workTicketId} row={row} field={field} />;
      }

      default: {
        return renderCellBatch3(); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  /**
  /* Render cell batch 1
   */
  const renderCellBatch1 = () => {
    // For external system: jira
    if (checkBelongToJiraFields({ row, field })) {
      return <JiraCell {...rest} workTicketId={workTicketId} row={row} field={field} />;
    }

    // For internal
    switch (field?.refName) {
      case checkIsKeyFieldRefName(field?.refName): {
        return (
          <KeyField
            workTicketId={workTicketId}
            fromModule={fromModule}
            row={row}
            field={field}
            disableEditingCells={rest?.disableEditingCells}
            restKeyField={restKeyField}
            onReload={onReload}
          />
        );
      }

      case field?.refName === SYSTEM_FIELD_VERSION && workTicketId === WORK_ITEM_TESTCASE_ID ? field?.refName : false: {
        return (
          <TestcaseVersionField {...rest} workTicketId={workTicketId} row={row} field={{ ...field, mandatory: true }} />
        );
      }

      case field?.refName === SYSTEM_FIELD_STATUS && workTicketId === WORK_ITEM_TEST_RUN_ID ? field?.refName : false: {
        return (
          <TestRunStatusField
            workTicketId={workTicketId}
            row={row}
            field={field}
            formItemName={rest?.formItemName}
            disableEditingCells={rest?.disableEditingCells}
          />
        );
      }

      default: {
        return renderCellBatch2(); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  return renderCellBatch1();
};

export default Cell;
