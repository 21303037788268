import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  tenantRoleList: null, // All of roles in current tenant
  loadingTenantRoleList: false,

  /**
   * Action: Set tenant role list
   */
  setTenantRoleList: action((state, payload) => {
    if (!(state?.tenantRoleList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.tenantRoleList = [];
    }

    state.tenantRoleList = payload;
  }),

  /**
   * Action: Set loading tenant role list
   */
  setLoadingTenantRoleList: action((state, payload) => {
    if (!(state?.loadingTenantRoleList !== undefined)) {
      return;
    }

    state.loadingTenantRoleList = payload;
  }),

  /**
   * Action: Call api to get tenant role list
   */
  getTenantRoleList: thunk(async (action, payload, helpers) => {
    try {
      action.setLoadingTenantRoleList(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.PERMISSION}/${globalTenant?.tenantKey}${ENDPOINTS.TENANT_ROLES}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTenantRoleList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingTenantRoleList(false);
    }
  })
};
