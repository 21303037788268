import { useStoreActions, useStoreState } from 'easy-peasy';
import objectPath from 'object-path';

import {
  SYSTEM_FIELD_KEY,
  FIELD_ATTACHMENTS,
  SYSTEM_FIELD_ASSIGN_TO,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_CREATED_BY,
  SYSTEM_FIELD_ATTACHMENTS
} from '../../../../constants';
import { findItemAndParentsOnTree } from '../../../../common/utils';
import { usePermission } from '../../../../common/hooks';

const useTestcase = () => {
  // For hooks
  const { isManagerRole } = usePermission();

  // For global store
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);

  // For repository tree store
  const tree = useStoreState(state => state.repositoryTree.tree);

  /**
   * Get query select from field config
   */
  const getSelectQueryFromFieldConfig = fieldConfig => {
    if (!fieldConfig) {
      return;
    }

    const visibleTestSteps = fieldConfig.visibleTestSteps;

    let newDefaultShow = fieldConfig.defaultShow;
    newDefaultShow =
      Array.isArray(newDefaultShow) && newDefaultShow.length
        ? [...newDefaultShow].filter(
            f => f?.refName && f?.refName !== 'leftActionColumn' && f?.refName !== 'order' && f?.refName !== 'action'
          )
        : [];

    if (visibleTestSteps && !newDefaultShow.some(item => item?.refName === SYSTEM_FIELD_TEST_STEPS)) {
      newDefaultShow = [...newDefaultShow, { refName: SYSTEM_FIELD_TEST_STEPS }];
    }

    if (!newDefaultShow.some(item => item?.refName === SYSTEM_FIELD_KEY)) {
      newDefaultShow = [{ refName: SYSTEM_FIELD_KEY }, ...newDefaultShow];
    }

    if (!newDefaultShow.some(item => item?.refName === SYSTEM_FIELD_ATTACHMENTS)) {
      newDefaultShow = [...newDefaultShow, { refName: SYSTEM_FIELD_ATTACHMENTS }];
    }

    const select = newDefaultShow.map(f => f?.refName).join(' ');

    return select;
  };

  /**
   * Get query params by folder field config
   */
  const getQueryParamsByFolderFieldConfig = ({ treeKey, folderConfig }) => {
    const newQueryParams = {
      treeKey,
      page: 1,
      limit: folderConfig?.defaultPageSize,
      order: `${folderConfig?.defaultOrder?.refName}|${folderConfig?.defaultOrder?.order}`,
      select: getSelectQueryFromFieldConfig(folderConfig),
      filter: undefined
    };

    return newQueryParams;
  };

  /**
   * Check is mine ticket
   */
  const checkIsMineTicket = ({ row, globalUserInfo }) => {
    const isMine =
      objectPath.get(row, SYSTEM_FIELD_CREATED_BY)?.username === globalUserInfo?.username ||
      objectPath.get(row, SYSTEM_FIELD_ASSIGN_TO)?.username === globalUserInfo?.username;

    return isMine;
  };

  /**
   * Check row permission
   */
  const checkRowPermission = ({ row, globalUserInfo, hasRepositoryTestcasePermission }) => {
    let hasPermission = false;

    if (isManagerRole) {
      hasPermission = true;
    } else {
      const isMine = checkIsMineTicket({ row, globalUserInfo });
      hasPermission = isMine;
    }

    return hasRepositoryTestcasePermission && hasPermission;
  };

  /**
   * Get all full files info
   */
  const getAllFilesInfoForTestcase = async testcase => {
    if (!testcase) {
      return;
    }

    const newTestcaseAttachments = objectPath.get(testcase, SYSTEM_FIELD_ATTACHMENTS);

    let testSteps = objectPath.get(testcase, SYSTEM_FIELD_TEST_STEPS);
    testSteps = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];

    const newTestStepAttachments = testSteps.reduce((accumulator, current) => {
      let attachments = current?.[FIELD_ATTACHMENTS];
      attachments = Array.isArray(attachments) && attachments.length ? [...attachments] : [];
      return [...accumulator, ...attachments];
    }, []);

    const newAllAttachmentList = [...newTestcaseAttachments, ...newTestStepAttachments];

    await getFullFilesInfo(newAllAttachmentList);
  };

  /**
   * Check is existed folder name
   */
  const checkIsExistedFolderName = (value, folder) => {
    if (!value) {
      return false;
    }

    const parentInfo = findItemAndParentsOnTree(tree, folder?.parentKey);

    let otherChildren = parentInfo?.item?.children;
    otherChildren =
      Array.isArray(otherChildren) && otherChildren.length
        ? [...otherChildren].filter(f => f?.key !== folder?.key)
        : [];

    if (!(Array.isArray(otherChildren) && otherChildren.length)) {
      return false;
    }

    if (
      otherChildren.some(item => {
        return item?.name?.toString().trim().toLowerCase() === value?.toString().trim().toLowerCase();
      })
    ) {
      return true;
    }

    return false;
  };

  return {
    getQueryParamsByFolderFieldConfig,
    getSelectQueryFromFieldConfig,
    checkIsMineTicket,
    checkRowPermission,
    getAllFilesInfoForTestcase,
    checkIsExistedFolderName
  };
};

export default useTestcase;
