import { Form, Button, Input, Spin, Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { SaveOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import TurndownService from 'turndown';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';

import {
  JIRA_DATA_TYPE_USER,
  JIRA_PLATFORM_ID,
  JIRA_FIELD_TYPE_DEFAULT,
  JIRA_TYPE_BODY_ARRAY,
  JIRA_TYPE_BODY_ARRAY_ID,
  JIRA_TYPE_BODY_HTML,
  JIRA_TYPE_BODY_OBJECT_ID,
  JIRA_TYPE_BODY_OBJECT_KEY,
  JIRA_TYPE_BODY_OPTION,
  INTEGRATION_FROM_TEST_PLAN_FOLDER,
  INTEGRATION_FROM_REPOSITORY_FOLDER,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_TEST_STEP_RESULT,
  JIRA_TYPE_BODY_ISSUELINKS,
  JIRA_DATA_TYPE_ISSUELINKS,
  JIRA_DATA_EPIC_LINK_ID,
  JIRA_DATA_PRODUCT_ID,
  JIRA_DATA_TYPE_SPRINT_ID,
  JIRA_TYPE_BODY_RAW_ARRAY,
  JIRA_DATA_TYPE_LABELS,
  JIRA_TYPE_BODY_PRIORITY,
  JIRA_DATA_TYPE_DATE_TIME,
  FULL_DATE_FORMAT
} from '../../constants';
import { scrollToFirstClassName } from '../../common/utils';
import { useFile } from '../../common/hooks';
import { BasicEditor } from '../../components';
import { JiraCustomField } from '../../components/custom-field/jira-custom-field';

const FormRelatedToJira = ({ fromModule, workItem, relation, onPrevious, customFields, onSubmit }) => {
  const [t] = useTranslation('akaat');
  const [form] = Form.useForm();

  // For upload
  const { getUploadPath } = useFile();

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const loadingObjectiveItem = useStoreState(state => state.global.loadingObjectiveItem);
  const loadingRelationItem = useStoreState(state => state.global.loadingRelationItem);
  const createObjective = useStoreActions(action => action.global.createObjective);
  const getFieldListCreateMeta = useStoreActions(action => action.jiraIntegration.getFieldListCreateMeta);

  // For jira store
  const createIssue = useStoreActions(action => action.jiraIntegration.createIssue);
  const setSuggestionList = useStoreActions(action => action.jiraIntegration.setSuggestionList);
  const loadingItemIssue = useStoreState(state => state.jiraIntegration.loadingItem);
  const integrationItem = useStoreState(state => state.integration.integrationItem);
  const suggestionList = useStoreState(state => state.jiraIntegration.suggestionList);

  // Component state
  const [description, setDescription] = useState('');
  const [visibleEditor, setVisibleEditor] = useState(false);

  /**
   * Set visible editor
   */
  useEffect(() => {
    setTimeout(() => setVisibleEditor(true), 0);
  }, []);

  useEffect(() => {
    if (!relation.jiraIssueType) {
      return;
    }

    getFieldListCreateMeta({
      issuetype: relation.jiraIssueType?.id
    });
  }, [relation]);

  useEffect(() => {
    if (!relation.jiraIssueType) {
      return;
    }

    let rawHtml = [];

    if (workItem) {
      rawHtml =
        (workItem?.[SYSTEM_FIELD_TEST_STEPS] || workItem?.[SYSTEM_FIELD_TEST_STEP_RESULT])?.map((value, index) => {
          const step = `<p>${index + 1}. ${value.step || ''} </p>`;
          const testData = `<li>Test Data: ${value.testData || ''} </li>`;
          const expectedResult = `<li>Expected Results: ${value.expectedResult || ''} </li>`;
          const status = `<li>Status: ${value.status?.name || ''} </li>`;
          return `${step}<ul>${testData + expectedResult + status}</ul>`;
        }) || [];

      setDescription(rawHtml.join(''));
    }

    form.setFieldsValue({
      summary: relation.item,
      description: rawHtml.join('')
    });
  }, [workItem, form, relation]);

  /**
   * Check no action when submit
   */
  const checkNoActionWhenSubmit = ({ values, globalProject }) => {
    return !values || !globalProject?.projectKey || !globalTenant?.tenantKey;
  };

  /**
   * Ignore special field
   */
  const ignoreSpecialField = ({ customFields, setJiraFieldTypeDefault }) => {
    customFields.forEach(field => {
      // Ignore special field
      if (
        field?.fieldId !== JIRA_DATA_TYPE_ISSUELINKS &&
        field?.fieldId !== JIRA_DATA_TYPE_SPRINT_ID &&
        field?.fieldId !== JIRA_DATA_EPIC_LINK_ID &&
        field?.fieldId !== JIRA_DATA_PRODUCT_ID
      ) {
        if (
          (field?.schema.customId && field?.schema.type === JIRA_TYPE_BODY_ARRAY) ||
          field?.schema.system === JIRA_DATA_TYPE_LABELS
        ) {
          setJiraFieldTypeDefault({ key: field?.fieldId, value: JIRA_TYPE_BODY_RAW_ARRAY });
        } else {
          setJiraFieldTypeDefault({ key: field?.fieldId, value: field?.schema.type });
        }
      }
    });
  };

  /**
   * Get body issuelinks util
   */
  const getBodyIssuelinksUtil = ({ suggestionList, values }) => {
    const issue = suggestionList?.issuelinks?.sections?.[0]?.issues.find(
      issue => issue.id === values?.issuelinks?.issue || issue.key === values?.issuelinks?.issue
    );
    const linkedType = suggestionList?.issueLinkTypes?.find(type => type.id === values?.issuelinks?.linkedType);

    if (!issue || !linkedType) {
      return;
    }

    let update = {};

    if (linkedType.type === 'outward') {
      update = {
        issuelinks: [
          {
            add: {
              outwardIssue: {
                key: issue.key
              },
              type: {
                name: linkedType.typeName,
                inward: linkedType.inward,
                outward: linkedType.name
              }
            }
          }
        ]
      };
    } else {
      update = {
        issuelinks: [
          {
            add: {
              inwardIssue: {
                key: issue.key
              },
              type: {
                name: linkedType.typeName,
                outward: linkedType.outward,
                inward: linkedType.name
              }
            }
          }
        ]
      };
    }

    return update;
  };

  /**
   * Convert to submit data batch 1
   */
  const convertToSubmitDataBatch1 = (values, key) => {
    const formData = {};
    let update = {};

    switch (JIRA_FIELD_TYPE_DEFAULT[key]) {
      case JIRA_TYPE_BODY_ARRAY_ID: {
        formData[key] = values[key].map(item => ({ id: item.value }));
        break;
      }

      case JIRA_DATA_TYPE_DATE_TIME: {
        formData[key] = moment(values[key]).format(FULL_DATE_FORMAT);
        break;
      }

      case JIRA_DATA_TYPE_DATE_TIME: {
        formData[key] = moment(values[key]).format('YYYY-MM-DDThh:mm:ss.908+1100');
        break;
      }

      case JIRA_TYPE_BODY_HTML: {
        const turndownService = new TurndownService();
        const markdown = values[key] ? turndownService.turndown(values[key]) : workItem?.name;
        formData[key] = markdown;
        break;
      }

      case JIRA_TYPE_BODY_ISSUELINKS: {
        const newVal = getBodyIssuelinksUtil({ suggestionList, values });

        if (newVal) {
          update = newVal;
        }

        break;
      }

      case JIRA_TYPE_BODY_ARRAY: {
        formData[key] = values[key]?.map(value => ({
          id: value
        }));
        break;
      }

      default: {
        formData[key] = values[key];
        break;
      }
    }

    return {
      formData,
      update
    };
  };

  /**
   * Convert to submit data
   */
  const convertToSubmitData = values => {
    const formData = {};

    Object.keys(values).forEach(key => {
      switch (JIRA_FIELD_TYPE_DEFAULT[key]) {
        case JIRA_DATA_TYPE_USER: {
          formData[key] = {
            id: values[key],
            name: values[key]
          };
          break;
        }

        case JIRA_TYPE_BODY_OPTION:
        case JIRA_TYPE_BODY_PRIORITY:
        case JIRA_TYPE_BODY_OBJECT_ID: {
          if (values[key]) {
            formData[key] = {
              id: values[key]
            };
          }
          break;
        }

        case JIRA_TYPE_BODY_OBJECT_KEY: {
          formData[key] = {
            key: values[key]
          };
          break;
        }

        default: {
          const result = convertToSubmitDataBatch1(values, key);
          formData[key] = result?.formData;
          update = result?.update;
          break;
        }
      }
    });

    return {
      formData,
      update
    };
  };

  /**
   * Hanlde submit
   */
  const hanldeSubmit = async values => {
    if (checkNoActionWhenSubmit({ values, globalProject })) {
      return;
    }

    let formData = {};
    let update = {};

    ignoreSpecialField({
      customFields,
      setJiraFieldTypeDefault: ({ key, value }) => (JIRA_FIELD_TYPE_DEFAULT[key] = value)
    });

    const result = convertToSubmitData(values);

    formData = { ...formData, ...result?.formData };
    update = result?.update;

    if (relation?.jiraIssueType?.id) {
      formData.issuetype = {
        id: relation.jiraIssueType.id
      };
    }

    const remoteLink = {
      title: `${window.location.origin}${window.location.pathname}`,
      link: window.location.href
    };

    const res = await createIssue({
      projectKey: globalProject.projectKey,
      tenantKey: globalTenant.tenantKey,
      formData,
      update,
      remoteLink
    });

    handleSumbitAfterCreateIssue({ res, fromModule, workItem, relation, onSubmitCallBack: onSubmit });
  };

  /**
   * Handle sumbit after create issue
   */
  const handleSumbitAfterCreateIssue = async ({ res, fromModule, workItem, relation, onSubmitCallBack }) => {
    if (
      res &&
      (fromModule === INTEGRATION_FROM_REPOSITORY_FOLDER || fromModule === INTEGRATION_FROM_TEST_PLAN_FOLDER)
    ) {
      const objectiveObj = {
        key: res.key,
        integrationSystem: JIRA_PLATFORM_ID,
        [JIRA_PLATFORM_ID]: {
          id: res.id,
          name: res.key,
          link: `${res.self?.split('/rest/api/2')[0]}/browse/${res.key}`
        }
      };

      onSubmitCallBack(objectiveObj);
    } else if (res) {
      const defectLink = {
        id: res.id,
        name: res.key,
        type: JIRA_PLATFORM_ID,
        link: `${relation?.jiraIssueType?.self?.split('/rest/api/2')[0]}/browse/${res.key}`
      };

      const objectiveObj = await createObjective({
        name: res.key,
        workTicketType: workItem?._id,
        external: true,
        externalData: defectLink
      });

      if (objectiveObj?._id) {
        onSubmitCallBack(objectiveObj);
      }
    } else {
    }
  };

  useEffect(() => {
    setSuggestionList();
  }, []);

  return (
    <>
      <Spin
        indicator={<Loading3QuartersOutlined spin />}
        spinning={loadingItemIssue || loadingObjectiveItem || loadingRelationItem}
      >
        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4 ád">
            <Form id="relatedToJiraForm" form={form} layout="vertical" onFinish={hanldeSubmit}>
              <Form.Item
                label={t('common.summary')}
                name="summary"
                rules={[{ required: true, whitespace: true, message: t('message.required') }]}
              >
                <Input placeholder={t('defect.enterDefectName')} />
              </Form.Item>

              {visibleEditor && (
                <Form.Item
                  label={t('workItem.description')}
                  name="description"
                  rules={[{ required: true, whitespace: true, message: t('message.required') }]}
                >
                  <BasicEditor
                    rawHtml={description}
                    restEditor={{ placeholder: t('workItem.enterDescription') }}
                    isProtectedFile={false}
                    uploadPath={getUploadPath(relation?.jiraIssueType?.id)}
                    onEditorRawHtmlChange={val => form.setFieldsValue({ description: val })}
                  />
                </Form.Item>
              )}

              {Array.isArray(customFields) && customFields.length > 0
                ? customFields.map((field, index) => {
                    return <JiraCustomField config={integrationItem?.jira} key={index} form={form} field={field} />;
                  })
                : ''}
            </Form>
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button onClick={onPrevious}>{t('common.previous')}</Button>

            <Button
              className="ml-2"
              form="relatedToJiraForm"
              htmlType="submit"
              type="primary"
              icon={<SaveOutlined />}
              onClick={() => scrollToFirstClassName('ant-form-item-has-error')}
            >
              {t('common.create')}
            </Button>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default FormRelatedToJira;
