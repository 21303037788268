import React from 'react';
import { Resizable } from 'react-resizable';

export const ResizableColumn = ({ width, onResize, onResizeStart, onResizeStop, ...rest }) => {
  if (!width) {
    return <th {...rest} />;
  }

  /**
   * Toogle width for sticky table
   */
  const toogleWidthForStickyTable = needToAdd => {
    const tables = document.querySelectorAll('.ant-table-sticky-holder table');

    if (tables.length) {
      for (let i = 0; i < tables.length; i++) {
        const item = tables[i];

        if (item && needToAdd) {
          const currentWidth = item?.getBoundingClientRect().width;
          item.style.width = `${currentWidth}px`;
        } else if (item && !needToAdd) {
          item.style.width = null;
        } else {
        }
      }
    }
  };

  return (
    <Resizable
      width={width}
      height={0}
      handle={<span className="react-resizable-handle" onClick={e => e.stopPropagation()} />}
      axis="x"
      minConstraints={[50, 50]}
      draggableOpts={{
        enableUserSelectHack: false
      }}
      onResize={onResize}
      onResizeStart={(e, data) => {
        toogleWidthForStickyTable(true);
        typeof onResizeStart === 'function' && onResizeStart(e, data);
      }}
      onResizeStop={(e, data) => {
        toogleWidthForStickyTable(false);
        typeof onResizeStop === 'function' && onResizeStop(e, data);
      }}
    >
      <th {...rest} />
    </Resizable>
  );
};
