import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Tooltip, Upload, notification } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';

import { getFullFileInfoFromInputFile } from '../../common/utils';
import { useFile } from '../../common/hooks';
import { SafeInnerHtml } from '../safe-inner-html';
import { BasicAttachmentList } from '../basic-attachment-list';

import './style.scss';

export const BasicUploadMultipleFiles = ({
  isProtectedFile = true,
  onlyPreviewFileOnClient, // For don't upload file to server, only preview base64, emit FileList
  uploadPath,
  attachments,
  acceptFileTypes, // Extension of files, string[]
  maxFileSize,
  maxCount,
  hasGetFullFilesInfo,
  isReadOnly = false,
  showAttachmentList = true,
  directlyDeleteAttachment = true,
  uploadIcon,
  noDataText,
  onChangeAttachments,
  onChangeDeteledAttachmentIds,
  className = '',
  restDragger,
  restAttachmentList,
  editingItem = {},
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For upload
  const { checkValidType, checkValidSize, checkValidMaxCount, uploadFile } = useFile();

  // For global store
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);

  // Component state
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [visiblePreviewFileModal, setVisiblePreviewFileModal] = useState(false);

  /**
   * Check max count
   */
  const validMaxCount = useMemo(() => {
    if (!/^[0-9]*$/.test(maxCount) || maxCount <= 0 || !(Array.isArray(attachments) && attachments.length)) {
      return true;
    }

    const valid = attachments.length <= maxCount - 1;

    return valid;
  }, [attachments, maxCount]);

  /**
   * No action when before upload
   */
  const noActionWhenBeforeUpload = ({ isReadOnly, validMaxCount, file, fileList }) => {
    const condition1 = isReadOnly || !validMaxCount || !file;
    const condition2 = !(
      Array.isArray(fileList) &&
      fileList.length &&
      file?.uid === fileList[fileList.length - 1]?.uid
    ); // For only run one

    return condition1 || condition2;
  };

  /**
   * Before upload attachment
   */
  const beforeUpload = (file, fileList) => {
    if (noActionWhenBeforeUpload({ isReadOnly, validMaxCount, file, fileList })) {
      return false;
    }

    if (!uploadPath) {
      notification.error({ message: t('common.error'), description: 'There are no uploadPath' });
      return;
    }

    if (isProtectedFile && (!globalTenant?.tenantKey || !globalProject?.projectKey)) {
      notification.error({ message: t('common.error'), description: 'There are no tenant key or no project key' });
      return;
    }

    const validFileList = fileList.filter((item, index) => {
      const isValidType = checkValidType({ file: item, acceptFileTypes });
      const isValidSize = checkValidSize({ file: item, maxFileSize });
      const isValidMaxCount = checkValidMaxCount({ file: item, index, maxCount, currentAttachments: attachments });

      return isValidType && isValidSize && isValidMaxCount;
    });

    if (Array.isArray(validFileList) && validFileList.length) {
      handleUpload(validFileList);
    }

    return false;
  };

  /**
   * Handle upload
   */
  const handleUpload = async fileList => {
    if (isReadOnly || !(Array.isArray(fileList) && fileList.length)) {
      return;
    }

    const newAttachments = [];

    try {
      setLoadingUpload(true);

      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        let res = null;

        if (onlyPreviewFileOnClient) {
          res = await getFullFileInfoFromInputFile({ file });
        } else {
          res = await uploadFile({ file, uploadPath, isProtectedFile, editingItem });
          await getFullFilesInfo([res]);
        }

        if (res) {
          newAttachments.push(res);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpload(false);
    }

    if (typeof onChangeAttachments === 'function') {
      const oldAttachments = Array.isArray(attachments) && attachments.length ? [...attachments] : [];
      onChangeAttachments([...oldAttachments, ...newAttachments]);
    }
  };

  /**
   * Render basic attachment list
   */
  const renderBasicAttachmentList = () => {
    if (!showAttachmentList) {
      return;
    }

    return (
      <div className={`box-attachment-list-wrapper ${isReadOnly ? '' : 'mt-3'}`}>
        <BasicAttachmentList
          onlyPreviewFileOnClient={onlyPreviewFileOnClient}
          attachments={attachments}
          noDataText={noDataText}
          directlyDeleteAttachment={directlyDeleteAttachment}
          isReadOnly={isReadOnly}
          hasGetFullFilesInfo={hasGetFullFilesInfo}
          onChangeVisiblePreviewFileModal={setVisiblePreviewFileModal}
          onChangeAttachments={val => {
            typeof onChangeAttachments === 'function' && onChangeAttachments(val);
          }}
          onChangeDeteledAttachmentIds={ids => {
            typeof onChangeDeteledAttachmentIds === 'function' && onChangeDeteledAttachmentIds(ids);
          }}
          {...restAttachmentList}
        />
      </div>
    );
  };

  /**
   * Disabled upload field
   */
  const disabledUploadField = useMemo(() => {
    return isReadOnly || loadingUpload || !validMaxCount || visiblePreviewFileModal;
  }, [isReadOnly || loadingUpload || !validMaxCount || visiblePreviewFileModal]);

  return (
    <>
      <div className={`c-basic-upload-multiple-files ${className}`} {...rest}>
        {!isReadOnly ? (
          <Upload.Dragger
            accept={Array.isArray(acceptFileTypes) && acceptFileTypes.length ? acceptFileTypes.join(',') : ''}
            multiple={true}
            showUploadList={false}
            disabled={disabledUploadField}
            className="w-100"
            beforeUpload={beforeUpload}
            {...restDragger}
          >
            <Tooltip
              title={!validMaxCount ? t('message.maximumCountAttachments', { max: maxCount }) : null}
              placement="top"
              destroyTooltipOnHide={true}
            >
              <div className="ant-upload-drag-icon text-center mb-0" style={{ opacity: !validMaxCount ? 0.5 : null }}>
                {loadingUpload ? (
                  <LoadingOutlined className="ic-loading vertical-align-middle" style={{ fontSize: '1.3rem' }} />
                ) : (
                  uploadIcon || <UploadOutlined className="text-primary" />
                )}

                <SafeInnerHtml
                  html={t('common.dropFileOrBrowser')}
                  className="d-inline txt-drop-file-or-browser"
                  style={{ opacity: loadingUpload ? 0.3 : null }}
                />
              </div>
            </Tooltip>

            {renderBasicAttachmentList()}
          </Upload.Dragger>
        ) : (
          renderBasicAttachmentList()
        )}
      </div>
    </>
  );
};
