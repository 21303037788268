import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { FileFilled, CloseOutlined, DownloadOutlined } from '@ant-design/icons';

import { Audio, ExternalLink, Image, Video } from '../../assets/svg-icons';

const PreviewHeader = ({ fileInfo, onlyPreviewFileOnClient, showCloseButton, onDownload, onClose }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For component state
  const [currentFileInfo, setCurrentFileInfo] = useState(null);

  /**
   * Set current file info
   */
  useEffect(() => {
    if (!fileInfo?.id) {
      setCurrentFileInfo(null);
      return;
    }

    const type = fileInfo.type && typeof fileInfo.type === 'string' ? fileInfo.type : '';

    setCurrentFileInfo({ ...fileInfo, type });
  }, [fileInfo]);

  /**
   * Check is show download
   */
  const checkIsShowDownload = ({ currentFileInfo, onlyPreviewFileOnClient }) => {
    const condition1 = !currentFileInfo?.fileNotFound && currentFileInfo?.id && !currentFileInfo?.isPublicFile;
    const condition2 =
      currentFileInfo?.type.includes('image/') ||
      currentFileInfo?.type.includes('video/') ||
      currentFileInfo?.type.includes('audio/');

    const newIsShowDownload = !onlyPreviewFileOnClient && condition1 && condition2;
    return newIsShowDownload;
  };

  /**
   * Render action
   */
  const renderAction = () => {
    const isShowDownload = checkIsShowDownload({ currentFileInfo, onlyPreviewFileOnClient });
    const isShowOpenInNewTab =
      !onlyPreviewFileOnClient && !currentFileInfo?.fileNotFound && !window.location.pathname.includes('/files/');
    const newTabLink = currentFileInfo?.isPublicFile ? currentFileInfo?.src : currentFileInfo?.previewUrl;

    return (
      <div className="box-action">
        {isShowDownload && (
          <Tooltip
            title={t('common.download')}
            placement="bottomRight"
            destroyTooltipOnHide={true}
            color="#fff"
            overlayInnerStyle={{ color: '#000' }}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button shape="circle" icon={<DownloadOutlined />} size="large" onClick={onDownload} />
          </Tooltip>
        )}

        {isShowOpenInNewTab && (
          <Tooltip
            title={t('common.openInNewTab')}
            placement="bottomRight"
            destroyTooltipOnHide={true}
            color="#fff"
            overlayInnerStyle={{ color: '#000' }}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              href={newTabLink}
              target="_blank"
              shape="circle"
              icon={<ExternalLink />}
              size="large"
              onClick={() => window.open(newTabLink, '_blank')}
            />
          </Tooltip>
        )}

        {showCloseButton && (
          <Tooltip
            title={t('common.close')}
            placement="bottom"
            destroyTooltipOnHide={true}
            color="#fff"
            overlayInnerStyle={{ color: '#000' }}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button shape="circle" icon={<CloseOutlined />} size="large" onClick={onClose} />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div className="box-header">
      <div className="box-name">
        <span title={currentFileInfo?.fileName || ''}>
          <span className="text-primary mr-2">
            {currentFileInfo?.type.includes('image/') ? (
              <Image />
            ) : currentFileInfo?.type.includes('video/') ? (
              <Video />
            ) : currentFileInfo?.type.includes('audio/') ? (
              <Audio />
            ) : (
              <FileFilled />
            )}
          </span>

          {currentFileInfo?.fileName || ''}
        </span>
      </div>

      {renderAction()}
    </div>
  );
};

export default PreviewHeader;
