import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLocation } from 'react-router-dom';

import DefaultLayout from './default';
import BlankLayout from './blank';
import Error404Layout from './error-404-layout';
import ExpiredLicenseLayout from './expired-license';
import HomeLayout from './home';

import env from '../env';
import {
  TENANT_KEY_TRIAL,
  PROJECT_ENGINE_MENU,
  PROJECT_MANAGER_MENU,
  PRODUCT_KEY_MANAGER,
  PRODUCT_KEY_TESTENGINE,
  PRODUCT_KEY_ON_URL_ENGINE,
  PRODUCT_KEY_ON_URL_MANAGER,
  ROLE_APP_ACCESS_MANAGER,
  ROLE_APP_ACCESS_TESTENGINE
} from '../constants';
import { Http } from '../core';
import { checkIsNotEmptyArray, getParentMenuByPathname } from '../common/utils';
import { useKeycloak } from '../common/hooks';
import { RouterLoading } from '../components';

import './style.scss';

const Layout = ({ children }) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX]; // IMPORTANT ===> FOR TENANT KEY
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX]; // IMPORTANT ===> FOR PROJECT KEY
  const thirdPath = location.pathname.split('/')[env.REACT_APP_PREFIX_PATH ? 4 : 3]; // Production key, settings, files,...

  // For keycloak
  const { keycloak, initialized } = useKeycloak();

  // For global user info store
  const getGlobalUserInfo = useStoreActions(action => action.global.getGlobalUserInfo);
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);

  // For global tenant store
  const myTenantList = useStoreState(state => state.global.myTenantList);

  // For global project store
  const myProjectList = useStoreState(state => state.global.myProjectList);

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);

  // For global project store
  const globalProject = useStoreState(state => state.global.globalProject);

  // For global production key store
  const setGlobalProductionKey = useStoreActions(action => action.global.setGlobalProductionKey);

  // For global process list
  const getProcessList = useStoreActions(action => action.global.getProcessList);

  // Component state
  const [isGetGlobalUserInfoWhenMounted, setIsGetGlobalUserInfoWhenMounted] = useState(false);
  const [isGetProcessListWhenMounted, setIsGetProcessListWhenMounted] = useState(false);

  /**
   * Set authorization bearer to header http service
   */
  useEffect(() => {
    if (!initialized || !keycloak?.token) {
      return;
    }

    Http.interceptors.request.use(
      config => {
        if (config.headers) {
          config.headers.Authorization = `Bearer ${keycloak?.token}`;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }, [keycloak, initialized]);

  /**
   * Get user info
   */
  useEffect(() => {
    if (
      !initialized ||
      !keycloak?.token ||
      thirdPath === 'files' // Don't check when preview file
    ) {
      return;
    }

    if (!isGetGlobalUserInfoWhenMounted) {
      const tenantKey = tenantPath || undefined;
      const projectKey = projectPath && projectPath !== '_settings' ? projectPath : undefined;

      getGlobalUserInfo({ token: keycloak?.token, tenantKey, projectKey });
      setIsGetGlobalUserInfoWhenMounted(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak, initialized, tenantPath, projectPath, thirdPath, isGetGlobalUserInfoWhenMounted, getGlobalUserInfo]);

  /**
   * Set global production key
   */
  useEffect(() => {
    if (!thirdPath) {
      return;
    }

    if (thirdPath === PRODUCT_KEY_ON_URL_MANAGER) {
      setGlobalProductionKey(PRODUCT_KEY_ON_URL_MANAGER);
    } else if (thirdPath === PRODUCT_KEY_ON_URL_ENGINE) {
      setGlobalProductionKey(PRODUCT_KEY_ON_URL_ENGINE);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPath, setGlobalProductionKey]);

  /**
   * Get process list
   */
  useEffect(() => {
    const hasRoleAppAccessManager =
      checkIsNotEmptyArray(globalUserInfo?.permissions) &&
      globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_MANAGER);
    const hasTenantKeyAndProjectKey = globalTenant?.tenantKey && globalProject?.projectKey;

    if (!hasRoleAppAccessManager || !hasTenantKeyAndProjectKey || thirdPath !== PRODUCT_KEY_ON_URL_MANAGER) {
      return;
    }

    if (!isGetProcessListWhenMounted) {
      getProcessList({ tenantKey: globalTenant.tenantKey, projectKey: globalProject.projectKey });
      setIsGetProcessListWhenMounted(true);
    }
  }, [thirdPath, globalUserInfo, globalTenant, globalProject, getProcessList, isGetProcessListWhenMounted]);

  /**
   * Check is blank layout
   */
  const checkIsBlankLayout = ({ location, thirdPath }) => {
    return (
      location.pathname === `/${env.REACT_APP_PREFIX_PATH}` ||
      ['403', '404', 'maintenance', 'files'].includes(thirdPath) ||
      ['enigne', 'job-runner', 'task-log'].every(i => location.pathname.split('/').includes(i))
    );
  };

  /**
   * Check is logged in
   */
  const checkIsLoggedIn = () => keycloak && !keycloak?.authenticated;

  /**
   * Check is expired license layout for manager product
   */
  const checkIsExpiredLicenseLayoutForManagerProduct = ({ globalTenant, location, globalUserInfo }) => {
    const condition1 =
      globalTenant?.tenantKey !== TENANT_KEY_TRIAL &&
      getParentMenuByPathname(location.pathname, PROJECT_MANAGER_MENU) &&
      Array.isArray(globalUserInfo?.permissions) &&
      globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_MANAGER);

    const condition2 = !(
      checkIsNotEmptyArray(globalTenant?.activeProduct) &&
      globalTenant?.activeProduct.some(
        item => item?.productKey === PRODUCT_KEY_MANAGER && item?.purchased && !item?.expiredStatus
      )
    );

    return condition1 && condition2;
  };

  /**
   * Check is expired license layout for engine product
   */
  const checkIsExpiredLicenseLayoutForEngineProduct = ({ globalTenant, location, globalUserInfo }) => {
    const condition1 =
      globalTenant?.tenantKey !== TENANT_KEY_TRIAL &&
      getParentMenuByPathname(location.pathname, PROJECT_ENGINE_MENU) &&
      Array.isArray(globalUserInfo?.permissions) &&
      globalUserInfo?.permissions.includes(ROLE_APP_ACCESS_TESTENGINE);

    const condition2 = !(
      checkIsNotEmptyArray(globalTenant?.activeProduct) &&
      globalTenant?.activeProduct.some(item => item?.productKey === PRODUCT_KEY_TESTENGINE && !item?.expiredStatus)
    );

    return condition1 && condition2;
  };

  /**
   * Check is error 404 layout
   */
  const checkIsError404Layout = ({ tenantPath, myTenantList, projectPath, myProjectList, thirdPath }) => {
    const condition1 =
      tenantPath &&
      tenantPath !== 'public' &&
      !(checkIsNotEmptyArray(myTenantList) && myTenantList.some(item => item?.tenantKey === tenantPath));

    const condition2 =
      projectPath &&
      projectPath !== '_settings' &&
      !(checkIsNotEmptyArray(myProjectList) && myProjectList.some(item => item?.projectKey === projectPath));

    const condition3 = projectPath === '_settings' && !thirdPath;

    return condition1 || condition2 || condition3;
  };

  /**
   * Check is home layout
   */
  const checkIsHomeLayout = ({ tenantPath, projectPath }) => tenantPath && !projectPath;

  /**
   * Get class name for default layout
   */
  const getClassNameForDefaultLayout = ({ tenantPath, projectPath, thirdPath }) => {
    const condition1 = tenantPath && projectPath === '_settings';
    const condition2 = tenantPath && projectPath && thirdPath === '_settings';

    return `${condition1 || condition2 ? 'auto-scroll-layout-style-1' : ''}`;
  };

  /**
   ****************************** Return html ******************************
   */

  /**
   * Blank layout
   */
  if (checkIsBlankLayout({ location, thirdPath })) {
    return <BlankLayout>{children}</BlankLayout>;
  }

  /**
   * Need to login
   */
  if (checkIsLoggedIn()) {
    keycloak.login();

    return <RouterLoading />;
  }

  /**
   * Loading when get user info
   */
  if (!globalUserInfo?.id) {
    return <RouterLoading />;
  }

  /**
   *  Check expired license for manager product
   */
  if (checkIsExpiredLicenseLayoutForManagerProduct({ globalTenant, location, globalUserInfo })) {
    return <ExpiredLicenseLayout expiredProductLicense={PRODUCT_KEY_MANAGER} />;
  }

  /**
   *  Check expired license for test engine product
   */
  if (checkIsExpiredLicenseLayoutForEngineProduct({ globalTenant, location, globalUserInfo })) {
    return <ExpiredLicenseLayout expiredProductLicense={PRODUCT_KEY_TESTENGINE} />;
  }

  /**
   * Check invalid tenantKey or invalid projectKey
   */
  if (checkIsError404Layout({ tenantPath, myTenantList, projectPath, myProjectList, thirdPath })) {
    return <Error404Layout />;
  }

  if (checkIsHomeLayout({ tenantPath, projectPath })) {
    return <HomeLayout className="auto-scroll-layout-style-1">{children}</HomeLayout>;
  }

  return (
    <DefaultLayout className={`${getClassNameForDefaultLayout({ tenantPath, projectPath, thirdPath })}`}>
      {children}
    </DefaultLayout>
  );
};

export default Layout;
