import { useEffect } from 'react';

import { getCtrlShiftAlt } from '../../../../common/utils';
import {
  checkIsDeleteWhenFocus,
  checkIsEnterThenGoDown,
  handleKeyboardEvents,
  checkIsOpenDropdownWhenFocus,
  noActionWhenOpenOptionDropdown2
} from '../../editable-cell-utils';

const JiraStatusKeyboardEvents = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  setEditingCell,
  handleOpen,
  handleSave,
  closeAndSetLastValue
}) => {
  /**
   * Handle key down
   */
  const handleKeyDown = e => {
    if (!e) {
      return;
    }

    const hasDisabledOutsideClick = document.querySelector('.disabled-outside-click');

    if (hasDisabledOutsideClick) {
      return;
    }

    const code = e.code;
    const ctrl = getCtrlShiftAlt(e, 'Control');
    const alt = getCtrlShiftAlt(e, 'Alt');
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');
    const boxVisualInput = document.querySelector('.c-editable-cell .box-visual-input');
    const hasOpenedSelectDropdown = document.querySelector('.ant-select-open');

    // Option dropdown is opening => Stop propagation
    if (noActionWhenOpenOptionDropdown2({ hasOpenedSelectDropdown, code })) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // Enter => No action
    else if (checkIsEnterThenGoDown({ boxVisualInput, code })) {
      e.preventDefault();
      e.stopPropagation();
      return;
    } else {
    }

    // Delete & has "focused" class => Don't open, keep "focused" class, save
    if (checkIsDeleteWhenFocus({ field, hasFocused, code })) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.resetFields([formItemName]);
      handleSave(null);
      return;
    }

    // When show "box-visual-input"
    else if (checkIsOpenDropdownWhenFocus({ code, ctrl, alt, hasFocused, boxVisualInput })) {
      handleOpen();
      return;
    } else {
    }

    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return null;
};

export default JiraStatusKeyboardEvents;
