import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreActions } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import { JIRA_PLATFORM_ID, SS_LAST_SAVED_VALUE } from '../../../../constants';
import { getObjectByValue, reactSessionStorage, handleSetLastSavedValueToSession } from '../../../../common/utils';
import { checkIsEditableCell, onOutsideClick } from '../../editable-cell-utils';
import { StatusLabel } from '../../../status-label';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import JiraStatusFormItem from './jira-status-form-item';
import JiraStatusKeyboardEvents from './jira-status-keyboard-events';

const JiraStatusField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For jira integration
  const getWorkflowTransitionList = useStoreActions(action => action.jiraIntegration.getWorkflowTransitionList);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [listStates, setListStates] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    typeof onChangeEditingCell === 'function' && onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastOption = lastSavedValue[formItemName];

    return lastOption !== undefined ? lastOption?.status : objectPath.get(row, field?.refName);
  }, [row, field, formItemName, isOpenDropdown]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const newListStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];

    let newOptions = newListStates.map(item => {
      return {
        label: item?.name,
        value: item?.id,
        status: item,
        disabled: item?.id === val?.id
      };
    });

    if (val?.id && !newOptions.some(item => item?.value === val?.id)) {
      const currentOption = {
        label: val?.name,
        value: val?.id,
        status: val,
        disabled: true
      };

      newOptions = [currentOption, ...newOptions];
    }

    return newOptions;
  }, [val, row, field, listStates]);

  /**
   * Handle get list states
   */
  const handleGetListStates = async () => {
    try {
      if (!row?.source?.jira?.id) {
        return;
      }

      setLoading(true);

      const res = await getWorkflowTransitionList(row.source.jira.id);

      let newListStates = Array.isArray(res) && res.length ? [...res] : [];
      newListStates = newListStates.map(item => ({
        ...item.to,
        externalSystem: JIRA_PLATFORM_ID
      }));

      setListStates(newListStates);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  /**
   * When dropdown is open: Handle get list states
   */
  useEffect(() => {
    if (isOpenDropdown) {
      setListStates();
      setTimeout(() => handleGetListStates(), 100);
    }
  }, [isOpenDropdown]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) {
      return null;
    }

    const val = objectPath.get(row, field?.refName);

    return getObjectByValue(val?.id, options);
  }, [row, field, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastOption = lastSavedValue[formItemName];

    return lastOption !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') {
      return;
    }

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newCurrentOption = getObjectByValue(opt?.value, options);

      handleSetLastSavedValueToSession({ [formItemName]: newCurrentOption });

      typeof onSave === 'function' && onSave({ formData: { [field?.refName]: opt?.value }, row, field });

      handleClose(opt);
    }
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    optionFilterProp: 'label',
    autoFocus: true,
    showSearch: true,
    allowClear: false,
    loading,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: isReadOnly,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => handleSave(option),
    onClear: () => handleSave(null),
    onClick: () => handleOpen()
  };

  /**
   * Render cell text utils
   */
  const renderCellTextUtilEditable = ({ option }) => {
    return (
      <div title={option?.label || ''} className="cell-text cursor-text" onClick={handleOpen}>
        <span className="cell-text-value text-truncate">
          <StatusLabel status={option?.status} />
        </span>
      </div>
    );
  };

  const renderCellTextUtilRelationField1 = ({ option, row, field }) => {
    return (
      <RelationLink row={row} field={field}>
        <StatusLabel status={option?.status} />
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ option, row, field }) => {
    return (
      <div title={option?.label || ''} className="cell-text cursor-default hide-after">
        <RelationLink row={row} field={field}>
          <StatusLabel status={option?.status} />
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ option }) => {
    return <StatusLabel status={option?.status} />;
  };

  const renderCellTextUtilReadonly2 = ({ option }) => {
    return (
      <div title={option?.label || ''} className="cell-text cursor-default hide-after">
        <span className="cell-text-value text-truncate">
          <StatusLabel status={option?.status} />
        </span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const option = getCurrentOption(currentOption);

    // Editable
    if (checkIsEditableCell({ isEditableField, isReadOnly, disableEditingCells })) {
      return renderCellTextUtilEditable({ option });
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ option, row, field });
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ option, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ option });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ option });
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <JiraStatusFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <JiraStatusKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText()}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper status-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default JiraStatusField;
