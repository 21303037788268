import React, { useState, useEffect, useMemo } from 'react';
import { Button, Drawer, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { EyeOutlined, EditOutlined, MinusOutlined, ArrowsAltOutlined, CloseCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { DEFAULT_WORK_ITEM_ICONS, FROM_MODULE_TEST_REPO, FROM_MODULE_WORK_TICKET } from '../../constants';
import { getObjectByValue, getPrePathLinkToTicket } from '../../common/utils';
import TestCaseModal from '../../pages/manager/test-repository/components/test-case-modal';
import EditWorkTicketModal from '../../pages/manager/work-item/components/edit-work-ticket-modal';

import './style.scss';

export const QuickEditingDrawer = ({ fromModule, workTicket, isReadOnly }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const setQuickEditingDrawer = useStoreActions(action => action.global.setQuickEditingDrawer);
  const quickEditingDrawer = useStoreState(state => state.global.quickEditingDrawer);

  // For test case store
  const getTestCaseByKey = useStoreActions(action => action.testcase.getTestCaseByKey);
  const setEditingTestCase = useStoreActions(action => action.testcase.setEditingTestCase);
  const editingTestCase = useStoreState(state => state.testcase.editingTestCase);
  const getTestCaseListByFilterQs = useStoreActions(action => action.testcase.getTestCaseListByFilterQs);
  const query = useStoreState(state => state.testcase.query);

  // For work item store
  const getWorkTicketById = useStoreActions(action => action.managerWorkItem.getWorkTicketById);
  const setDetailWorkTicket = useStoreActions(action => action.managerWorkItem.setDetailWorkTicket);
  const detailWorkTicket = useStoreState(state => state.managerWorkItem.detailWorkTicket);

  // Component state
  const [animatedMinimizedButton, setAnimatedMinimizedButton] = useState(false);

  /**
   * Work ticket icon
   */
  const workTicketIcon = useMemo(
    () => getObjectByValue(workTicket?.icon, DEFAULT_WORK_ITEM_ICONS)?.icon || '',
    [workTicket?.icon]
  );

  /**
   * Get editing item
   */
  useEffect(() => {
    if (!fromModule || !quickEditingDrawer?.key) {
      return;
    }

    switch (fromModule) {
      case FROM_MODULE_TEST_REPO: {
        getTestCaseByKey({ key: quickEditingDrawer?.key });
        break;
      }

      case FROM_MODULE_WORK_TICKET: {
        getWorkTicketById({ id: quickEditingDrawer?.key });
        break;
      }

      default:
        break;
    }
  }, [fromModule, quickEditingDrawer?.key, getTestCaseByKey, getWorkTicketById]);

  /**
   * Editing item
   */
  const editingItem = useMemo(() => {
    if (!fromModule) {
      return;
    }

    switch (fromModule) {
      case FROM_MODULE_TEST_REPO:
        return editingTestCase;

      case FROM_MODULE_WORK_TICKET:
        return detailWorkTicket;

      default:
        return;
    }
  }, [fromModule, editingTestCase, detailWorkTicket]);

  /**
   * Detail link
   */
  const detailLink = useMemo(() => {
    const prefixPath = getPrePathLinkToTicket({ workTicketId: workTicket?.id, noGoToTestcaseVersion: true });
    const link = `${prefixPath}${editingItem?.key}${window.location.search}`;

    return link;
  }, [workTicket?.id, editingItem]);

  /**
   * Reset editing item
   */
  useEffect(() => {
    if (!quickEditingDrawer) {
      setEditingTestCase(null);
      setDetailWorkTicket(null);
    }
  }, [quickEditingDrawer, setEditingTestCase, setDetailWorkTicket]);

  /**
   * Reset animatedMinimizedButton
   */
  useEffect(() => {
    if (!quickEditingDrawer?.minimized) {
      setAnimatedMinimizedButton(false);
    }
  }, [quickEditingDrawer?.minimized]);

  /**
   * On close drawer
   */
  const onCloseDrawer = () => {
    setQuickEditingDrawer(null);
  };

  /**
   * Render drawer title
   */
  const renderDrawerTitle = () => {
    return (
      <Row justify="space-between" align="middle">
        <div>
          <h3 className="text-primary font-weight-medium font-size-16 mt-0 mb-0">
            {isReadOnly ? (
              <>
                <EyeOutlined className="mr-1" />
                {workTicket?.name}
              </>
            ) : (
              <>
                <EditOutlined className="mr-1" />
                {fromModule === FROM_MODULE_TEST_REPO ? (
                  t('testRepo.editTestCase')
                ) : (
                  <>
                    {t('common.edit')} {workTicket?.name}
                  </>
                )}
              </>
            )}
          </h3>

          <div className="font-size-12 font-weight-normal text-gray-2">
            {editingItem?.externalSystem && (
              <div
                title={editingItem?.externalSystem}
                className={`external-system-label mr-2 ${editingItem?.externalSystem}`}
              >
                {editingItem?.externalSystem}
              </div>
            )}
            {`${globalTenant?.name} / ${globalProject?.name} / `}
            {workTicketIcon} {workTicket?.name} /{' '}
            <Link to={detailLink} onClick={onCloseDrawer}>
              {quickEditingDrawer?.key}
            </Link>
          </div>
        </div>

        <Row align="middle" className="box-drawer-extra">
          <Tooltip title={t('common.minimize')} placement="bottom" destroyTooltipOnHide={true}>
            <Button
              className="border-0 bg-transparent w-auto h-auto p-0 ml-3"
              onClick={() => {
                setQuickEditingDrawer({ visible: false, minimized: true, key: quickEditingDrawer?.key });
                setTimeout(() => setAnimatedMinimizedButton(true), 400);
              }}
            >
              <MinusOutlined />
            </Button>
          </Tooltip>

          <Tooltip title={t('common.goToDetail')} placement="bottom" destroyTooltipOnHide={true}>
            <Link
              to={detailLink}
              className="ant-btn border-0 bg-transparent w-auto h-auto p-0 ml-3"
              onClick={() => setQuickEditingDrawer(null)}
            >
              <ArrowsAltOutlined className="font-size-16" />
            </Link>
          </Tooltip>
        </Row>
      </Row>
    );
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setQuickEditingDrawer(false);
      setEditingTestCase(null);
      setDetailWorkTicket(null);
    };
  }, [setQuickEditingDrawer, setEditingTestCase, setDetailWorkTicket]);

  return (
    <div className="c-quick-editing-drawer">
      <Drawer
        title={renderDrawerTitle()}
        open={quickEditingDrawer?.visible}
        placement="right"
        width={1160}
        className="c-quick-editing-drawer-modal"
        onClose={onCloseDrawer}
      >
        {quickEditingDrawer?.visible && editingItem?.key && (
          <>
            {fromModule === FROM_MODULE_TEST_REPO ? (
              <TestCaseModal
                fromModule="QUICK_EDIT_MODAL"
                editingTestCase={editingItem}
                visible={true}
                isReadOnly={isReadOnly}
                onUpdated={() => getTestCaseListByFilterQs(query)}
                onCancel={onCloseDrawer}
              />
            ) : (
              <EditWorkTicketModal
                fromModule="QUICK_EDIT_MODAL"
                qKey={quickEditingDrawer?.key}
                workTicketId={workTicket?.id}
                detailWorkTicket={editingItem}
                visible={true}
                isReadOnly={isReadOnly}
                onCancel={onCloseDrawer}
              />
            )}
          </>
        )}
      </Drawer>

      {quickEditingDrawer?.minimized && (
        <button
          type="button"
          className={`btn-minimized ${animatedMinimizedButton ? 'animated' : ''}`}
          onClick={() => setQuickEditingDrawer({ visible: true, minimized: false, key: quickEditingDrawer?.key })}
        >
          <span className="d-block text-truncate">
            {workTicketIcon} {workTicket?.name} - {editingItem?.key || ''}
            <Tooltip title={t('common.close')} placement="topRight" destroyTooltipOnHide={true}>
              <CloseCircleFilled
                className="btn-close-minimize"
                onClick={e => {
                  e.stopPropagation();
                  setQuickEditingDrawer(null);
                }}
              />
            </Tooltip>
          </span>
        </button>
      )}
    </div>
  );
};
