import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  workTicketExportedFieldList: [],
  loadingWorkTicketExportedFieldList: false,
  loadingWorkTicketExportedData: false,

  /**
   * Action: Set work ticket exported field list
   */
  setWorkTicketExportedFieldList: action((state, payload) => {
    if (state?.workTicketExportedFieldList === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.workTicketExportedFieldList = [];
      return;
    }

    state.workTicketExportedFieldList = payload.data;
  }),

  /**
   * Action: Set loading work ticket exported field list
   */
  setLoadingWorkTicketExportedFieldList: action((state, payload) => {
    if (state?.loadingWorkTicketExportedFieldList === undefined) {
      return;
    }

    state.loadingWorkTicketExportedFieldList = payload;
  }),

  /**
   * Action: Set loading work ticket exported data
   */
  setLoadingWorkTicketExportedData: action((state, payload) => {
    if (state?.loadingWorkTicketExportedData === undefined) {
      return;
    }

    state.loadingWorkTicketExportedData = payload;
  }),

  /**
   * Action: Call api to get work ticket exported excel testcase
   */
  getWorkTicketExportedFieldList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get work ticket exported field list: Invalid Params');
      }

      action.setLoadingWorkTicketExportedFieldList(true);

      const { tenantId, projectId } = payload;
      const url = `${ENDPOINTS.EXPORT_GET_FIELD}/tenant-${tenantId}/project-${projectId}?type=repository`;
      const res = await Http.get(url).then(res => res.data);

      action.setWorkTicketExportedFieldList(res);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingWorkTicketExportedFieldList(false);
    }
  }),

  /**
   * Action: Call api to get work ticket exported csv testcase
   */
  getWorkTicketExportedCSVData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get work ticket exported csv data: Invalid Params');
      }

      action.setLoadingWorkTicketExportedData(true);

      const { collectionName, fieldList, option } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EXPORT}/csv/${collectionName}?${option}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, { fieldList }, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingWorkTicketExportedData(false);
    }
  }),

  /**
   * Action: Call api to get work ticket exported data
   */
  getWorkTicketExportedExcelData: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get work ticket exported excel data: Invalid Params');
      }

      action.setLoadingWorkTicketExportedData(true);

      const { collectionName, fieldList, option } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.BIG_DATA}${ENDPOINTS.MANAGER_COMMON}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.EXPORT}/excel/${collectionName}`;
      const config = { responseType: 'blob' };
      const res = await Http.post(url, { fieldList, option }, config);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingWorkTicketExportedData(false);
    }
  })
};
