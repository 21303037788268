import _debounce from 'lodash/debounce';

import { checkIsNotEmptyArray, getCtrlShiftAlt } from '../../common/utils';

const EFFECT_FLASH = 'effect-flash';

/**
 * Scroll to focused element
 */
const scrollToFocusedElement = _debounce(elm => {
  if (!elm?.dataset.formItemName || document.querySelector('.disable-scroll-to-focused-element')) {
    return;
  }
  document.querySelector(`[data-form-item-name="${elm?.dataset.formItemName}"]`)?.scrollIntoView();
}, 300);

/**
 * Go left right
 */
export const goLeftRight = async ({ e, x, y, actionType, setEditingCell }) => {
  if (isNaN(x) || isNaN(y) || !actionType) {
    return;
  }

  e?.stopPropagation();
  e?.preventDefault();

  const triggerRowList = document.querySelectorAll(`.trigger-focus-element[data-x="${x}"]`);

  if (!(Array.isArray(Array.from(triggerRowList)) && Array.from(triggerRowList).length)) {
    return;
  }

  const currentIndex = Array.from(triggerRowList).findIndex(item => +item.dataset.x === x && +item.dataset.y === y);
  const step = actionType === 'RIGHT' ? 1 : actionType === 'LEFT' ? -1 : 0;
  const newElement = triggerRowList[currentIndex + step];

  // For go to left or right
  if (newElement) {
    typeof setEditingCell === 'function' && setEditingCell(null);
    newElement.click();
    scrollToFocusedElement(newElement);
  }

  // For go to new row
  else {
    const triggerAllRowList = document.querySelectorAll('.trigger-focus-element');
    const newTriggerAllRowList = Array.from(triggerAllRowList);

    if (!(Array.isArray(newTriggerAllRowList) && newTriggerAllRowList.length)) {
      return;
    }

    const currentIndex = newTriggerAllRowList.findIndex(item => +item.dataset.x === x && +item.dataset.y === y);
    const newElement = triggerAllRowList[currentIndex + step];

    if (actionType === 'RIGHT' && newElement) {
      typeof setEditingCell === 'function' && setEditingCell(null);
      newElement?.click();
      scrollToFocusedElement(newElement);
    } else if (actionType === 'LEFT' && newElement) {
      typeof setEditingCell === 'function' && setEditingCell(null);
      newElement?.click();
      scrollToFocusedElement(newElement);
    } else {
    }
  }
};

/**
 * Go up down
 */
export const goUpDown = ({ e, x, y, actionType, setEditingCell }) => {
  if (isNaN(x) || isNaN(y) || !actionType) {
    return;
  }

  e?.stopPropagation();
  e?.preventDefault();

  const step = actionType === 'DOWN' ? 1 : actionType === 'UP' ? -1 : 0;
  const newElement = document.querySelector(`.trigger-focus-element[data-x="${x + step}"][data-y="${y}"]`);

  if (newElement) {
    typeof setEditingCell === 'function' && setEditingCell(null);
    newElement.click();
  }
};

/**
 * On outside click
 */
export const onOutsideClick = ({ e, tableForm, x, y, setEditingCell }) => {
  if (
    !e ||
    !tableForm ||
    isNaN(x) ||
    isNaN(y) ||
    typeof setEditingCell !== 'function' ||
    e?.target.closest('.ant-picker-dropdown') ||
    document.querySelector('.ant-select-open') ||
    document.querySelector('.disabled-outside-click')
  ) {
    return;
  }

  // const isEditing = document.querySelector('.trigger-focus-element.editing');
  const currentElement = document.querySelector(`[data-x="${x}"][data-y="${y}"]`);
  const formItemName = currentElement?.dataset.formItemName;
  const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);

  // Exit editing when the form has any errors
  if (!e?.target?.classList.contains('cell-text')) {
    const errors = tableForm.getFieldsError();

    if (Array.isArray(errors) && errors.length && errors.some(e => Array.isArray(e?.errors) && e?.errors.length)) {
      setEditingCell(null);
    }
  }

  // When editing, click outsite (not ".cell-text") => focus
  if (
    !e?.target?.classList.contains('cell-text') &&
    triggerFocusElement?.classList.contains('editing') &&
    !triggerFocusElement?.classList.contains('focused')
  ) {
    triggerFocusElement?.classList.add('focused');
    document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
  }

  // Click to other "cell-text", close current
  else if (e?.target?.classList.contains('cell-text') && e?.target?.dataset.formItemName !== formItemName) {
    setEditingCell(null);
  }

  // Click outsite, no "cell-text", close current
  else if (
    !e?.target?.classList.contains('cell-text') &&
    document.querySelector('.trigger-focus-element.editing.focused')
  ) {
    setEditingCell(null);
    document.querySelector('.trigger-reload-table-data-helper.need-to-reload')?.click();
    document.querySelector('.trigger-set-children-data-to-parent-data.has-quick-edit')?.click();
  } else {
  }
};

/**
 * Allowed character codes
 */
export const ALLOWED_CHARACTER_CODES = [
  'Backquote',
  'Digit1',
  'Digit2',
  'Digit3',
  'Digit4',
  'Digit5',
  'Digit6',
  'Digit7',
  'Digit8',
  'Digit9',
  'Digit0',
  'Minus',
  'Equal',
  'Backspace',
  'KeyA',
  'KeyB',
  'KeyC',
  'KeyD',
  'KeyE',
  'KeyF',
  'KeyG',
  'KeyH',
  'KeyI',
  'KeyJ',
  'KeyK',
  'KeyL',
  'KeyM',
  'KeyN',
  'KeyO',
  'KeyP',
  'KeyQ',
  'KeyR',
  'KeyS',
  'KeyT',
  'KeyU',
  'KeyV',
  'KeyW',
  'KeyX',
  'KeyY',
  'KeyZ',
  'BracketLeft',
  'BracketRight',
  'Semicolon',
  'Quote',
  'Backslash',
  'Comma',
  'Period',
  'Slash',
  'Insert',
  'Home',
  'End',
  'Numpad1',
  'Numpad2',
  'Numpad3',
  'Numpad4',
  'Numpad5',
  'Numpad6',
  'Numpad7',
  'Numpad8',
  'Numpad9',
  'Numpad0',
  'NumpadDivide',
  'NumpadMultiply',
  'NumpadSubtract+',
  'NumpadAdd',
  'NumpadDecimal'
];

/**
 * Handle keyboard events
 */
export const handleKeyboardEvents = ({ e, x, y, formItemName, tableForm, setEditingCell, closeAndSetLastValue }) => {
  if (!e || isNaN(x) || isNaN(y) || !formItemName || !tableForm || typeof setEditingCell !== 'function') {
    return;
  }

  const code = e.code;
  const ctrl = getCtrlShiftAlt(e, 'Control');
  const shift = getCtrlShiftAlt(e, 'Shift');
  const alt = getCtrlShiftAlt(e, 'Alt');
  const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
  const hasFocused = triggerFocusElement?.classList.contains('focused');
  const isEditing = triggerFocusElement?.classList.contains('editing');

  if (hasFocused || isEditing) {
    const errors = tableForm.getFieldsError();

    // Esc => Exit
    if (
      code === 'Escape' &&
      checkIsNotEmptyArray(errors) &&
      errors.some(e => Array.isArray(e?.errors) && e?.errors.length)
    ) {
      e.stopPropagation();
      e.preventDefault();

      setEditingCell(null);
      typeof closeAndSetLastValue === 'function' && closeAndSetLastValue();

      return;
    }
  }

  // When editing, no focus
  if (isEditing && !hasFocused) {
    const triggerSaveElement = document.querySelector(`.trigger-save-element[data-form-item-name="${formItemName}"]`);

    // Left
    if (shift && code === 'Tab') {
      triggerSaveElement?.click();
      goLeftRight({ e, x, y, actionType: 'LEFT', setEditingCell });
    }

    // Right
    else if (code === 'Tab') {
      triggerSaveElement?.click();
      goLeftRight({ e, x, y, actionType: 'RIGHT', setEditingCell });
    }

    // Down
    else if (code === 'Enter' || code === 'NumpadEnter') {
      triggerSaveElement?.click();
      triggerFocusElement.classList.add('focused');
      document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
    }

    // Esc
    else if (code === 'Escape') {
      triggerSaveElement?.click();
      triggerFocusElement.classList.add('focused');
      document.querySelector(`#${formItemName}`)?.focus({ cursor: 'end' });
    } else {
    }
  }

  // When focused
  else if (hasFocused) {
    // Left
    if (code === 'ArrowLeft' || (shift && code === 'Tab')) {
      goLeftRight({ e, x, y, actionType: 'LEFT', setEditingCell });
    }

    // Right
    else if (code === 'ArrowRight' || code === 'Tab') {
      goLeftRight({ e, x, y, actionType: 'RIGHT', setEditingCell });
    }

    // Up
    else if (code === 'ArrowUp') {
      goUpDown({ e, x, y, actionType: 'UP', setEditingCell });
    }

    // Down
    else if (code === 'ArrowDown') {
      goUpDown({ e, x, y, actionType: 'DOWN', setEditingCell });
    }

    // Enter
    else if (code === 'Enter' || code === 'NumpadEnter') {
      setTimeout(() => triggerFocusElement.classList.remove('focused'), 0);
    }

    // Esc
    else if (code === 'Escape' && isEditing) {
      e.stopPropagation();
      e.preventDefault();

      setEditingCell(null);
      document.querySelector('.trigger-reload-table-data-helper.need-to-reload')?.click();
      document.querySelector('.trigger-set-children-data-to-parent-data.has-quick-edit')?.click();
    } else {
    }

    // Switch from focus to edit
    if (ctrl || alt || ALLOWED_CHARACTER_CODES.includes(code)) {
      e.stopPropagation();
      triggerFocusElement.classList.remove('focused');
    }
  }
};

/**
 * Check is editable cell
 */
export const checkIsEditableCell = ({ isEditableField, isReadOnly, disableEditingCells }) => {
  return isEditableField && !isReadOnly && !disableEditingCells;
};

/**
 * Add effect to selected date
 */
export const addEffectToSelectedDate = () => {
  const pickerCellWithEffect = document.querySelector('.ant-picker-cell.effect-flash');
  const pickerCellSelected = document.querySelector('.ant-picker-cell-selected:not(.effect-flash)');
  const pickerCellToday = document.querySelector('.ant-picker-cell-today:not(.effect-flash)');

  // Add "effect-flash" to selected date
  if (pickerCellSelected && !pickerCellWithEffect) {
    pickerCellSelected?.classList.add(EFFECT_FLASH);
    setTimeout(() => pickerCellSelected?.classList.remove(EFFECT_FLASH), 1000);
  }

  // Add "effect-flash" to today
  else if (pickerCellToday && !pickerCellWithEffect) {
    pickerCellToday?.classList.add(EFFECT_FLASH);
    setTimeout(() => pickerCellToday?.classList.remove(EFFECT_FLASH), 1000);
  } else {
  }
};

/**
 * Option dropdown is opening => Stop propagation
 */
export const noActionWhenOpenOptionDropdown1 = ({ hasOpenedSelectDropdown, code }) => {
  return hasOpenedSelectDropdown && ['ArrowUp', 'ArrowDown', 'Tab'].includes(code);
};

/**
 * Option dropdown is opening => Stop propagation
 */
export const noActionWhenOpenOptionDropdown2 = ({ hasOpenedSelectDropdown, code }) => {
  return hasOpenedSelectDropdown && ['Enter', 'NumpadEnter', 'ArrowUp', 'ArrowDown', 'Tab'].includes(code);
};

/**
 * When show "box-visual-input" => open dropdown
 */
export const checkIsOpenDropdownWhenFocus = ({ code, ctrl, alt, hasFocused, boxVisualInput }) => {
  const condition1 = hasFocused && boxVisualInput;
  const condition2 = ['Enter', 'NumpadEnter'].includes(code) || ctrl || alt || ALLOWED_CHARACTER_CODES.includes(code);

  return condition1 && condition2;
};

/**
 * Delete & has "focused" class => Don't open, keep "focused" class, save
 */
export const checkIsDeleteWhenFocus = ({ field, hasFocused, code }) => {
  return !field?.mandatory && hasFocused && ['Delete', 'NumpadDecimal'].includes(code);
};

/**
 * Enter => Select => Save and go down
 */
export const checkIsEnterThenGoDown = ({ boxVisualInput, code }) => {
  return !boxVisualInput && ['Enter', 'NumpadEnter'].includes(code);
};

/**
 * When editing, no focused, press Shift + Tab => Don't go left
 */
export const checkDontGoLeftWhenEditing = ({ hasFocused, isEditing, shift, code }) => {
  return !hasFocused && isEditing && shift && code === 'Tab';
};

/**
 * Check is show calendar
 */
export const checkIsShowCalendar = () => {
  const datePickerDropdown = document.querySelector('.ant-picker-dropdown');
  return datePickerDropdown && !datePickerDropdown.classList.contains('ant-picker-dropdown-hidden');
};

/**
 * When show calendar
 * Enter && show calendar && empty value => Close + Save
 */
export const checkIsEmptyValueWhenPressEnter = ({ code, val }) => {
  const condition = (code === 'Enter' || code === 'NumpadEnter') && !val;
  return checkIsShowCalendar() && condition;
};

/**
 * When show calendar
 * Tab => When pressing tab, add flash effect to selected date on the calendar
 */
export const checkIsAddEffectToSelectedDate = ({ code, shift }) => {
  const condition = code === 'Tab' && !(shift && code === 'Tab');
  return checkIsShowCalendar() && condition;
};
