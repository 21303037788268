import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import prettyBytes from 'pretty-bytes';
import moment from 'moment';
import Filedownload from 'js-file-download';
import { Button, Tooltip, Modal, Spin, Row } from 'antd';
import { FileOutlined, DeleteOutlined, DownloadOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import env from '../../env';
import { FULL_DATE_FORMAT, JIRA_PLATFORM_ID } from '../../constants';
import { Audio, Video, ExternalLink, DeletedFile, JiraAtlassian } from '../../assets/svg-icons';
import { PreviewFileModal } from '../preview-file-modal';

import './style.scss';

export const BasicAttachmentList = ({
  onlyPreviewFileOnClient,
  attachments,
  noDataText,
  directlyDeleteAttachment = true,
  hasGetFullFilesInfo = true,
  isReadOnly = false,
  className = '',
  onChangeVisiblePreviewFileModal,
  onChangeAttachments,
  onChangeDeteledAttachmentIds,
  ...rest
}) => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalFullFilesInfo = useStoreState(state => state.global.globalFullFilesInfo);
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const deleteFiles = useStoreActions(action => action.global.deleteFiles);
  const getFileById = useStoreActions(action => action.global.getFileById);

  // For Jira integration
  const deleteAttachment = useStoreActions(action => action.jiraIntegration.deleteAttachment);

  // For component state
  const [currentAttachments, setCurrentAttachments] = useState(null); // Fix multiple call handleGetCurrentAttachmentList
  const [currentAttachmentList, setCurrentAttachmentList] = useState([]); // For show attachments on list screen
  const [deteledAttachmentIds, setDeteledAttachmentIds] = useState([]);
  const [loadingAttachmentList, setLoadingAttachmentList] = useState(false);
  const [currentPreviewFile, setCurrentPreviewFile] = useState(null);
  const [visiblePreviewFileModal, setVisiblePreviewFileModal] = useState(false);

  /**
   * Handle get current attachment list
   */
  const handleGetCurrentAttachmentList = async attachments => {
    const originAttachments =
      Array.isArray(attachments) && attachments.length ? [...attachments].filter(item => item) : [];
    let newCurrentAttachmentList = [];

    try {
      setLoadingAttachmentList(true);

      for (let i = 0; i < originAttachments.length; i++) {
        const item = originAttachments[i];
        let newFileInfo = null;

        // For show base64 file from input file
        if (onlyPreviewFileOnClient) {
          newFileInfo = item;
        }

        // For public file
        else if (globalFullFilesInfo?.[item]?.isPublicFile) {
          newFileInfo = globalFullFilesInfo?.[item];
        }

        // For private file
        else if (globalFullFilesInfo?.[item?.id]) {
          newFileInfo = globalFullFilesInfo?.[item?.id];
        }

        // Need to get full files info
        else if (hasGetFullFilesInfo) {
          const res = await getFullFilesInfo([item]);
          newFileInfo = Array.isArray(res) && res.length ? res[0] : null;
        } else {
        }

        newCurrentAttachmentList.push(newFileInfo);
      }

      newCurrentAttachmentList = newCurrentAttachmentList.filter(item => item);

      setCurrentAttachmentList(newCurrentAttachmentList);
    } catch (err) {
    } finally {
      setLoadingAttachmentList(false);
    }
  };

  /**
   * Only set step list when mounted
   */
  useEffect(() => {
    if (!globalTenant?.tenantKey || !globalProject?.projectKey) {
      return;
    }

    // Fix multiple run handleGetCurrentAttachmentList
    if (JSON.stringify(attachments) !== JSON.stringify(currentAttachments)) {
      handleGetCurrentAttachmentList(Array.isArray(attachments) && attachments.length ? [...attachments] : []);
      setCurrentAttachments(attachments);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, currentAttachments, globalFullFilesInfo, globalTenant, globalProject]);

  /**
   * Handle remove attachment item
   */
  const handleRemoveAttachmentItem = async ({ item, originAttachmentList }) => {
    let res = null;

    if (directlyDeleteAttachment && item?.id) {
      if (item?.externalSystem == JIRA_PLATFORM_ID) {
        res = await deleteAttachment(item?.id);
      } else {
        res = await deleteFiles({ globalTenant, globalProject, ids: [item?.id] });
      }
    }

    // On change attachments
    const newAttachmentList = [...originAttachmentList].filter(attach => {
      return (
        (item?.id && item?.id !== attach?.id) || // For private attachment
        (item?.isPublicFile && item?.src !== attach) // For public attachment
      );
    });

    onChangeAttachments(newAttachmentList);

    // For emit "id of deleted files"
    if (item?.id) {
      const newDeteledAttachmentIds =
        Array.isArray(deteledAttachmentIds) && deteledAttachmentIds.length
          ? [...deteledAttachmentIds, item?.id]
          : [item?.id];

      setDeteledAttachmentIds(newDeteledAttachmentIds);
      onChangeDeteledAttachmentIds(newDeteledAttachmentIds);
    }

    return res;
  };

  /**
   * On remove attachment item
   */
  const onRemoveAttachmentItem = item => {
    if (!item) {
      return;
    }

    const originAttachmentList = Array.isArray(attachments) && attachments.length ? [...attachments] : [];

    // For: File not found
    // For: Only preview file in client
    if (item.fileNotFound || onlyPreviewFileOnClient) {
      const newAttachmentList = [...originAttachmentList].filter(sub => item?.id !== sub?.id);

      onChangeAttachments(newAttachmentList);

      return;
    }

    Modal.confirm({
      title: t('common.warning'),
      content: t('message.areYouSureDeleteThisAttachment'),
      autoFocusButton: null,
      maskClosable: true,
      okText: t('common.delete'),
      okButtonProps: { danger: true, type: 'primary', icon: <DeleteOutlined /> },
      cancelText: t('common.cancel'),
      onOk: () => handleRemoveAttachmentItem({ item, originAttachmentList })
    });
  };

  /**
   * On change visible preview file modal
   */
  useEffect(() => {
    typeof onChangeVisiblePreviewFileModal === 'function' && onChangeVisiblePreviewFileModal(visiblePreviewFileModal);
  }, [visiblePreviewFileModal]);

  /**
   * Handle show preview file modal
   */
  const handleShowPreviewFileModal = file => {
    if (file?.fileNotFound) {
      return;
    }

    setCurrentPreviewFile(file);
    setVisiblePreviewFileModal(true);
  };

  /**
   * On download
   */
  const onDownload = async item => {
    try {
      if (!item?.id) {
        return;
      }

      // For external system: jira
      if (item?.externalSystem === JIRA_PLATFORM_ID) {
        window.open(item?.previewUrl, '_blank');
        return;
      }

      // For internal system
      else {
        setLoadingAttachmentList(true);

        const res = await getFileById({ tenantKey: tenantPath, projectKey: projectPath, fileId: item?.id });

        if (res?.data) {
          Filedownload(res?.data, item?.fileName);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAttachmentList(false);
    }
  };

  /**
   * Render action
   */
  const renderAction = item => {
    const isShowDownload = !onlyPreviewFileOnClient && !item?.fileNotFound && item?.id && !item?.isPublicFile;

    const isShowOpenInNewTab =
      !onlyPreviewFileOnClient && !item?.fileNotFound && item?.externalSystem !== JIRA_PLATFORM_ID;

    const newTabLink = item?.isPublicFile ? item?.src : item?.previewUrl;

    return (
      <Row align="middle" className="box-btns">
        {isShowDownload && (
          <Tooltip
            title={t('common.download')}
            placement="top"
            destroyTooltipOnHide={true}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              type="link"
              danger
              icon={<DownloadOutlined />}
              size="small"
              className="text-hover-dark-primary border-transparent"
              style={{ color: '#8d8d8d' }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onDownload(item);
              }}
            />
          </Tooltip>
        )}

        {isShowOpenInNewTab && (
          <Tooltip
            title={t('common.openInNewTab')}
            placement="top"
            destroyTooltipOnHide={true}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              href={newTabLink}
              target="_blank"
              type="link"
              icon={<ExternalLink />}
              size="small"
              className="text-hover-dark-primary border-transparent"
              style={{ color: '#8d8d8d' }}
              onClick={e => {
                e.stopPropagation();
                window.open(newTabLink, '_blank');
              }}
            />
          </Tooltip>
        )}

        {!isReadOnly && (
          <Tooltip
            title={t('common.delete')}
            placement="top"
            destroyTooltipOnHide={true}
            overlayStyle={{ pointerEvents: 'none' }}
          >
            <Button
              type="link"
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onRemoveAttachmentItem(item);
              }}
            />
          </Tooltip>
        )}
      </Row>
    );
  };

  /**
   * Render box file
   */
  const renderBoxFile = ({ item, type }) => {
    if (item?.fileNotFound) {
      return <DeletedFile className="ic-other-file gray" />;
    } else if (item?.externalSystem === JIRA_PLATFORM_ID) {
      return <JiraAtlassian className="jira-icon" />;
    } else if (type.includes('image/')) {
      return <img src={item?.src} alt="File" />;
    } else if (type.includes('video/')) {
      return <Video className="ic-other-file gray" />;
    } else if (type.includes('audio/')) {
      return <Audio className="ic-other-file gray" />;
    } else {
      return <FileOutlined className="ic-other-file gray" />;
    }
  };
  /**
   * Render box name
   */
  const renderBoxName = item => {
    return (
      <div className={`box-name text-truncate ${item?.fileNotFound ? 'text-gray-2' : 'text-primary'}`}>
        {item?.fileNotFound ? t('common.fileNotFound') : item?.fileName || ''}
      </div>
    );
  };
  /**
   * Render attachment list
   */
  const renderAttachmentList = () => {
    const attachments =
      Array.isArray(currentAttachmentList) && currentAttachmentList.length > 0 ? [...currentAttachmentList] : [];

    return (
      <>
        {attachments.length > 0 && (
          <ul className="list">
            {attachments.map((item, index) => {
              if (!item) {
                return;
              }

              const type = typeof item?.type === 'string' ? item?.type : '';

              return (
                <li
                  id={`file-${item?.id}`}
                  key={index}
                  className={`item ${item?.fileNotFound ? 'file-not-found' : ''}`}
                >
                  <div className="box-item">
                    <button
                      id={`open-preview-file-${item?.id}-button`}
                      title={item?.fileName || ''}
                      className="btn-open-preview-file"
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();

                        if (item?.externalSystem === JIRA_PLATFORM_ID) {
                          window.open(item?.previewUrl, '_blank');
                          return;
                        }

                        handleShowPreviewFileModal(item);
                      }}
                    >
                      <div className="box-file">{renderBoxFile({ item, type })}</div>

                      {renderBoxName(item)}

                      {!item?.fileNotFound && (
                        <div className="box-meta text-truncate text-gray-2">
                          <div className="txt-date text-truncate pr-1">
                            {item?.createdAt && moment(item?.createdAt).isValid() && (
                              <span title={moment(item?.createdAt).format(FULL_DATE_FORMAT)}>
                                {moment(item?.createdAt).format(FULL_DATE_FORMAT)}
                              </span>
                            )}
                          </div>
                          <div className="txt-size">
                            {!isNaN(item?.size) && item?.size > 0 && (
                              <span title={prettyBytes(item?.size)} className="txt-size">
                                {prettyBytes(item?.size)}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                    </button>

                    {renderAction(item)}
                  </div>
                </li>
              );
            })}
          </ul>
        )}

        {!attachments.length && isReadOnly && (
          <div className="txt-no-attachment">
            {noDataText || <span className="text-gray">{t('common.noAttachment')}</span>}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className={`c-basic-attachment-list ${className}`} {...rest}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingAttachmentList}>
          <div className={`${loadingAttachmentList ? 'py-2' : ''}`}>{renderAttachmentList()}</div>
        </Spin>
      </div>

      {visiblePreviewFileModal && (
        <PreviewFileModal
          visible={visiblePreviewFileModal}
          fileInfo={currentPreviewFile}
          attachments={currentAttachmentList}
          onlyPreviewFileOnClient={onlyPreviewFileOnClient}
          onCancel={() => setVisiblePreviewFileModal(false)}
        />
      )}
    </>
  );
};
