import { action } from 'easy-peasy';

export default {
  /**
   * State
   */
  globalTemplateList: null,

  /**
   * Action: Set global template list
   */
  setGlobalTemplateList: action((state, payload) => {
    if (!(state?.globalTemplateList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.globalTemplateList = [];
      return;
    }

    state.globalTemplateList = payload;
  })
};
