import { action, thunk } from 'easy-peasy';
import { notification } from 'antd';
import i18next from 'i18next';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

const NO_TEAM_KEY = 'No Team Key';

export default {
  /**
   * State
   */
  projectTeamList: null, // All of teams in current project
  editingTeam: null,
  loadingProjectTeamList: false,
  loadingProjectTeamItem: false,

  /**
   * Action: Set project team list
   */
  setProjectTeamList: action((state, payload) => {
    if (!(state?.projectTeamList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.projectTeamList = [];
    }

    state.projectTeamList = payload;
  }),

  /**
   * Action: Set editing team
   */
  setEditingTeam: action((state, payload) => {
    if (state?.editingTeam === undefined) {
      return;
    }

    state.editingTeam = payload;
  }),

  /**
   * Action: Set loading project team list
   */
  setProjectLoadingTeamList: action((state, payload) => {
    if (!(state?.loadingProjectTeamList !== undefined)) {
      return;
    }

    state.loadingProjectTeamList = payload;
  }),

  /**
   * Action: Set loading project team item
   */
  setLoadingProjectTeamItem: action((state, payload) => {
    if (!(state?.loadingProjectTeamItem !== undefined)) {
      return;
    }

    state.loadingProjectTeamItem = payload;
  }),

  /**
   * Action: Call api to get project team list
   */
  getProjectTeamList: thunk(async (action, payload, helpers) => {
    try {
      action.setProjectLoadingTeamList(true);

      const noSetProjectTeamList = payload?.noSetProjectTeamList;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MY_TENANTS}/${globalTenant?.tenantKey}/projects/${globalProject?.projectKey}${ENDPOINTS.TEAM}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetProjectTeamList) {
        action.setProjectTeamList(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setProjectLoadingTeamList(false);
    }
  }),

  /**
   * Action: Call api to get get team by key
   */
  getTeamByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error(NO_TEAM_KEY);
      }

      action.setLoadingProjectTeamItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTeam(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectTeamItem(false);
    }
  }),

  /**
   * Action: Call api to add team to project
   */
  createTeam: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Invalid Payload');
      }

      action.setLoadingProjectTeamItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}/team`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:team.messageCreatingTeam'),
        description: i18next.t('akaat:message.createdSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectTeamItem(false);
    }
  }),

  /**
   * Action: Call api to update team to project
   */
  updateTeam: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.teamKey) {
        throw new Error(NO_TEAM_KEY);
      }

      action.setLoadingProjectTeamItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload.teamKey}`;
      const res = await Http.put(url, payload.data).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:team.messageUpdatingTeam'),
        description: i18next.t('akaat:message.updatedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectTeamItem(false);
    }
  }),

  /**
   * Action: Call api to remove team from project
   */
  deleteTeam: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error(NO_TEAM_KEY);
      }

      action.setLoadingProjectTeamItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.TEAM}/${payload}`;
      await Http.delete(url).then(res => res.data);

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectTeamItem(false);
    }
  })
};
