import React, { useRef, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { arrayMoveImmutable } from 'array-move';
import { Form } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { FIELD_SYSTEM, SYSTEM_FIELD_ASSIGN_TO, SYSTEM_FIELD_EXECUTED_BY } from '../../../constants';
import { debounce, convertUserForSubmitData, getFieldName } from '../../../common/utils';
import { useUnassignedUser } from '../../../common/hooks';
import { UserAvatar, EditableSelectOption } from '../../../components';

const BoxJiraPeople = ({
  workTicketId,
  fieldList,
  editingItem,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  ...rest
}) => {
  const timerRef = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For jira integration
  const getAssignableUserList = useStoreActions(action => action.jiraIntegration.getAssignableUserList);
  const setAssignableUserList = useStoreActions(action => action.jiraIntegration.setAssignableUserList);
  const assignableUserList = useStoreState(state => state.jiraIntegration.assignableUserList);
  const loadingAssignableUserList = useStoreState(state => state.jiraIntegration.loadingAssignableUserList);

  // Component state
  const [keywords, setKeywords] = useState(false);

  /**
   * Compute fields
   */
  const fields = useMemo(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return [];
    }

    let newFields = [];

    const systemFields = fieldList.filter(item => item?.type === FIELD_SYSTEM);
    const otherFields = fieldList.filter(item => item?.type !== FIELD_SYSTEM);

    newFields = [...systemFields, ...otherFields];

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_EXECUTED_BY)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_EXECUTED_BY);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    if (newFields.some(item => item?.refName === SYSTEM_FIELD_ASSIGN_TO)) {
      const oldIndex = newFields.findIndex(item => item?.refName === SYSTEM_FIELD_ASSIGN_TO);
      newFields = arrayMoveImmutable(newFields, oldIndex, 0);
    }

    return newFields;
  }, [fieldList]);

  /**
   * On search
   */
  const onSearch = async (keyword, refName) => {
    // Reset
    setAssignableUserList([]);

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(
      debounce(() => {
        setKeywords({ ...keywords, [refName]: keyword });

        getAssignableUserList({
          username: keyword
        });
      }),
      300
    );
  };

  /**
   * Handle submit
   */
  const handleSubmit = (refName, val, user) => {
    if (!refName) {
      return;
    }

    const currentVal = objectPath.get(editingItem, refName);
    if (val === currentVal?.id) {
      return;
    }

    const accentUnassignedValue = refName === SYSTEM_FIELD_ASSIGN_TO && val && val !== UNASSIGNED.value;
    const newVal = accentUnassignedValue || val ? convertUserForSubmitData(user) || { username: '' } : { username: '' };

    onSubmit({ [refName]: newVal });
  };

  /**
   * Render assign to field
   */
  const renderAssignToField = ({ field, val, label, currentUser, userList, noResults, handleSubmit }) => {
    return (
      <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
        <EditableSelectOption
          restValueText={{ id: `value_${field?.refName}-field` }}
          restEditButton={{ id: `edit-button_${field?.refName}-field` }}
          defaultVal={val?.id || UNASSIGNED.value}
          options={noResults ? [] : [UNASSIGNED, ...currentUser, ...userList]}
          restField={{
            showSearch: true,
            allowClear: !field?.mandatory,
            dropdownMatchSelectWidth: false,
            loading: loadingAssignableUserList,
            onFocus: () => onSearch('', field?.refName)
          }}
          placeholder={t('common.searchEmail')}
          isReadOnly={isReadOnly}
          onSearch={keyword => onSearch(keyword, field?.refName)}
          onSelect={(val, option) => handleSubmit(field?.refName, val, option?.user)}
        />
      </Form.Item>
    );
  };

  /**
   * Render available insert fields
   */
  const renderAvailableInsertFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const val = objectPath.get(editingItem, field?.refName);
    const currentUser = val?.id ? [{ label: <UserAvatar user={val} />, value: val?.id, user: val }] : [];

    const userList =
      Array.isArray(assignableUserList) && assignableUserList.length
        ? [...assignableUserList]
            .filter(u => u?.id !== val?.id)
            .map(u => ({ label: <UserAvatar user={u} />, value: u.id, user: u }))
        : [];

    const noResults = keywords?.[field?.refName] && !(Array.isArray(assignableUserList) && assignableUserList.length);

    switch (field?.refName) {
      case SYSTEM_FIELD_ASSIGN_TO: {
        return renderAssignToField({ field, val, label, currentUser, userList, noResults, handleSubmit });
      }

      default: {
        return (
          <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              defaultVal={val?.id}
              options={noResults ? [] : [...currentUser, ...userList]}
              restField={{
                showSearch: true,
                allowClear: !field?.mandatory,
                dropdownMatchSelectWidth: false,
                loading: loadingAssignableUserList,
                onFocus: () => onSearch('', field?.refName)
              }}
              placeholder={t('common.searchEmail')}
              isReadOnly={isReadOnly}
              onClear={() => handleSubmit(field?.refName, null, null)}
              onSearch={keyword => onSearch(keyword, field?.refName)}
              onSelect={(val, option) => handleSubmit(field?.refName, val, option?.user)}
            />
          </Form.Item>
        );
      }
    }
  };

  /**
   * Render readonly fields
   */
  const renderReadonlyFields = field => {
    if (!field?.refName) {
      return;
    }

    const labelText = getFieldName(field);
    const label = (
      <span id={`label_${field?.refName}-field`} title={labelText}>
        {labelText}
      </span>
    );
    const val = objectPath.get(editingItem, field?.refName);

    return (
      <Form.Item key={field?.refName} label={label} className="label-truncate mb-0">
        <div id={`value_${field?.refName}-field`}>
          {val?.id ? <UserAvatar user={val} /> : <span className="text-gray">N/A</span>}
        </div>
      </Form.Item>
    );
  };

  return (
    <div
      id="anchorPeople"
      className={`c-detail-components type-people for-external-system-jira collapse-item mb-4 ${className}`}
      {...rest}
    >
      <h5 className="btn-toggle ant-typography mb-0">
        <span id="toggle-people-button" onClick={() => onChangeCollapse('anchorPeople')}>
          <>{collapseKeys.includes('anchorPeople') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
          <span>{t('anchor.anchorPeople')}</span>
        </span>
      </h5>

      <div className={`collapse-body ${collapseKeys.includes('anchorPeople') ? 'show' : ''}`}>
        <div className="pt-2 pb-2">
          {Array.isArray(fields) &&
            fields.length > 0 &&
            fields.map(field => (field?.isInsert ? renderAvailableInsertFields(field) : renderReadonlyFields(field)))}
        </div>
      </div>
    </div>
  );
};

export default BoxJiraPeople;
