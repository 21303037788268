import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TEST_PLAN_WORKFLOW } from '../../../constants';

const useTestPlanWorkflow = () => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [testPlanWorkflow, setTestPlanWorkflow] = useState(null);

  /**
   * Set editing test case to form
   */
  useEffect(() => {
    if (
      !(Array.isArray(TEST_PLAN_WORKFLOW?.listStates) && TEST_PLAN_WORKFLOW?.listStates.length) ||
      !(Array.isArray(TEST_PLAN_WORKFLOW?.listSteps) && TEST_PLAN_WORKFLOW?.listSteps.length)
    ) {
      return;
    }

    const listStates = TEST_PLAN_WORKFLOW.listStates.map(item => {
      return {
        ...item,
        name: t(item.name)
      };
    });

    const listSteps = TEST_PLAN_WORKFLOW.listSteps.map(item => {
      return {
        ...item,
        name: t(item.name)
      };
    });

    setTestPlanWorkflow({ listStates, listSteps });
  }, []);

  return [testPlanWorkflow];
};

export default useTestPlanWorkflow;
