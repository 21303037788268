import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Tooltip } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import {
  DATA_TYPE,
  FIELD_STEP,
  COMPONENT_TYPE,
  SS_NEW_TEST_STEPS,
  FIELD_ATTACHMENTS,
  TEST_STEP_FIELD_LIST,
  SYSTEM_FIELD_ORDER_ID,
  SYSTEM_FIELD_ATTACHMENTS
} from '../../../constants';
import { ABOVE, BELOW } from '../test-step-draggable-row-table-constants';
import { Drag2 } from '../../../assets/svg-icons';
import {
  debounce,
  jsonParse,
  resetErrorFields,
  getResizeColumns,
  reactSessionStorage,
  generateTestStepKey,
  toggleEditingClassToBody,
  removeSessionForEditingCell,
  toggleEditingClassToTestStepRow,
  toggleEditingTestStepClassToBody,
  toggleEditingClassToExpandedTable
} from '../../../common/utils';
import { useFile } from '../../../common/hooks';
import { BasicTable, EditableCell, ResizableColumn, EditableCellComponentHelpers } from '../..';
import TopToolBtns from './top-tool-btns';
import BottomToolBtns from './bottom-tool-btns';
import RowAction from './row-action';

const BoxTestStepTable = ({
  workTicketId,
  fromModule,
  form = {},
  editingItemKey,
  currentTestStepList,
  onChangeTestStepList,
  uploadPath,
  hasGetFullFilesInfo,
  directlyDeleteAttachment = true,
  onChangeDeteledAttachmentIds,
  loading = false,
  isReadOnly,
  className = '',
  ...rest
}) => {
  const timerRef = useRef({});

  // For language
  const [t] = useTranslation('akaat');

  // For file
  const { deleteTestStepAttachments } = useFile();

  // For global store
  const getFullFilesInfo = useStoreActions(action => action.global.getFullFilesInfo);
  const globalFullFilesInfo = useStoreState(state => state.global.globalFullFilesInfo);

  // Component state
  const [testStepList, setTestStepList] = useState([]);
  const [isSetTestStepListWhenMounted, setIsSetTestStepListWhenMounted] = useState(false);
  const [columns, setColumns] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clipboardSteps, setClipboardSteps] = useState([]);

  /**
   * Parent key
   * Clipboard step keys
   */
  const parentKey = useMemo(() => editingItemKey || 'new', [editingItemKey]);
  const clipboardStepKeys = useMemo(() => {
    return Array.isArray(clipboardSteps) && clipboardSteps.length ? [...clipboardSteps].map(s => s?.key) : [];
  }, [clipboardSteps]);

  /**
   * Handle set test step list
   */
  const handleSetTestStepList = ({ testSteps, parentKey }) => {
    if (!parentKey) {
      return;
    }

    let newTestStepList = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];
    newTestStepList = newTestStepList.map((item, index) => {
      const testStepXNumber = index + 1 + 1000000000;

      return {
        ...item,
        [SYSTEM_FIELD_ORDER_ID]: index + 1,
        testStepXNumber,
        parentKey
      };
    });

    setTestStepList(newTestStepList);
  };

  /**
   * Handle emit test step list
   */
  const handleEmitTestStepList = testSteps => {
    let newTestStepList = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];

    if (newTestStepList.every(s => s?.isNew)) {
      return;
    }

    newTestStepList = newTestStepList
      .filter(s => !s?.isNew && s?.[FIELD_STEP])
      .map(s => {
        const newItem = { ...s };

        delete newItem.key;
        delete newItem.parentKey;
        delete newItem.testStepXNumber;

        return newItem;
      });

    onChangeTestStepList(newTestStepList);
  };

  /**
   * Get all files info
   */
  const getAllFilesInfo = async testSteps => {
    if (!(Array.isArray(testSteps) && testSteps)) {
      return;
    }

    const newTestStepAttachments = testSteps.reduce((accumulator, current) => {
      let attachments = current?.[SYSTEM_FIELD_ATTACHMENTS];
      attachments = Array.isArray(attachments) && attachments.length ? [...attachments] : [];
      return [...accumulator, ...attachments];
    }, []);

    await getFullFilesInfo(newTestStepAttachments);
  };

  /**
   * Only set step list when mounted
   */
  useEffect(() => {
    if (!parentKey || !(Array.isArray(currentTestStepList) && currentTestStepList.length)) {
      return;
    }

    if (!isSetTestStepListWhenMounted) {
      const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
      let sessionNewTestSteps = newTestStepsInSession[parentKey];
      sessionNewTestSteps =
        Array.isArray(sessionNewTestSteps) && sessionNewTestSteps.length ? [...sessionNewTestSteps] : [];

      handleSetTestStepList({ testSteps: [...currentTestStepList, ...sessionNewTestSteps], parentKey });
      setIsSetTestStepListWhenMounted(true);
    }
  }, [currentTestStepList, parentKey, isSetTestStepListWhenMounted]);

  /**
   * Drag handle
   */
  const DragHandle = SortableHandle(() => (
    <Tooltip
      title={t('common.dragDropToReorder')}
      placement="left"
      destroyTooltipOnHide={true}
      overlayStyle={{ pointerEvents: 'none' }}
    >
      <Drag2 className="btn-drag btn-action-editable-cell text-gray text-hover-primary font-size-12 cursor-move" />
    </Tooltip>
  ));

  /**
   * Compute: Left action column
   */
  const leftActionColumn = useMemo(() => {
    const columns = [
      {
        title: '',
        dataIndex: 'leftActionColumn',
        width: 0,
        className: 'left-utils-column hide-resizable vertical-align-middle px-1',
        render: (value, row) => {
          return (
            !row?.isNew && (
              <div className="btn-action-editable-cell-wrapper">
                <DragHandle />
              </div>
            )
          );
        }
      }
    ];

    return columns;
  }, []);

  /**
   * Compute: Action column
   */
  const actionColumn = useMemo(() => {
    const columns = [
      {
        dataIndex: 'action',
        title: '',
        width: 100,
        fixed: 'right',
        className: 'action text-nowrap text-right px-1',
        hideOnColumnChooser: true,
        render: (value, row, index) => {
          return (
            <RowAction
              row={row}
              index={index}
              clipboardStepKeys={clipboardStepKeys}
              inClipboard={clipboardStepKeys.includes(row?.key)}
              onCopyOne={onCopyOne}
              onPasteOne={onPasteOne}
              onDeleteMultipleRows={onDeleteMultipleRows}
            />
          );
        }
      }
    ];

    return columns;
  }, [form, testStepList, clipboardStepKeys]);

  /**
   * Handle change editing cell
   */
  const onChangeEditingCell = ({ isEditing, field, tableForm, parentKey, x }) => {
    if (!field?.refName) {
      return;
    }

    toggleEditingClassToTestStepRow({ isEditing, x });

    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(
      debounce(() => {
        if (isEditing && Array.isArray(selectedRowKeys) && selectedRowKeys.length) {
          setSelectedRows([]);
          setSelectedRowKeys([]);
        }

        if (isEditing && clipboardStepKeys.length) {
          setClipboardSteps([]);
        }

        if (isEditing) {
          resetErrorFields(tableForm);
        }

        toggleEditingClassToBody({ isEditing });
        toggleEditingTestStepClassToBody({ isEditing });
        toggleEditingClassToExpandedTable({ isEditing, parentKey });
        removeSessionForEditingCell({ isEditing, field });
      }),
      200
    );
  };

  /**
   * On save test step
   */
  const onSave = async ({ formData, row, field }) => {
    if (!formData || !row?.key || !field) {
      return;
    }

    const newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
    const index = newTestStepList.findIndex(r => r?.key === row.key);

    if (index === -1) {
      return;
    }

    newTestStepList[index][field.refName] = formData[field.refName];

    // Set values
    handleEmitTestStepList(newTestStepList);

    // Get all files info
    if (field.componentType === COMPONENT_TYPE.ATTACHMENTS) {
      getAllFilesInfo(newTestStepList);
    }
  };

  /**
   * Handle after set new values to session
   */
  const handleAfterSetNewValuesToSession = ({ formData, row, field }) => {
    if (!formData || !row?.key || !field) {
      return;
    }

    if (field?.componentType === COMPONENT_TYPE.ATTACHMENTS) {
      const newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
      const index2 = newTestStepList.findIndex(r => r?.key === row.key);

      if (index2 !== -1) {
        newTestStepList[index2][field.refName] = formData[field.refName];

        handleSetTestStepList({ testSteps: newTestStepList, parentKey });
        getAllFilesInfo(newTestStepList);
      }
    }
  };

  /**
   * On set new values to session
   */
  const onSetNewValuesToSession = ({ formData, row, field }) => {
    if (!formData || !row?.key || !field) {
      return;
    }

    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});

    let newSessionTestStepList = newTestStepsInSession[parentKey];
    newSessionTestStepList =
      Array.isArray(newSessionTestStepList) && newSessionTestStepList.length ? [...newSessionTestStepList] : [];

    const index = newSessionTestStepList.findIndex(r => r?.key === row.key);

    if (index !== -1) {
      newSessionTestStepList[index][field.refName] = formData[field.refName];
    } else {
      const newStep = { key: row.key, isNew: true, ...formData };
      newSessionTestStepList = [...newSessionTestStepList, newStep];
    }

    const newRecordList = { ...newTestStepsInSession, [parentKey]: newSessionTestStepList };

    reactSessionStorage.setObject(SS_NEW_TEST_STEPS, newRecordList);
    handleAfterSetNewValuesToSession({ formData, row, field });
  };

  /**
   * All of test step columns for table
   */
  useEffect(() => {
    const orderIdColumn = {
      dataIndex: SYSTEM_FIELD_ORDER_ID,
      title: t('workItem.id'),
      width: 50,
      ellipsis: true,
      className: SYSTEM_FIELD_ORDER_ID,
      render: (value, row) => (
        <div className="c-editable-cell">
          <div title={value} className="cell-text">
            <span className={`cell-text-value text-truncate ${row?.isNew ? 'pt-2' : ''}`}>{value}</span>
          </div>
        </div>
      )
    };

    const attachmentsColumn = {
      name: 'workItem.attachments',
      refName: FIELD_ATTACHMENTS,
      dataType: DATA_TYPE.ARRAY,
      componentType: COMPONENT_TYPE.ATTACHMENTS,
      mandatory: false,
      isInsert: true
    };

    const middleColumns = TEST_STEP_FIELD_LIST.filter(field => field?.refName !== SYSTEM_FIELD_ORDER_ID)
      .concat([attachmentsColumn])
      .map((field, index) => {
        if (!field?.refName) {
          return;
        }

        const title = t(field.name);

        return {
          dataIndex: field.refName,
          title: <span className={`${field.mandatory ? 'has-required-prefix-icon' : ''} text-truncate`}>{title}</span>,
          plainTitle: title,
          width: field.refName === FIELD_ATTACHMENTS ? 210 : 200,
          className: field.refName,
          render: (value, row) => {
            const x = row?.testStepXNumber + 1000000;
            const isTextPreLine = field?.componentType === COMPONENT_TYPE.HTML;

            return (
              <EditableCell
                workTicketId={workTicketId}
                x={x}
                y={index + 1}
                row={row}
                field={{ ...field, isTextPreLine }}
                tableForm={form}
                isReadOnly={isReadOnly}
                restAttachmentsField={{
                  uploadPath,
                  hasGetFullFilesInfo,
                  maxCount: 4,
                  directlyDeleteAttachment,
                  onChangeDeteledAttachmentIds
                }}
                onSave={info => {
                  if (row?.isNew) {
                    onSetNewValuesToSession(info);
                  } else {
                    onSave(info);
                  }
                }}
                onChangeEditingCell={editingCell => {
                  const isEditing = editingCell?.x && editingCell?.y;
                  onChangeEditingCell({ isEditing, field, form, parentKey, x });
                }}
              />
            );
          }
        };
      });

    let allColumns = [orderIdColumn, ...middleColumns];

    if (!isReadOnly) {
      allColumns = [...leftActionColumn, orderIdColumn, ...middleColumns, ...actionColumn];
    }

    setColumns(allColumns);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    t,
    form,
    workTicketId,
    parentKey,
    testStepList,
    clipboardSteps,
    clipboardStepKeys,
    selectedRowKeys,
    isReadOnly,
    globalFullFilesInfo,
    uploadPath,
    directlyDeleteAttachment
  ]);

  /**
   * On copy step
   */
  const onCopyOne = ({ row, inClipboard }) => {
    if (!row?.key) {
      return;
    }

    if (inClipboard) {
      setClipboardSteps([...clipboardSteps.filter(s => s?.key !== row?.key)]);
    } else {
      setClipboardSteps([...clipboardSteps, row]);
    }
  };

  /**
   * On paste one
   */
  const onPasteOne = ({ row, index, type }) => {
    if (!row?.key || !(type === ABOVE || type === BELOW)) {
      return;
    }

    let newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
    const newSteps = [...clipboardSteps].map(item => {
      return {
        ...item,
        key: generateTestStepKey(parentKey),
        [FIELD_ATTACHMENTS]: []
      };
    });

    if (type === ABOVE) {
      newTestStepList.splice(index, 0, ...newSteps);
    } else if (type === BELOW) {
      newTestStepList.splice(index + 1, 0, ...newSteps);
    } else {
    }

    newTestStepList = newTestStepList.map((s, idx) => ({ ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 }));

    // Set values
    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    handleEmitTestStepList(newTestStepList);

    // Reset
    setClipboardSteps([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  /**
   * On paste multi
   * For copy and paste
   */
  const onPasteMulti = async type => {
    if (!(type === ABOVE || type === BELOW)) {
      return;
    }

    let newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
    const newSteps = [...clipboardSteps].map(item => {
      return {
        ...item,
        key: generateTestStepKey(parentKey),
        [FIELD_ATTACHMENTS]: null
      };
    });

    if (type === ABOVE) {
      newTestStepList = [...newSteps, ...testStepList];
    } else if (type === BELOW) {
      const newItems = newTestStepList.filter(s => s?.isNew);
      const oldItems = newTestStepList.filter(s => !s?.isNew);

      newTestStepList = [...oldItems, ...newSteps, ...newItems];
    } else {
    }

    newTestStepList = newTestStepList.map((s, idx) => ({ ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 }));

    // Set values
    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    handleEmitTestStepList(newTestStepList);

    // Reset
    setClipboardSteps([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  /**
   * Handle delete test step in session
   */
  const handleDeleteTestStepInSession = rows => {
    if (!(Array.isArray(rows) && rows.length)) {
      return;
    }

    const deletedStepKeys = [...rows].map(s => s?.key);

    const newTestStepsInSession = reactSessionStorage.getObject(SS_NEW_TEST_STEPS, {});
    let sessionNewTestSteps = newTestStepsInSession[parentKey];
    sessionNewTestSteps =
      Array.isArray(sessionNewTestSteps) && sessionNewTestSteps.length ? [...sessionNewTestSteps] : [];

    const newTestSteps = sessionNewTestSteps.filter(s => !deletedStepKeys.includes(s?.key));
    newTestStepsInSession[parentKey] = newTestSteps;

    reactSessionStorage.setObject(SS_NEW_TEST_STEPS, newTestStepsInSession);
  };

  /**
   * Handle delete test step attachments
   */
  const handleDeleteTestStepAttachments = testSteps => {
    deleteTestStepAttachments({ testSteps, directlyDeleteAttachment, onChangeDeteledAttachmentIds });
  };

  /**
   * On delete one
   */
  const onDeleteMultipleRows = rows => {
    if (!(Array.isArray(rows) && rows.length)) {
      return;
    }

    let newTestStepList = Array.isArray(testStepList) && testStepList.length ? [...testStepList] : [];
    const deletedStepKeys = [...rows].map(s => s?.key);

    newTestStepList = newTestStepList
      .filter(s => !deletedStepKeys.includes(s?.key))
      .map((s, idx) => ({ ...s, [SYSTEM_FIELD_ORDER_ID]: idx + 1 }));

    // Set values
    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    handleEmitTestStepList(newTestStepList);
    handleDeleteTestStepInSession(rows);
    handleDeleteTestStepAttachments(rows);

    // Reset
    setClipboardSteps([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  /**
   * On sort end
   */
  const onSortEnd = async dropInfo => {
    if (!dropInfo || dropInfo.oldIndex === dropInfo.newIndex || dropInfo.oldIndex === -1 || dropInfo.newIndex === -1) {
      return;
    }

    let newTestStepList =
      Array.isArray(testStepList) && testStepList.length ? jsonParse(JSON.stringify(testStepList)) : [];
    const indexOfIsFirstNewRow = newTestStepList.findIndex(item => item?.isNew);

    // Disable drag bellow isNew row
    if (indexOfIsFirstNewRow !== -1 && dropInfo?.newIndex >= indexOfIsFirstNewRow) {
      return;
    }

    newTestStepList = arrayMoveImmutable(newTestStepList, dropInfo?.oldIndex, dropInfo?.newIndex);
    newTestStepList = newTestStepList.map((item, idx) => ({ ...item, [SYSTEM_FIELD_ORDER_ID]: idx + 1 }));

    // Set values
    handleSetTestStepList({ testSteps: newTestStepList, parentKey });
    handleEmitTestStepList(newTestStepList);

    // Reset
    setClipboardSteps([]);
    setSelectedRowKeys([]);
    setSelectedRows([]);
  };

  /**
   * Sortable item
   */
  const SortableItem = SortableElement(props => <tr {...props} />);

  /**
   * Sortable body
   */
  const SortableBody = SortableContainer(props => <tbody {...props} />);

  /**
   * Droppable table body
   */
  const DroppableTableBody = props => {
    return (
      <SortableBody
        useDragHandle
        helperClass="row-dragging-test-step-on-editable-cell-table"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
  };

  /**
   * Dragable table row
   */
  const DragableTableRow = (rowProps, parentKey) => {
    let index = rowProps?.['data-row-key'];
    index = !isNaN(index) ? +index - 1 : 0;

    return <SortableItem index={index} data-parent-row-key={parentKey} {...rowProps} />;
  };

  /**
   * Row selection
   */
  const rowSelection = !isReadOnly
    ? {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: (rowKeys, rows) => {
          setSelectedRowKeys(rowKeys);
          setSelectedRows(rows);
          setClipboardSteps([]);
        },
        getCheckboxProps: record => ({
          disabled: record?.isNew ? 'd-none' : '',
          className: record?.isNew ? 'd-none' : ''
        })
      }
    : null;

  /**
   * Return html
   */
  if (!parentKey) {
    return;
  }

  return (
    <>
      <div
        data-parent-row-key={parentKey}
        className={`c-test-step-table children-table-on-expanded-row ${className}`}
        {...rest}
      >
        {!isReadOnly && (
          <TopToolBtns
            selectedRows={selectedRows}
            clipboardSteps={clipboardSteps}
            clipboardStepKeys={clipboardStepKeys}
            setClipboardSteps={setClipboardSteps}
            onPasteMulti={onPasteMulti}
            onDeleteMultipleRows={onDeleteMultipleRows}
          />
        )}

        <BasicTable
          rowKey={SYSTEM_FIELD_ORDER_ID}
          columns={getResizeColumns({ activeColumns: columns, setActiveColumns: setColumns })}
          data={testStepList}
          pagination={false}
          loading={loading}
          components={{
            header: {
              cell: ResizableColumn
            },
            body: {
              wrapper: DroppableTableBody, // For drag and drop
              row: rowProps => DragableTableRow(rowProps, parentKey) // For drag and drop,
            }
          }}
          rowSelection={rowSelection}
          rowClassName={(record, index) => (record?.isNew ? 'is-new-row' : '')}
          className={`editable-cells-table show-required-icon-on-title-column ${
            !isReadOnly ? 'has-drag-drop-to-order' : ''
          }`}
        />

        {!isReadOnly && (
          <>
            <BottomToolBtns
              fromModule={fromModule}
              form={form}
              parentKey={parentKey}
              testStepList={testStepList}
              handleSetTestStepList={handleSetTestStepList}
              handleEmitTestStepList={handleEmitTestStepList}
              handleDeleteTestStepAttachments={handleDeleteTestStepAttachments}
            />

            <EditableCellComponentHelpers tableForm={form} disableScrollToFocusedElement={true} />
          </>
        )}
      </div>
    </>
  );
};

export default BoxTestStepTable;
