import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import './style.scss';

export const MainFooter = ({ className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  return (
    <div className={`c-main-footer ${className}`} {...rest}>
      <Row justify="space-between">
        <Col>
          <div className="txt-copyright">
            <a href="https://akaat.com/" target="_blank" rel="noopener noreferrer">
              akaAT
            </a>{' '}
            {t('footer.copyright', { year: new Date().getFullYear() })}
          </div>
        </Col>

        <Col>
          <div className="txt-powered">
            <span className="txt-powered-by">{t('footer.poweredBy')}</span>{' '}
            <span className="powered font-weight-medium">IVS.PRO-FPTSoftware</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
