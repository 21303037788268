import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _debounce from 'lodash/debounce';
import { Button, Dropdown, Input, Row, Tooltip } from 'antd';
import { SearchOutlined, CloseCircleFilled } from '@ant-design/icons';

import { usePermission } from '../../../common/hooks';
import { checkIsNotEmptyObject, getActiveProjectKeys } from '../../../common/utils';
import { Filter, PlusOutlined } from '../../../assets/svg-icons';
import BoxFilterBtns from './box-filter-btns';

const DEFAULT_FILTERS = {
  keyword: '',
  status: 'ALL',
  userExistsInProjectStatus: null
};

const BoxProjectActions = ({ className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For hooks
  const { hasTenantSettingProjectSetupPermission } = usePermission();

  // For global store
  const setFilteredMyProjectList = useStoreActions(action => action.global.setFilteredMyProjectList);
  const filteredMyProjectList = useStoreState(state => state.global.filteredMyProjectList);
  const myProjectList = useStoreState(state => state.global.myProjectList);
  const myProjectsMoreInfo = useStoreState(state => state.global.myProjectsMoreInfo);

  // Component state
  const [projectFilters, setProjectFilters] = useState(null);
  const [productCountInfo, setProductCountInfo] = useState(null);
  const [currentKeyword, setCurrentKeyword] = useState('');

  /**
   * Compute: Active project keys
   */
  const activeProjectKeys = useMemo(() => {
    const newActiveProjectKeys = getActiveProjectKeys(myProjectList);

    return Array.isArray(newActiveProjectKeys) && newActiveProjectKeys.length ? newActiveProjectKeys : [];
  }, [myProjectList]);

  /**
   * Get filtered my project list 1
   */
  const getFilteredMyProjectList1 = ({ newMyProjectList, newFilter, keyword }) => {
    if (!(Array.isArray(newMyProjectList) && newMyProjectList.length)) {
      return;
    }

    const newList = newMyProjectList.filter(item => {
      const projectKey = typeof item?.projectKey === 'string' ? item?.projectKey : '';
      const name = typeof item?.name === 'string' ? item?.name : '';

      const filter1 =
        name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
        projectKey.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
      let filter2 = false;

      if (newFilter?.userExistsInProjectStatus === 'UNLOCK') {
        filter2 = myProjectsMoreInfo?.[projectKey]?.userExistsInProject;
      } else if (newFilter?.userExistsInProjectStatus === 'LOCK') {
        filter2 = !myProjectsMoreInfo?.[projectKey]?.userExistsInProject;
      } else {
        filter2 = true;
      }

      return filter1 && filter2;
    });

    return newList;
  };

  /**
   * Get filtered my project list 2
   */
  const getFilteredMyProjectList2 = ({ newFilteredMyProjectList1, activeProjectKeys, newFilter }) => {
    if (!(Array.isArray(newFilteredMyProjectList1) && newFilteredMyProjectList1.length)) {
      return;
    }

    const newList = newFilteredMyProjectList1.filter(item => {
      const projectKey = typeof item?.projectKey === 'string' ? item?.projectKey : '';

      let filter = false;

      if (newFilter?.status === 'ACTIVE') {
        filter = activeProjectKeys.includes(projectKey);
      } else if (newFilter?.status === 'INACTIVE') {
        filter = !activeProjectKeys.includes(projectKey);
      } else {
        filter = true;
      }

      return filter;
    });

    return newList;
  };

  /**
   * On change filter
   */
  const onChangeFilter = newFilter => {
    const newMyProjectList = Array.isArray(myProjectList) && myProjectList.length ? [...myProjectList] : [];
    const keyword = typeof newFilter?.keyword === 'string' ? newFilter?.keyword : '';

    // Filter project theo keyword và trạng thái "User exists in project"
    const newFilteredMyProjectList1 = getFilteredMyProjectList1({ newMyProjectList, newFilter, keyword });

    // Tính project count cho tabs
    const activeCount = newFilteredMyProjectList1.reduce((accumulator, current) => {
      return accumulator + (activeProjectKeys.includes(current?.projectKey) ? 1 : 0);
    }, 0);
    const newProductCountInfo = {
      all: newFilteredMyProjectList1.length,
      active: activeCount,
      inactive: newFilteredMyProjectList1.length - activeCount
    };

    // Filter project khi thay đổi tab
    const newFilteredMyProjectList2 = getFilteredMyProjectList2({
      newFilteredMyProjectList1,
      activeProjectKeys,
      newFilter
    });

    setProjectFilters(newFilter);
    setProductCountInfo(newProductCountInfo);
    setFilteredMyProjectList(newFilteredMyProjectList2);
  };

  /**
   * Filter projects when mounted
   */
  useEffect(() => {
    if (!(Array.isArray(myProjectList) && myProjectList.length)) {
      return;
    }

    onChangeFilter(DEFAULT_FILTERS);
  }, [myProjectList, myProjectsMoreInfo]);

  /**
   * On search
   */
  const onSearch = useCallback(
    _debounce(keyword => onChangeFilter({ ...projectFilters, keyword }), 300),
    [projectFilters, myProjectList, filteredMyProjectList, myProjectsMoreInfo, productCountInfo]
  );

  /**
   * Render user exists in project filter menus
   */
  const renderUserExistsInProjectFilterMenus = () => {
    const items = [
      {
        key: 'unlockProject',
        label: t('home.unlockProject'),
        className: projectFilters?.userExistsInProjectStatus === 'UNLOCK' ? 'active' : '',
        onClick: () => onChangeFilter({ ...projectFilters, userExistsInProjectStatus: 'UNLOCK' })
      },
      {
        key: 'lockProject',
        label: t('home.lockProject'),
        className: projectFilters?.userExistsInProjectStatus === 'LOCK' ? 'active' : '',
        onClick: () => onChangeFilter({ ...projectFilters, userExistsInProjectStatus: 'LOCK' })
      }
    ];

    return items;
  };

  /**
   * Render search input
   */
  const renderSearchInput = props => {
    return (
      <Input
        value={props?.value}
        allowClear={true}
        placeholder={t('common.search')}
        prefix={<SearchOutlined />}
        className="search-project-input my-1 ml-2"
        style={props?.style}
        onClick={e => e.stopPropagation()}
        onChange={e => {
          onSearch(e?.target?.value || '');
          setCurrentKeyword(e?.target?.value || '');
        }}
      />
    );
  };

  return (
    <>
      <Row
        wrap={false}
        align="middle"
        justify="space-between"
        className={`c-box-project-action ${className}`}
        {...rest}
      >
        <Row wrap={false} align="middle">
          <BoxFilterBtns
            projectFilters={projectFilters}
            productCountInfo={productCountInfo}
            onChangeFilter={onChangeFilter}
          />
        </Row>

        <Row wrap={false} align="middle">
          {renderSearchInput({ value: currentKeyword || '' })}

          <Dropdown
            menu={{
              items: [
                {
                  key: 'searchInput',
                  label: renderSearchInput({ value: currentKeyword || '', style: { margin: '0px !important' } }),
                  className: 'p-0'
                }
              ]
            }}
            trigger={['click']}
            destroyPopupOnHide={true}
            placement="bottom"
            overlayClassName="dropdown-user-exists-in-project-filter"
            className="btn-dropdown-search-input-filter my-1 ml-2"
          >
            <Button icon={<SearchOutlined />} />
          </Dropdown>

          {checkIsNotEmptyObject(myProjectsMoreInfo) && (
            <Dropdown
              menu={{ items: renderUserExistsInProjectFilterMenus() }}
              trigger={['click']}
              destroyPopupOnHide={true}
              placement="bottom"
              overlayClassName="dropdown-user-exists-in-project-filter"
              className="btn-dropdown-user-exists-in-project-filter my-1 ml-2"
            >
              <Button icon={<Filter className="ic-filter" />}>
                {projectFilters?.userExistsInProjectStatus && (
                  <Tooltip placement="right" title={t('search.removeThisCondition')} destroyTooltipOnHide={true}>
                    <CloseCircleFilled
                      className="ic-remove text-hover-danger"
                      onClick={e => {
                        e.stopPropagation();
                        onChangeFilter({ ...projectFilters, userExistsInProjectStatus: null });
                      }}
                    />
                  </Tooltip>
                )}
              </Button>
            </Dropdown>
          )}

          {hasTenantSettingProjectSetupPermission && (
            <Button
              id="add-new-project-button"
              icon={<PlusOutlined />}
              type="primary"
              className="my-1 ml-2"
              onClick={() => document.querySelector('.add-new-project-button')?.click()}
            >
              {t('home.addProject')}
            </Button>
          )}
        </Row>
      </Row>
    </>
  );
};

export default BoxProjectActions;
