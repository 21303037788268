import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import objectPath from 'object-path';
import { Dropdown, Modal, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import { JIRA_PLATFORM_ID, SYSTEM_FIELD_STATUS } from '../../constants';
import { checkIsNotEmptyArray, getObjectByValue } from '../../common/utils';
import { useStatus } from '../../common/hooks';
import { ArrowRightLong } from '../../assets/svg-icons';
import { WorkflowDiagramModal } from './workflow-diagram-modal';
import { StatusLabel } from '../status-label';
import { UpdateTicketStatusModal } from '../update-ticket-status-modal';

import './style.scss';

export const StatusLabelForWorkflow = ({
  workTicketId,
  status,
  editingItem,
  defaultWorkflow,
  defaultStepsForCurrentStatus,
  showViewWorkflowButton,
  noShowChangeStatusInWorkflowModal,
  allowChangeStatus = false,
  loading = false,
  isReadOnly = false,
  className = '',
  uploadPath,
  restDropdownTrigger,
  restStatusLabel,
  onEditorAttachFiles,
  onSubmit,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For status
  const { getStatusFromWorkflowById } = useStatus();

  // For workfow store
  const workflowByWorkItem = useStoreState(state => state.workflow.workflowByWorkItem);

  // For jira integration
  const workflowTransitionList = useStoreState(state => state.jiraIntegration.workflowTransitionList);
  const getWorkflowTransitionList = useStoreActions(action => action.jiraIntegration.getWorkflowTransitionList);

  // Component state
  const [workflow, setWorkflow] = useState([]);
  const [currentStatusList, setCurrentStatusList] = useState([]);
  const [currentStepList, setCurrentStepList] = useState([]);
  const [stepsForCurrentStatus, setStepsForCurrentStatus] = useState([]);
  const [currentStep, setCurrentStep] = useState(null);
  const [visibleWorkflowDiagramModal, setVisibleWorkflowDiagramModal] = useState(false);
  const [visibleUpdateTicketStatusModal, onSetVisibleUpdateTicketStatusModal] = useState(false);

  /**
   * Set workflow
   */
  useEffect(() => {
    if (defaultWorkflow) {
      setWorkflow(defaultWorkflow);
    } else if (workflowByWorkItem && workTicketId) {
      setWorkflow(workflowByWorkItem[workTicketId]);
    } else {
    }
  }, [defaultWorkflow, workTicketId, workflowByWorkItem]);

  /**
   * Compute origin status
   */
  const originStatus = useMemo(() => {
    if (editingItem?.externalSystem === JIRA_PLATFORM_ID) {
      return {
        ...status,
        externalSystem: JIRA_PLATFORM_ID
      };
    }
    const newStatus = getStatusFromWorkflowById(status, workflow);

    return newStatus;
  }, [status, workflow, editingItem]);

  /**
   * Set current status list
   * Set current step list
   */
  useEffect(() => {
    if (!workflow) {
      return;
    }

    const newCurrentStatusList =
      Array.isArray(workflow.listStates) && workflow.listStates.length ? workflow.listStates : [];
    const newCurrentStepList = Array.isArray(workflow.listSteps) && workflow.listSteps.length ? workflow.listSteps : [];

    setCurrentStatusList(newCurrentStatusList);
    setCurrentStepList(newCurrentStepList);
  }, [workflow]);

  /**
   * Set steps for current status
   */
  useEffect(() => {
    switch (editingItem?.externalSystem) {
      case JIRA_PLATFORM_ID: {
        const steps = workflowTransitionList.map(item => {
          return {
            from: item.id,
            name: item.name,
            to: item.to.id,
            toStatus: { ...item.to, externalSystem: JIRA_PLATFORM_ID },
            systemType: JIRA_PLATFORM_ID
          };
        });
        setStepsForCurrentStatus(steps);
        break;
      }

      default: {
        if (
          !editingItem?.[SYSTEM_FIELD_STATUS] ||
          !checkIsNotEmptyArray(workflow?.listStates) ||
          !checkIsNotEmptyArray(workflow?.listSteps)
        ) {
          return;
        }

        if (checkIsNotEmptyArray(defaultStepsForCurrentStatus)) {
          setStepsForCurrentStatus(defaultStepsForCurrentStatus);
        } else {
          const currentStatusId = objectPath.get(editingItem, `${SYSTEM_FIELD_STATUS}.id`);
          const status = getObjectByValue(currentStatusId, workflow.listStates, 'id') || workflow.defaultState;

          const steps = [...workflow.listSteps]
            .filter(item => item.from === status?.id)
            .map(item => {
              return {
                ...item,
                toStatus: workflow.listStates.find(s => s.id === item.to)
              };
            });

          setStepsForCurrentStatus(steps);
        }
        break;
      }
    }
  }, [defaultStepsForCurrentStatus, workflow, editingItem, workflowTransitionList]);

  /**
   * On open change status modal
   */
  const onOpenUpdateTicketStatusModal = step => {
    setCurrentStep(step);
    onSetVisibleUpdateTicketStatusModal(true);
  };

  /**
   * On close change status modal
   */
  const onCloseUpdateTicketStatusModal = () => {
    setCurrentStep(null);
    onSetVisibleUpdateTicketStatusModal(false);
  };

  /**
   * On change status
   */
  const onChangeStatus = (formData, step) => {
    if (!formData) {
      return;
    }

    const newFormData = {
      ...formData,
      [SYSTEM_FIELD_STATUS]: step ? step?.toStatus?.id : currentStep?.toStatus?.id
    };

    setTimeout(() => onCloseUpdateTicketStatusModal(), 300);

    onSubmit(newFormData);
  };

  /**
   * On confirm change status
   */
  const onConfirmChangeStatus = step => {
    Modal.confirm({
      content: t('message.areYouSureYouWantToChangeStatus'),
      autoFocusButton: null,
      maskClosable: true,
      zIndex: 1060,
      okText: t('common.ok'),
      cancelText: t('common.cancel'),
      onOk: () => onSubmit({ [SYSTEM_FIELD_STATUS]: step?.to })
    });
  };

  /**
   * Menus
   */
  const menus = useMemo(() => {
    const stepList = Array.isArray(stepsForCurrentStatus) ? [...stepsForCurrentStatus] : [];

    const items = [];

    if (Array.isArray(stepList) && stepList.length) {
      stepList.forEach(step => {
        items.push({
          key: `${step?.from}-${step?.to}`,
          id: `from-status-${step?.from}_to-status-${step?.to}`,
          label: (
            <>
              <span className="col-step">{t(step?.name)}</span>
              <span className="col-arrow">
                <ArrowRightLong className="mx-2" />
              </span>
              <span className="col-status">
                <StatusLabel status={step?.toStatus} />
              </span>
            </>
          ),
          className: 'p-0',
          onClick: () => {
            if (editingItem?.externalSystem === JIRA_PLATFORM_ID) {
              setCurrentStep(step);
              onChangeStatus({ assignTo: editingItem?.assignTo }, step);
              return;
            }

            if (noShowChangeStatusInWorkflowModal) {
              onConfirmChangeStatus(step);
            } else {
              onOpenUpdateTicketStatusModal(step);
            }
          }
        });
      });
    }

    return items;
  }, [editingItem, stepsForCurrentStatus, noShowChangeStatusInWorkflowModal]);

  return (
    <>
      <Row align="middle" className={`c-status-label-for-workflow ${className}`} {...rest}>
        {checkIsNotEmptyArray(stepsForCurrentStatus) && allowChangeStatus && !isReadOnly ? (
          <Dropdown
            menu={{ items: menus, selectable: false }}
            trigger={['click']}
            destroyPopupOnHide={true}
            placement="bottomLeft"
            overlayClassName="c-status-label-for-workflow-dropdown ant-dropdown-menu-p-0"
            onOpenChange={visible => {
              if (visible && editingItem?.externalSystem === JIRA_PLATFORM_ID && editingItem.source.jira.id) {
                getWorkflowTransitionList(editingItem.source.jira.id);
              }
            }}
          >
            <Row align="middle" {...restDropdownTrigger}>
              <StatusLabel status={originStatus} className="cursor-pointer" {...restStatusLabel} />
              <CaretDownOutlined className="font-size-12 text-gray cursor-pointer" />
            </Row>
          </Dropdown>
        ) : (
          <StatusLabel status={originStatus} />
        )}

        {showViewWorkflowButton && editingItem?.externalSystem !== JIRA_PLATFORM_ID && (
          <div className="ml-1" style={{ marginTop: '-0.2em' }}>
            <span
              id="view-workflow-button"
              className="font-size-12 cursor-pointer text-primary over"
              onClick={() => setVisibleWorkflowDiagramModal(true)}
            >
              ({t('common.viewWorkflow')})
            </span>
          </div>
        )}
      </Row>

      {visibleWorkflowDiagramModal && (
        <WorkflowDiagramModal
          visible={visibleWorkflowDiagramModal}
          currentStatusList={currentStatusList}
          currentStepList={currentStepList}
          onCancel={() => setVisibleWorkflowDiagramModal(false)}
        />
      )}

      {visibleUpdateTicketStatusModal && (
        <UpdateTicketStatusModal
          visible={visibleUpdateTicketStatusModal}
          workflow={workflow}
          currentStep={currentStep}
          editingItem={editingItem}
          uploadPath={uploadPath}
          loading={loading}
          onChangeStatus={onChangeStatus}
          onEditorAttachFiles={onEditorAttachFiles}
          onCancel={onCloseUpdateTicketStatusModal}
        />
      )}
    </>
  );
};
