import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  SYSTEM_FIELD_STATUS,
  SS_LAST_SAVED_VALUE,
  SS_EDITING_CELL,
  SS_LOADED_USER_LIST_BY_ROLE_INFO
} from '../../../../constants';
import {
  getObjectByValue,
  reactSessionStorage,
  checkIsUserInProject,
  filterOptionForUserField,
  convertUserForSubmitData,
  getUserListByRoleFromStore,
  handleSetLastSavedValueToSession
} from '../../../../common/utils';
import { useEditableCell, useUnassignedUser } from '../../../../common/hooks';
import { checkIsEditableCell, onOutsideClick } from '../../editable-cell-utils';
import { UserAvatar } from '../../../../components';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import AssignToKeyboardEvents from './assign-to-keyboard-events';
import AssignToFormItem from './assign-to-form-item';

const AssignToField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  onGetUserListByRole,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Use editable cell
  const { getLastUserOptionInSessionStorage } = useEditableCell();

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  // For user list by role store
  const userListByRole = useStoreState(state => state.global.userListByRole);
  const loadingUserListByRole = useStoreState(state => state.global.loadingUserListByRole);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [triggerRender, setTriggerRender] = useState(0);

  /**
   * Get last role keys
   */
  const getLastRoleKeys = ({ row, workTicketId, ticketListData }) => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastStatus = lastSavedValue[`key-${row?.key}-${SYSTEM_FIELD_STATUS}`];
    let listStates = ticketListData?.[workTicketId]?.workFlow?.listStates;
    listStates = Array.isArray(listStates) && listStates.length ? [...listStates] : [];
    let currentStatus = null;

    if (lastStatus !== undefined) {
      currentStatus = listStates.find(item => item?.id === lastStatus);
    } else {
      currentStatus = listStates.find(item => item?.id === row?.[SYSTEM_FIELD_STATUS]?.id);
    }

    const roleKeys =
      Array.isArray(currentStatus?.assignmentRoles) && currentStatus?.assignmentRoles.length
        ? [...currentStatus?.assignmentRoles]
        : [];

    return roleKeys;
  };

  /**
   * On change editing cell
   */
  useEffect(() => {
    typeof onChangeEditingCell === 'function' && onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Val
   */
  const val = useMemo(() => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : objectPath.get(row, field?.refName);
  }, [row, field, formItemName]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const roleKeys = getLastRoleKeys({ row, workTicketId, ticketListData });
    const newUserListByRole = getUserListByRoleFromStore({ roleKeys, userListByRole });
    const isUserInProject = checkIsUserInProject({ user: val, projectUserList });

    const currentUser = val?.username
      ? [
          {
            label: <UserAvatar user={val} inactive={!isUserInProject} />,
            value: val?.username,
            disabled: !isUserInProject,
            user: val
          }
        ]
      : [];

    const userList = [...newUserListByRole]
      .filter(u => u?.username !== val?.username)
      .map(u => ({ label: <UserAvatar user={u} />, value: u?.username, user: u }));

    const newOptions = [UNASSIGNED, ...currentUser, ...userList];

    return newOptions;
  }, [val, workTicketId, row, field, userListByRole, ticketListData]);

  /**
   * Handle get user list by role
   */
  const handleGetUserListByRole = () => {
    const roleKeys = getLastRoleKeys({ row, workTicketId, ticketListData });
    const roleKeysString = JSON.stringify(roleKeys);
    const roleKeysInSession = reactSessionStorage.getObject(SS_LOADED_USER_LIST_BY_ROLE_INFO, {});

    // Disable multiple call api
    if (Object.keys(roleKeysInSession).includes(roleKeysString)) {
      return;
    }

    typeof onGetUserListByRole === 'function' && onGetUserListByRole({ roleKeys });

    reactSessionStorage.setObject(SS_LOADED_USER_LIST_BY_ROLE_INFO, {
      ...roleKeysInSession,
      [roleKeysString]: 'getted'
    });
  };

  /**
   * When dropdown is open: Get user list by role
   */
  useEffect(() => {
    if (!row?.isNew && isOpenDropdown) {
      setTimeout(() => handleGetUserListByRole(), 100);
    }
  }, [isOpenDropdown, workTicketId, row, field, ticketListData]);

  /**
   * When isNew: Get user list by role
   */
  useEffect(() => {
    if (row?.isNew) {
      handleGetUserListByRole();
    }
  }, [row, field, ticketListData]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!val?.username || !(Array.isArray(options) && options.length)) {
      return null;
    }

    return getObjectByValue(val.username, options);
  }, [val, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastOption = getLastUserOptionInSessionStorage({ formItemName, projectUserList });
    return lastOption !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') {
      return;
    }

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });

    reactSessionStorage.setObject(SS_EDITING_CELL, { x, y, hasOpenedSelectDropdown: true });
  };

  /**
   * Handle open when has opened
   */
  useEffect(() => {
    const editingCellLocal = reactSessionStorage.getObject(SS_EDITING_CELL, {});

    if (editingCellLocal?.x === x && editingCellLocal?.y === y && editingCellLocal?.hasOpenedSelectDropdown) {
      handleOpen();
    }
  }, [x, y]);

  /**
   * Handle close
   */
  const handleClose = opt => {
    if (row?.isNew) {
      setIsOpenDropdown(false);
      setCurrentOption(opt);
      return;
    }

    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value === UNASSIGNED.value && !defaultOption?.value) {
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const newVal = convertUserForSubmitData(opt?.user);
      handleSetLastSavedValueToSession({ [formItemName]: newVal });

      typeof onSave === 'function' && onSave({ formData: { [field?.refName]: newVal }, row, field });
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    if (row?.isNew) {
      setIsOpenDropdown(visible);
      return;
    }

    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    open: isOpenDropdown,
    options,
    filterOption: filterOptionForUserField,
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    placeholder: placeholder || t('common.search'),
    loading: loadingUserListByRole,
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => {
      if (!row?.isNew) {
        handleSave(option);
      }
    },
    onClear: () => {
      if (!row?.isNew) {
        handleSave(null);
      }
    },
    onChange: (val, option) => {
      if (row?.isNew) {
        handleSave(option);
      }
    },
    onClick: () => {
      if (!row?.isNew) {
        handleOpen();
      }
    }
  };

  /**
   * Render cell text utils
   */
  const renderCellTextUtilEditable = ({ editingCell, label, props }) => {
    return (
      <div
        className={`cell-text cursor-text ${editingCell?.x && editingCell?.y ? '' : 'hide-after'}`}
        onClick={handleOpen}
      >
        <span className="cell-text-value min-h-22 text-truncate">{label || props?.placeholder}</span>
      </div>
    );
  };

  const renderCellTextUtilRelationField1 = ({ label, row, field }) => {
    return (
      <RelationLink row={row} field={field}>
        {label}
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ label, row, field }) => {
    return (
      <div className="cell-text cursor-default hide-after">
        <RelationLink row={row} field={field}>
          {label}
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly2 = ({ label }) => {
    return (
      <div className="cell-text cursor-default hide-after">
        <span className="cell-text-value min-h-22 text-truncate">{label}</span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const option = getCurrentOption(currentOption);
    const isUserInProject = checkIsUserInProject({ user: option?.user, projectUserList });
    const label = option?.value ? <UserAvatar user={option?.user} inactive={!isUserInProject} /> : UNASSIGNED?.label;

    // Editable
    if (checkIsEditableCell({ isEditableField, isReadOnly, disableEditingCells })) {
      return renderCellTextUtilEditable({ editingCell, label, props });
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ label, row, field });
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ label, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return label;
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ label });
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <AssignToFormItem
        field={field}
        formItemName={formItemName}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <AssignToKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{t('common.search')}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper assign-to-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>

        <div
          data-form-item-name={formItemName}
          className="trigger-render-field-element d-none"
          onClick={() => setTriggerRender(pre => ++pre)}
        >
          {triggerRender}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new assign-to-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default AssignToField;
