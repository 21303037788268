import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Form, Select } from 'antd';

import { COMPONENT_TYPE, NUMBER_PATTERN } from '../../../constants';

const DropdownContent = ({ field, currentField, handleSetVisibleDropdown, onClear, onApply }) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [currentOperation, setCurrentOperation] = useState(null);

  /**
   * Set value to form
   */
  useEffect(() => {
    if (!field?.referenceField) {
      return;
    }

    const newCurrentOperation = currentField?.operation
      ? currentField.operation
      : Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
      ? field?.supportedOperations[0]
      : '~';

    form.setFieldsValue({ val: currentField ? currentField.value : null });
    setCurrentOperation(newCurrentOperation);
  }, [form, field, currentField]);

  /**
   * On submit
   */
  const onSubmit = values => {
    handleSetVisibleDropdown(false);
    onApply(values?.val, currentOperation);
  };

  return (
    <Form
      form={form}
      className="ant-dropdown-menu c-field-string-number-dropdown p-0"
      onClick={e => e.stopPropagation()}
      onFinish={onSubmit}
    >
      <Form.Item
        name="val"
        rules={[
          {
            pattern: field?.componentType === COMPONENT_TYPE.NUMBER ? new RegExp(NUMBER_PATTERN) : null,
            message: t('message.fieldMustBeANumber')
          }
        ]}
        wrapperCol={{ xs: 24 }}
        style={{ padding: '8px 12px 5px 12px' }}
        className="mb-0"
      >
        <Input
          addonBefore={
            field?.supportedOperations ? (
              <Select
                value={currentOperation}
                options={
                  Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
                    ? field?.supportedOperations.map(sub => {
                        return {
                          label: sub,
                          value: sub
                        };
                      })
                    : []
                }
                dropdownMatchSelectWidth={false}
                onSelect={setCurrentOperation}
              />
            ) : (
              ''
            )
          }
          autoFocus
          autoComplete="off"
          onPressEnter={() => form.submit()}
        />
      </Form.Item>

      <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

      <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
        <Button
          size="small"
          className="w-auto ml-2"
          onClick={() => {
            handleSetVisibleDropdown(false);
            onClear();
          }}
        >
          {t('common.clear')}
        </Button>

        <Button htmlType="submit" type="primary" size="small" className="w-auto ml-2">
          {t('common.apply')}
        </Button>
      </div>
    </Form>
  );
};

export default DropdownContent;
