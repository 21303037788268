import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';
import { buildQueryForParamsUrl } from '../../common/utils';

export default {
  /**
   * State
   */
  systemUserList: null, // All of users in the system
  loadingSystemUserList: false,

  /**
   * Action: Set system user list
   */
  setSystemUserList: action((state, payload) => {
    if (!(state?.systemUserList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.systemUserList = [];
    }

    state.systemUserList = payload;
  }),

  /**
   * Action: Set loading system user list
   */
  setLoadingSystemUserList: action((state, payload) => {
    if (!(state?.loadingSystemUserList !== undefined)) {
      return;
    }

    state.loadingSystemUserList = payload;
  }),

  /**
   * Action: Call api to get system user list
   */
  getSystemUserList: thunk(async (action, payload) => {
    try {
      action.setLoadingSystemUserList(true);

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}${ENDPOINTS.USER}?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setSystemUserList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingSystemUserList(false);
    }
  })
};
