import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const JiraIssueTypeList = ({ setDefect, jiraIssueTypeId, form }) => {
  const [t] = useTranslation('akaat');

  // For jira integration store
  const getCreateIssueMeta = useStoreActions(action => action.jiraIntegration.getCreateIssueMeta);
  const createIssueMeta = useStoreState(state => state.jiraIntegration.createIssueMeta);
  const loadingIssueMeta = useStoreState(state => state.jiraIntegration.loadingIssueMeta);

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);

  useEffect(() => {
    if (!globalProject.projectKey || !globalTenant.tenantKey) {
      return;
    }

    getCreateIssueMeta({ projectKey: globalProject.projectKey, tenantKey: globalTenant.tenantKey });
    // eslint-disable-next-line
  }, [globalProject, globalTenant]);

  useEffect(() => {
    if (!jiraIssueTypeId || !Array.isArray(createIssueMeta) || !createIssueMeta.length) {
      return;
    }

    setDefect({ jiraIssueType: createIssueMeta.find(issuetype => issuetype.id === jiraIssueTypeId) });

    form.setFieldsValue({ jiraIssueTypeId: jiraIssueTypeId });
  }, [jiraIssueTypeId, createIssueMeta]);

  return (
    <>
      <Form.Item
        label={t('common.issueType')}
        name="jiraIssueTypeId"
        rules={[{ required: true, message: t('message.required') }]}
        hidden
      >
        <Select
          dropdownMatchSelectWidth={false}
          placeholder={t('common.pleaseSelect')}
          loading={loadingIssueMeta}
          onSelect={(val, option) => setDefect({ jiraIssueType: option.item })}
        >
          {createIssueMeta
            .filter(issuetype => !issuetype.subtask && issuetype.id === jiraIssueTypeId)
            .map(issuetype => {
              return (
                <Select.Option value={issuetype.id} key={issuetype.id} title={issuetype.name} item={issuetype}>
                  <img src={issuetype.iconUrl} alt="Icon Issue Type" /> {issuetype.name}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    </>
  );
};

export default JiraIssueTypeList;
