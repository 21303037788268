import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Button, Layout, Menu, Row, Spin, Tooltip } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { PROJECT_SETTING_MENU } from '../../constants';
import {
  convertToNestedMenu,
  recognizeExtensions,
  buildSubPathNameList,
  convertNestedMenuToGroupMenu
} from '../../common/utils';
import { useMenu } from '../../common/hooks';

import './style.scss';

/**
 * Project Settings
 */
export const ProjectSettingSidebar = ({ className = '' }) => {
  const location = useLocation();

  // For language
  const [t] = useTranslation('akaat');

  // For menu
  const { convertGroupMenuToAntMenuItems } = useMenu();

  // For global user info store
  const globalUserInfo = useStoreState(state => state.global.globalUserInfo);
  const loadingGlobalUserInfo = useStoreState(state => state.global.loadingGlobalUserInfo);
  const globalProject = useStoreState(state => state.global.globalProject);
  const dataExtensions = useStoreState(state => state.extensions.data);

  // Component state
  const [projectSettingMenus, setProjectSettingMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [visibleToggleCollapseTooltip, setVisibleToggleCollapseTooltip] = useState(false);

  /**
   * Set project manager menus
   */
  useEffect(() => {
    const userPermissions =
      Array.isArray(globalUserInfo?.permissions) && globalUserInfo?.permissions.length
        ? [...globalUserInfo?.permissions]
        : [];

    const allMenus = PROJECT_SETTING_MENU.filter(item => {
      if (item.isHideOnSidebar) {
        return false;
      }
      if (item.permission && !userPermissions.includes(item.permission)) {
        return false;
      }
      if (Array.isArray(item.permissions) && !userPermissions.some(p => item.permissions.includes(p))) {
        return false;
      }
      if (item.id === 'agentPools' && !recognizeExtensions(dataExtensions, globalProject)) {
        return false;
      }
      return true;
    });
    const nestedMenu = convertToNestedMenu(allMenus, null);
    const groupMenu = convertNestedMenuToGroupMenu(nestedMenu, []);

    const newMenus = convertGroupMenuToAntMenuItems({
      groupMenu,
      restSubMenu: { popupClassName: 'c-left-sidebar-sub-menu-popup menu-style-2' }
    });

    setProjectSettingMenus(newMenus);
  }, [location, globalUserInfo, dataExtensions]);

  /**
   * Render aciton top
   */
  const renderActionTop = () => {
    return (
      <Row wrap={false} justify="space-between" align="middle" className="box-header-sidebar">
        {!collapsed && <div className="txt-header-sidebar">{t('menu.projectSettings')}</div>}

        <Tooltip
          open={visibleToggleCollapseTooltip}
          title={collapsed ? t('common.expand') : t('common.collapse')}
          placement="right"
          destroyTooltipOnHide={true}
          onOpenChange={setVisibleToggleCollapseTooltip}
        >
          <Button
            type="link"
            icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            className="btn-toggle-collapse text-hover-primary"
            onClick={() => {
              setCollapsed(!collapsed);
              setVisibleToggleCollapseTooltip(false);
            }}
          />
        </Tooltip>
      </Row>
    );
  };

  /**
   * Render aciton bottom
   */
  const renderActionBottom = () => {
    return <div className="box-toggle-collapse" style={{ width: collapsed ? 80 : 250 }} />;
  };

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      theme="light"
      width={250}
      className={`c-left-sidebar for-project-setting-sidebar ${className}`}
      onCollapse={() => {
        setCollapsed(!collapsed);
        setVisibleToggleCollapseTooltip(false);
      }}
    >
      {renderActionTop()}

      {!globalUserInfo?.id && <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingGlobalUserInfo} />}

      <Menu
        items={projectSettingMenus}
        selectedKeys={buildSubPathNameList(location.pathname)}
        mode="inline"
        theme="light"
        className="menu-style-2"
      />

      {renderActionBottom()}
    </Layout.Sider>
  );
};
