import React, { useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Button, Tooltip, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import {
  OPERATION_MAPPING,
  COMPONENT_TYPE,
  OPERATION_VALUE_REGEX,
  OPERATION_VALUE_IN,
  OPERATION_VALUE_IN_RANGE,
  OPERATION_VALUE_NOT_IN_RANGE,
  OPERATION_VALUE_LTE,
  OPERATION_VALUE_GTE,
  OPERATION_VALUE_GT,
  OPERATION_VALUE_LT,
  OPERATION_VALUE_NOT
} from '../../constants';
import {
  jsonParse,
  getKeyByValueInObject,
  parseCodeQSKeepType,
  checkValidMomentDateString,
  checkIsNotEmptyObject,
  checkIsNotEmptyArray
} from '../../common/utils';
import { ClearFilter } from '../../assets/svg-icons';
import { DataSourceSelection } from './data-source-selection';
import { MoreDropdown } from './more-dropdown';
import { FieldStringNumber } from './field-string-number';
import { FieldDate } from './field-date';
import { FieldSelect } from './field-select';
import { FieldSuggestion } from './field-suggestion';
import { FieldUser } from './field-user';

import './style.scss';

export const BasicSearch = ({
  fieldList,
  allowChangeQueryParamsUrl = true,
  hideDataSourceSelection,
  noPageParam,
  loading,
  className = '',
  onChangeFilter,
  onSearch,
  currentDataSource,
  setCurrentDataSource,
  ...rest
}) => {
  const [searchForm] = Form.useForm();

  // For query params on url
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    filter: StringParam
  });

  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion);
  const setSuggestion = useStoreActions(action => action.global.setSuggestion);

  // For test data list
  const loadingList = useStoreState(state => state.engineTestData.loadingList);

  // Component state
  const [isSetCurrentValues, setIsSetCurrentValues] = useState(false);
  const [currentValues, setCurrentValues] = useState([]); // Example: [{ referenceField: 'status', value: ['PASS'] }]
  const [currentMoreFields, setCurrentMoreFields] = useState([]);
  const [filter, setFilter] = useState('');
  const [fieldData, setFieldData] = useState([]);

  /**
   * Has field data
   */
  const hasFieldData = useMemo(() => {
    return Array.isArray(fieldData) && fieldData.length > 0;
  }, [fieldData]);

  /**
   * Has current more fields
   */
  const hasCurrentMoreFields = useMemo(() => {
    return Array.isArray(currentMoreFields) && currentMoreFields.length > 0;
  }, [currentMoreFields]);

  /**
   * Set field list
   */
  useEffect(() => {
    if (!(Array.isArray(fieldList) && fieldList.length)) {
      return;
    }

    setFieldData(fieldList);
  }, [fieldList]);

  /**
   * Handle set current values utils
   */
  const handleSetCurrentValuesUtilCheckString = async ({ found, filterValue }) => {
    const condition1 = [COMPONENT_TYPE.STRING, COMPONENT_TYPE.NUMBER].includes(found?.componentType);
    const condition2 = checkIsNotEmptyObject(filterValue);

    return condition1 && condition2;
  };
  const handleSetCurrentValuesUtilCheckDateDateTime = async found => {
    return found?.componentType === COMPONENT_TYPE.DATE || found?.componentType === COMPONENT_TYPE.DATE_TIME;
  };
  const handleSetCurrentValuesUtilCheckPicklist = async ({ found, filterValue }) => {
    const condition1 = [
      COMPONENT_TYPE.PICKLIST,
      COMPONENT_TYPE.SUGGESTION,
      COMPONENT_TYPE.OPTION,
      COMPONENT_TYPE.USER
    ].includes(found?.componentType);

    const condition2 = Array.isArray(filterValue[OPERATION_VALUE_IN]) && filterValue[OPERATION_VALUE_IN].length;

    return condition1 && condition2;
  };
  const getCurrentValuesForString = ({ key, found, filterValue, emitCurrentValues }) => {
    const newValue = Object.values(filterValue)[0];
    const operationValue = Object.keys(filterValue)[0];
    const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

    emitCurrentValues({
      referenceField: key,
      value: newValue,
      componentType: found?.componentType,
      operation: operationLabel
    });
  };
  const getLteGteLtGtValues = filterValue => {
    const lteValue = Object.keys(filterValue)?.includes(OPERATION_VALUE_LTE)
      ? filterValue?.[OPERATION_VALUE_LTE]
      : null;
    const gteValue = Object.keys(filterValue)?.includes(OPERATION_VALUE_GTE)
      ? filterValue?.[OPERATION_VALUE_GTE]
      : null;
    const ltValue = filterValue?.[OPERATION_VALUE_NOT]
      ? Object.keys(filterValue?.[OPERATION_VALUE_NOT])?.includes(OPERATION_VALUE_LT)
        ? filterValue?.[OPERATION_VALUE_NOT]?.[OPERATION_VALUE_LT]
        : null
      : null;
    const gtValue = filterValue?.[OPERATION_VALUE_NOT]
      ? Object.keys(filterValue?.[OPERATION_VALUE_NOT])?.includes(OPERATION_VALUE_GT)
        ? filterValue?.[OPERATION_VALUE_NOT]?.[OPERATION_VALUE_GT]
        : null
      : null;

    return {
      lteValue,
      gteValue,
      ltValue,
      gtValue
    };
  };
  const getCurrentValuesForDateDatetime = ({ key, found, filterValue, emitCurrentValues }) => {
    const vals = getLteGteLtGtValues(filterValue);
    const lteValue = vals?.lteValue;
    const gteValue = vals?.gteValue;
    const ltValue = vals?.ltValue;
    const gtValue = vals?.gtValue;

    const singleDateVal = Object.values(filterValue)[0];

    // For in range
    if (checkValidMomentDateString([lteValue, gteValue])) {
      emitCurrentValues({
        referenceField: key,
        value: [moment(lteValue).local(), moment(gteValue).local()],
        componentType: found?.componentType,
        operation: OPERATION_VALUE_IN_RANGE
      });
    }

    // For not in range
    if (checkValidMomentDateString([ltValue, gtValue])) {
      emitCurrentValues({
        referenceField: key,
        value: [moment(ltValue).local(), moment(gtValue).local()],
        componentType: found?.componentType,
        operation: OPERATION_VALUE_NOT_IN_RANGE
      });
    }

    // For other: Example: =, <, >, <=, >=
    else if (checkValidMomentDateString(singleDateVal)) {
      const operationValue = Object.keys(filterValue)[0];
      const operationLabel = getKeyByValueInObject(operationValue, OPERATION_MAPPING);

      emitCurrentValues({
        referenceField: key,
        value: moment(singleDateVal).local(),
        componentType: found?.componentType,
        operation: operationLabel
      });
    } else {
    }
  };
  const getCurrentValuesForPicklist = async ({ key, found, filterValue, emitCurrentValues }) => {
    const operationLabel = getKeyByValueInObject(OPERATION_VALUE_IN, OPERATION_MAPPING);

    emitCurrentValues({
      referenceField: key,
      value: filterValue[OPERATION_VALUE_IN],
      componentType: found?.componentType,
      operation: operationLabel
    });

    if (found?.data?.url) {
      await getSuggestion({
        referenceField: key,
        url: found?.data.url,
        page: null,
        limit: null,
        order: null,
        group: found?.data.fieldValue
      });
    }
  };

  /**
   * No action when handle set current values
   */
  const noActionWhenHandleSetCurrentValues = ({ params, hasFieldData }) => !params || !hasFieldData;

  /**
   * Handle set current values
   */
  const handleSetCurrentValues = params => {
    if (noActionWhenHandleSetCurrentValues({ params, hasFieldData })) {
      return;
    }

    setFilter(params.filter);

    // For basic
    // Parse value filter keep type
    const filterObj = parseCodeQSKeepType(params.filter);
    const newCurrentValues = [];

    Object.keys(filterObj).forEach(async key => {
      const filterValue = filterObj[key];
      const found = fieldData.find(fi => fi.referenceField === key);

      if (!found) {
        return;
      }

      // For string, number
      if (handleSetCurrentValuesUtilCheckString({ found, filterValue })) {
        getCurrentValuesForString({ key, found, filterValue, emitCurrentValues: val => newCurrentValues.push(val) });
      }

      // For date, datetime
      else if (handleSetCurrentValuesUtilCheckDateDateTime(found)) {
        getCurrentValuesForDateDatetime({
          key,
          found,
          filterValue,
          emitCurrentValues: val => newCurrentValues.push(val)
        });
      }

      // For picklist
      else if (handleSetCurrentValuesUtilCheckPicklist({ found, filterValue })) {
        await getCurrentValuesForPicklist({
          key,
          found,
          filterValue,
          emitCurrentValues: val => newCurrentValues.push(val)
        });
      } else {
      }
    });

    setCurrentValues(newCurrentValues);
    handleCurrentMoreFields(filterObj);
  };

  /**
   * Handle current more fields
   */
  const handleCurrentMoreFields = filterObj => {
    const newCurrentMoreFields = [];
    const newFieldData = hasFieldData ? fieldData : [];

    Object.keys(filterObj).forEach(key => {
      const found = newFieldData.find(item => !item?.isDefaultSearch && item?.referenceField === key);
      if (found) {
        newCurrentMoreFields.push(key);
      }
    });

    setCurrentMoreFields(newCurrentMoreFields);
  };

  /**
   * Watching change of queryParams on url
   * Watching change of
   */
  useEffect(() => {
    if (!queryParams || !hasFieldData) {
      return;
    }

    const newQuery = {};

    if (queryParams?.filter) {
      newQuery.filter = queryParams?.filter;
    }

    if (!isSetCurrentValues) {
      handleSetCurrentValues(newQuery);
      setIsSetCurrentValues(true);

      return;
    } else if (
      // ==========> For change query params on url
      newQuery?.filter !== filter
    ) {
      handleSetCurrentValues(newQuery);
    } else {
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFieldData, fieldData, searchForm, queryParams, filter, isSetCurrentValues, setQueryParams]);

  /**
   * Validate valid JSON
   *
   * @param {object} rule - Rule of validation
   * @param {string} value - Value of input
   */
  const validateValidJson = async (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    value = value.trim();

    if (!jsonParse(value)) {
      return Promise.reject(t('message.invalidFormat'));
    }

    return Promise.resolve();
  };

  /**
   * Convert to filter string utils
   */
  const checkInvalidValueIsNull = ({ operation, value }) => {
    return operation !== 'isNull' && (value === null || value === undefined);
  };
  const getFilterStringForDateOrDatetime = ({ value }) => {
    let newValue = value;

    if (Array.isArray(value) && value.every(sub => sub && moment(sub).isValid())) {
      newValue = value.map(val => moment.utc(val).format());
    } else if (value && moment(value).isValid()) {
      newValue = moment.utc(value).format();
    } else {
    }

    return newValue;
  };
  const getFilterStringForOtherCase = ({ item, operation, value }) => {
    let newValue = value;

    if (item?.operation === OPERATION_VALUE_IN_RANGE && Array.isArray(item?.value) && item?.value.length) {
      newValue = {
        [OPERATION_VALUE_GTE]: value[0], // >=
        [OPERATION_VALUE_LTE]: value[1] // <=
      };
    } else if (item?.operation === OPERATION_VALUE_NOT_IN_RANGE && Array.isArray(item?.value) && item?.value.length) {
      newValue = {
        [OPERATION_VALUE_NOT]: {
          [OPERATION_VALUE_GT]: value[0], // <
          [OPERATION_VALUE_LT]: value[1] // >
        }
      };
    } else if (item?.operation === '~') {
      newValue = { [OPERATION_VALUE_REGEX]: value, $options: 'i' };
    } else {
      newValue = { [operation]: value };
    }

    return newValue;
  };

  /**
   * Convert to filter string
   */
  const convertToFilterString = values => {
    if (!checkIsNotEmptyArray(values)) {
      return;
    }

    // ==========> Search
    const queryObj = {};

    for (let index = 0; index < values.length; index++) {
      const item = values[index];

      const operation = OPERATION_MAPPING[item?.operation];
      let value = item?.value;

      if (!operation) {
        queryObj[item?.referenceField] = value;
        break;
      }

      if (checkInvalidValueIsNull({ operation: item?.operation, value })) {
        break;
      }

      if ([COMPONENT_TYPE.DATE, COMPONENT_TYPE.DATE_TIME].includes(item?.componentType)) {
        value = getFilterStringForDateOrDatetime({ value });
      }

      const otherValue = getFilterStringForOtherCase({ item, operation, value });

      if (otherValue) {
        value = otherValue;
      }

      queryObj[item?.referenceField] = value;
    }

    const newFilter = qs.stringify(queryObj, { encode: false }) || undefined;

    return newFilter;
  };
  /**
   * On apply
   */
  const onApply = ({ field, value, operation }) => {
    if (!field?.referenceField) {
      return;
    }

    // ==========> Get values
    let values = Array.isArray(currentValues) && currentValues.length ? [...currentValues] : [];
    const newVal = { referenceField: field.referenceField, componentType: field.componentType, value, operation };

    values = [...values].filter(item => item?.referenceField !== field.referenceField); // Remove first
    values = [...values, newVal]; // Then, add new

    const newFilter = convertToFilterString(values);

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: newFilter });
    } else {
      onSearch({ filterString: newFilter });
    }

    typeof onChangeFilter === 'function' && onChangeFilter(newFilter);
  };

  /**
   * On clear value
   */
  const onClearValue = field => {
    if (!field?.referenceField) {
      return;
    }

    onApply({ field, value: undefined });
  };

  /**
   * On remove from more fields
   */
  const onRemoveFromMoreFields = field => {
    if (!field?.referenceField) {
      return;
    }

    const newCurrentMoreFields = hasCurrentMoreFields
      ? [...currentMoreFields].filter(item => item !== field.referenceField)
      : [];

    onClearValue(field);
    setCurrentMoreFields(newCurrentMoreFields);
  };

  /**
   * On clear all filters
   */
  const onClearAllFilters = () => {
    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: undefined });
    } else {
      onSearch({ filterString: undefined });
    }

    typeof onChangeFilter === 'function' && onChangeFilter(undefined);
  };

  /**
   * Render field item
   */
  const renderFieldItem = field => {
    if (!field?.referenceField) {
      return null;
    }

    if (field.componentType === COMPONENT_TYPE.STRING || field.componentType === COMPONENT_TYPE.NUMBER) {
      return (
        <FieldStringNumber
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.DATE || field.componentType === COMPONENT_TYPE.DATE_TIME) {
      return (
        <FieldDate
          field={field}
          currentValues={currentValues}
          onApply={(value, operation) => onApply({ field, value, operation })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.PICKLIST || field.componentType === COMPONENT_TYPE.OPTION) {
      return (
        <FieldSelect
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.SUGGESTION) {
      return (
        <FieldSuggestion
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }

    if (field.componentType === COMPONENT_TYPE.USER) {
      return (
        <FieldUser
          field={field}
          currentValues={currentValues}
          onApply={value => onApply({ field, value, operation: 'in' })}
          onClear={() => onClearValue(field)}
          onRemove={() => onRemoveFromMoreFields(field)}
        />
      );
    }
  };

  /**
   * Render more button
   */
  const renderMoreButton = () => {
    const optionData = hasFieldData
      ? fieldData
          .filter(item => !item?.isDefaultSearch)
          .map(item => {
            return {
              label: t(`workItem.${item?.name}`),
              value: item?.referenceField
            };
          })
      : [];

    return (
      <MoreDropdown
        currentValues={currentMoreFields}
        optionData={optionData}
        onChangeSelected={setCurrentMoreFields}
        onClear={fields => {
          const newFieldData = hasFieldData ? fieldData : [];
          const newFields = Array.isArray(fields) && fields.length ? fields : [];

          newFields.forEach(sub => {
            const found = newFieldData.find(fi => fi.referenceField === sub);

            if (found) {
              onApply({ field: found, value: undefined });
            }
          });

          setCurrentMoreFields([]);
        }}
      />
    );
  };

  /**
   * Render right button
   */
  const RightButton = () => {
    return (
      <Row align="middle">
        <Tooltip title={t('search.clearAllFilters')} placement="topRight" destroyTooltipOnHide={true}>
          <Button
            type="link"
            className="border-transparent text-hover-dark-primary my-1 ml-2 px-0"
            onClick={onClearAllFilters}
          >
            <ClearFilter style={{ fontSize: '18px' }} />
          </Button>
        </Tooltip>
      </Row>
    );
  };

  /**
   * On submit and search
   */
  const onSubmit = values => {
    if (loading) {
      return;
    }

    const queryParsed = jsonParse(`${values.filterInput}`);
    const newFilter = qs.stringify(queryParsed, { encode: false });

    if (allowChangeQueryParamsUrl) {
      setQueryParams({ page: noPageParam ? undefined : 1, filter: newFilter });
    } else if (typeof onSearch === 'function') {
      onSearch({ filterString: newFilter });
    } else {
    }

    typeof onChangeFilter === 'function' && onChangeFilter(newFilter);
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setSuggestion([]);
    };
  }, [setSuggestion]);

  return (
    <div className={`c-basic-search ${className}`} {...rest}>
      <Row>
        {!hideDataSourceSelection && (
          <Col className="col-data-source">
            <Row align="middle">
              <span className="text-primary font-weight-medium mr-1">{t('search.dataSource')}:</span>

              <DataSourceSelection
                currentDataSource={currentDataSource}
                setCurrentDataSource={val => {
                  typeof setCurrentDataSource === 'function' && setCurrentDataSource(val);
                }}
              />
            </Row>
          </Col>
        )}

        <Col flex="1 1 auto" className="col-fields">
          <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingList}>
            <div>
              <Row gutter="20">
                <Col flex="1 1 auto">
                  {/* For default fields */}
                  {hasFieldData && (
                    <Row align="middle">
                      {fieldData
                        .filter(item => item?.isDefaultSearch)
                        .map(item => (
                          <div key={item?.referenceField} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}

                      <div className="my-1 mr-2">{renderMoreButton()}</div>
                    </Row>
                  )}

                  {/* For more fields */}
                  {hasFieldData && hasCurrentMoreFields && (
                    <Row align="middle">
                      {fieldData
                        .filter(item => currentMoreFields.includes(item?.referenceField))
                        .map(item => (
                          <div key={item?.referenceField} className="my-1 mr-2">
                            {renderFieldItem(item)}
                          </div>
                        ))}
                    </Row>
                  )}
                </Col>

                <Col>
                  <RightButton />
                </Col>
              </Row>
            </div>
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
