import React from 'react';

import { Error404 } from '../components';

const Error404Layout = ({ className = '' }) => {
  return (
    <div className={`l-blank ${className}`}>
      <Error404 />
    </div>
  );
};

export default Error404Layout;
