import { action, thunk } from 'easy-peasy';
import qs from 'qs';

import { ENDPOINTS } from '../../constants';
import { Http, handleError } from '../../core';

export default {
  /**
   * State
   */
  projectUserList: null, // All of users in current project
  userListByRole: {},
  loadingProjectUserList: false,
  loadingProjectUserItem: false,
  loadingUserListByRole: false,

  /**
   * Action: Set project user list
   */
  setProjectUserList: action((state, payload) => {
    if (!(state?.projectUserList !== undefined)) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.projectUserList = [];
    }

    state.projectUserList = payload;
  }),

  /**
   * Action: Set user list by role
   */
  setUserListByRole: action((state, payload) => {
    if (!(state?.userListByRole !== undefined)) {
      return;
    }

    if (!payload) {
      state.userListByRole = {};
      return;
    }

    if (payload?.key && !(Array.isArray(payload?.value) && payload?.value.length)) {
      state.userListByRole[payload?.key] = [];
      return;
    }

    state.userListByRole[payload.key] = payload.value;
  }),

  /**
   * Action: Set loadingproject user list
   */
  setLoadingProjectUserList: action((state, payload) => {
    if (!(state?.loadingProjectUserList !== undefined)) {
      return;
    }

    state.loadingProjectUserList = payload;
  }),

  /**
   * Action: Set loading project user item
   */
  setLoadingProjectUserItem: action((state, payload) => {
    if (!(state?.loadingProjectUserItem !== undefined)) {
      return;
    }

    state.loadingProjectUserItem = payload;
  }),

  /**
   * Action: Set loading user list by role
   */
  setLoadingUserListByRole: action((state, payload) => {
    if (!(state?.loadingUserListByRole !== undefined)) {
      return;
    }

    state.loadingUserListByRole = payload;
  }),

  /**
   * Action: Call api to get project user list
   */
  getProjectUserList: thunk(async (action, payload) => {
    try {
      action.setLoadingProjectUserList(true);

      if (!payload?.projectKey || !payload?.tenantKey) {
        throw new Error('Get project user list: Invalid Params');
      }

      const { tenantKey, projectKey } = payload;

      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${tenantKey}/${projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.USERS}`;
      const res = await Http.get(url).then(res => res.data);

      action.setProjectUserList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectUserList(false);
    }
  }),

  /**
   * Action: Call api to add user to project
   */
  addUserToProject: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.userId) {
        throw new Error('Add user to project: No User ID');
      }

      action.setLoadingProjectUserItem(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.USERS}/${userId}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectUserItem(false);
    }
  }),

  /**
   * Action: Call api to remove user from project
   */
  removeUserFromProject: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Remove user from project: No User ID');
      }

      action.setLoadingProjectUserItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.USERS}/${payload}`;
      await Http.delete(url).then(res => res.data);

      return true;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectUserItem(false);
    }
  }),

  /**
   * Action: Call api to change role of a user in project
   */
  changeRoleOfUserInProject: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?.userId) {
        throw new Error('Change role of user in project: No User ID');
      }

      action.setLoadingProjectUserItem(true);

      const newPayload = { ...payload };
      const { userId } = newPayload;
      delete newPayload.userId;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.MANAGER}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.PROJECT}${ENDPOINTS.USERS}/${userId}/role`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingProjectUserItem(false);
    }
  }),

  /**
   * Action: Call api to get user list by role
   */
  getUserListByRole: thunk(async (action, payload, helpers) => {
    try {
      const roleKeys = Array.isArray(payload?.roleKeys) && payload?.roleKeys.length ? [...payload?.roleKeys] : [];
      const key = JSON.stringify(roleKeys);
      const oldUserListByRole = helpers.getState()?.userListByRole;

      if (!payload?.force && Object.keys({ ...oldUserListByRole }).includes(key)) {
        return;
      }

      action.setLoadingUserListByRole(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;

      const queryString = {
        filter: { roleKeys }
      };
      const url = `${ENDPOINTS.IDENTITY}${ENDPOINTS.PERMISSION}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.USERS}/roleKeys?${qs.stringify(queryString)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setUserListByRole({
        key,
        value: res?.data
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingUserListByRole(false);
    }
  })
};
