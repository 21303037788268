import { action } from 'easy-peasy';

export default {
  /**
   * State
   */
  globalLanguage: '',

  /**
   * Action: Set global language
   */
  setGlobalLanguage: action((state, payload) => {
    if (!(state?.globalLanguage !== undefined)) {
      return;
    }

    state.globalLanguage = payload;
  })
};
