import React from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { Row, Tag } from 'antd';

import {
  PRIORITIES,
  URL_PATTERN,
  COMPONENT_TYPE,
  FULL_DATE_FORMAT,
  SHORT_DATE_FORMAT,
  SYSTEM_FIELD_TAG,
  SYSTEM_FIELD_PRIORITY,
  SYSTEM_FIELD_TEST_STEPS,
  SYSTEM_FIELD_ATTACHMENTS,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_TEST_CONFIG
} from '../../../constants';
import { addTokenToRawHtml, convertMinutesToShortTime } from '../../utils';
import { useStatus, useKeycloak } from '../../hooks';
import {
  SafeInnerHtml,
  StatusLabel,
  UserAvatar,
  BasicUploadMultipleFiles,
  TestEnvironmentVariableListPopover
} from '../../../components';

const useActivityHistoryRendering = () => {
  // For keycloak
  const { keycloak } = useKeycloak();

  // For status
  const { getStatusFromWorkflowById } = useStatus();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // For project user list store
  const projectUserList = useStoreState(state => state.global.projectUserList);

  /**
   * Get renderer activity history
   */
  const getRendererActivityHistory = ({ key, index, value, fieldList, workTicketId }) => {
    const newFieldList = Array.isArray(fieldList) && fieldList.length ? [...fieldList] : [];
    const field = newFieldList.find(item => item.refName === key || item.refName === `properties.${key}`);
    const label = i18next.exists(`akaat:workItem.${field?.refName}`)
      ? i18next.t(`akaat:workItem.${field?.refName}`)
      : i18next.exists(`akaat:workItem.${key}`)
      ? i18next.t(`akaat:workItem.${key}`)
      : field?.name;

    switch (key) {
      case SYSTEM_FIELD_PRIORITY: {
        const priority = PRIORITIES.find(item => item.value === value);
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>
            {priority ? (
              <div className="d-flex align-items-center">
                {priority?.icon}
                {priority?.label}
              </div>
            ) : (
              <span className="text-gray">None</span>
            )}
          </li>
        );
      }

      case SYSTEM_FIELD_TAG: {
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>{' '}
            {value && typeof value === 'string' ? (
              value.split(',').map((item, idx) => <Tag key={idx}>{item}</Tag>)
            ) : (
              <span className="text-gray">None</span>
            )}
          </li>
        );
      }

      case SYSTEM_FIELD_LATEST_RESULT: {
        const status = getStatusFromWorkflowById(value, ticketListData?.[workTicketId]?.workFlow);
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>
            {value?.id ? <StatusLabel status={status} /> : <span className="text-gray">None</span>}
          </li>
        );
      }

      case SYSTEM_FIELD_ATTACHMENTS: {
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>
            <BasicUploadMultipleFiles attachments={value} hasGetFullFilesInfo={true} isReadOnly={true} />
          </li>
        );
      }

      case SYSTEM_FIELD_TEST_STEPS: {
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>
            <ul style={{ margin: 0, padding: 0 }}>
              {value.map(s => {
                return (
                  <>
                    {s?.step && <li className="text-truncate">Step: {s?.step}</li>}
                    {s?.testData && <li className="text-truncate">Test Data: {s?.testData}</li>}
                    {s?.expectedResult && <li className="text-truncate">Expected Results: {s?.expectedResult}</li>}
                  </>
                );
              })}
            </ul>
          </li>
        );
      }

      case 'Related Tickets': {
        return (
          <ul key={`${key}-${index}`} style={{ margin: 0, padding: 0 }}>
            {Object.keys(value).map(key => {
              if (!value[key]) {
                return (
                  <div key={key} className="text-gray">
                    None
                  </div>
                );
              } else {
                return (
                  <li key={key} className="d-flex align-items-center my-1">
                    <div className="font-weight-bold mr-1">{key}: </div> {value[key]}
                  </li>
                );
              }
            })}
          </ul>
        );
      }

      case 'properties': {
        return Object.keys(value).map((key, index) => {
          return getRendererActivityHistory({ key, index, value: value[key], fieldList: newFieldList });
        });
      }

      case SYSTEM_FIELD_TEST_CONFIG: {
        return (
          <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
            <div className="font-weight-bold mr-1">{label}:</div>
            {value?.id ? (
              <Row align="middle">
                <span className="mr-2">{value?.name}</span>
                <TestEnvironmentVariableListPopover testEnvironment={value} />
              </Row>
            ) : (
              <span className="text-gray">None</span>
            )}
          </li>
        );
      }

      default: {
        switch (field?.componentType) {
          case COMPONENT_TYPE.TIME_TRACKING: {
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {convertMinutesToShortTime(+value)}
              </li>
            );
          }

          case COMPONENT_TYPE.STATUS: {
            const status = getStatusFromWorkflowById(value, ticketListData?.[workTicketId]?.workFlow);
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {value?.id ? <StatusLabel status={status} /> : <span className="text-gray">None</span>}
              </li>
            );
          }

          case COMPONENT_TYPE.USER: {
            const projectUsers = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];
            const isUserInProject = projectUsers.some(u => u?.username === value?.username);
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {value?.username ? (
                  <UserAvatar user={value} inactive={!isUserInProject} />
                ) : (
                  <span className="text-gray">None</span>
                )}
              </li>
            );
          }

          case COMPONENT_TYPE.HTML: {
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {value ? (
                  <SafeInnerHtml html={addTokenToRawHtml({ rawHtml: value, token: keycloak?.token })} />
                ) : (
                  <span className="text-gray">None</span>
                )}
              </li>
            );
          }

          case COMPONENT_TYPE.DATE:
          case COMPONENT_TYPE.DATE_TIME: {
            const format = field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {value && moment(value).isValid() ? moment(value).format(format) : ''}
              </li>
            );
          }

          case COMPONENT_TYPE.URL: {
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>{' '}
                {new RegExp(URL_PATTERN).test(value) ? (
                  <a href={value} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
                    {value}
                  </a>
                ) : value ? (
                  JSON.stringify(value)
                ) : (
                  <span className="text-gray">None</span>
                )}
              </li>
            );
          }

          case COMPONENT_TYPE.PICKLIST: {
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>{' '}
                {Array.isArray(value) && value.length ? (
                  value.map((item, idx) => <Tag key={idx}>{item}</Tag>)
                ) : (
                  <span className="text-gray">None</span>
                )}
              </li>
            );
          }

          default: {
            return (
              <li key={`${key}-${index}`} className="d-flex align-items-center my-1">
                <div className="font-weight-bold mr-1">{label}:</div>
                {JSON.stringify(value)}
              </li>
            );
          }
        }
      }
    }
  };

  return {
    getRendererActivityHistory
  };
};

export default useActivityHistoryRendering;
