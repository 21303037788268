import { AutoComplete, Empty, Input, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TESTMAN_PLATFORM_ID, JIRA_PLATFORM_ID, OPERATION_VALUE_REGEX, OPERATION_VALUE_OR } from '../../../constants';
import { debounce, escapeRegExpMongodb } from '../../../common/utils';

let typingTimerOfLazyLoad = 0;

const ExistWorkTicket = ({ relation, workItem, form }) => {
  const [t] = useTranslation('akaat');

  // For global store
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const objectiveList = useStoreState(state => state.global.objectiveList);
  const objectiveTotal = useStoreState(state => state.global.objectiveTotal);
  const loadingRelationTypeList = useStoreState(state => state.global.loadingRelationTypeList);
  const getObjectiveList = useStoreActions(action => action.global.getObjectiveList);
  const setObjectiveList = useStoreActions(action => action.global.setObjectiveList);
  const relationList = useStoreState(state => state.global.relationList);

  // For jira store
  const searchJiraByJql = useStoreActions(action => action.jiraIntegration.searchByJql);
  const setIssueList = useStoreActions(action => action.jiraIntegration.setIssueList);
  const jiraIssueList = useStoreState(state => state.jiraIntegration.data);
  const loadingListJira = useStoreState(state => state.jiraIntegration.loadingList);

  // Component state
  const [workTicketList, setWorkTicketList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [openAutoCompleteDropdown, setOpenAutoCompleteDropdown] = useState(false);
  const [allowShowAutoCompleteDropdown, setAllowShowAutoCompleteDropdown] = useState(false);

  const [page, setPage] = useState({
    jira: 1,
    manager: 1
  });

  useEffect(() => {
    if (!relation.jiraIssueType?.name) {
      return;
    }

    setWorkTicketList([]);
    setSearchValue('');
  }, [relation.jiraIssueType]);

  /**
   * Set jira defect item list
   */
  useEffect(() => {
    if (!Array.isArray(jiraIssueList)) {
      return;
    }

    const options = jiraIssueList.map(item => {
      return {
        title: `${item?.key}: ${item?.fields?.summary}`,
        label: <span className="text-truncate">{`${item?.key}: ${item?.fields?.summary}`}</span>,
        value: `${item?.key}: ${item?.fields?.summary}`,
        item
      };
    });

    setWorkTicketList(options);
  }, [jiraIssueList]);

  /**
   * Set objective list
   */
  useEffect(() => {
    if (!Array.isArray(objectiveList) || !objectiveList.length) {
      setWorkTicketList([]);
    }

    const options = objectiveList.map(item => {
      const itself = item?.key === workItem?.key;
      const isExist = relationList?.some(relation => item._id === relation?.target?._id);
      const label = item.name
        ? `${item?.workTicketType?.name} - ${item.key} - ${item.name}`
        : `${item?.workTicketType?.name} - ${item.key}`;

      return {
        label: (
          <Tooltip title={isExist ? t('relationTo.thisItemHasBeenRelatedTo') : label}>
            <span className="text-truncate">{label}</span>
          </Tooltip>
        ),
        value: label,
        item,
        disabled: itself || isExist
      };
    });

    setWorkTicketList(options);
  }, [objectiveList, relationList, workItem]);

  /**
   * Handle search when type
   */
  const handleSearchWhenType = val => {
    if (val === '') {
      setWorkTicketList([]);
      form.setFieldsValue({ item: '' });
      return;
    }

    if (relation.integrationSystem === JIRA_PLATFORM_ID) {
      getDataFromJira(val);
    }

    if (relation.integrationSystem === TESTMAN_PLATFORM_ID) {
      getDataFromManager(val, page.manager);
    }
  };

  /**
   * Check no action when search
   */
  const checkNoActionWhenSearch = (loadingListJira, globalProject, globalTenant, relation) => {
    return loadingListJira || !globalProject?.projectKey || !globalTenant.tenantKey || !relation;
  };

  /**
   * Handle search by keyword
   */
  const handleSearchByKeyword = val => {
    if (checkNoActionWhenSearch(loadingListJira, globalProject, globalTenant, relation)) {
      return;
    }

    if (typeof val === 'string') {
      val = val.trim();
    }

    setPage({
      jira: 0,
      manager: 0
    });
    setObjectiveList([]);

    if (!val) {
      setWorkTicketList([]);
      form.setFieldsValue({ item: '' });
      setAllowShowAutoCompleteDropdown(false);
      return;
    } else {
      handleSearchWhenType(val);
    }
  };

  const getDataFromJira = val => {
    if (!val || !relation) {
      return;
    }

    let jiraType = '';

    if (relation?.jiraIssueType?.name) {
      jiraType = `AND type = '${relation.jiraIssueType.name}'`;
    }

    const jql = `text ~ '${val}' ${jiraType}`;

    searchJiraByJql({
      jql,
      projectKey: globalProject.projectKey,
      tenantKey: globalTenant.tenantKey
    });
  };

  const getDataFromManager = async (val, page) => {
    if (!val || !relation) {
      return;
    }

    const limit = 100 * page;

    if (page > 1 && limit - 10 > objectiveTotal) {
      return;
    }

    const query = {
      filter: {
        [OPERATION_VALUE_OR]: [
          {
            name: { [OPERATION_VALUE_REGEX]: escapeRegExpMongodb(val), $options: 'i' }
          },
          {
            key: val
          }
        ],
        'workTicketType.id': relation.workTicketType
      },
      limit
    };

    clearTimeout(typingTimerOfLazyLoad);

    typingTimerOfLazyLoad = setTimeout(
      debounce(() => {
        getObjectiveList(query);
      }),
      300
    );
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setObjectiveList([]);
      setIssueList([]);
    };
  }, [setObjectiveList, setIssueList]);

  /**
   * Handle get more suggestion
   */
  const handleGetMoreSuggestion = async () => {
    const currentPage = page.manager + 1;
    await getDataFromManager(searchValue, currentPage);
    setPage({ ...page, manager: currentPage });
  };

  /**
   * Unmount
   */
  useEffect(() => {
    return () => {
      setObjectiveList([]);
      setIssueList([]);
    };
  }, [setObjectiveList, setIssueList]);

  return (
    <>
      <AutoComplete
        open={openAutoCompleteDropdown}
        value={searchValue}
        options={workTicketList}
        allowClear
        className="has-enter-and-clear-button"
        notFoundContent={searchValue && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        onSelect={(val, option) => {
          setSearchValue(val);
          setOpenAutoCompleteDropdown(false);
          form.setFieldsValue({ item: option?.item });
        }}
        onChange={setSearchValue}
        onPopupScroll={async e => {
          if (e?.target?.scrollTop + e?.target?.offsetHeight === e?.target?.scrollHeight) {
            handleGetMoreSuggestion();
          }
        }}
        onDropdownVisibleChange={visible => {
          if (visible && allowShowAutoCompleteDropdown) {
            setTimeout(() => {
              setOpenAutoCompleteDropdown(true);
            }, 200);
          } else {
            setOpenAutoCompleteDropdown(false);
          }
        }}
      >
        <Input.Search
          placeholder={`${t('common.search')}...`}
          loading={loadingListJira || loadingRelationTypeList}
          onSearch={val => {
            handleSearchByKeyword(val);
            setTimeout(() => {
              const hasValue = !!val;
              setAllowShowAutoCompleteDropdown(hasValue);
              hasValue && setOpenAutoCompleteDropdown(true);
            }, 200);
          }}
        />
      </AutoComplete>
    </>
  );
};

export default ExistWorkTicket;
