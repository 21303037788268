import { action } from 'easy-peasy';

export default {
  /**
   * State
   */
  quickEditingDrawer: null,
  isShownConfirmDiscardChangesModal: false,

  /**
   * Action: Set quick editing drawer
   */
  setQuickEditingDrawer: action((state, payload) => {
    if (state?.quickEditingDrawer === undefined) {
      return;
    }

    if (JSON.stringify(payload) !== JSON.stringify(state.quickEditingDrawer)) {
      state.quickEditingDrawer = payload;
    }
  }),

  /**
   * Action: Set is shown confirm discard changes modal
   */
  setIsShownConfirmDiscardChangesModal: action((state, payload) => {
    if (state?.isShownConfirmDiscardChangesModal === undefined) {
      return;
    }

    if (payload !== state.isShownConfirmDiscardChangesModal) {
      state.isShownConfirmDiscardChangesModal = payload;
    }
  })
};
