import React from 'react';

import { PRIORITIES, SYSTEM_FIELD_PRIORITY } from '../../constants';

export const WORK_ITEM_SEARCH_FIELD_LABEl_RENDER = (referenceField, item) => {
  switch (referenceField) {
    case SYSTEM_FIELD_PRIORITY: {
      return (
        <>
          {PRIORITIES.map(priority => {
            if (priority.value === item.value) {
              return (
                <>
                  {priority.icon}
                  {priority.label}
                </>
              );
            }
          })}
        </>
      );
    }

    default: {
      break;
    }
  }
};
