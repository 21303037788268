import { action, thunk } from 'easy-peasy';
import { notification } from 'antd';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../../constants';
import { Http, handleError } from '../../../../../core';
import { parseRsql, buildQueryForParamsUrl, buildQueryForFilterMongo } from '../../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  editingTestResult: {},
  query: {},
  jobStatuses: null,
  fieldList: [],
  collapseKeys: [], // For collapse/expand boxes on detail form
  activeActivityTab: '',
  loadingList: false,
  loadingItem: false,
  loadingFieldList: false,

  /**
   * Action: Set test result list
   */
  setTestResultList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing test result
   */
  setEditingTestResult: action((state, payload) => {
    if (state?.editingTestResult === undefined) {
      return;
    }

    state.editingTestResult = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set job statuses
   */
  setJobStatuses: action((state, payload) => {
    if (state?.jobStatuses === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.jobStatuses = [];
      return;
    }

    state.jobStatuses = payload;
  }),

  /**
   * Action: Set field list
   */
  setFieldList: action((state, payload) => {
    if (state?.fieldList === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.fieldList = [];
      return;
    }

    state.fieldList = payload;
  }),

  /**
   * Action: Set collapse keys
   */
  setCollapseKeys: action((state, payload) => {
    if (state?.collapseKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.collapseKeys = [];
      return;
    }

    state.collapseKeys = payload;
  }),

  /**
   * Action: Set active activity tab
   */
  setActiveActivityTab: action((state, payload) => {
    if (state?.activeActivityTab === undefined) {
      return;
    }

    state.activeActivityTab = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set loading field list
   */
  setLoadingFieldList: action((state, payload) => {
    if (state?.loadingFieldList === undefined) {
      return;
    }

    state.loadingFieldList = payload;
  }),

  /**
   * Action: Call api to get test result list
   */
  getTestResultList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test result list: Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload.aql)) {
        throw new Error('Get test result list: Invalid aql');
      } // aql is invalid

      const newPayload = payload ? { ...payload } : {};
      const { hasGetJobStatuses } = newPayload;
      delete newPayload.hasGetJobStatuses;

      action.setLoadingList(true);
      action.setQuery(payload);

      let res1 = null;
      let res2 = null;

      const url1 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_TEST_RESULT
      }/search?${buildQueryForParamsUrl(newPayload)}`;
      res1 = Http.get(url1).then(res => res.data);

      if (hasGetJobStatuses) {
        const params2 = { page: null, limit: null, order: null, aql: newPayload.aql };
        const url2 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
          ENDPOINTS.AUTOMATION_TEST_RESULT
        }/job/summary?${buildQueryForParamsUrl(params2)}`;
        res2 = Http.get(url2).then(res => res.data);
      }

      const promises = await Promise.all([res1, res2]);

      action.setTestResultList(promises[0]?.data);
      action.setJobStatuses(promises[1]?.data);

      return promises[0];
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get test result list by filter mongo
   */
  getTestResultListByFilter: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test result list by filter: Invalid Params');
      }

      if (!payload) {
        throw new Error('Get test result list by filter: Invalid aql');
      } // aql is invalid

      action.setLoadingList(true);
      action.setQuery(payload);

      const url1 = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_TEST_RESULT
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url1).then(res => res.data);

      action.setTestResultList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get test result by Id
   */
  getTestResultById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get test result by id: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Get test result by id: There are no ID');
      }

      const newPayload = { ...payload };
      const { id, noSetEditingTestResult } = newPayload;
      delete newPayload.noSetEditingTestResult;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/${id}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetEditingTestResult) {
        action.setEditingTestResult(res?.data);
      }

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get field list: Invalid Params');
      }

      if (!payload) {
        throw new Error('getFieldList: Invalid data source');
      }

      action.setLoadingFieldList(true);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_COLLECTION}/field/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setFieldList(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingFieldList(false);
    }
  }),

  /**
   * Action: Call api to get field list
   */
  updateTestResult: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update test result: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Update test result: Invalid Payload');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { id, noShowSuccessMessage } = newPayload;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_TEST_RESULT}/${id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:job.messageUpdatingJob'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: On change collapse
   */
  onChangeCollapse: thunk(async (action, payload, helpers) => {
    if (!payload) {
      throw new Error('On change collapse: There are no key');
    }

    let collapseKeys = helpers.getState()['collapseKeys'];
    let newKeys = [];

    collapseKeys = Array.isArray(collapseKeys) && collapseKeys.length ? [...collapseKeys] : [];

    if (collapseKeys.includes(payload)) {
      newKeys = [...collapseKeys].filter(item => item !== payload);
    } else {
      newKeys = [...collapseKeys, payload];
    }

    action.setCollapseKeys(newKeys);
  })
};
