import { SYSTEM_FIELD_STATUS, COLORS, SYSTEM_FIELD_LATEST_RESULT, WORK_ITEM_TEST_RESULT_ID } from '../../constants';
import { convertRGBObjectToHex, getDefaultChartLabel, removeDuplicate } from '.';

/**
 * Get chart colors utils
 */
const noActionWhenGetChartColors = ({ previewPayload, previewChart, ticketListData, chartInfo }) => {
  return !previewPayload || !previewChart || !ticketListData || !chartInfo;
};

const getChartColorsUtilGetWorkTicket = ({ previewPayload, ticketListData }) => {
  let workTicket = null;

  if (
    previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT ||
    previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT
  ) {
    workTicket = ticketListData?.[WORK_ITEM_TEST_RESULT_ID];
  } else {
    workTicket = ticketListData?.[previewPayload?.source];
  }

  return workTicket;
};

const getChartColorsUtilCheckIsMutipleData = ({ previewPayload, previewChart }) => {
  return (
    previewPayload?.x?.valueField &&
    previewPayload?.group?.valueField &&
    Array.isArray(previewChart) &&
    previewChart.length
  );
};

const getChartColorsUtilCheckIsSingleData = ({ previewPayload, previewChart }) => {
  const condition1 = previewPayload?.x?.valueField && !previewPayload?.group?.valueField;
  const condition2 =
    Array.isArray(previewChart) &&
    previewChart.length === 1 &&
    Array.isArray(previewChart[0]?.data) &&
    previewChart[0]?.data.length;

  return condition1 && condition2;
};

const getChartColorsUtilCheckValidStatusColors1 = ({ hasListState, isSingleData, previewPayload }) => {
  const condition1 = hasListState && isSingleData;
  const condition2 =
    previewPayload?.x?.refName === SYSTEM_FIELD_STATUS || previewPayload?.x?.refName === SYSTEM_FIELD_LATEST_RESULT;

  return condition1 && condition2;
};

const getChartColorsUtilCheckValidStatusColors2 = ({ hasListState, isMutipleData, previewPayload }) => {
  const condition1 = hasListState && isMutipleData;
  const condition2 =
    previewPayload?.group?.refName === SYSTEM_FIELD_STATUS ||
    previewPayload?.group?.refName === SYSTEM_FIELD_LATEST_RESULT;

  return condition1 && condition2;
};

const getChartColorsUtilCheckValidColorArray = ({ editingChart, previewChart, isMutipleData, isSingleData }) => {
  const condition1 = editingChart?._id;
  const condition2 =
    Array.isArray(editingChart?.colors) && editingChart?.colors.length > 0 && editingChart?.colors.every(c => c?.value);

  const validColorForMutipleData =
    isMutipleData && editingChart?.colors.length === removeDuplicate([...previewChart], 'label').length;
  const validColorForSingleData =
    isSingleData && editingChart?.colors.length === removeDuplicate([...previewChart[0]?.data], 'x').length;

  return condition1 && condition2 && (validColorForMutipleData || validColorForSingleData);
};

const getChartColorsUtilGetColors1 = ({ editingChart }) => {
  const hasColors = editingChart?._id && Array.isArray(editingChart?.colors) && editingChart?.colors.length;
  const newColors = [{ value: hasColors ? editingChart?.colors[0]?.value : COLORS[0] }];

  return newColors;
};

const getChartColorsUtilGetColors2 = ({ previewChart, workTicket, defaultState }) => {
  const newColors = previewChart.map((item, idx) => {
    const state = workTicket?.workFlow?.listStates.find(s => s?.id === item?.label?.value) || defaultState;
    const value = state ? convertRGBObjectToHex(state.background) : COLORS[idx % COLORS.length];

    return { label: state?.name || '', value };
  });

  return newColors;
};

const getChartColorsUtilGetColors3 = ({ previewChart, workTicket, defaultState }) => {
  const newColors = previewChart[0]?.data.map((item, idx) => {
    const state = workTicket?.workFlow?.listStates.find(s => s?.id === item?.x?.value) || defaultState;
    const value = state ? convertRGBObjectToHex(state.background) : COLORS[idx % COLORS.length];

    return { label: state?.name || '', value };
  });

  return newColors;
};

const getChartColorsUtilGetColors4 = ({ previewChart, previewPayload }) => {
  const newData = removeDuplicate([...previewChart], 'label');
  const newColors = newData.map((item, idx) => {
    const label = item?.label?.display || getDefaultChartLabel(previewPayload?.group?.refName);

    return { label, value: COLORS[idx % COLORS.length] };
  });

  return newColors;
};

const getChartColorsUtilGetColors5 = ({ previewChart, previewPayload }) => {
  const newData = removeDuplicate([...previewChart[0]?.data], 'x');
  const newColors = newData.map((item, idx) => {
    const label = item?.x?.display || getDefaultChartLabel(previewPayload?.x?.refName);

    return { label, value: COLORS[idx % COLORS.length] };
  });

  return newColors;
};

/**
 * Get chart colors
 */
const getChartColors = ({ previewPayload, previewChart, editingChart, ticketListData, chartInfo }) => {
  if (noActionWhenGetChartColors({ previewPayload, previewChart, ticketListData, chartInfo })) {
    return;
  }

  const workTicket = getChartColorsUtilGetWorkTicket({ previewPayload, ticketListData });
  let colors = null;

  const hasListState = Array.isArray(workTicket?.workFlow?.listStates) && workTicket?.workFlow?.listStates.length;
  const defaultState = workTicket?.workFlow?.defaultState;

  const isMutipleData = getChartColorsUtilCheckIsMutipleData({ previewPayload, previewChart });
  const isSingleData = getChartColorsUtilCheckIsSingleData({ previewPayload, previewChart });

  // For single line
  if (chartInfo.chartType === 'line' && chartInfo.childrenChartType === 'single') {
    colors = getChartColorsUtilGetColors1({ editingChart });
  }

  // For mutiple: grouped, stacked
  // Fixed color when type is status
  else if (getChartColorsUtilCheckValidStatusColors1({ hasListState, isMutipleData, previewPayload })) {
    colors = getChartColorsUtilGetColors2({ previewChart, workTicket, defaultState });
  }

  // For single
  // Fixed color when type is status
  else if (getChartColorsUtilCheckValidStatusColors2({ hasListState, isSingleData, previewPayload })) {
    colors = getChartColorsUtilGetColors3({ previewChart, workTicket, defaultState });
  }

  // For detail chart: Validate color list
  else if (getChartColorsUtilCheckValidColorArray({ editingChart, previewChart, isMutipleData, isSingleData })) {
    colors = [...editingChart?.colors];
  }

  // For mutiple: grouped, stacked
  else if (isMutipleData) {
    colors = getChartColorsUtilGetColors4({ previewChart, previewPayload });
  }

  // For single
  else if (isSingleData) {
    colors = getChartColorsUtilGetColors5({ previewChart, previewPayload });
  }

  // Else
  else {
    colors = COLORS.map(value => ({ value }));
  }

  return colors;
};

export default getChartColors;
