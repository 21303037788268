import React, { useEffect, useMemo, useState } from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.scss';

export const SelectedRowActionsWithAnimation = ({ selectedRows, children, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [animated, setAnimated] = useState(false);

  /**
   * Selected rows count
   */
  const selectedRowsCount = useMemo(() => (Array.isArray(selectedRows) ? selectedRows.length : 0), [selectedRows]);

  /**
   * Set animated
   */
  useEffect(() => {
    if (selectedRowsCount > 0) {
      setTimeout(() => setAnimated(true), 100);
    } else {
      setAnimated(false);
    }
  }, [selectedRowsCount]);

  /**
   * Return html
   */
  if (!selectedRowsCount > 0) {
    return;
  }

  return (
    <>
      <div className={`c-selected-row-actions-with-animation ${animated ? 'animated' : ''} ${className}`} {...rest}>
        <Row align="middle" className="box-transform">
          <div className="box-count text-primary mr-2">
            {t('common.selected')}: {selectedRowsCount}
          </div>

          {children}
        </Row>
      </div>
    </>
  );
};
