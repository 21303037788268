import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, Tag, Modal } from 'antd';
import { EditOutlined, SaveOutlined, WarningOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { SYSTEM_FIELD_PRIORITY, JIRA_PLATFORM_ID } from '../../constants';
import { SafeInnerHtml } from '../safe-inner-html';

import './style.scss';
import { checkIsNotEmptyArray } from '../../common/utils';

export const EditableSelectOption = ({
  type = 'SELECT', // SELECT, TAGS. Default is SELECT
  form,
  defaultVal,
  externalSystem = '',
  defaultValueForSuggestionField,
  options,
  hasPrefixIcon,
  onlyShowPrefixIcon,
  iShowTitle = false,
  isShowEditingIcon = false,
  isReadOnly = false,
  placeholder,
  confirmSave,
  className = '',
  loading,
  restFormItem,
  restField,
  restBoxTxtLabel,
  restValueText,
  restEditButton,
  onOpen,
  onClose,
  onSearch,
  onChange,
  onSelect,
  onClear,
  onSave,
  ...rest
}) => {
  const refInput = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [jiraCurrentPriority, setJiraCurrentPriority] = useState(null);

  /**
   * On set current value
   */
  const onSetCurrentValue = val => {
    setCurrentValue({ label: val, value: val });

    if (type === 'SELECT') {
      if (!checkIsNotEmptyArray(options)) {
        setCurrentValue({});
        return;
      }

      const found = options.find(item => item.value === val);

      if (found) {
        setCurrentValue(found);
      }
    }

    if (type === 'TAGS') {
      if (!checkIsNotEmptyArray(val)) {
        setCurrentValue([]);
        return;
      }

      setCurrentValue(val);
    }
  };

  /**
   * Set current value by default value
   */
  useEffect(() => {
    onSetCurrentValue(defaultVal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, options, defaultVal]);

  useEffect(() => {}, [options, restFormItem]);

  /**
   * Focus to input after get list
   */
  useEffect(() => {
    refInput?.current && refInput.current.focus({ cursor: 'end' });
  }, [options]);

  useEffect(() => {
    const jiraPriority = options?.find(el => el.value == defaultVal);
    setJiraCurrentPriority(jiraPriority);
  }, [currentValue]);

  /**
   * Focus to input after open
   */
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        setIsOpen(true);
        refInput?.current && refInput.current.focus({ cursor: 'end' });
      }, 200);
    }
  }, [isEditing]);

  /**
   * Handle open
   */
  const handleOpen = e => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    setTimeout(() => {
      typeof onOpen === 'function' && onOpen();

      if (restFormItem?.name && typeof form?.setFieldsValue === 'function') {
        const isUpdatedPriorityJira =
          restFormItem?.name.includes(SYSTEM_FIELD_PRIORITY) && externalSystem === JIRA_PLATFORM_ID;
        form.setFieldsValue({
          [restFormItem.name]: isUpdatedPriorityJira ? defaultVal?.toString() || '' : defaultVal
        });
      }

      setIsEditing(true);
    }, 300);
  };

  /**
   * Handle close
   */
  const handleClose = () => {
    typeof onClose === 'function' && onClose();

    setIsOpen(false);
    setIsEditing(false);

    if (!isFormValid()) {
      onSetCurrentValue(defaultVal);
    }
  };

  /**
   * Handle save
   */
  const handleSave = val => {
    if (!isFormValid()) {
      return;
    }

    if (val === defaultVal) {
      handleClose();
      return;
    }

    if (confirmSave) {
      if (!document.querySelector('.c-confirm-save-modal')) {
        Modal.confirm({
          title: t('common.confirm'),
          content: <SafeInnerHtml html={t('message.ifYouCancelNowYouWillLoseYourChanges')} />,
          icon: <WarningOutlined className="text-danger" />,
          autoFocusButton: null,
          maskClosable: true,
          width: 500,
          className: 'c-confirm-save-modal',
          okText: t('common.save'),
          okButtonProps: { type: 'primary', icon: <SaveOutlined /> },
          cancelText: t('common.cancel'),
          onOk: () => {
            typeof onSave === 'function' && onSave(val);
            handleClose();
            Modal.destroyAll();
          },
          ...confirmSave,
          onCancel: () => {
            handleClose();
            typeof confirmSave?.onCancel === 'function' && confirmSave?.onCancel();
            Modal.destroyAll();
          }
        });
      }

      return;
    }

    typeof onSave === 'function' && onSave(val);
    handleClose();
  };

  /**
   * Check validation
   */
  const isFormValid = () => {
    if (!(restFormItem?.name && typeof form?.getFieldError === 'function')) {
      return true;
    }

    const errors = form.getFieldError(restFormItem.name);

    if (checkIsNotEmptyArray(errors)) {
      return false;
    }

    return true;
  };

  /**
   * Selection props
   */
  const selectProps = {
    ref: refInput,
    open: isOpen,
    optionFilterProp: 'label',
    showSearch: true,
    allowClear: true,
    filterOption: typeof onSearch !== 'function',
    placeholder: placeholder || t('common.pleaseSelect'),
    loading,
    disabled: isReadOnly,
    onDropdownVisibleChange: val => {
      if (!val) {
        setTimeout(() => handleClose(), 200);
      } // Check validation before close

      setIsOpen(val);
    },
    onSearch: val => {
      typeof onSearch === 'function' && onSearch(val);
    },
    onChange: (val, option) => {
      typeof onChange === 'function' && onChange(val, option);
    },
    onSelect: (val, option) => {
      typeof onSelect === 'function' && onSelect(val, option);

      onSetCurrentValue(val);
      handleClose();
      handleSave(val);
    },
    onBlur: e => {
      typeof onBlur === 'function' && onBlur(e);
    },
    onClear: () => {
      typeof onClear === 'function' && onClear(null);

      handleClose();
      handleSave(null);
    },
    onKeyDown: e => {
      if (e?.key === 'Escape' || e?.keyCode === 27) {
        handleClose();
      }
    }
  };

  /**
   * Tags props
   */
  const tagsProps = {
    mode: 'tags',
    ref: refInput,
    open: isOpen,
    optionFilterProp: 'label',
    showSearch: true,
    allowClear: true,
    placeholder: placeholder || t('common.pleaseSelect'),
    loading,
    disabled: isReadOnly,
    popupClassName: 'disable-select-deselect-for-tags',
    onDropdownVisibleChange: val => {
      if (!val) {
        setTimeout(() => handleSave(currentValue), 200);
      } // Save, check validation before close

      setIsOpen(val);
    },
    onBlur: e => {
      typeof onBlur === 'function' && onBlur(e);

      const val = e?.target?.value;

      if (val && checkIsNotEmptyArray(currentValue) && currentValue.every(t => t !== val)) {
        setCurrentValue([...currentValue, val]);
        setTimeout(() => handleSave([...currentValue, val]), 200);
      }
    },
    onChange: (val, option) => {
      typeof onChange === 'function' && onChange(val, option);

      setCurrentValue(val);
    },
    onKeyDown: e => {
      if (e?.key === 'Escape' || e?.keyCode === 27) {
        handleClose();
      }
    },
    onInputKeyDown: e => {
      if (e?.key === 'Enter' || e?.keyCode === 13) {
        const val = e?.target?.value;
        const currentTags = checkIsNotEmptyArray(currentValue) ? [...currentValue] : [];

        if (!val || currentTags.includes(val)) {
          e.stopPropagation();
          e.preventDefault();
        }
      }
    }
  };

  /**
   * Render tag field
   */
  const renderTagField = () => {
    const isShowTag = type === 'TAGS' && isEditing && tagsProps?.ref;

    return (
      isShowTag && (
        <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem}>
          <Select {...tagsProps} {...restField} />
        </Form.Item>
      )
    );
  };

  /**
   * Render select field with prefix icon
   */
  const renderSelectFieldWithPrefixIcon = () => {
    const isShowSelect = type === 'SELECT' && isEditing && selectProps?.ref && hasPrefixIcon;

    return (
      isShowSelect && (
        <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem}>
          <Select {...selectProps} {...restField}>
            {checkIsNotEmptyArray(options)
              ? options.map(item => (
                  <Select.Option key={item.value} title={item.label} value={item.value}>
                    {item.icon}
                    {onlyShowPrefixIcon ? null : item.label}
                  </Select.Option>
                ))
              : null}
          </Select>
        </Form.Item>
      )
    );
  };

  /**
   * Render select field
   */
  const renderSelectField = () => {
    const isShowSelect = type === 'SELECT' && isEditing && selectProps?.ref && !hasPrefixIcon;

    return (
      isShowSelect && (
        <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem}>
          <Select options={checkIsNotEmptyArray(options) ? options : []} {...selectProps} {...restField} />
        </Form.Item>
      )
    );
  };

  /**
   * Render tag label
   */
  const renderTagLabel = () => {
    if (type !== 'TAGS') {
      return;
    }

    let label = '';

    if (!checkIsNotEmptyArray(currentValue) && isReadOnly) {
      label = <span className="text-gray">N/A</span>;
    } else if (!checkIsNotEmptyArray(currentValue)) {
      label = <span className="text-gray">{placeholder || t('common.enterValue')}</span>;
    } else {
      label = currentValue.map((item, idx) => (
        <Tag key={idx} style={{ marginTop: 1, marginBottom: 1 }}>
          {item}
        </Tag>
      ));
    }

    return label;
  };

  /**
   * Render select label
   */
  const renderSelectLabel = () => {
    if (type !== 'SELECT') {
      return;
    }

    let label = '';

    if (externalSystem === JIRA_PLATFORM_ID) {
      label = hasPrefixIcon ? (
        <>
          {jiraCurrentPriority?.icon}
          {onlyShowPrefixIcon ? null : jiraCurrentPriority?.label}
        </>
      ) : (
        jiraCurrentPriority.label
      );
    } else {
      if (defaultValueForSuggestionField) {
        label = defaultValueForSuggestionField;
      } else if (isReadOnly && !currentValue?.value) {
        label = <span className="text-gray">N/A</span>;
      } else if (!currentValue?.value) {
        label = <span className="text-gray">{placeholder}</span>;
      } else if (hasPrefixIcon) {
        label = (
          <>
            {currentValue.icon}
            {onlyShowPrefixIcon ? null : currentValue.label}
          </>
        );
      } else {
        label = currentValue.label;
      }
    }

    return label;
  };

  /**
   * Get class name
   */
  const getClassName = isEditing => {
    return `c-editable-select-option ${isEditing ? 'is-editing' : ''} ${
      isShowEditingIcon ? 'is-show-editing-icon' : ''
    } ${isReadOnly ? 'is-read-only' : ''} ${className}`;
  };

  return (
    <div className={getClassName(isEditing)} onClick={e => e.stopPropagation()} {...rest}>
      {renderTagField()}
      {renderSelectFieldWithPrefixIcon()}
      {renderSelectField()}

      {!isEditing && (
        <div className="box-txt-label min-h-22" onClick={handleOpen} {...restBoxTxtLabel}>
          <div
            title={iShowTitle ? currentValue?.label : null}
            className={`txt-label cursor-text type-${type} ${restValueText?.className || ''}`}
            {...restValueText}
          >
            {renderTagLabel()}
            {renderSelectLabel()}
          </div>

          {!isReadOnly && (
            <Button
              type="link"
              icon={<EditOutlined />}
              size="small"
              className={`btn-open-editable ${restEditButton?.className || ''}`}
              onClick={handleOpen}
              {...restEditButton}
            />
          )}

          {loading && <Loading3QuartersOutlined spin={loading} className="ic-loading" />}
        </div>
      )}
    </div>
  );
};
