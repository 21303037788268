import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useStoreState } from 'easy-peasy';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { notification } from 'antd';
import { convertToRaw, EditorState, AtomicBlockUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import vi from './custom-language/vi.js';

import env from '../../env.js';
import { ENDPOINTS, URL_PATTERN, IMAGE_PATTERN, BASE64_IMAGE_PATTERN } from '../../constants';
import { Http, handleError } from '../../core';
import { sleep, convertRawHtmlToContentState, getFileListFromPastedBase64Text } from '../../common/utils';
import { useKeycloak } from '../../common/hooks';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

export const BasicEditor = ({
  rawHtml,
  autoFocus,
  isProtectedFile = true,
  uploadPath,
  isReadOnly = false,
  className = '',
  onEditorStateChange,
  onEditorRawHtmlChange,
  onEditorAttachFiles,
  onFocus,
  onBlur,
  editorFunction,
  restEditor,
  restToolbar,
  ...rest
}) => {
  const refEditor = useRef(null);
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For keycloak
  const { keycloak, initialized } = useKeycloak();

  // For language
  const [t] = useTranslation('akaat');

  // For global store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const globalProject = useStoreState(state => state.global.globalProject);
  const globalLanguage = useStoreState(state => state.global.globalLanguage);

  // For testcase
  const editingTestCase = useStoreState(state => state.testcase.editingTestCase);

  // Component state
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorRawHtml, setEditorRawHtml] = useState('');
  const [currentToken, setCurrentToken] = useState(keycloak?.token);
  const [isSetContentStateWhenMounted, setIsSetContentStateWhenMounted] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  /**
   * Set editor function
   * For: Call child function from parent component
   */
  useEffect(() => {
    if (editorFunction) {
      editorFunction.current = {
        handleSetContentState,
        resetEditorState
      };
    }
  }, [editorFunction]);

  /**
   * Reset editor state
   */
  const resetEditorState = () => {
    setEditorState(EditorState.createEmpty());
  };

  /**
   * Convert role for get user list
   */
  const convertRoleForGetUserList = ({ item, editingTestCase }) => {
    let role = '';

    if (item?.email === editingTestCase?.createdBy?.email) {
      role = ' Create By';
    }

    if (item?.email === editingTestCase?.assignTo?.email) {
      role = role ? `${role}, Assign To` : 'Assign To';
    }

    if (item?.email === editingTestCase?.updatedBy?.email) {
      role = role ? `${role}, Modified By` : 'Modified By';
    }

    return role;
  };

  /**
   * List user
   */
  const listUser = useMemo(() => {
    if (!(Array.isArray(projectUserList) && projectUserList.length)) {
      return;
    }

    const newUsers = projectUserList.map(item => {
      const role = convertRoleForGetUserList({ item, editingTestCase });

      return {
        text: `${item?.username} - ${item?.email} (${item?.firstName || ''} ${item?.lastName || ''}) ${role}`,
        value: `${item?.firstName || ''} ${item?.lastName || ''}`,
        url: '/'
      };
    });

    return newUsers;
  }, [projectUserList, editingTestCase]);

  /**
   * On trigger focus
   */
  const onTriggerFocus = () => {
    refEditor?.current?.editor && refEditor.current.editor.focus();
  };

  /**
   * Set editor state
   */
  useEffect(() => {
    if (!autoFocus) {
      return;
    }

    setTimeout(() => {
      onTriggerFocus();
    }, 200);
  }, [autoFocus]);

  /**
   * Handle set content state
   */
  const handleSetContentState = ({ rawHtml, token }) => {
    try {
      const contentState = convertRawHtmlToContentState({
        rawHtml,
        token,
        hasAddTokenToEntityMaps: true
      });

      if (!contentState) {
        return;
      }

      const newEditorState = EditorState.createWithContent(contentState);

      setEditorRawHtml(rawHtml);
      setEditorState(newEditorState);
      setCurrentToken(token);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * When mounted
   * Set editor state
   */
  useEffect(() => {
    if (!rawHtml || !initialized) {
      return;
    }

    if (!isSetContentStateWhenMounted) {
      handleSetContentState({ rawHtml, token: keycloak?.token });
      setIsSetContentStateWhenMounted(true);
    }
  }, [isSetContentStateWhenMounted, rawHtml, keycloak?.token, initialized]);

  /**
   * Watching token
   * Set editor state
   */
  useEffect(() => {
    if (!editorRawHtml || !initialized) {
      return;
    }

    if (keycloak?.token && keycloak?.token !== currentToken) {
      handleSetContentState({ rawHtml: editorRawHtml, token: keycloak?.token });
    }
  }, [editorRawHtml, currentToken, keycloak?.token, initialized]);

  /**
   * Handle editor state change
   */
  const handleEditorStateChange = val => {
    const contentState = val.getCurrentContent();
    const newRawHtml = contentState.hasText() ? draftToHtml(convertToRaw(contentState)) : '';

    setEditorState(val);
    setEditorRawHtml(newRawHtml);

    typeof onEditorStateChange === 'function' && onEditorStateChange(val);
    typeof onEditorRawHtmlChange === 'function' && onEditorRawHtmlChange(newRawHtml);
  };

  /**
   * Check valid before upload
   */
  const checkValidBeforeUpload = () => {
    if (!uploadPath) {
      notification.error({ message: t('common.error'), description: 'There are no uploadPath' });
      return;
    }

    if (isProtectedFile && (!globalTenant?.tenantKey || !globalProject?.projectKey)) {
      notification.error({ message: t('common.error'), description: 'There are no tenant key or no project key' });
      return;
    }

    return true;
  };

  /**
   * Compute: Upload promise url
   */
  const uploadPromiseUrl = useMemo(() => {
    const url = isProtectedFile
      ? `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.FILE}`
      : `${ENDPOINTS._HOST}${ENDPOINTS.FILE_UPLOAD}`;

    return url;
  }, [globalTenant, globalProject]);

  /**
   * Get form data
   */
  const getFormData = file => {
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('path', `${uploadPath}/${moment().format('YYYYMMDDHHmmssSSS')}`);

    return formData;
  };

  /**
   * Handle upload image callback
   * For upload dropdown
   */
  const handleUploadImageCallback = file => {
    if (!checkValidBeforeUpload() || !file) {
      return;
    }

    const formData = getFormData(file);

    return Http.post(uploadPromiseUrl, formData)
      .then(res => res.data)
      .then(res => {
        let link = '';

        if (isProtectedFile) {
          const attachment = {
            id: res?.data?.id,
            previewUrl: res?.data?.previewUrl,
            downloadUrl: res?.data?.downloadUrl
          };
          link = `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${tenantPath}/${projectPath}${ENDPOINTS.FILE}/${res?.data?.id}?authorization=${keycloak?.token}`;

          typeof onEditorAttachFiles === 'function' && onEditorAttachFiles([attachment]);
        } else {
          link = encodeURI(res?.url);
        }

        return { data: { link } };
      })
      .catch(handleError);
  };

  /**
   * Insert entitys to editor
   */
  const insertEntitysToEditor = async entityDataList => {
    if (!(Array.isArray(entityDataList) && entityDataList.length)) {
      return;
    }

    await sleep(100); // Fix: only show image icon, no show real image

    let newEditorState = editorState;

    entityDataList.forEach(item => {
      const entityKey = newEditorState
        .getCurrentContent()
        .createEntity('IMAGE', 'MUTABLE', item)
        .getLastCreatedEntityKey();

      newEditorState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
    });

    setEditorState(newEditorState);
  };

  /**
   * Handle upload images utils
   */
  const handleUploadImagesUtils = ({ attachments, uploadedImages, isProtectedFile, onEditorAttachFiles }) => {
    const onInsertEntitys = () => {
      if (Array.isArray(uploadedImages) && uploadedImages.length) {
        insertEntitysToEditor(uploadedImages);
      }
    };

    const onEditorAttachFileList = () => {
      if (isProtectedFile && typeof onEditorAttachFiles === 'function' && attachments.length) {
        onEditorAttachFiles(attachments);
      }
    };

    return {
      onInsertEntitys,
      onEditorAttachFileList
    };
  };

  /**
   * Handle upload images utils
   */
  const handleUploadImagesUtilInsertEntitys = uploadedImages => {
    if (Array.isArray(uploadedImages) && uploadedImages.length) {
      insertEntitysToEditor(uploadedImages);
    }
  };
  const handleUploadImagesUtilEditorAttachFiles = attachments => {
    if (isProtectedFile && typeof onEditorAttachFiles === 'function' && attachments.length) {
      onEditorAttachFiles(attachments);
    }
  };

  /**
   * Handle upload images
   * For: Paste image(s)
   */
  const handleUploadImages = async fileList => {
    if (!(Array.isArray(fileList) && fileList.length)) {
      return;
    }

    const uploadedImages = [];
    const attachments = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      await Http.post(uploadPromiseUrl, getFormData(file))
        .then(res => res.data)
        .then(res => {
          if (isProtectedFile) {
            const attachment = {
              id: res?.data?.id,
              previewUrl: res?.data?.previewUrl,
              downloadUrl: res?.data?.downloadUrl
            };

            uploadedImages.push({
              src: `${ENDPOINTS._HOST}${ENDPOINTS.FILES}/${tenantPath}/${projectPath}${ENDPOINTS.FILE}/${res?.data?.id}?authorization=${keycloak?.token}`,
              alt: res?.data?.fileName || t('common.unknown')
            });

            attachments.push(attachment);
          } else {
            uploadedImages.push({
              src: encodeURI(res?.url),
              alt: typeof res?.url === 'string' ? res?.url.split('/').pop() : t('common.unknown')
            });
          }
        })
        .catch(handleError);
    }

    handleUploadImagesUtilInsertEntitys(uploadedImages);
    handleUploadImagesUtilEditorAttachFiles(attachments);
  };

  /**
   * Handle pasted text
   */
  const handlePastedText = async text => {
    try {
      // Paste images with src is base64
      if (text && new RegExp(BASE64_IMAGE_PATTERN).test(text)) {
        const fileList = await getFileListFromPastedBase64Text({ text });

        handleUploadImages(fileList);

        return true;
      }

      // Paste images with src is url
      else if (text && new RegExp(IMAGE_PATTERN).test(text)) {
        const matches = [...text.matchAll(IMAGE_PATTERN)];
        const srcList = matches.map(match => match[1]);
        const images = [];

        srcList.forEach(src => {
          if (new RegExp(URL_PATTERN).test(src)) {
            images.push({ src });
          }
        });

        if (Array.isArray(images) && images.length) {
          insertEntitysToEditor(images);
        }

        return true;
      }

      // Other: Notthing
      else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  /**
   * Handle pasted files
   */
  const handlePastedFiles = async fileList => {
    if (!(Array.isArray(fileList) && fileList.length)) {
      return;
    }

    const imageList = fileList.filter(item => item?.type.startsWith('image/'));

    handleUploadImages(imageList);
  };

  return (
    <>
      <div
        className={`c-basic-editor ${className} ${hasFocus ? 'has-focus' : ''} ${isReadOnly ? 'is-read-only' : ''}`}
        {...rest}
      >
        <Editor
          ref={refEditor}
          editorState={editorState}
          localization={{
            locale:
              globalLanguage === 'fr' ? 'fr' : globalLanguage === 'ja' ? 'ja' : globalLanguage === 'vi' ? 'vi' : 'en',
            translations: globalLanguage === 'vi' ? vi : null
          }}
          toolbar={{
            image: {
              uploadCallback: handleUploadImageCallback,
              previewImage: true
            },
            textAlign: { inDropdown: true },
            list: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            ...restToolbar
          }}
          mention={{
            separator: ' ',
            trigger: '@',
            suggestions: listUser
          }}
          toolbarHidden={isReadOnly}
          readOnly={isReadOnly}
          handlePastedText={handlePastedText}
          handlePastedFiles={handlePastedFiles}
          onEditorStateChange={handleEditorStateChange}
          onFocus={e => {
            setHasFocus(true);
            typeof onFocus === 'function' && onFocus(e);
          }}
          onBlur={e => {
            setHasFocus(false);
            typeof onBlur === 'function' && onBlur(e);
          }}
          {...restEditor}
        />

        <div className="trigger-focus-to-editor" onClick={onTriggerFocus} />
        <div className="trigger-reset-editor" onClick={resetEditorState} />
      </div>
    </>
  );
};
