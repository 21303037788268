import { notification } from 'antd';
import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  editingCycle: {},
  loadingItem: false,

  /**
   * Action: Set editing cycle
   */
  setEditingCycle: action((state, payload) => {
    if (state?.editingCycle === undefined) {
      return;
    }

    state.editingCycle = payload;
  }),

  /**
   * Action: Set loading item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get cycle by Id
   */
  getCycleById: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Get cycle by id: There are no cycle ID');
      }

      action.setLoadingItem(true);

      const { _id, noSetDataToStore } = payload;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${_id}`;
      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setEditingCycle(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to create cycle
   */
  createCycle: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Create cycle: Invalid Payload');
      }

      action.setLoadingItem(true);

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}`;
      const res = await Http.post(url, payload).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:common.successful'),
        description: i18next.t('akaat:testPlan.messageCreatingCycle')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update cycle
   */
  updateCycle: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Update cycle: There are no cycle ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id, noShowSuccessMessage } = newPayload;
      delete newPayload._id;
      delete newPayload.noShowSuccessMessage;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t('akaat:testPlan.messageUpdatingCycle'),
          description: i18next.t('akaat:message.updatedSuccessfully')
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to delete cycle
   */
  deleteCycle: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Delete cycle: There are no cycle ID');
      }

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${payload}`;
      await Http.delete(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageDeletingCycle'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return true;
    } catch (err) {
      handleError(err);
    }
  }),

  /**
   * Action: Call api to change status cycle
   */
  changeStatusCycle: thunk(async (action, payload, helpers) => {
    try {
      if (!payload?._id) {
        throw new Error('Change status cycle: There are no cycle ID');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${_id}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to clone cycle
   */
  reCycleByCycle: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Clone cycle: Invalid Payload');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { _id } = newPayload;
      delete newPayload._id;

      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${_id}/reCycle`;
      const res = await Http.post(url, newPayload).then(res => res.data);

      notification.success({
        description: i18next.t('akaat:message.reCycleSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to clone format cycle
   */
  cloneFormatCycle: thunk(async (action, payload, helpers) => {
    try {
      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;

      if (!globalTenant?.tenantKey || !globalProject?.projectKey) {
        throw new Error('Invalid Params');
      }

      if (!payload) {
        throw new Error('Clone cycle: No Id');
      }

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_PLAN_CYCLE}/${payload}/clone-tree`;
      const res = await Http.get(url).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testPlan.messageCloning', { name: i18next.t('akaat:testPlan.cycle') }),
        description: i18next.t('akaat:message.clonedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
