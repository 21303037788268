import { action, thunk } from 'easy-peasy';
import moment from 'moment';

import { ENDPOINTS, ENGINE_TASK_JOB_STATUS_WAITING } from '../../../../../constants';
import { Http, handleError } from '../../../../../core';
import { parseRsql, buildQueryForParamsUrl, buildQueryForFilterMongo } from '../../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: [],
  total: 0,
  tasks: [],
  query: {},
  editingJobRunner: {},
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set job runner list
   */
  setJobRunnerList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set editing job runner
   */
  setEditingJobRunner: action((state, payload) => {
    if (state?.editingJobRunner === undefined) {
      return;
    }

    state.editingJobRunner = payload;
  }),

  /**
   * Action: Set tasks job runner
   */
  setTaskJobRunner: action((state, payload) => {
    if (state?.tasks === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.tasks) && payload.tasks.length)) {
      state.tasks = [];

      return;
    }

    const newTask = [...payload.tasks].map((task, index) => {
      if (!task) {
        return;
      }

      if (index === 0 || task?.status === ENGINE_TASK_JOB_STATUS_WAITING) {
        return task;
      }

      let endTime = moment();

      if (!task.startTime) {
        return task;
      }

      if (task.endTime) {
        endTime = moment(task.endTime);
      }

      const duration = moment.duration(endTime.diff(moment(task.startTime))).asMilliseconds();
      task.duration = task.duration ? task.duration : duration;

      return task;
    });

    state.tasks = newTask;
  }),

  /**
   * Action: Call api to get jo runner list
   */
  getJobRunnerList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get job runner list: Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload?.aql)) {
        throw new Error('getJobRunnerList: Invalid aql');
      } // aql is invalid

      if (!payload?.isGetByInterval) {
        action.setLoadingList(true);
        action.setQuery(payload);
      }

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB_RUNNER
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobRunnerList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Action: Call api to get jo runner list by filter
   */
  getJobRunnerListByFilter: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get job runner list by filter: Invalid Params');
      }

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB_RUNNER
      }?${buildQueryForFilterMongo(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobRunnerList(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  }),

  /**
   * Get detail job runner
   */
  getJobRunnerById: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get job runner by id: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Get job runner by id: There are no id');
      }

      const { id } = payload;

      if (!payload.isGetByInterval) {
        action.setLoadingItem(true);
        action.setQuery(payload);
      }

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB_RUNNER}/${id}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingJobRunner(res?.data);
      action.setTaskJobRunner(res?.data);
      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Get detail job runner
   */
  executeJobRunner: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Execute job runner: Invalid Params');
      }

      if (!payload?.id) {
        throw new Error('Execute job runner: There are no job runner id');
      }

      const { id, reTestFailTc, jobId } = payload;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB_RUNNER}/execute/${jobId}?reTestFailTc=${reTestFailTc}`;
      const res = await Http.post(url, { id });

      return res.data;
    } catch (error) {
      const status = error.response?.status;
      if (status === 409) {
        return {
          error
        };
      } else {
        handleError(error);
      }
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Stop job runner
   */
  stopJobRunner: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Stop job runner: Invalid Params');
      }

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB_RUNNER}/${payload}/stop`;
      const res = await Http.put(url, {});

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),
  /**
   * Get log for task in job runner
   */
  getAllLogTaskJobRunner: thunk(async (action, payload) => {
    try {
      if (!payload?.projectKey || !payload?.tenantKey) {
        throw new Error('Get all log task job runner: Invalid Params');
      }

      const { jobRunnerId, tenantKey, projectKey } = payload;

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenantKey}/${projectKey}${ENDPOINTS.AUTOMATION_JOB_RUNNER_LOGS}/${jobRunnerId}/logs`;
      const res = await Http.get(url);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),
  /**
   * Get log for task in job runner
   */
  getLogTaskJobRunner: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get log task job runner: Invalid Params');
      }

      const { jobRunnerId, taskId, page, size } = payload;
      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_JOB_RUNNER_LOGS}/${jobRunnerId}/logs?taskId=${taskId}&page=${page}&size=${size}`;
      const res = await Http.get(url);

      return res.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  })
};
