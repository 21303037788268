import { useEffect } from 'react';

import { getCtrlShiftAlt, sleep } from '../../../../common/utils';
import {
  checkIsDeleteWhenFocus,
  handleKeyboardEvents,
  checkIsOpenDropdownWhenFocus,
  addEffectToSelectedDate,
  checkDontGoLeftWhenEditing,
  checkIsEmptyValueWhenPressEnter,
  checkIsAddEffectToSelectedDate
} from '../../editable-cell-utils';

const DatetimeKeyboardEvents = ({
  x,
  y,
  field,
  tableForm,
  formItemName,
  setEditingCell,
  handleOpen,
  handleClose,
  handleSave,
  closeAndSetLastValue
}) => {
  const EFFECT_FLASH = 'effect-flash';

  /**
   * Add effect to time panel
   */
  const addEffectToTimePanel = () => {
    const pickerTimePanel = document.querySelector('.ant-picker-time-panel');

    pickerTimePanel?.classList.add(EFFECT_FLASH);
    setTimeout(() => pickerTimePanel?.classList.remove(EFFECT_FLASH), 1000);
  };

  /**
   * Handle add effect to selected date
   */
  const handleAddEffectToSelectedDate = async e => {
    const panel = document.querySelector('.ant-picker-panel');

    if (
      document.querySelector('.ant-picker-panel.dirty-effect-flash') &&
      !document.querySelector('.ant-picker-date-panel-active') &&
      !document.querySelector('.ant-picker-datetime-panel-active')
    ) {
      e.preventDefault();
      e.stopPropagation();
      panel?.classList.add(EFFECT_FLASH);
      setTimeout(() => panel?.classList.remove(EFFECT_FLASH), 1000);
      return;
    }

    await sleep(100);

    if (panel && !panel?.classList.contains('dirty-effect-flash')) {
      e.preventDefault();
      e.stopPropagation();

      const datePanel = document.querySelector('.ant-picker-date-panel');
      datePanel?.classList.add(EFFECT_FLASH);
      setTimeout(() => datePanel?.classList.remove(EFFECT_FLASH), 1000);

      panel?.classList.add('dirty-effect-flash');

      return;
    } else {
      if (document.querySelector('.ant-picker-date-panel-active')) {
        e.preventDefault();
        e.stopPropagation();
        addEffectToSelectedDate();
        return;
      } else if (document.querySelector('.ant-picker-datetime-panel-active')) {
        e.preventDefault();
        e.stopPropagation();
        addEffectToTimePanel();
        return;
      } else {
      }
    }

    return true;
  };

  /**
   * Handle key down
   */
  const handleKeyDown = async e => {
    if (!e) {
      return;
    }

    const code = e.code;
    const ctrl = getCtrlShiftAlt(e, 'Control');
    const shift = getCtrlShiftAlt(e, 'Shift');
    const alt = getCtrlShiftAlt(e, 'Alt');
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    const hasFocused = triggerFocusElement?.classList.contains('focused');
    const isEditing = triggerFocusElement?.classList.contains('editing');
    const boxVisualInput = document.querySelector('.c-editable-cell .box-visual-input');

    // Delete & has "focused" class => Don't open, keep "focused" class, save
    if (checkIsDeleteWhenFocus({ field, hasFocused, code })) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.resetFields([formItemName]);
      handleSave(null);
      return;
    }

    // When show "box-visual-input"
    else if (checkIsOpenDropdownWhenFocus({ code, ctrl, alt, hasFocused, boxVisualInput })) {
      handleOpen();
      return;
    }

    // When editing, no focused, press Shift + Tab => Don't go left
    else if (checkDontGoLeftWhenEditing({ hasFocused, isEditing, shift, code })) {
      e.stopPropagation();
      return;
    }

    // When show calendar
    // Enter && show calendar && empty value => Close + Save
    else if (checkIsEmptyValueWhenPressEnter({ code, val: e?.target?.value })) {
      e.preventDefault();
      e.stopPropagation();

      tableForm?.setFieldsValue({ [formItemName]: null });
      handleClose(null);
      setTimeout(() => handleSave(null), 200);
      return;
    }

    // When show calendar
    // Tab => When pressing tab, add flash effect to selected date on the calendar
    else if (checkIsAddEffectToSelectedDate({ code, shift })) {
      const res = await handleAddEffectToSelectedDate(e);

      if (!res) {
        return;
      }
    } else {
    }

    return handleKeyboardEvents({
      e,
      x,
      y,
      formItemName,
      tableForm,
      setEditingCell,
      closeAndSetLastValue
    });
  };

  /**
   * Event Listener
   */
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  return null;
};

export default DatetimeKeyboardEvents;
