import { action, thunk } from 'easy-peasy';
import queryString from 'query-string';

import { ENDPOINTS } from '../../../../../constants';
import { handleError, Http } from '../../../../../core';
import { checkIsNotEmptyObject } from '../../../../../common/utils';

export const model = {
  /**
   * State
   */
  testCaseExecutionProgressData: [],
  trendChartByStatusData: {},
  mostExecutedTestcasesData: [],
  mostFailedTestcasesData: [],
  query: {},
  loadingReport: false,

  /**
   * Action: Set test case execution progress data
   */
  setTestCaseExecutionProgressData: action((state, payload) => {
    if (state?.testCaseExecutionProgressData === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.testCaseExecutionProgressData = [];
      return;
    }

    state.testCaseExecutionProgressData = Object.keys(payload).map((key, index) => {
      return {
        id: index + 1,
        plan: {
          id: payload[key].id,
          name: key
        },
        count: payload[key].data
      };
    });
  }),

  /**
   * Action: Set trend chart by status data
   */
  setTrendChartByStatusData: action((state, payload) => {
    if (state?.trendChartByStatusData === undefined) {
      return;
    }

    if (!checkIsNotEmptyObject(payload)) {
      state.trendChartByStatusData = {};
      return;
    }

    state.trendChartByStatusData = payload;
  }),

  /**
   * Action: Set most executed test cases data
   */
  setMostExecutedTestCasesData: action((state, payload) => {
    if (state?.mostExecutedTestcasesData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostExecutedTestcasesData = [];
      return;
    }

    state.mostExecutedTestcasesData = payload;
  }),

  /**
   * Action: Set most failed test cases data
   */
  setMostFailedTestCasesData: action((state, payload) => {
    if (state?.mostFailedTestcasesData === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.mostFailedTestcasesData = [];
      return;
    }

    state.mostFailedTestcasesData = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set loading report
   */
  setLoadingReport: action((state, payload) => {
    if (state?.loadingReport === undefined) {
      return;
    }

    state.loadingReport = payload;
  }),

  /**
   * Action: Call api to Get test case execution progress list
   */
  getTestCaseExecutionProgressList: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get test case execution progress list: Invalid Payload');
      }

      action.setLoadingReport(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.AUTOMATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_TEST_CASE_EXECUTION_PROGRESS
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTestCaseExecutionProgressData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingReport(false);
    }
  }),

  /**
   * Action: Call api to Get trend chart by status
   */
  getTrendChartByStatus: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart by status: Invalid Payload');
      }

      action.setLoadingReport(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.AUTOMATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_TREND_CHART_BY_STATUS
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setTrendChartByStatusData(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingReport(false);
    }
  }),

  /**
   * Action: Call api to Get chart by most executed testcase
   */
  getTrendChartMostExecuted: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most executed: Invalid Payload');
      }

      action.setLoadingReport(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.AUTOMATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_MOST_EXECUTED_TEST_CASES
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostExecutedTestCasesData(res?.data?.mostExecuted);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingReport(false);
    }
  }),

  /**
   * Action: Call api to Get chart by most failed testcase
   */
  getTrendChartMostFailed: thunk(async (action, payload, helpers) => {
    try {
      if (!(payload !== null && typeof payload === 'object')) {
        throw new Error('Get trend chart most failed: Invalid Payload');
      }

      action.setLoadingReport(true);
      action.setQuery(payload);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.AUTOMATION}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${
        ENDPOINTS.AUTOMATION_REPORT_MOST_FAILED_TEST_CASES
      }?${queryString.stringify(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setMostFailedTestCasesData(res?.data?.mostFailed);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingReport(false);
    }
  })
};
