import { notification } from 'antd';
import Axios from 'axios';
import i18next from 'i18next';

import env from '../../env';
import { ENDPOINTS, HTTP_STATUS_CODES } from '../../constants';
import { getObjectByValue } from '../../common/utils';

/**
 * Axios create
 */
const Http = Axios.create({
  baseURL: ENDPOINTS._HOST
});

// /**
//  * Request interceptor
//  */
// Http.interceptors.request.use(
//   request => {
//     return request;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

const checkIsExpiredJwt = (status, error) => {
  return status === 403 && error.response?.data?.error?.message === 'jwt expired';
};

const checkIsGetFile = error => {
  return (
    typeof error.response?.config?.method === 'string' &&
    error.response?.config?.method.toLowerCase() === 'get' &&
    error.response?.config?.url.includes('/files/') &&
    error.response?.config?.url.includes('/file/')
  );
};

/**
 * Response interceptor
 */
Http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const status = error.response?.status;

    // For maintenance
    if (status === 502) {
      const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
      const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

      window.location.href = `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/maintenance`;
      return Promise.reject(error.response ? error.response : error);
    }

    // For jwt expired
    if (checkIsExpiredJwt(status, error)) {
      window.location.reload();
      return Promise.reject(error.response ? error.response : error);
    }

    // Don't push notice when get file
    if (checkIsGetFile(error)) {
      return Promise.reject(error.response ? error.response : error);
    }

    // Push error notification
    notification.error({
      message: HTTP_STATUS_CODES.some(item => item.value === status)
        ? getObjectByValue(status, HTTP_STATUS_CODES)?.label
        : i18next.t('akaat:common.error'),
      description: error.response?.data?.message || ''
    });

    return Promise.reject(error.response ? error.response : error);
  }
);

export default Http;
