import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Tooltip } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { COMPONENT_TYPE, NUMBER_PATTERN } from '../../../constants';
import DropdownContent from './dropdown-content';

// For string and number type
export const FieldStringNumber = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.referenceField || !(Array.isArray(currentValues) && currentValues.length)) {
      return null;
    }

    const newField = [...currentValues].find(item => item.referenceField === field?.referenceField);

    if (
      !(
        (field?.componentType === COMPONENT_TYPE.STRING && newField?.value) ||
        (field?.componentType === COMPONENT_TYPE.NUMBER && new RegExp(NUMBER_PATTERN).test(newField?.value))
      )
    ) {
      return null;
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return t(`workItem.${field?.name}`);
  }, [t, field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    return currentField
      ? `${fieldName}: ${currentField?.operation} ${currentField?.value}`
      : `${fieldName}: ${t('common.all')}`;
  }, [fieldName, currentField]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [
            {
              key: 'menu',
              label: (
                <DropdownContent
                  field={field}
                  currentField={currentField}
                  handleSetVisibleDropdown={setVisibleDropdown}
                  onClear={onClear}
                  onApply={onApply}
                />
              ),
              className: 'p-0'
            }
          ],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ width: 290 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={setVisibleDropdown}
        {...restDropdown}
      >
        <Button title={btnFieldTitle} className={`btn-field field-string-number ${className}`} {...rest}>
          {currentField ? (
            <>
              <span className="text-truncate">
                {fieldName}: {currentField.operation} {currentField.value}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
