import React from 'react';
import objectPath from 'object-path';

const TestPlanningField = ({ workTicketId, row, field, disableEditingCells }) => {
  /**
   * Render cell text
   */
  const renderCellText = () => {
    const title = objectPath.get(row, field?.refName) || '';

    if (disableEditingCells) {
      return <span title={title}>{title}</span>;
    }

    return (
      <div title={title} className="cell-text cursor-default hide-after">
        <span className="cell-text-value min-h-22 text-truncate">{title}</span>
      </div>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return <div className="field-wrapper test-planning-field">{renderCellText()}</div>;
};

export default TestPlanningField;
