import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { FolderOutlined } from '@ant-design/icons';

import { TEST_PLAN_WORKFLOW, TEST_PLAN_TREE_TYPE_CYCLE, TEST_PLAN_TREE_TYPE_RELEASE } from '../../constants';
import { useStatus } from '../../common/hooks';
import { Iteration, Release } from '../../assets/svg-icons';

export const TestPlanningIcon = ({ planningItem, planningItemType, ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For status
  const { getStatusFromWorkflowById } = useStatus();

  /**
   * Compute current planning item
   */
  const currentPlanningItem = useMemo(() => {
    if (!planningItem) {
      return;
    }

    const status = getStatusFromWorkflowById(planningItem?.status, TEST_PLAN_WORKFLOW);

    return { ...planningItem, status };
  }, [planningItem]);

  /**
   * Render icon
   */
  const renderIcon = item => {
    if (!item) {
      return;
    }

    const status = item.status;
    const iconColor = status?.color ? `rgb(${status?.color?.r}, ${status?.color?.g}, ${status?.color?.b})` : null;
    const iconBackground = status?.background
      ? `rgb(${status?.background?.r}, ${status?.background?.g}, ${status?.background?.b})`
      : null;

    return (
      <Tooltip
        title={t(status?.name) || ''}
        placement="top"
        destroyTooltipOnHide={true}
        color={iconBackground}
        overlayInnerStyle={{ color: iconColor }}
        {...rest}
      >
        {planningItemType === TEST_PLAN_TREE_TYPE_RELEASE ? (
          <Release style={{ color: iconBackground }} />
        ) : planningItemType === TEST_PLAN_TREE_TYPE_CYCLE ? (
          <Iteration style={{ color: iconBackground }} />
        ) : (
          <FolderOutlined style={{ color: iconBackground }} />
        )}
      </Tooltip>
    );
  };

  if (!planningItem) {
    return <></>;
  }

  return <>{renderIcon(currentPlanningItem)}</>;
};
