import React, { useMemo, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  SS_LAST_SAVED_VALUE,
  SYSTEM_FIELD_STATUS,
  SYSTEM_FIELD_ORDER_ID,
  SYSTEM_FIELD_TEST_STEPS,
  WORK_ITEM_TEST_RESULT_ID,
  WORKFLOW_STATUS_TYPE_OPEN
} from '../../../../constants';
import {
  getObjectByValue,
  reactSessionStorage,
  getLatestResultStatusIdByTestStepStatusList,
  handleSetLastSavedValueToSession
} from '../../../../common/utils';
import { checkIsEditableCell, onOutsideClick } from '../../editable-cell-utils';
import { StatusLabel } from '../../../status-label';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import StepResultStatusKeyboardEvents from './step-result-status-keyboard-events';
import StepResultStatusFormItem from './step-result-status-form-item';

const StepResultStatusField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  const refInput = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  /**
   * On change editing cell
   */
  useEffect(() => {
    typeof onChangeEditingCell === 'function' && onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenDropdown(false);
    }

    setEditingCell(info);
  };

  /**
   * Open options dropdown when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenDropdown(true);
    }
  }, [isEditing, editingCell]);

  /**
   * State list
   */
  const listStates = useMemo(() => {
    const newListStates = ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow?.listStates;
    return Array.isArray(newListStates) && newListStates.length ? [...newListStates] : [];
  }, [ticketListData]);

  /**
   * Options
   */
  const options = useMemo(() => {
    const newOptions = [...listStates].map(item => {
      return {
        label: item?.name,
        value: item?.id,
        status: item,
        disabled: item?.type === WORKFLOW_STATUS_TYPE_OPEN
      };
    });

    return newOptions;
  }, [field, listStates]);

  /**
   * Default option
   */
  const defaultOpt = useMemo(() => {
    if (!(Array.isArray(options) && options.length)) {
      return null;
    }

    const val = objectPath.get(row, SYSTEM_FIELD_STATUS);
    let newOption = getObjectByValue(val?.id, options);

    if (!newOption?.value) {
      newOption = options.find(item => item?.status?.type === WORKFLOW_STATUS_TYPE_OPEN);
    }

    return newOption;
  }, [row, field, options]);

  /**
   * Current option
   */
  const [currentOption, setCurrentOption] = useState(defaultOpt);

  /**
   * Set current option by default value
   */
  useEffect(() => setCurrentOption(defaultOpt), [defaultOpt]);

  /**
   * Get current option
   */
  const getCurrentOption = opt => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastOption = getObjectByValue(lastValue, options);

    return lastValue !== undefined ? lastOption : opt;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') {
      return;
    }

    if (isEditing) {
      const option = getCurrentOption(defaultOpt);
      tableForm.setFieldsValue({ [formItemName]: option?.value || null });
    }
  }, [isEditing, isReadOnly, tableForm, formItemName, defaultOpt]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenDropdown(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = opt => {
    setIsOpenDropdown(false);
    setTimeout(() => setVisibleFormItem(false), 0);
    setCurrentOption(opt);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultOption = getCurrentOption(defaultOpt);
    handleClose(defaultOption);
  };

  /**
   * Handle save
   */
  const handleSave = opt => {
    const defaultOption = getCurrentOption(defaultOpt);

    if (field?.mandatory && !getObjectByValue(opt?.value, options)) {
      handleClose(defaultOption);
      return;
    }

    if (opt?.value !== defaultOption?.value) {
      const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});

      const newLastSavedValueSession = {
        [formItemName]: opt?.value || null
      };

      let testSteps = row?.[SYSTEM_FIELD_TEST_STEPS];
      testSteps = Array.isArray(testSteps) && testSteps.length ? [...testSteps] : [];

      const newTestSteps = testSteps.map((s, idx) => {
        const lastValue = lastSavedValue[`key-${s?.key}-${field?.refName}`];
        const lastValueObj = lastValue !== undefined ? listStates.find(l => l?.id === lastValue) : null;
        const newStep = {
          ...s,
          [SYSTEM_FIELD_ORDER_ID]: idx + 1,
          [SYSTEM_FIELD_STATUS]: lastValueObj || s?.status || null
        };

        if (s?.key === row?.key) {
          newStep[SYSTEM_FIELD_STATUS] = opt?.status || null;
        }

        delete newStep.key;
        delete newStep.parentKey;
        delete newStep.testStepXNumber;
        return newStep;
      });

      // Execute test run
      const latestResultStatus =
        getLatestResultStatusIdByTestStepStatusList({ testSteps: newTestSteps, listStates }) || null;

      const formData = {
        key: row?.parentKey,
        [SYSTEM_FIELD_STATUS]: latestResultStatus?.id,
        [SYSTEM_FIELD_TEST_STEPS]: newTestSteps.map(item => ({ ...item, [SYSTEM_FIELD_STATUS]: item?.status?.id }))
      };

      // Handle set last saved value to session
      if (Object.keys(newLastSavedValueSession).length) {
        handleSetLastSavedValueToSession(newLastSavedValueSession);
      }

      typeof onSave === 'function' && onSave({ formData, row, field });
    }

    handleClose(opt);
  };

  /**
   * Handle dropdown visible change
   */
  const handleDropdownVisibleChange = visible => {
    setIsOpenDropdown(visible);
    setTimeout(() => handleClose(currentOption), 200);
  };

  /**
   * Selection props
   */
  const selectProps = {
    ref: refInput,
    open: isOpenDropdown,
    optionFilterProp: 'label',
    autoFocus: true,
    showSearch: true,
    allowClear: false,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: isReadOnly,
    onDropdownVisibleChange: handleDropdownVisibleChange,
    onSelect: (val, option) => handleSave(option),
    onClear: () => handleSave(null),
    onClick: () => handleOpen()
  };

  /**
   * Render cell text utils
   */
  const renderCellTextUtilEditable = ({ option }) => {
    return (
      <div className="cell-text cursor-text" onClick={handleOpen}>
        <span className="cell-text-value text-truncate">
          <StatusLabel status={option?.status} />
        </span>
      </div>
    );
  };

  const renderCellTextUtilRelationField1 = ({ option, row, field }) => {
    return (
      <RelationLink row={row} field={field}>
        <StatusLabel status={option?.status} />
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ option, row, field }) => {
    return (
      <div className="cell-text cursor-default hide-after">
        <RelationLink row={row} field={field}>
          <StatusLabel status={option?.status} />
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ option }) => {
    return <StatusLabel status={option?.status} />;
  };

  const renderCellTextUtilReadonly2 = ({ option }) => {
    return (
      <div className="cell-text cursor-default hide-after">
        <span className="cell-text-value text-truncate">
          <StatusLabel status={option?.status} />
        </span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const option = getCurrentOption(currentOption);

    // Editable
    if (checkIsEditableCell({ isEditableField, isReadOnly, disableEditingCells })) {
      return renderCellTextUtilEditable({ option });
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ option, row, field });
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ option, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ option });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ option });
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <StepResultStatusFormItem
        field={field}
        formItemName={formItemName}
        options={options}
        selectProps={selectProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <StepResultStatusKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText()}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentOption)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={setEditingCell}
          />
        )}

        <div className={`field-wrapper step-result-status-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return isEditing ? (
    <OutsideClickHandler onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell })}>
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default StepResultStatusField;
