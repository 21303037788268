import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, Row, Col, Avatar, Modal } from 'antd';
import { SaveOutlined, EditOutlined, CloseOutlined, WarningOutlined } from '@ant-design/icons';

import { checkIsNotEmptyArray, filterOptionForUserField } from '../../common/utils';
import { UserAvatar } from '../user-avatar';
import { SafeInnerHtml } from '../safe-inner-html';

import './style.scss';

export const EditableSelectMultiUserOption = ({
  form,
  defaultVal = '',
  options,
  isShowEditingIcon = false,
  isReadOnly = false,
  disabled = false,
  placeholder,
  confirmSave,
  className = '',
  restFormItem,
  restField,
  restBoxEditableText,
  restValueText,
  restEditButton,
  restCloseButton,
  restAvatarGroup,
  onOpen,
  onClose,
  onSearch,
  onChange,
  onClear,
  onSave,
  ...rest
}) => {
  const refInput = useRef(null);

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [isEditing, setIsEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemValues, setSelectedItemValues] = useState([]);

  /**
   * On set selected items
   */
  const onSetSelectedItems = values => {
    if (!checkIsNotEmptyArray(options) || !checkIsNotEmptyArray(values)) {
      setSelectedItems([]);
      setSelectedItemValues([]);
      return;
    }

    const valuesMapping = [];

    values.forEach(val => {
      const optionFound = options.find(item => item?.value === val);

      if (optionFound) {
        valuesMapping.push(optionFound);
      }
    });

    setSelectedItems(valuesMapping);
    setSelectedItemValues(values);
  };

  /**
   * Set current value by default value
   */
  useEffect(() => {
    if (!checkIsNotEmptyArray(options)) {
      return;
    }

    onSetSelectedItems(defaultVal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal, options]);

  /**
   * Focus to input after get list
   */
  useEffect(() => {
    refInput?.current && refInput.current.focus({ cursor: 'end' });
  }, [options]);

  /**
   * Focus to input after open
   */
  useEffect(() => {
    if (isEditing) {
      setTimeout(() => {
        setIsOpen(true);
        refInput?.current && refInput.current.focus({ cursor: 'end' });
      }, 200);
    }
  }, [isEditing]);

  /**
   * Handle open
   */
  const handleOpen = e => {
    e.stopPropagation();

    if (isReadOnly) {
      return;
    }

    setTimeout(() => {
      typeof onOpen === 'function' && onOpen();

      if (restFormItem?.name && typeof form?.setFieldsValue === 'function') {
        form.setFieldsValue({ [restFormItem.name]: defaultVal });
      }

      setIsEditing(true);
    }, 300);
  };

  /**
   * Handle close
   */
  const handleClose = () => {
    typeof onClose === 'function' && onClose();

    setIsOpen(false);
    setIsEditing(false);

    if (!isFormValid()) {
      onSetSelectedItems(defaultVal);
    }
  };

  /**
   * Handle save
   */
  const handleSave = val => {
    if (!isFormValid()) {
      return;
    }

    if (JSON.stringify(val) === JSON.stringify(defaultVal)) {
      handleClose();
      return;
    }

    if (confirmSave) {
      if (!document.querySelector('.c-confirm-save-modal')) {
        Modal.confirm({
          title: t('common.confirm'),
          content: <SafeInnerHtml html={t('message.ifYouCancelNowYouWillLoseYourChanges')} />,
          icon: <WarningOutlined className="text-danger" />,
          autoFocusButton: null,
          maskClosable: true,
          width: 500,
          className: 'c-confirm-save-modal',
          okText: t('common.save'),
          okButtonProps: { type: 'primary', icon: <SaveOutlined /> },
          cancelText: t('common.cancel'),
          onOk: () => {
            typeof onSave === 'function' && onSave(val);
            handleClose();
            Modal.destroyAll();
          },
          ...confirmSave,
          onCancel: () => {
            handleClose();
            typeof confirmSave?.onCancel === 'function' && confirmSave?.onCancel();
            Modal.destroyAll();
          }
        });
      }

      return;
    }

    typeof onSave === 'function' && onSave(val);
    handleClose();
  };

  /**
   * Check validation
   */
  const isFormValid = () => {
    if (!(restFormItem?.name && typeof form?.getFieldError === 'function')) {
      return true;
    }

    const errors = form.getFieldError(restFormItem.name);

    if (checkIsNotEmptyArray(errors)) {
      return false;
    }

    return true;
  };

  /**
   * Input props
   */
  const inputProps = {
    ref: refInput,
    open: isOpen,
    mode: 'multiple',
    options: checkIsNotEmptyArray(options) ? options : [],
    optionFilterProp: 'label',
    filterOption: filterOptionForUserField,
    showSearch: true,
    allowClear: true,
    placeholder: placeholder || t('common.pleaseSelect'),
    disabled: disabled,
    onDropdownVisibleChange: val => {
      if (!val) {
        handleSave(selectedItemValues);
        setTimeout(() => handleClose(), 200); // Check validation before close
      }

      setIsOpen(val);
    },
    onSearch: val => {
      typeof onSearch === 'function' && onSearch(val);
    },
    onChange: (val, option) => {
      typeof onChange === 'function' && onChange(val, option); // => "val" is value list, example ["1", "2", ...]

      onSetSelectedItems(val);
    },
    onClear: () => {
      typeof onClear === 'function' && onClear(null);

      handleClose();
      handleSave(null);
    },
    onKeyDown: e => {
      if (e?.key === 'Escape' || e?.keyCode === 27) {
        handleClose();
      }
    }
  };

  /**
   * Render txt label
   */
  const renderTxtLabel = () => {
    let label = '';

    if (!checkIsNotEmptyArray(selectedItems) && isReadOnly) {
      label = <span className="text-gray">N/A</span>;
    } else if (!checkIsNotEmptyArray(selectedItems)) {
      label = <span className="text-gray">{placeholder}</span>;
    } else if (checkIsNotEmptyArray(selectedItems)) {
      label = (
        <Avatar.Group maxCount={5} {...restAvatarGroup}>
          {selectedItems.map((item, idx) => (
            <UserAvatar
              key={idx}
              user={item?.item}
              restAvatar={{ size: 30 }}
              restInfoDropdown={{ mouseEnterDelay: 0.1 }}
              showName={false}
            />
          ))}
        </Avatar.Group>
      );
    } else {
    }

    return (
      <span className={`txt-label cursor-text ${restValueText?.className || ''}`} {...restValueText}>
        {label}
      </span>
    );
  };

  /**
   * Get class name
   */
  const getClassName = isEditing => {
    return `c-editable-select-multi-user-option ${isEditing ? 'is-editing' : ''} ${
      isShowEditingIcon ? 'is-show-editing-icon' : ''
    } ${isReadOnly ? 'is-read-only' : ''} ${className}`;
  };

  return (
    <div className={getClassName(isEditing)} onClick={e => e.stopPropagation()} {...rest}>
      {isEditing && (
        <Row>
          <Col flex="1">
            <Form.Item wrapperCol={{ xs: 24 }} {...restFormItem}>
              <Select {...inputProps} {...restField} />
            </Form.Item>
          </Col>

          <Col flex="0">
            <Button
              title={t('common.close')}
              size="small"
              icon={<CloseOutlined />}
              className={`btn-close-editable ml-1 ${restCloseButton?.className || ''}`}
              onClick={e => {
                e.stopPropagation();
                handleClose();
              }}
              {...restCloseButton}
            />
          </Col>
        </Row>
      )}

      {!isEditing && (
        <div
          className={`box-txt-label min-h-22 ${restBoxEditableText?.className || ''}`}
          onClick={handleOpen}
          {...restBoxEditableText}
        >
          {renderTxtLabel()}

          {!isReadOnly && (
            <Button
              title={t('common.close')}
              type="link"
              icon={<EditOutlined />}
              size="small"
              className={`btn-open-editable ${restEditButton?.className || ''}`}
              onClick={handleOpen}
              {...restEditButton}
            />
          )}
        </div>
      )}
    </div>
  );
};
