import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Tooltip, Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ESTIMATED_TIME_PATTERN, NUMBER_PATTERN, SPECIAL_CHARACTERS_PATTERN, COMPONENT_TYPE } from '../../../constants';
import { SafeInnerHtml } from '../../safe-inner-html';

const DropdownContent = ({ field, currentField, handleSetVisibleDropdown, onClear, onApply }) => {
  const [form] = Form.useForm();

  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [currentOperation, setCurrentOperation] = useState(null);
  const [currentValueField, setCurrentValueField] = useState(null);

  /**
   * Set value to form
   */
  useEffect(() => {
    if (!field?.refName) {
      return;
    }

    let newCurrentOperation;

    if (currentField?.operation) {
      newCurrentOperation = currentField?.operation;
    } else {
      newCurrentOperation = field?.supportedOperations[0];
    }

    form.setFieldsValue({
      val: Array.isArray(currentField?.value) ? null : currentField?.value
    });

    setCurrentValueField(currentField?.value);
    setCurrentOperation(newCurrentOperation);
  }, [form, field, currentField]);

  /**
   * Render field for multiple value
   */
  const renderFieldForMultipleValue = () => {
    return (
      <div className="operation-field-string-number mb-0" style={{ padding: '8px 12px 5px 12px' }}>
        <Select
          value={currentOperation}
          options={
            Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
              ? field?.supportedOperations.map(sub => {
                  return {
                    label: sub,
                    value: sub
                  };
                })
              : []
          }
          onSelect={val => {
            setCurrentValueField([]);
            setCurrentOperation(val);
            form.setFieldsValue({ val: '' });
          }}
        />

        <Select
          style={{ width: '243px' }}
          mode="tags"
          value={currentValueField}
          options={[]}
          onChange={newValue => {
            setCurrentValueField(newValue);
          }}
          placeholder={t('common.pleaseSelect')}
          maxTagCount={2}
        />
      </div>
    );
  };

  /**
   * Render field for single value
   */
  const renderFieldForSingleValue = () => {
    return (
      <Form.Item
        name="val"
        rules={[
          {
            pattern: field?.componentType === COMPONENT_TYPE.NUMBER ? new RegExp(NUMBER_PATTERN) : null,
            message: t('message.fieldMustBeANumber')
          },
          {
            pattern: field?.componentType === COMPONENT_TYPE.STRING ? new RegExp(SPECIAL_CHARACTERS_PATTERN) : null,
            message: t('message.fieldNotContainCharacters')
          },
          {
            pattern: field?.componentType === COMPONENT_TYPE.TIME_TRACKING ? ESTIMATED_TIME_PATTERN : null,
            message: t('message.invalidFormat')
          }
        ]}
        wrapperCol={{ xs: 24 }}
        style={{ padding: '8px 12px 5px 12px' }}
        className="mb-0"
      >
        <Input
          addonBefore={
            field?.supportedOperations ? (
              <Select
                value={currentOperation}
                options={
                  Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
                    ? field?.supportedOperations.map(sub => {
                        return {
                          label: sub,
                          value: sub
                        };
                      })
                    : []
                }
                dropdownMatchSelectWidth={false}
                onSelect={val => {
                  setCurrentValueField([]);
                  setCurrentOperation(val);

                  if (val === 'isNull') {
                    form.resetFields(['val']);
                  }
                }}
              />
            ) : (
              ''
            )
          }
          disabled={currentOperation === 'isNull'}
          suffix={
            field?.componentType === COMPONENT_TYPE.TIME_TRACKING ? (
              <Tooltip
                title={<SafeInnerHtml html={t('common.estimatedTimeHelp')} />}
                placement="top"
                destroyTooltipOnHide={true}
              >
                <InfoCircleOutlined className="text-gray ml-2" />
              </Tooltip>
            ) : null
          }
          autoFocus
          autoComplete="off"
          onPressEnter={() => form.submit()}
        />
      </Form.Item>
    );
  };

  /**
   * On submit
   */
  const onSubmit = values => {
    if (currentOperation === 'in' || currentOperation === 'not in') {
      onApply(currentValueField, currentOperation);
    } else if (currentOperation === 'isNull') {
      onApply(null, currentOperation);
    } else {
      onApply(values?.val, currentOperation);
    }

    setTimeout(() => handleSetVisibleDropdown(false), 300);
  };

  return (
    <Form
      form={form}
      className="ant-dropdown-menu c-field-string-number"
      onClick={e => e.stopPropagation()}
      onFinish={onSubmit}
    >
      {currentOperation === 'in' || currentOperation === 'not in'
        ? renderFieldForMultipleValue()
        : renderFieldForSingleValue()}

      <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

      <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
        <Button
          size="small"
          className="w-auto ml-2"
          onClick={() => {
            handleSetVisibleDropdown(false);
            onClear();
          }}
        >
          {t('common.clear')}
        </Button>

        <Button htmlType="submit" type="primary" size="small" className="w-auto ml-2">
          {t('common.apply')}
        </Button>
      </div>
    </Form>
  );
};

export default DropdownContent;
