import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Layout } from 'antd';

import env from '../../env';
import { HOME_MENU, PROJECT_SETTING_MENU, PROJECT_MANAGER_MENU, PROJECT_ENGINE_MENU } from '../../constants';
import { getParentMenuByPathname } from '../../common/utils';
import { BugSearching } from '../../assets/svg-icons';
import { TenantListSidebar } from './tenant-list-sidebar';
import { HomeSidebar } from './home-sidebar';
import { ProjectManagerSidebar } from './project-manager-sidebar';
import { ProjectSettingSidebar } from './project-setting-sidebar';

import './style.scss';

export const LeftSidebar = () => {
  const location = useLocation();

  const tenantPath = location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
  const projectPath = location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

  // For global tenant store
  const globalTenant = useStoreState(state => state.global.globalTenant);
  const myTenantList = useStoreState(state => state.global.myTenantList);

  // For global production key store
  const globalProcessList = useStoreState(state => state.global.globalProcessList);

  // Component state
  const [workItemList, setWorkItemList] = useState([]);

  /**
   * Has my tenant list
   */
  const hasMyTenantList = useMemo(() => {
    return Array.isArray(myTenantList) && myTenantList.length > 0;
  }, [myTenantList]);

  /**
   * Set work item list
   */
  useEffect(() => {
    if (!(Array.isArray(globalProcessList) && globalProcessList.length)) {
      return;
    }

    const list = globalProcessList.map(item => {
      return {
        id: `${item?.workTicketId}`,
        parentId: null,
        groupId: 'workItems',
        path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/${item?.workTicketId}`,
        name: `${item?.name}`,
        icon: <BugSearching />
      };
    });

    setWorkItemList(list);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalProcessList]);

  /**
   * Return component
   */
  if (!projectPath && tenantPath && tenantPath === globalTenant?.tenantKey && hasMyTenantList) {
    return <TenantListSidebar />;
  }

  if (getParentMenuByPathname(location.pathname, HOME_MENU)) {
    return <HomeSidebar />;
  }

  if (getParentMenuByPathname(location.pathname, PROJECT_SETTING_MENU)) {
    return <ProjectSettingSidebar />;
  }

  if (getParentMenuByPathname(location.pathname, [...PROJECT_MANAGER_MENU, ...workItemList, ...PROJECT_ENGINE_MENU])) {
    return <ProjectManagerSidebar />;
  }

  return <Layout.Sider collapsible theme="light" width={220} className="c-left-sidebar" />;
};
