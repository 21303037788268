import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Form } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import objectPath from 'object-path';
import _debounce from 'lodash/debounce';

import {
  PRIORITIES,
  URL_PATTERN,
  NUMBER_PATTERN,
  COMPONENT_TYPE,
  SYSTEM_FIELD_KEY,
  SYSTEM_FIELD_NAME,
  SYSTEM_FIELD_PRIORITY
} from '../../constants';
import {
  debounce,
  checkIsNotEmptyArray,
  checkIsTagFieldRefName,
  getPrePathLinkToTicket,
  filterOptionForUserField,
  convertMinutesToShortTime
} from '../../common/utils';
import { useField, useUnassignedUser } from '../../common/hooks';
import {
  UserAvatar,
  EditableInput,
  EditableSelectOption,
  StatusLabelForWorkflow,
  EditableSelectMultiOption
} from '../../components';

const LANG_COMMON_PLEASE_SELECT = 'common.pleaseSelect';
const LANG_COMMON_ENTER_VALUE = 'common.enterValue';
const LANG_MESSAGE_REQUIRED = 'message.required';
const LANG_HAS_ICON_REQUIRED = 'has-icon-required';

let typingTimerOfSearch = 0;

export const CustomField = ({ field, form, uploadPath, isReadOnly = false, onSave, className = '', ...rest }) => {
  // For language
  const [t] = useTranslation('akaat');

  // For unassigned user
  const [UNASSIGNED] = useUnassignedUser();

  // For hooks
  const { getSuggestionData, getRelationLabel } = useField();

  // For global store
  const getTicketTypeById = useStoreActions(action => action.global.getTicketTypeById);
  const ticketListData = useStoreState(state => state.global.ticketListData);
  const projectUserList = useStoreState(state => state.global.projectUserList);
  const loadingProjectUserList = useStoreState(state => state.global.loadingProjectUserList);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // Component state
  const [suggestionData, setSuggestionData] = useState({});
  const [suggestionParams, setSuggestionParams] = useState({});
  const [suggestionKeyword, setSuggestionKeyword] = useState({});
  const [userSearchText, setUserSearchText] = useState('');

  /**
   * Get ticket type by id
   */
  useEffect(() => {
    if (!field?.lookup?.workTicketId) {
      return;
    }

    if (!ticketListData?.[field?.lookup?.workTicketId]?.id) {
      getTicketTypeById(field?.lookup?.workTicketId);
    }
  }, [field?.lookup?.workTicketId, ticketListData, getTicketTypeById]);

  /**
   * Compute user list (users no in tenant)
   */
  const userList = useMemo(() => {
    const usersByRole = Array.isArray(projectUserList) && projectUserList.length ? [...projectUserList] : [];

    if (userSearchText === '') {
      return usersByRole;
    }

    const newList = [...usersByRole].filter(item => {
      return filterOptionForUserField(userSearchText, { item });
    });

    return newList;
  }, [userSearchText, projectUserList, loadingProjectUserList]);

  /**
   * Handle get suggestion data
   */
  const handleGetSuggestionData = ({ field, page, keyword, isLoadType }) => {
    getSuggestionData({
      field,
      page,
      keyword,
      isLoadType,
      setSuggestionData,
      suggestionData,
      setSuggestionParams,
      suggestionParams,
      suggestionKeyword
    });
  };

  /**
   * On search suggestion data
   */
  const onSearchSuggestionData = useCallback(
    _debounce(({ field, keyword, isLoadType }) => {
      handleGetSuggestionData({ field, keyword, isLoadType });
    }, 500),
    []
  );

  /**
   * Get field item tag ref name
   */
  const getFieldItemTagRefName = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          required={field?.mandatory}
          className={`c-custom-field type-string ref-name-tags ${
            field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''
          } ${className}`}
          {...rest}
        >
          <EditableSelectOption
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            type="TAGS"
            form={form}
            defaultVal={
              field?.currentValue && typeof field?.currentValue === 'string' ? field?.currentValue.split(',') : []
            }
            restFormItem={{ name: field?.name, rules }}
            isReadOnly={isReadOnly}
            placeholder={t('workItem.enterTags')}
            onChange={val => form.setFieldsValue({ [field?.refName]: val })}
            onClear={val => form.setFieldsValue({ [field?.refName]: val })}
            onSave={val => onSave({ [field?.refName]: val?.join(',') })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item string
   */
  const getFieldItemString = field => {
    const rules = [
      { required: field?.mandatory, whitespace: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
      {
        min: field?.refName === SYSTEM_FIELD_NAME && field?.mandatory ? 2 : null,
        message: t('message.minLength', { min: 2 })
      }
    ];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          required={field?.mandatory}
          className={`c-custom-field type-string ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableInput
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            restSaveButton={{ id: `save-button_${field?.refName}-field` }}
            restCloseButton={{ id: `close-button_${field?.refName}-field` }}
            form={form}
            defaultVal={field?.currentValue}
            restFormItem={{ name: field?.name, rules }}
            iShowTitle={true}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_ENTER_VALUE)}
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item option
   */
  const getFieldItemOption = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-option ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableSelectOption
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            form={form}
            defaultVal={field?.currentValue}
            options={
              Array.isArray(field?.data) && field?.data.length
                ? field?.data.map(item => ({
                    label: item.label,
                    value: item.value
                  }))
                : []
            }
            restFormItem={{ name: field?.name, rules }}
            restField={{ showSearch: true, allowClear: !field?.mandatory }}
            iShowTitle={true}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_PLEASE_SELECT)}
            onSelect={val => form.setFieldsValue({ [field?.refName]: val })}
            onClear={val => form.setFieldsValue({ [field?.refName]: val })}
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item picklist
   */
  const getFieldItemPicklist = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    const options =
      Array.isArray(field?.data) && field?.data.length
        ? field?.data.map(item => ({
            label: item.label,
            value: item.value
          }))
        : [];

    const defaultVal =
      Array.isArray(field?.currentValue) && field?.currentValue.length
        ? field?.currentValue.filter(v => options.some(o => o?.value === v))
        : [];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-picklist ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableSelectMultiOption
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            form={form}
            defaultVal={defaultVal}
            options={options}
            restFormItem={{ name: field?.name, rules }}
            restField={{ showSearch: true, allowClear: !field?.mandatory }}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_PLEASE_SELECT)}
            onChange={val => form.setFieldsValue({ [field?.refName]: val })}
            onClear={() => form.setFieldsValue({ [field?.refName]: [] })}
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item status
   */
  const getFieldItemStatus = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          rules={rules}
          required={field?.mandatory}
          className={`c-custom-field type-status ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          {field?.currentValue ? (
            <StatusLabelForWorkflow
              status={field?.currentValue}
              workTicketId={field?.workTicketId}
              allowChangeStatus={false}
              showViewWorkflowButton={true}
            />
          ) : (
            <span className="text-gray">N/A</span>
          )}
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item user
   */
  const getFieldItemUser = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          rules={rules}
          required={field?.mandatory}
          className={`c-custom-field type-user ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          {isReadOnly ? (
            field?.currentValue ? (
              <div className="text-truncate">
                <UserAvatar user={field?.currentValue} />
              </div>
            ) : (
              <span className="text-gray">N/A</span>
            )
          ) : (
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              defaultVal={field?.currentValue || UNASSIGNED.value}
              options={
                Array.isArray(userList) && userList.length
                  ? [
                      UNASSIGNED,
                      ...userList.map(item => {
                        return {
                          label: <UserAvatar user={item} />,
                          value: item.username
                        };
                      })
                    ]
                  : [UNASSIGNED]
              }
              restField={{
                filterOption: false,
                optionFilterProp: 'value',
                allowClear: !field?.mandatory,
                dropdownMatchSelectWidth: false
              }}
              loading={loadingProjectUserList}
              placeholder={t(LANG_COMMON_PLEASE_SELECT)}
              isReadOnly={isReadOnly}
              onSearch={val => {
                clearTimeout(typingTimerOfSearch);

                typingTimerOfSearch = setTimeout(
                  debounce(() => setUserSearchText(val)),
                  300
                );
              }}
              onSelect={val => onSave({ [field?.refName]: val })}
            />
          )}
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item priority
   */
  const getFieldItemPriority = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-priority ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          {field?.currentValue ? (
            <EditableSelectOption
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              form={form}
              defaultVal={field?.currentValue}
              options={PRIORITIES}
              hasPrefixIcon={true}
              restFormItem={{
                name: SYSTEM_FIELD_PRIORITY,
                rules
              }}
              restField={{ allowClear: !field?.mandatory }}
              placeholder={t(LANG_COMMON_PLEASE_SELECT)}
              isReadOnly={isReadOnly}
            />
          ) : (
            <span className="text-gray">N/A</span>
          )}
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item time tracking
   */
  const getFieldItemTimeTracking = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-time-tracking ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          {field?.currentValue ? (
            <EditableInput
              restValueText={{ id: `value_${field?.refName}-field` }}
              restEditButton={{ id: `edit-button_${field?.refName}-field` }}
              restSaveButton={{ id: `save-button_${field?.refName}-field` }}
              restCloseButton={{ id: `close-button_${field?.refName}-field` }}
              form={form}
              defaultVal={/^[0-9]*$/.test(field?.currentValue) ? convertMinutesToShortTime(field?.currentValue) : null}
              restFormItem={{
                name: field?.name,
                rules: rules
              }}
              restField={{ allowClear: !field?.mandatory }}
              iShowTitle={true}
              isReadOnly={isReadOnly}
              placeholder={t('workItem.enterEstimatedTime')}
            />
          ) : (
            <span className="text-gray">N/A</span>
          )}
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item date / datetime
   */
  const getFieldItemDateOrDatetime = field => {
    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-date-time-date ${
            field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''
          } ${className}`}
          {...rest}
        >
          <EditableInput
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            restSaveButton={{ id: `save-button_${field?.refName}-field` }}
            restCloseButton={{ id: `close-button_${field?.refName}-field` }}
            type={field?.componentType === COMPONENT_TYPE.DATE ? 'DATEPICKER' : 'DATETIMEPICKER'}
            form={form}
            defaultVal={field?.currentValue}
            restFormItem={{ name: field?.refName, rules }}
            restField={{ allowClear: !field?.mandatory }}
            iShowTitle={true}
            isReadOnly={isReadOnly}
            onSave={val => {
              onSave({
                [field?.refName]: val && moment(val).isValid() ? moment(val).format() : null
              });
            }}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item number
   */
  const getFieldItemNumber = field => {
    const rules = [
      { required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
      { pattern: NUMBER_PATTERN, message: t('message.fieldMustBeANumber') }
    ];

    return (
      <Form.Item
        label={
          <span id={`label_${field?.refName}-field`} title={field?.name}>
            {field?.name}
          </span>
        }
        name={field?.refName}
        required={field?.mandatory}
        className={`c-custom-field type-number ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
        {...rest}
      >
        <EditableInput
          restValueText={{ id: `value_${field?.refName}-field` }}
          restEditButton={{ id: `edit-button_${field?.refName}-field` }}
          restSaveButton={{ id: `save-button_${field?.refName}-field` }}
          restCloseButton={{ id: `close-button_${field?.refName}-field` }}
          form={form}
          defaultVal={field?.currentValue}
          restFormItem={{ name: field?.refName, rules }}
          iShowTitle={true}
          isReadOnly={isReadOnly}
          placeholder={t(LANG_COMMON_ENTER_VALUE)}
          onChange={() => form.validateFields([field?.refName])}
          onSave={val => onSave({ [field?.refName]: val })}
        />
      </Form.Item>
    );
  };

  /**
   * Get field item url
   */
  const getFieldItemUrl = field => {
    const rules = [
      { required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) },
      { pattern: URL_PATTERN, message: t('message.invalidFormat') }
    ];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-url ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableInput
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            restSaveButton={{ id: `save-button_${field?.refName}-field` }}
            restCloseButton={{ id: `close-button_${field?.refName}-field` }}
            form={form}
            defaultVal={field?.currentValue}
            restFormItem={{ name: field?.refName, rules }}
            iShowTitle={true}
            isLink={true}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_ENTER_VALUE)}
            onChange={() => form.validateFields([field?.refName])}
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item html
   */
  const getFieldItemHtml = field => {
    const rules = [{ required: field?.mandatory, whitespace: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    return (
      <>
        <Form.Item
          labelCol={{ span: 24 }}
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          required={field?.mandatory}
          className={`c-custom-field type-html ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableInput
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            restSaveButton={{ id: `save-button_${field?.refName}-field` }}
            restCloseButton={{ id: `close-button_${field?.refName}-field` }}
            type="EDITOR"
            form={form}
            defaultVal={field?.currentValue}
            restFormItem={{ name: field?.refName, rules }}
            restField={{ rows: 10 }}
            uploadPath={uploadPath}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_ENTER_VALUE)}
            className="pt-1"
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Get field item folder
   */
  const getFieldItemFolder = field => {
    return (
      <Form.Item
        label={
          <span id={`label_${field?.refName}-field`} title={field?.name}>
            {field?.name}
          </span>
        }
        required={field?.mandatory}
        className={`c-custom-field type-number ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
        {...rest}
      >
        <EditableInput
          restValueText={{ id: `value_${field?.refName}-field` }}
          restEditButton={{ id: `edit-button_${field?.refName}-field` }}
          restSaveButton={{ id: `save-button_${field?.refName}-field` }}
          restCloseButton={{ id: `close-button_${field?.refName}-field` }}
          form={form}
          defaultVal={field?.currentValue}
          iShowTitle={true}
          isReadOnly={isReadOnly}
          placeholder={t(LANG_COMMON_ENTER_VALUE)}
        />
      </Form.Item>
    );
  };

  /**
   * Get field item suggestion or relation
   */
  const getFieldItemSuggestionOrRelation = field => {
    if (!ticketListData?.[field?.lookup?.workTicketId]?.id) {
      return;
    }

    const rules = [{ required: field?.mandatory, message: t(LANG_MESSAGE_REQUIRED) }];

    let fields = ticketListData?.[field?.lookup?.workTicketId]?.fields;
    fields = checkIsNotEmptyArray(fields) ? [...fields] : [];
    const workFlow = ticketListData?.[field?.lookup?.workTicketId]?.workFlow;

    const fieldToDisplay = fields.find(f => f?.refName === field?.data?.displayField);
    const relationItem = field?.currentValue;
    const relationItemKey = field?.currentValue?.[SYSTEM_FIELD_KEY];
    const relationLabel = getRelationLabel({ relationItem, fieldToDisplay, workFlow });

    const link = (
      <a
        title={`${relationItemKey} - ${relationLabel?.plainText || ''}`}
        href={`${getPrePathLinkToTicket({ workTicketId: field?.lookup?.workTicketId, noGoToTestcaseVersion: true })}${
          relationItem?.[SYSTEM_FIELD_KEY]
        }`}
        target="_blank"
        rel="noreferrer"
        className={`component-type-${fieldToDisplay?.componentType} ref-name-${fieldToDisplay?.refName} text-truncate`}
        onClick={e => e.stopPropagation()}
      >
        {relationItemKey}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
      </a>
    );

    const defaultValueForSuggestionField = relationItem?.[SYSTEM_FIELD_KEY] ? (
      link
    ) : isReadOnly ? (
      <span className="text-gray">N/A</span>
    ) : (
      <span className="text-gray">{t(LANG_COMMON_PLEASE_SELECT)}</span>
    );

    const options = checkIsNotEmptyArray(suggestionData?.[field?.refName]?.rows)
      ? suggestionData?.[field?.refName]?.rows?.map(item => {
          const key = item?.[SYSTEM_FIELD_KEY];
          const relationLabel = getRelationLabel({ relationItem: item, fieldToDisplay, workFlow });

          return {
            title: `${key} - ${relationLabel?.plainText || ''}`,
            label: (
              <div className="text-truncate">
                {key}&nbsp;-&nbsp;{relationLabel?.label || <span className="text-gray">N/A</span>}
              </div>
            ),
            value: item?.[field?.data?.fieldValue]
          };
        })
      : [];

    return (
      <>
        <Form.Item
          label={
            <span id={`label_${field?.refName}-field`} title={field?.name}>
              {field?.name}
            </span>
          }
          name={field?.refName}
          required={field?.mandatory}
          className={`c-custom-field type-relation ${field?.mandatory ? LANG_HAS_ICON_REQUIRED : ''} ${className}`}
          {...rest}
        >
          <EditableSelectOption
            restValueText={{ id: `value_${field?.refName}-field` }}
            restEditButton={{ id: `edit-button_${field?.refName}-field` }}
            form={form}
            defaultVal={objectPath.get(relationItem, field?.data?.fieldValue)}
            defaultValueForSuggestionField={defaultValueForSuggestionField}
            options={options}
            restFormItem={{ name: field?.name, rules }}
            restField={{
              optionFilterProp: 'title',
              showSearch: true,
              allowClear: !field?.mandatory,
              filterOption: false,
              loading: loadingSuggestion,
              onPopupScroll: e => {
                if (loadingSuggestion) {
                  return;
                }

                if (
                  e?.target?.scrollTop + e?.target?.offsetHeight === e?.target?.scrollHeight &&
                  !suggestionParams?.[field?.refName]?.isLoadedAll
                ) {
                  const page = suggestionParams?.[field?.refName]?.page;
                  handleGetSuggestionData({ field, page: page + 1, isLoadType: 'SCROLL' });
                }
              },
              onSearch: keyword => {
                setSuggestionKeyword({ ...suggestionKeyword?.[field?.refName], [field?.refName]: keyword });
                onSearchSuggestionData({ field, keyword, isLoadType: 'SEARCH' });
              }
            }}
            isReadOnly={isReadOnly}
            placeholder={t(LANG_COMMON_PLEASE_SELECT)}
            onFocus={() => handleGetSuggestionData({ field, page: 1, isLoadType: 'SCROLL' })}
            onSelect={val => form.setFieldsValue({ [field?.refName]: val })}
            onSave={val => onSave({ [field?.refName]: val })}
          />
        </Form.Item>
      </>
    );
  };

  /**
   * Render field item batch 3
   */
  const renderFieldItemBatch3 = field => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.DATE:
      case COMPONENT_TYPE.DATE_TIME: {
        return getFieldItemDateOrDatetime(field);
      }

      case COMPONENT_TYPE.NUMBER: {
        return getFieldItemNumber(field);
      }

      case COMPONENT_TYPE.URL: {
        return getFieldItemUrl(field);
      }

      case COMPONENT_TYPE.HTML: {
        return getFieldItemHtml(field);
      }

      case COMPONENT_TYPE.FOLDER: {
        return getFieldItemFolder(field);
      }

      case COMPONENT_TYPE.SUGGESTION:
      case COMPONENT_TYPE.RELATION: {
        return getFieldItemSuggestionOrRelation(field);
      }

      default: {
        return null;
      }
    }
  };

  /**
   * Render field item batch 2
   */
  const renderFieldItemBatch2 = field => {
    switch (field?.componentType) {
      case COMPONENT_TYPE.STRING: {
        return getFieldItemString(field);
      }

      case COMPONENT_TYPE.OPTION: {
        return getFieldItemOption(field);
      }

      case COMPONENT_TYPE.PICKLIST: {
        return getFieldItemPicklist(field);
      }

      case COMPONENT_TYPE.STATUS: {
        return getFieldItemStatus(field);
      }

      case COMPONENT_TYPE.USER: {
        return getFieldItemUser(field);
      }

      case COMPONENT_TYPE.PRIORITY: {
        return getFieldItemPriority(field);
      }

      case COMPONENT_TYPE.TIME_TRACKING: {
        return getFieldItemTimeTracking(field);
      }

      default: {
        return renderFieldItemBatch3(field); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  /**
   * Render field item batch 1
   */
  const renderFieldItemBatch1 = field => {
    switch (field?.refName) {
      case checkIsTagFieldRefName(field?.refName): {
        return getFieldItemTagRefName(field);
      }

      default: {
        return renderFieldItemBatch2(field); // Tách ra nhiều batch để sửa lỗi sonar: Độ phức tạp của hàm không được vượt quá 10
      }
    }
  };

  return renderFieldItemBatch1(field);
};
