import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Tooltip } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { ESTIMATED_TIME_PATTERN, NUMBER_PATTERN, COMPONENT_TYPE } from '../../../constants';
import { convertMinutesToShortTime, getFieldName } from '../../../common/utils';
import DropdownContent from './dropdown-content';

// For string and number type
export const FieldStringNumber = ({
  field,
  currentValues,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);

  /**
   * Check valid string value
   */
  const checkValidStringValue = ({ field, newField }) => {
    const condition1 =
      field?.componentType === COMPONENT_TYPE.STRING && newField?.operation === 'isNull' && newField?.value == null;
    const condition2 = field?.componentType === COMPONENT_TYPE.STRING && newField?.value;

    return condition1 || condition2;
  };

  /**
   * Check valid number value
   */
  const checkValidNumberValue = ({ field, newField }) => {
    const isArrayType = ['in', 'not in'].includes(newField?.operation);
    const condition1 =
      field?.componentType === COMPONENT_TYPE.NUMBER && newField?.operation === 'isNull' && newField?.value == null;
    const condition2 = field?.componentType === COMPONENT_TYPE.NUMBER && isArrayType && Array.isArray(newField?.value);
    const condition3 =
      field?.componentType === COMPONENT_TYPE.NUMBER && new RegExp(NUMBER_PATTERN).test(newField?.value);
    return condition1 || condition2 || condition3;
  };

  /**
   * Check valid html value
   */
  const checkValidHtmlValue = ({ field, newField }) => {
    const condition1 =
      field?.componentType === COMPONENT_TYPE.HTML && newField?.operation === 'isNull' && newField?.value == null;
    const condition2 = field?.componentType === COMPONENT_TYPE.HTML && newField?.value;

    return condition1 || condition2;
  };

  /**
   * Check valid url value
   */
  const checkValidUrlValue = ({ field, newField }) => {
    const condition1 =
      field?.componentType === COMPONENT_TYPE.URL && newField?.operation === 'isNull' && newField?.value == null;
    const condition2 = field?.componentType === COMPONENT_TYPE.URL && newField?.value;

    return condition1 || condition2;
  };

  /**
   * Check valid time tracking value
   */
  const checkValidTimeTrackingValue = ({ field, newField }) => {
    return (
      field?.componentType === COMPONENT_TYPE.TIME_TRACKING &&
      (new RegExp(ESTIMATED_TIME_PATTERN).test(convertMinutesToShortTime(newField?.value)) ||
        convertMinutesToShortTime(newField?.value) === t('common.unestimated'))
    );
  };

  /**
   * Check valid current values
   */
  const checkValidCurrentValues = ({ field, newField }) => {
    const condition1 =
      checkValidStringValue({ field, newField }) ||
      checkValidNumberValue({ field, newField }) ||
      checkValidHtmlValue({ field, newField }) ||
      checkValidUrlValue({ field, newField });

    const condition2 = checkValidTimeTrackingValue({ field, newField });

    return condition1 || condition2;
  };

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.refName || !(Array.isArray(currentValues) && currentValues.length)) {
      return null;
    }

    const newField = [...currentValues].find(item => item?.refName === field?.refName);

    if (newField && newField?.componentType === COMPONENT_TYPE.TIME_TRACKING) {
      newField.value = convertMinutesToShortTime(newField.value);
    }

    if (!checkValidCurrentValues({ field, newField })) {
      return null;
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Text value
   */
  const textValue = useMemo(() => {
    return currentField
      ? /\.\*(.*)\.\*/.exec(currentField?.value)?.[1]
        ? /\.\*(.*)\.\*/.exec(currentField?.value)[1]
        : currentField?.value
      : null;
  }, [currentField]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    return `${fieldName}: ${
      textValue || currentField?.operation === 'isNull' ? currentField?.operation : t('common.all')
    } ${textValue || ''}`;
  }, [fieldName, textValue, currentField]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [
            {
              key: 'menu',
              label: (
                <DropdownContent
                  field={field}
                  currentField={currentField}
                  handleSetVisibleDropdown={setVisibleDropdown}
                  onClear={onClear}
                  onApply={onApply}
                />
              ),
              className: 'p-0'
            }
          ]
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        disabled={disabled}
        overlayStyle={{ minWidth: 290 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={setVisibleDropdown}
        {...restDropdown}
      >
        <Button title={btnFieldTitle} className={`btn-field field-string-number ${className}`} {...rest}>
          {currentField ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {textValue || currentField?.operation === 'isNull' ? currentField?.operation : ''}{' '}
                {Array.isArray(textValue) ? textValue.join(',') : textValue}
              </span>
              <CaretDownOutlined className="ic-caret-down ml-1" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {t('common.all')}
              </span>
              <CaretDownOutlined className="ic-caret-down ml-1" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
