import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal, Row } from 'antd';

import { AttachmentPlus } from '../../assets/svg-icons';
import { BasicUploadMultipleFiles } from '../basic-upload-multiple-files';
import { BasicPasteUploadFile } from '../basic-paste-upload-file';

export const BasicUploadMultipleFilesModal = ({
  visible,
  isProtectedFile,
  onlyPreviewFileOnClient,
  attachments,
  uploadPath,
  hasGetFullFilesInfo,
  maxCount,
  directlyDeleteAttachment = true,
  enabledPasteToUpload = true,
  isReadOnly = false,
  className = '',
  onChangeAttachments,
  onChangeDeteledAttachmentIds,
  onCancel,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For component state
  const [currentAttachments, setCurrentAttachments] = useState([]);

  /**
   * Set current attachments
   */
  useEffect(() => {
    setCurrentAttachments(attachments);
  }, [attachments]);

  return (
    <>
      <Modal
        open={visible}
        width={950}
        maskClosable={false}
        keyboard={false}
        footer={null}
        forceRender
        centered // For "modal-fixed-header"
        wrapClassName="modal-fixed-header"
        className={`c-basic-upload-multiple-files-modal hide-modal-close hide-modal-header modal-content-rounded-10 p-0-modal-body ${className}`}
        onCancel={onCancel}
        {...rest}
      >
        <div className="px-4 pt-3">
          <Row justify="space-between" align="middle">
            <h3 className="text-primary font-weight-medium font-size-16 m-0">
              <AttachmentPlus className="mr-1" /> {t('workItem.attachments')}
            </h3>
          </Row>

          <Divider className="mt-3 mb-0" />
        </div>

        <div className="modal-body-with-scroll" style={{ maxHeight: 'calc(100vh - 126px)' }}>
          <div className="form-wrapper p-4">
            <BasicUploadMultipleFiles
              isProtectedFile={isProtectedFile}
              onlyPreviewFileOnClient={onlyPreviewFileOnClient}
              attachments={currentAttachments}
              uploadPath={uploadPath}
              hasGetFullFilesInfo={hasGetFullFilesInfo}
              maxCount={maxCount}
              directlyDeleteAttachment={directlyDeleteAttachment}
              isReadOnly={isReadOnly}
              onChangeAttachments={val => {
                setCurrentAttachments(val);
                typeof onChangeAttachments === 'function' && onChangeAttachments(val);
              }}
              onChangeDeteledAttachmentIds={ids => {
                typeof onChangeDeteledAttachmentIds === 'function' && onChangeDeteledAttachmentIds(ids);
              }}
            />
          </div>
        </div>

        <div className="ant-modal-footer border-top-0 pt-0 px-4 pb-3">
          <Divider className="mt-0 mb-3" />

          <div className="text-right">
            <Button type="text" onClick={onCancel}>
              {t('common.close')}
            </Button>
          </div>
        </div>
      </Modal>

      {enabledPasteToUpload && (
        <BasicPasteUploadFile
          attachments={currentAttachments}
          acceptMineTypes={['image/']}
          uploadPath={uploadPath}
          disabledWhenOpeningAnyModal={false}
          onChangeAttachments={val => {
            setCurrentAttachments(val);
            typeof onChangeAttachments === 'function' && onChangeAttachments(val);
          }}
        />
      )}
    </>
  );
};
