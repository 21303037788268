import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Dropdown, Empty, Input, Tooltip, Checkbox, Spin, Tabs, Divider } from 'antd';
import { SearchOutlined, CaretDownOutlined, CloseCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';

import { WORK_ITEM_SEARCH_FIELD_LABEl_RENDER } from '../../../constants/fields/manager-fields';
import { checkIsNotEmptyArray, debounce, getFieldName } from '../../../common/utils';
import { JIRA_PLATFORM_ID, TESTMAN_PLATFORM_ID } from '../../../constants';

let typingTimerOfSearch = 0;

export const FieldPriority = ({
  isTestTicket,
  field,
  defaultSystem = TESTMAN_PLATFORM_ID,
  currentValues,
  allowSearch = true,
  disabled,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // For global project store
  const getSuggestion = useStoreActions(action => action.global.getSuggestion); // Get all data for picklist
  const suggestion = useStoreState(state => state.global.suggestion);
  const loadingSuggestion = useStoreState(state => state.global.loadingSuggestion);

  // For jira integration
  const priorityList = useStoreState(state => state.jiraIntegration.priorityList);
  const loadingPriorityList = useStoreState(state => state.jiraIntegration.loadingPriorityList);

  // For integration
  const integrationSystemList = useStoreState(state => state.integration.integrationSystemList);

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [system, setSystem] = useState();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  /**
   * Has current values
   */
  const hasCurrentValues = useMemo(() => {
    return Array.isArray(currentValues) && currentValues.length > 0;
  }, [currentValues]);

  /**
   * Set default system
   */
  useEffect(() => {
    setSystem(defaultSystem);
  }, [defaultSystem]);

  /**
   * Set option list
   */
  useEffect(() => {
    if (!system) {
      return;
    }

    const newOptionList = getOptions();
    setOptions(newOptionList);
  }, [t, field, priorityList, system]);

  /**
   * Get options
   */
  const getOptions = () => {
    const newOptionList = [];

    if (Array.isArray(priorityList) && priorityList.length) {
      newOptionList.push(
        ...priorityList.map(item => {
          return {
            ...item,
            option: item,
            systemType: JIRA_PLATFORM_ID
          };
        })
      );
    }

    if (Array.isArray(field?.data) && field?.data.length) {
      newOptionList.push(
        ...field.data.map(item => {
          return {
            label: t(item.label),
            value: item.value,
            systemType: TESTMAN_PLATFORM_ID
          };
        })
      );
    }

    return newOptionList;
  };

  /**
   * Compute current selected options from currentValues
   */
  const currentSelectedOptions = useMemo(() => {
    if (!field?.refName || !hasCurrentValues || !(Array.isArray(options) && options.length)) {
      return [];
    }

    const currentField = [...currentValues].find(item => item.refName === field.refName);
    const currentValue = Array.isArray(currentField?.value) && currentField.value.length ? currentField.value : [];
    const newSelectedOptions = options.filter(
      item => currentValue.includes(item.value) || currentValue.includes(Number(item.value))
    );

    return newSelectedOptions;
  }, [field, options, hasCurrentValues, currentValues]);

  /**
   * On visible change
   */
  const onVisibleChange = visible => {
    setVisibleDropdown(visible);
    handleSearch('');

    if (visible) {
      setSelectedOptions(currentSelectedOptions);

      // For: Check all
      const newOriginOptions = Array.isArray(options) && options.length ? [...options] : [];
      setIsCheckAll(currentSelectedOptions.length === newOriginOptions.length);
      setIsIndeterminate(currentSelectedOptions.length > 0 && currentSelectedOptions.length < newOriginOptions.length);
    }

    if (visible && field?.refName && field?.data?.url && !suggestion?.[field.refName]?.loadedCount) {
      getSuggestion({
        referenceField: field.refName,
        url: field.data.url,
        page: null,
        limit: null,
        order: null,
        group: field.data.fieldValue
      });
    }
  };

  /**
   * Handle change selected
   */
  const handleChangeSelected = (checked, currentOption) => {
    if (loadingSuggestion || !currentOption?.value) {
      return;
    }

    let newSelectedValues = selectedOptions.filter(item => options.some(item1 => item1.value === item.value));

    if (checked) {
      newSelectedValues = [...newSelectedValues, currentOption];
    } else {
      newSelectedValues = [...newSelectedValues, currentOption].filter(item => item.value !== currentOption.value);
    }

    setSelectedOptions(newSelectedValues);

    // For: Check all
    const newOriginOptions = Array.isArray(options) && options.length ? [...options] : [];
    setIsCheckAll(newSelectedValues.length === newOriginOptions.length);
    setIsIndeterminate(newSelectedValues.length > 0 && newSelectedValues.length < newOriginOptions.length);
  };

  /**
   * On check all fields
   */
  const onCheckAllFields = isChecked => {
    const newOriginOptions = Array.isArray(options) && options.length ? [...options] : [];

    setSelectedOptions(isChecked ? [...newOriginOptions] : []);
    setIsIndeterminate(false);
    setIsCheckAll(isChecked);
  };

  /**
   * Handle search
   */
  const handleSearch = val => {
    if (!field?.refName) {
      return;
    }

    clearTimeout(typingTimerOfSearch);

    typingTimerOfSearch = setTimeout(
      debounce(() => {
        const originList = getOptions();

        const newOptionList = originList
          .filter(item => typeof item.label === 'string' && item.label.toLowerCase().indexOf(val.toLowerCase()) !== -1)
          .map(item => {
            return {
              label: t(item.label),
              value: item.value,
              ...item
            };
          });

        setOptions(newOptionList);
      }),
      300
    );
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    const selectedValue = checkIsNotEmptyArray(selectedOptions) ? selectedOptions.map(item => item.value) : [];
    const integrationSystems = checkIsNotEmptyArray(integrationSystemList) ? [...integrationSystemList] : [];
    const hasOptions = checkIsNotEmptyArray(options);

    const tabItems = integrationSystems
      .filter(item => {
        if (isTestTicket && item?.id !== TESTMAN_PLATFORM_ID) {
          return false;
        }
        return true;
      })
      .map(item => {
        return {
          key: item?.id,
          label: item?.title,
          children: (
            <>
              {allowSearch && (
                <>
                  <div style={{ padding: '8px 12px 8px 12px' }} onClick={e => e.stopPropagation()}>
                    <Input
                      placeholder={t('common.search')}
                      suffix={<SearchOutlined />}
                      autoFocus
                      autoComplete="off"
                      allowClear
                      size="small"
                      onChange={e => handleSearch(e?.target?.value)}
                    />
                  </div>

                  {hasOptions && (
                    <>
                      <div style={{ padding: '0 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
                        <Checkbox
                          checked={isCheckAll}
                          indeterminate={isIndeterminate}
                          className="w-100"
                          onChange={e => onCheckAllFields(e?.target?.checked)}
                        >
                          <span title={t('common.checkAll')}>{t('common.checkAll')}</span>
                        </Checkbox>
                      </div>
                      <Divider className="mt-0 mb-0" />
                    </>
                  )}
                </>
              )}

              {!hasOptions && (
                <ul className="ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none">
                  <li
                    className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                    onClick={e => e.stopPropagation()}
                  >
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="my-0" />
                  </li>
                </ul>
              )}

              {hasOptions && (
                <ul
                  className="checkbox-dropdown-menu ant-dropdown-menu ant-dropdown-menu-light ant-dropdown-menu-root ant-dropdown-menu-vertical box-shadow-none"
                  onClick={e => e.stopPropagation()}
                >
                  {/* Selected Item */}
                  {options
                    .filter(item => item?.systemType === system)
                    .map(item => (
                      <li
                        key={item?.value}
                        title={item?.label}
                        className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child"
                        onClick={e => e.stopPropagation()}
                      >
                        <Checkbox
                          checked={selectedValue.includes(item?.value)}
                          className="w-100"
                          onChange={e => handleChangeSelected(e?.target?.checked, item)}
                        >
                          {WORK_ITEM_SEARCH_FIELD_LABEl_RENDER(field?.componentType, item) || item?.label}
                        </Checkbox>
                      </li>
                    ))}
                </ul>
              )}
            </>
          )
        };
      });

    return (
      <div className="ant-dropdown-menu c-field-priority-dropdown p-0" onClick={e => e.stopPropagation()}>
        <Spin indicator={<Loading3QuartersOutlined spin />} spinning={loadingPriorityList}>
          <Tabs
            items={tabItems}
            defaultActiveKey={system}
            size="small"
            tabBarStyle={{ padding: '2px 12px 0' }}
            className="ant-tabs-nav-mb-1"
            onChange={setSystem}
          />

          <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

          <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
            <Button
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                setSelectedOptions([]);
                onClear();
              }}
            >
              {t('common.clear')}
            </Button>

            <Button
              type="primary"
              size="small"
              className="w-auto ml-2"
              onClick={() => {
                onApply(selectedOptions.map(item => item.value));
                setTimeout(() => setVisibleDropdown(false), 300);
              }}
            >
              {t('common.apply')}
            </Button>
          </div>
        </Spin>
      </div>
    );
  };

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return getFieldName(field, 'originRefName');
  }, [field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    const hasCurrentSelectedOptions = Array.isArray(currentSelectedOptions) && currentSelectedOptions.length > 0;

    return hasCurrentSelectedOptions
      ? `${fieldName}: ${currentSelectedOptions.map(item => item?.label).join(', ')}`
      : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, currentSelectedOptions]);

  return (
    <div className="c-field-select">
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayClassName="dropdown-with-checkbox-list-style ant-dropdown-menu-p-0"
        overlayStyle={{ width: 250 }}
        disabled={disabled}
        onOpenChange={onVisibleChange}
        {...restDropdown}
      >
        <Button
          title={btnFieldTitle}
          className={`btn-field field-priority btn-toggle-dropdown-with-checkbox-list ${className}`}
          {...rest}
        >
          {Array.isArray(currentSelectedOptions) && currentSelectedOptions.length ? (
            <>
              <span className="txt-label text-truncate">
                {fieldName}: {currentSelectedOptions.map(item => item?.label).join(', ')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="txt-label text-truncate">
                {fieldName} : {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </div>
  );
};
