import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { parseRsql, buildQueryForParamsUrl } from '../../../../common/utils';

export const model = {
  /**
   * State
   */
  data: null,
  total: null,
  query: {},
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set job list by agent pool
   */
  setJobListByAgentPool: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    // Reset
    if (payload === null) {
      state.data = null;
      state.total = null;
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get jobs by agent pool
   */
  getJobListByAgentPool: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Invalid Params');
      }

      if (payload?.aql && !parseRsql(payload.aql)) {
        throw new Error('Invalid aql');
      } // aql is invalid

      action.setLoadingList(true);
      action.setQuery(payload);

      const url = `${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${
        ENDPOINTS.AUTOMATION_JOB_RUNNER
      }/search?${buildQueryForParamsUrl(payload)}`;
      const res = await Http.get(url).then(res => res.data);

      action.setJobListByAgentPool(res?.data);

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingList(false);
    }
  })
};
