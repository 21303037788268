import React from 'react';
import { CalendarOutlined, LineChartOutlined, FileDoneOutlined } from '@ant-design/icons';

import env from '../../env';
import { P_PROJECT_CHART_SETUP, P_TEST_MANAGER_TRACEABILITY_SETUP } from '../permissions';
import { Archive, PieChart, BarChart, TraceabilityIcon } from '../../assets/svg-icons';

const tenantPath = window.location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX];
const projectPath = window.location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX];

const MENU_TRACEABILITY = 'menu.traceability';

/**
 * Project manager menu group
 */
export const PROJECT_MANAGER_MENU_GROUP = [
  {
    id: 'dashboard',
    name: 'menuGroup.dashboard'
  },
  {
    id: 'workItems',
    name: 'menuGroup.workItems'
  },
  {
    id: 'testManagement',
    name: 'menuGroup.testManagement'
  },
  {
    id: 'extensions',
    name: null
  }
];

/**
 * Project manager menu
 */
export const PROJECT_MANAGER_MENU = [
  {
    id: 'manager',
    parentId: null,
    groupId: 'dashboard',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager`,
    name: 'menu.dashboard',
    icon: <PieChart />,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'dashboard',
    parentId: null,
    groupId: 'dashboard',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/dashboard`,
    name: 'menu.dashboard',
    icon: <PieChart />
  },
  {
    id: 'dashboardDetail',
    parentId: 'dashboard',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/dashboard/detail/:_id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'workItem',
    parentId: '',
    groupId: 'workItems',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/work-item/:refName`
  },
  {
    id: 'testPlan',
    parentId: null,
    groupId: 'testManagement',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan`,
    name: 'menu.testPlanning',
    icon: <CalendarOutlined />
  },
  {
    id: 'testRunDetail',
    parentId: 'testPlan',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan/detail/:key`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testResultDetail',
    parentId: 'testPlan',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan/detail/:key/:testResultKey`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testPlanPlanning',
    parentId: 'testPlan',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-plan/planning`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testRepository',
    parentId: null,
    groupId: 'testManagement',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo`,
    name: 'menu.testRepository',
    icon: <Archive />
  },
  {
    id: 'testRepositoryDetail',
    parentId: 'testRepository',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo/detail/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testRepositoryVersionDetail',
    parentId: 'testRepository',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-repo/testcase-version/:id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'testInsight',
    parentId: null,
    groupId: 'testManagement',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight`,
    name: 'menu.testInsight',
    icon: <LineChartOutlined />,
    noLink: true
  },
  {
    id: 'testData',
    parentId: 'testInsight',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/test-data`,
    name: 'menu.testData',
    icon: <FileDoneOutlined />
  },
  {
    id: 'testDataDetail',
    parentId: 'testData',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/test-data/:key`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingMyMetric',
    parentId: 'testInsight',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports`,
    name: 'menu.myMetric',
    icon: <BarChart />
  },
  {
    id: 'reportingTraceability',
    parentId: 'testInsight',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/traceability`,
    name: MENU_TRACEABILITY,
    icon: <TraceabilityIcon />
  },
  {
    id: 'reportingTraceabilityDetail',
    parentId: 'reportingTraceability',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/traceability/detail/:id`,
    name: MENU_TRACEABILITY,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_TEST_MANAGER_TRACEABILITY_SETUP
  },
  {
    id: 'reportingTraceabilityCreate',
    parentId: 'reportingTraceability',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/traceability/create`,
    name: MENU_TRACEABILITY,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_TEST_MANAGER_TRACEABILITY_SETUP
  },
  {
    id: 'reportingTraceabilityView',
    parentId: 'reportingTraceability',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/traceability/view/:id`,
    name: MENU_TRACEABILITY,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingMyMetricCreate',
    parentId: 'reportingMyMetric',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports/create`,
    name: 'menu.create',
    isHideOnSidebar: true,
    permission: P_PROJECT_CHART_SETUP
  },
  {
    id: 'reportingMyMetricCreateFromTemplate',
    parentId: 'reportingMyMetric',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports/create/template/:_id`,
    name: 'menu.create',
    isHideOnSidebar: true,
    permission: P_PROJECT_CHART_SETUP
  },
  {
    id: 'reportingMyMetricDetail',
    parentId: 'reportingMyMetric',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports/detail/:_id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true
  },
  {
    id: 'reportingMyMetricClone',
    parentId: 'reportingMyMetric',
    path: `/${env.REACT_APP_PREFIX_PATH}${tenantPath}/${projectPath}/manager/test-insight/my-reports/clone/:_id`,
    isHideOnSidebar: true,
    isHideOnBreadcrumb: true,
    permission: P_PROJECT_CHART_SETUP
  }
];
