import { action, thunk } from 'easy-peasy';

import { ENDPOINTS } from '../../../../constants';
import { Http, handleError } from '../../../../core';

export const model = {
  /**
   * State
   */
  data: [],
  loadingExtensionsList: false,

  /**
   * Action: Set test case exported field list
   */
  setExtensionsList: action((state, payload) => {
    if (state?.data === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.data) && payload.data.length)) {
      state.data = [];
      return;
    }

    state.data = payload.data;
  }),

  /**
   * Action: Set loading test case exported field list
   */
  setLoadingExtensionsList: action((state, payload) => {
    if (state?.loadingExtensionsList === undefined) {
      return;
    }

    state.loadingExtensionsList = payload;
  }),

  /**
   * Action: Call api to get test case exported excel testcase
   */
  getExtensionsList: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Get extensions list: Invalid Params');
      }

      action.setLoadingExtensionsList(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_EXTENSION}`;
      const res = await Http.get(url).then(res => res.data);

      action.setExtensionsList(res);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingExtensionsList(false);
    }
  }),

  /**
   * Action: Call api to get test case exported data
   */
  installExtension: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Install extension: Invalid Params');
      }

      action.setLoadingExtensionsList(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_EXTENSION}/install/${payload}`;
      const res = await Http.post(url);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingExtensionsList(false);
    }
  }),

  /**
   * Action: Call api to remove extension
   */
  removeExtension: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Remove extension: Invalid Params');
      }

      action.setLoadingExtensionsList(true);

      const url = `${ENDPOINTS._HOST}${ENDPOINTS.AUTOMATION}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.AUTOMATION_EXTENSION}/${payload}`;
      const res = await Http.put(url);

      return res?.data;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingExtensionsList(false);
    }
  })
};
