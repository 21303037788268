import { io } from 'socket.io-client';

import env from './env';
import { ENDPOINTS } from './constants';
import { keycloak } from './core';

export class SocketInstance {
  namespace = ENDPOINTS.SOCKET_NAMESPACE;
  header = {};
  auth = {};
  constructor(namespace, header = null, auth = null) {
    if (namespace) {
      this.namespace = namespace;
    }
    if (header) {
      this.header = header;
    } else {
      this.header = {
        authorization: `Bearer ${keycloak?.token}`,
        tenant_key: location.pathname.split('/')[env.REACT_APP_TENANT_PATH_INDEX],
        project_key: location.pathname.split('/')[env.REACT_APP_PROJECT_PATH_INDEX],
        name_space: this.namespace
      };
    }

    if (auth) {
      this.auth = auth;
    }
  }
  socket() {
    return io(
      /^http/.test(env.REACT_APP_API_WS_ENDPOINT)
        ? `${env.REACT_APP_API_WS_ENDPOINT}${this.namespace}`
        : `${window.location.protocol}//${window.location.hostname}${env.REACT_APP_API_WS_ENDPOINT}${this.namespace}`,
      {
        extraHeaders: this.header,
        auth: this.auth
      }
    );
  }
}
