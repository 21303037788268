import React from 'react';
import { useTranslation } from 'react-i18next';
import objectPath from 'object-path';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import { SYSTEM_FIELD_DESCRIPTION } from '../../constants';
import { EditableInput } from '../editable-input';

const BoxDescription = ({
  workTicketId,
  editingItem,
  form,
  uploadPath,
  onEditorAttachFiles,
  collapseKeys,
  isReadOnly,
  onChangeCollapse,
  onSubmit,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // const [defaultValue, setDefaultValue] = useState('');

  // useEffect(() => {
  //   let descriptions = objectPath.get(editingItem, SYSTEM_FIELD_DESCRIPTION);
  //   const attachments = objectPath.get(editingItem, SYSTEM_FIELD_ATTACHMENTS);

  //   attachments?.forEach(el => {
  //     if (descriptions?.includes(el?.filename)) {
  //       descriptions = descriptions
  //         // .replace(
  //         //   `!${el.filename}!`,
  //         //   `<img src="${el?.content}" style="height: 100px; width: 100px;" alt=${el.filename} />`
  //         // )
  //         .split('\r\n')
  //         .map(content => {
  //           if (content.includes(`!${el.filename}!`)) {
  //             return content
  //               .split('!')
  //               .map(i => {
  //                 if (i === `${el.filename}`) {
  //                   return `<p><img src="${el?.content}" style="height: 100px; width: 100px;" alt=${el.filename} /></p>`;
  //                 } else {
  //                   return `<p>${i}</p>`;
  //                 }
  //               })
  //               .join('');
  //           } else {
  //             return `<p>${content}</p>`;
  //           }
  //         })
  //         .join('');
  //       setDefaultValue(descriptions);
  //     }
  //   });
  // }, [editingItem]);

  return (
    <div
      id="anchorDescription"
      className={`c-detail-components type-description collapse-item mb-4 ${className}`}
      {...rest}
    >
      <h5 className="btn-toggle ant-typography mb-0">
        <span id="toggle-description-button" onClick={() => onChangeCollapse('anchorDescription')}>
          <>{collapseKeys.includes('anchorDescription') ? <CaretDownOutlined /> : <CaretRightOutlined />}</>{' '}
          <span>{t('anchor.anchorDescription')}</span>
        </span>
      </h5>

      <div className={`collapse-body ${collapseKeys.includes('anchorDescription') ? 'show' : ''}`}>
        <div className="pt-2">
          <span id={`label_${SYSTEM_FIELD_DESCRIPTION}-field`} title={t('workItem.description')} className="d-none">
            {t('workItem.description')}
          </span>

          <EditableInput
            restValueText={{ id: `value_${SYSTEM_FIELD_DESCRIPTION}-field` }}
            restEditButton={{ id: `edit-button_${SYSTEM_FIELD_DESCRIPTION}-field` }}
            restSaveButton={{ id: `save-button_${SYSTEM_FIELD_DESCRIPTION}-field` }}
            restCloseButton={{ id: `close-button_${SYSTEM_FIELD_DESCRIPTION}-field` }}
            type="EDITOR"
            form={form}
            defaultVal={objectPath.get(editingItem, SYSTEM_FIELD_DESCRIPTION)}
            restFormItem={{ name: SYSTEM_FIELD_DESCRIPTION }}
            uploadPath={uploadPath}
            isReadOnly={isReadOnly}
            placeholder={t('workItem.enterDescription')}
            className="d-block w-100"
            restBoxEditableText={rest?.restBoxEditableText}
            onEditorAttachFiles={val => {
              typeof onEditorAttachFiles === 'function' && onEditorAttachFiles(val);
            }}
            onChange={val => {
              form.setFieldsValue({ [SYSTEM_FIELD_DESCRIPTION]: val });
              form.validateFields([SYSTEM_FIELD_DESCRIPTION]);
            }}
            onSave={val => onSubmit({ [SYSTEM_FIELD_DESCRIPTION]: val })}
          />
        </div>
      </div>
    </div>
  );
};

export default BoxDescription;
