import React, { useState } from 'react';
import objectPath from 'object-path';
import { useStoreState } from 'easy-peasy';

import { SS_LAST_SAVED_VALUE } from '../../../../constants';
import { reactSessionStorage } from '../../../../common/utils';
import { useStatus } from '../../../../common/hooks';
import { StatusLabel } from '../../../status-label';
import RelationLink from '../../relation-link';

const TestRunStatusField = ({ workTicketId, row, field, formItemName, disableEditingCells }) => {
  // For status
  const { getStatusFromWorkflowById } = useStatus();

  // For ticket type store
  const ticketListData = useStoreState(state => state.global.ticketListData);

  // Component state
  const [triggerRender, setTriggerRender] = useState(0);

  /**
   * Render cell text utils
   */
  const renderCellTextUtilRelationField1 = ({ currentStatus, row, field }) => {
    return (
      <RelationLink row={row} field={field}>
        <StatusLabel status={currentStatus} />
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ currentStatus, row, field }) => {
    return (
      <div className="cell-text">
        <RelationLink row={row} field={field} className="cell-text-value">
          <StatusLabel status={currentStatus} />
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ currentStatus }) => {
    return <StatusLabel status={currentStatus} />;
  };

  const renderCellTextUtilReadonly2 = ({ currentStatus }) => {
    return (
      <div className="cell-text cursor-default hide-after">
        <span className="cell-text-value">
          <StatusLabel status={currentStatus} />
        </span>
      </div>
    );
  };

  /**
   * Render cell text
   */
  const renderCellText = () => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];
    const lastStatus = lastValue !== undefined ? { id: lastValue } : objectPath.get(row, field?.refName);
    const currentStatus = getStatusFromWorkflowById(lastStatus, ticketListData?.[workTicketId]?.workFlow);

    // Relation field
    if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ currentStatus, row, field });
    }

    // Relation field
    if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ currentStatus, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ currentStatus });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ currentStatus });
    }
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  return (
    <>
      <div className={`field-wrapper test-run-status-field`}>{renderCellText()}</div>

      <div
        data-form-item-name={formItemName}
        className="trigger-render-field-element d-none"
        onClick={() => setTriggerRender(pre => ++pre)}
      >
        {triggerRender}
      </div>
    </>
  );
};

export default TestRunStatusField;
