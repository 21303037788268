import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreState } from 'easy-peasy';
import { Radio, Row } from 'antd';

import { MONACO_MODE, MONACO_MODE_VIEW, MONACO_MODE_EDITABLE, SYSTEM_FIELD_AUTOMATION_STATUS } from '../../constants';
import { recognizeExtensions } from '../../common/utils';
import BoxTestStepTable from './box-test-step-table';
import BoxMonacoEditor from './box-monaco-editor';

import './style.scss';

const TestStepDraggableRowTableComponent = ({
  workTicketId,
  fromModule,
  form = {},
  editingItemKey,
  currentTestStepList,
  onChangeTestStepList,
  currentTestStepRaw,
  onSubmitTestStepRaw,
  uploadPath,
  hasGetFullFilesInfo,
  directlyDeleteAttachment = true,
  onChangeDeteledAttachmentIds,
  loading = false,
  isReadOnly = false,
  className = '',
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');
  const globalProject = useStoreState(state => state.global.globalProject);
  const dataExtensions = useStoreState(state => state.extensions.data);

  // Component state
  const [tableMode, setTableMode] = useState(
    currentTestStepRaw?.[SYSTEM_FIELD_AUTOMATION_STATUS] && recognizeExtensions(dataExtensions, globalProject)
      ? MONACO_MODE_EDITABLE
      : MONACO_MODE_VIEW
  );

  return (
    <div className={`c-test-step-draggable-row-table position-relative ${className}`} {...rest}>
      {recognizeExtensions(dataExtensions, globalProject) && (
        <Row align="middle" justify="end" className="btn-action-editable-cell-wrapper mb-2">
          <Radio.Group
            value={tableMode}
            options={MONACO_MODE.map(item => {
              return {
                label: (
                  <span className="font-weight-medium">
                    <span className="vertical-align-middle">{item.icon}</span> {t(item.label)}
                  </span>
                ),
                value: item.value
              };
            })}
            optionType="button"
            buttonStyle="solid"
            size="small"
            className="btn-action-editable-cell reduce-opacity-when-is-editing-cell rounded-full"
            onChange={e => setTableMode(e?.target?.value)}
          />
        </Row>
      )}

      {tableMode === MONACO_MODE_VIEW ? (
        <BoxTestStepTable
          workTicketId={workTicketId}
          fromModule={fromModule}
          form={form}
          editingItemKey={editingItemKey}
          currentTestStepList={currentTestStepList}
          onChangeTestStepList={onChangeTestStepList}
          uploadPath={uploadPath}
          hasGetFullFilesInfo={hasGetFullFilesInfo}
          directlyDeleteAttachment={directlyDeleteAttachment}
          onChangeDeteledAttachmentIds={onChangeDeteledAttachmentIds}
          loading={loading}
          isReadOnly={isReadOnly}
        />
      ) : (
        <>
          {recognizeExtensions(dataExtensions, globalProject) && (
            <BoxMonacoEditor
              fromModule={fromModule}
              currentTestStepRaw={currentTestStepRaw}
              onSubmitTestStepRaw={onSubmitTestStepRaw}
              isReadOnly={isReadOnly}
            />
          )}
        </>
      )}
    </div>
  );
};

const equalProps = (prevProps, nextProps) => {
  const hasChanged =
    prevProps?.testStepRaw !== nextProps?.testStepRaw ||
    JSON.stringify(prevProps?.currentTestStepList) !== JSON.stringify(nextProps?.currentTestStepList) ||
    prevProps?.isReadOnly !== nextProps?.isReadOnly ||
    prevProps?.loading !== nextProps?.loading;

  return !hasChanged;
};

export const TestStepDraggableRowTable = memo(TestStepDraggableRowTableComponent, equalProps);
