import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Dropdown, Tooltip, DatePicker, Select } from 'antd';
import { CaretDownOutlined, CloseCircleOutlined } from '@ant-design/icons';

import {
  COMPONENT_TYPE,
  FULL_DATE_FORMAT,
  OPERATION_VALUE_IN_RANGE,
  OPERATION_VALUE_NOT_IN_RANGE,
  SHORT_DATE_FORMAT
} from '../../../constants';

export const FieldDate = ({
  field,
  currentValues,
  className = '',
  restDropdown,
  onApply,
  onClear,
  onRemove,
  ...rest
}) => {
  // For language
  const [t] = useTranslation('akaat');

  // Component state
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentOperation, setCurrentOperation] = useState(null);

  /**
   * Check is range
   */
  const checkIsRange = operation => {
    return operation === OPERATION_VALUE_IN_RANGE || operation === OPERATION_VALUE_NOT_IN_RANGE;
  };

  /**
   * Check invalid date range
   */
  const checkInvalidDateRange = ({ isRange, newField }) => {
    const validDateRange =
      Array.isArray(newField?.value) &&
      newField.value.length &&
      newField.value.every(sub => sub && moment(sub).isValid());

    return isRange && !validDateRange;
  };

  /**
   * Check invalid date
   */
  const checkInvalidDate = ({ isRange, newField }) => {
    return !isRange && !(newField?.value && moment(newField.value).isValid());
  };

  /**
   * Compute current field from currentValues
   */
  const currentField = useMemo(() => {
    if (!field?.referenceField || !(Array.isArray(currentValues) && currentValues.length)) {
      return null;
    }

    const newField = [...currentValues].find(item => item.referenceField === field.referenceField);
    const isRange = checkIsRange(newField?.operation);

    if (checkInvalidDateRange({ isRange, newField })) {
      return null;
    } else if (checkInvalidDate({ isRange, newField })) {
      return null;
    } else {
    }

    if (isRange) {
      newField.value = [...newField.value].map(sub => moment(sub));
    } else {
      newField.value = moment(newField.value);
    }

    return newField;
  }, [field, currentValues]);

  /**
   * Compute date format
   */
  const dateFormat = useMemo(() => {
    return field?.componentType === COMPONENT_TYPE.DATE ? SHORT_DATE_FORMAT : FULL_DATE_FORMAT;
  }, [field]);

  /**
   * Set current operation
   */
  useEffect(() => {
    if (!field?.referenceField) {
      return;
    }

    const newCurrentOperation = currentField?.operation
      ? currentField.operation
      : Array.isArray(field.supportedOperations) && field.supportedOperations.length
      ? field.supportedOperations[0]
      : null;

    setCurrentOperation(newCurrentOperation);
  }, [field, currentField]);

  /**
   * On visible change
   */
  const onOpenChange = visible => {
    setVisibleDropdown(visible);

    if (visible) {
      setCurrentDate(currentField?.value || null);
    }
  };

  /**
   * Handle apply utils
   */
  const handleApplyUtilCheckValidRangeDate = ({ isRange, currentDate }) => {
    const validDates =
      Array.isArray(currentDate) && currentDate.length === 2 && currentDate.every(sub => sub && moment(sub).isValid());

    return isRange && validDates;
  };
  const handleApplyUtilCheckValidSingleDate = ({ isRange, currentDate }) => {
    return !isRange && currentDate && moment(currentDate.isValid());
  };

  /**
   * Handle apply
   */
  const handleApply = () => {
    let newDate = null;
    const isRange = checkIsRange(currentOperation);

    if (handleApplyUtilCheckValidRangeDate({ isRange, currentDate })) {
      newDate = [currentDate[0], currentDate[1]];
    } else if (handleApplyUtilCheckValidSingleDate({ isRange, currentDate })) {
      newDate = currentDate;
    } else {
    }

    setVisibleDropdown(false);

    onApply(newDate, currentOperation);
  };

  /**
   * Render dropdown
   *
   * @return {object} - Element
   */
  const renderDropdown = () => {
    return (
      <div className="ant-dropdown-menu c-field-date-dropdown p-0" onClick={e => e.stopPropagation()}>
        <div style={{ padding: '8px 12px 5px 12px' }} onClick={e => e.stopPropagation()}>
          <span className="ant-input-group-wrapper">
            <span className="ant-input-wrapper ant-input-group">
              <span className="ant-input-group-addon">
                <Select
                  value={currentOperation}
                  options={
                    Array.isArray(field?.supportedOperations) && field?.supportedOperations.length
                      ? field.supportedOperations.map(sub => {
                          return {
                            label: sub,
                            value: sub
                          };
                        })
                      : []
                  }
                  dropdownMatchSelectWidth={false}
                  onSelect={val => {
                    setCurrentDate(null);
                    setCurrentOperation(val);
                  }}
                />
              </span>

              {checkIsRange(currentOperation) ? (
                <DatePicker.RangePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  showTime={{ format: 'HH:mm' }}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                />
              ) : currentOperation === '=' ? (
                <DatePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={{ format: 'HH:mm:ss' }}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                />
              ) : (
                <DatePicker
                  value={currentDate}
                  format={FULL_DATE_FORMAT}
                  placeholder={`${dateFormat}`}
                  showTime={false}
                  showNow
                  className="w-100"
                  onChange={setCurrentDate}
                />
              )}
            </span>
          </span>
        </div>

        <div className="ant-dropdown-menu-item-divider" onClick={e => e.stopPropagation()}></div>

        <div className="box-footer text-right" style={{ padding: '5px 12px' }} onClick={e => e.stopPropagation()}>
          <Button
            size="small"
            className="w-auto ml-2"
            onClick={() => {
              setCurrentDate(null);
              onClear();
            }}
          >
            {t('common.clear')}
          </Button>

          <Button type="primary" size="small" className="w-auto ml-2" onClick={handleApply}>
            {t('common.apply')}
          </Button>
        </div>
      </div>
    );
  };

  /**
   * Btn field title
   */
  const dateText = useMemo(() => {
    const isRange = checkIsRange(currentField?.operation);
    const validRangeDate = currentField && isRange && Array.isArray(currentField?.value) && currentField?.value.length;

    return validRangeDate
      ? `${moment(currentField.value[0]).format(dateFormat)} ⇀ ${moment(currentField.value[1]).format(dateFormat)}`
      : currentField
      ? moment(currentField.value).format(dateFormat)
      : 'N/A';
  }, [currentField, dateFormat]);

  /**
   * Field name
   */
  const fieldName = useMemo(() => {
    return t(`workItem.${field?.name}`);
  }, [t, field]);

  /**
   * Btn field title
   */
  const btnFieldTitle = useMemo(() => {
    return currentField ? `${fieldName}: ${currentField.operation} ${dateText}` : `${fieldName}: ${t('common.all')}`;
  }, [field, fieldName, dateText, currentField]);

  return (
    <>
      <Dropdown
        open={visibleDropdown}
        menu={{
          items: [{ key: 'menu', label: renderDropdown(), className: 'p-0' }],
          selectable: false
        }}
        trigger={['click']}
        destroyPopupOnHide={true}
        placement="bottomLeft"
        overlayStyle={{ minWidth: 290 }}
        overlayClassName="ant-dropdown-menu-p-0"
        onOpenChange={onOpenChange}
        {...restDropdown}
      >
        <Button title={btnFieldTitle} className={`btn-field field-date ${className}`} {...rest}>
          {currentField ? (
            <>
              <span className="text-truncate">
                {fieldName}: {currentField.operation} {dateText}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          ) : (
            <>
              <span className="text-truncate">
                {fieldName}: {t('common.all')}
              </span>{' '}
              <CaretDownOutlined className="ic-caret-down" />
            </>
          )}

          {!field?.isDefaultSearch && (
            <Tooltip title={t('search.removeThisCondition')} placement="top" destroyTooltipOnHide={true}>
              <CloseCircleOutlined
                className="ic-close"
                onClick={e => {
                  e.stopPropagation();
                  typeof onRemove === 'function' && onRemove();
                }}
              />
            </Tooltip>
          )}
        </Button>
      </Dropdown>
    </>
  );
};
