import React, { useRef, useEffect, useState, useMemo } from 'react';
import objectPath from 'object-path';
import moment from 'moment';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  SHORT_DATE_FORMAT,
  SYSTEM_FIELD_PATH,
  SS_LAST_SAVED_VALUE,
  SYSTEM_FIELD_END_DATE_PLAN,
  SYSTEM_FIELD_START_DATE_PLAN
} from '../../../../constants';
import {
  checkValidField,
  reactSessionStorage,
  checkValidMomentDateString,
  disabledOutOfRangeDate,
  findItemAndParentsOnTree,
  handleSetLastSavedValueToSession
} from '../../../../common/utils';
import { checkIsEditableCell, onOutsideClick } from '../../editable-cell-utils';
import RelationLink from '../../relation-link';
import TriggerFocusElement from '../../trigger-focus-element';
import DateKeyboardEvents from './plan-date-keyboard-events';
import DateFormItem from './plan-date-form-item';

const PlanDateField = ({
  workTicketId,
  x,
  y,
  row,
  field,
  tableForm,
  formItemName,
  currentTreeItem,
  disableEditingCells,
  isEditableField,
  isReadOnly,
  placeholder,
  className = '',
  restFormItem,
  restField,
  onSave,
  onChangeEditingCell,
  ...rest
}) => {
  const refInput = useRef(null);

  // Component state
  const [editingCell, setEditingCell] = useState(null);
  const [visibleFormItem, setVisibleFormItem] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [validRangeDate, setValidRangeDate] = useState({});

  /**
   * Get tree item
   */
  const getTreeItem = ({ row, currentTreeItem }) => {
    if (!row?.key || !currentTreeItem?.key) {
      return;
    }

    const newTreeItem = findItemAndParentsOnTree([currentTreeItem], row?.[SYSTEM_FIELD_PATH], SYSTEM_FIELD_PATH);

    return newTreeItem?.item;
  };

  /**
   * Get valid range date for start date
   */
  const getValidRangeDateForStartDateField = () => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    let endDateLocal = lastSavedValue[`key-${row?.key}-${SYSTEM_FIELD_END_DATE_PLAN}`];
    if (endDateLocal) {
      endDateLocal = moment.utc(endDateLocal).format();
    }

    const endDateRow = row?.[SYSTEM_FIELD_END_DATE_PLAN];

    const treeItem = getTreeItem({ row, currentTreeItem });
    const startDateTreeItem = treeItem?.startDate;
    const endDateTreeItem = treeItem?.endDate;

    let endDateList = [];

    // Có endDateLocal nghĩa là vừa sửa end date trên row
    // Giữa ngày end vừa sửa (ví dụ ngày 12) và ngày end của tree item (ví dụ ngày 16). Lấy ngày nhỏ nhất (ngày 12)
    if (endDateLocal) {
      endDateList = [endDateLocal, endDateTreeItem];
    }

    // Không endDateLocal nghĩa là chưa sửa end date trên row
    // Giữa ngày end của row (ví dụ ngày 15) và ngày end của tree item (ví dụ ngày 16). Lấy ngày nhỏ nhất (ngày 15)
    else {
      endDateList = [endDateRow, endDateTreeItem];
    }

    endDateList = endDateList.filter(item => checkValidMomentDateString(item)).map(item => moment(item));

    const minEndDate = moment.min(endDateList);

    // startDate được lấy bằng start date của tree item
    // endDate phụ thuộc vào:
    // - giá trị end date của row hiện tại
    // - giá trị end date vừa sửa trên row
    // - giá trị end date cả tree item
    const newValidRangeDate = {
      startDate: startDateTreeItem,
      endDate: moment.utc(minEndDate).format()
    };

    if (JSON.stringify(newValidRangeDate) !== JSON.stringify(validRangeDate)) {
      setValidRangeDate(newValidRangeDate);
    }
  };

  /**
   * Get valid range date for end date
   */
  const getValidRangeDateForEndDateField = () => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    let startDateLocal = lastSavedValue[`key-${row?.key}-${SYSTEM_FIELD_START_DATE_PLAN}`];
    if (startDateLocal) {
      startDateLocal = moment.utc(startDateLocal).format();
    }

    const startDateRow = row?.[SYSTEM_FIELD_START_DATE_PLAN];

    const treeItem = getTreeItem({ row, currentTreeItem });
    const startDateTreeItem = treeItem?.startDate;
    const endDateTreeItem = treeItem?.endDate;

    let startDateList = [];

    // Có startDateLocal nghĩa là vừa sửa start date trên row
    // Giữa ngày start vừa sửa (ví dụ ngày 3) và ngày start của tree item (ví dụ ngày 2). Lấy ngày lớn nhất (ngày 3)
    if (startDateLocal) {
      startDateList = [startDateLocal, startDateTreeItem];
    }

    // Không startDateLocal nghĩa là chưa sửa start date trên row
    // Giữa ngày start của row (ví dụ ngày 4) và ngày start của tree item (ví dụ ngày 2). Lấy ngày lớn nhất (ngày 4)
    else {
      startDateList = [startDateRow, startDateTreeItem];
    }

    startDateList = startDateList.filter(item => checkValidMomentDateString(item)).map(item => moment(item));

    const maxStartDate = moment.max(startDateList);

    // endDate được lấy bằng end date của tree item
    // startDate phụ thuộc vào:
    // - giá trị start date cả tree item
    // - giá trị start date của row hiện tại
    // - giá trị start date vừa sửa trên row
    const newValidRangeDate = {
      startDate: moment.utc(maxStartDate).format(),
      endDate: endDateTreeItem
    };

    if (JSON.stringify(newValidRangeDate) !== JSON.stringify(validRangeDate)) {
      setValidRangeDate(newValidRangeDate);
    }
  };

  /**
   * On change editing cell
   */
  useEffect(() => {
    typeof onChangeEditingCell === 'function' && onChangeEditingCell(editingCell);
  }, [editingCell]);

  /**
   * Is editing
   */
  const isEditing = useMemo(() => x === editingCell?.x && y === editingCell?.y, [x, y, editingCell]);

  /**
   * Handle set editing cell
   */
  const handleSetEditingCell = info => {
    if (!(info?.x && info?.y)) {
      setVisibleFormItem(false);
      setIsOpenCalendar(false);
    }

    setEditingCell(info);
  };

  /**
   * Open calendar when click
   */
  useEffect(() => {
    if (isEditing && editingCell?.hadJustClicked) {
      setIsOpenCalendar(true);
    }
  }, [isEditing, editingCell]);

  /**
   * Default val
   */
  const defaultVal = useMemo(() => {
    const val = objectPath.get(row, field?.refName);

    return checkValidMomentDateString(val) ? moment(val) : null;
  }, [row, field]);

  /**
   * Current value
   */
  const [currentValue, setCurrentValue] = useState(defaultVal);

  /**
   * Set current value by default value
   */
  useEffect(() => setCurrentValue(defaultVal), [defaultVal]);

  /**
   * Get current value
   */
  const getCurrentValue = val => {
    const lastSavedValue = reactSessionStorage.getObject(SS_LAST_SAVED_VALUE, {});
    const lastValue = lastSavedValue[formItemName];

    return lastValue !== undefined ? lastValue : val;
  };

  /**
   * Set value to form
   */
  useEffect(() => {
    if (isReadOnly || !formItemName || typeof tableForm?.setFieldsValue !== 'function') {
      return;
    }

    if (isEditing) {
      const val = getCurrentValue(defaultVal);
      const validDate = val && moment(val).isValid() ? moment(val) : null;
      tableForm.setFieldsValue({ [formItemName]: validDate });

      if (field?.refName === SYSTEM_FIELD_START_DATE_PLAN) {
        getValidRangeDateForStartDateField();
      } else if (field?.refName === SYSTEM_FIELD_END_DATE_PLAN) {
        getValidRangeDateForEndDateField();
      } else {
      }
    }
  }, [row, field, isEditing, isReadOnly, tableForm, formItemName, defaultVal, currentTreeItem, validRangeDate]);

  /**
   * Handle open
   */
  const handleOpen = () => {
    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.remove('focused');

    setIsOpenCalendar(true);
    setVisibleFormItem(true);
    handleSetEditingCell({ x, y, hadJustClicked: true });
  };

  /**
   * Handle close
   */
  const handleClose = date => {
    if (row?.isNew) {
      setIsOpenCalendar(false);
      setCurrentValue(date);
      return;
    }

    setIsOpenCalendar(false);
    setVisibleFormItem(false);
    setCurrentValue(date);

    const triggerFocusElement = document.querySelector(`.trigger-focus-element[data-form-item-name="${formItemName}"]`);
    triggerFocusElement?.classList.add('focused');
    refInput?.current && refInput.current.focus({ cursor: 'end' });
  };

  /**
   * Close and set last value
   */
  const closeAndSetLastValue = () => {
    const defaultValue = getCurrentValue(defaultVal);
    handleClose(defaultValue);
  };

  /**
   * Handle save
   */
  const handleSave = async val => {
    const defaultValue = getCurrentValue(defaultVal);
    const valid = await checkValidField({ form: tableForm, formItemName });

    if (!valid || (field?.mandatory && !(val && moment(val).isValid()))) {
      handleClose(defaultValue);
      return;
    }

    const newValue = val && moment(val).isValid() ? moment(val).format() : null;
    const oldValue = defaultValue && moment(defaultValue).isValid() ? moment(defaultValue).format() : null;

    if (newValue !== oldValue) {
      handleSetLastSavedValueToSession({ [formItemName]: newValue });
      typeof onSave === 'function' && onSave({ formData: { [field?.refName]: newValue }, row, field });
    }

    handleClose(val);
  };

  /**
   * Handle open change
   */
  const handleOpenChange = visible => {
    if (row?.isNew) {
      setIsOpenCalendar(visible);
      return;
    }

    setIsOpenCalendar(visible);
    setTimeout(() => handleSave(currentValue), 200);
  };

  /**
   * Input props
   */
  const inputProps = {
    ref: refInput,
    open: isOpenCalendar,
    autoFocus: !row?.isNew,
    showSearch: true,
    allowClear: !field?.mandatory,
    format: SHORT_DATE_FORMAT,
    placeholder: placeholder || SHORT_DATE_FORMAT,
    disabledDate: currentDate => {
      return disabledOutOfRangeDate({
        currentDate,
        startDate: validRangeDate?.startDate,
        endDate: validRangeDate?.endDate
      });
    },
    disabled: !((row?.isNew && isEditableField) || !isReadOnly),
    onOpenChange: handleOpenChange,
    onChange: val => {
      setCurrentValue(val);
      if (row?.isNew) {
        handleSave(val);
      }
    },
    onClear: () => {
      if (!row?.isNew) {
        handleSave(null);
      }
    },
    onClick: () => {
      if (!row?.isNew) {
        handleOpen();
      }
    }
  };

  /**
   * Render cell text utils
   */
  const renderCellTextUtilEditable = ({ dateString, props }) => {
    return (
      <div title={dateString} className="cell-text cursor-text" onClick={handleOpen}>
        <span className="cell-text-value min-h-22 text-truncate">{dateString || props?.placeholder}</span>
      </div>
    );
  };

  const renderCellTextUtilRelationField1 = ({ dateString, row, field }) => {
    return (
      <RelationLink title={dateString} row={row} field={field}>
        {dateString}
      </RelationLink>
    );
  };

  const renderCellTextUtilRelationField2 = ({ dateString, row, field }) => {
    return (
      <div title={dateString} className="cell-text cursor-default hide-after">
        <RelationLink row={row} field={field}>
          {dateString}
        </RelationLink>
      </div>
    );
  };

  const renderCellTextUtilReadonly1 = ({ dateString }) => {
    return <span title={dateString}>{dateString}</span>;
  };

  const renderCellTextUtilReadonly2 = ({ dateString }) => {
    return (
      <div title={dateString} className="cell-text cursor-default hide-after">
        <span className="cell-text-value min-h-22 text-truncate">{dateString}</span>
      </div>
    );
  };

  const convertToDateString = val => {
    const dateString = val && moment(val).isValid() ? moment(val).format(SHORT_DATE_FORMAT) : '';
    return dateString;
  };

  /**
   * Render cell text
   */
  const renderCellText = props => {
    const val = getCurrentValue(currentValue);
    const dateString = convertToDateString(val);

    // Editable
    if (checkIsEditableCell({ isEditableField, isReadOnly, disableEditingCells })) {
      return renderCellTextUtilEditable({ dateString, props });
    }

    // Relation field
    else if (field?.isRelationDisplayField && disableEditingCells) {
      return renderCellTextUtilRelationField1({ dateString, row, field });
    }

    // Relation field
    else if (field?.isRelationDisplayField && !disableEditingCells) {
      return renderCellTextUtilRelationField2({ dateString, row, field });
    }

    // Readonly
    else if (disableEditingCells) {
      return renderCellTextUtilReadonly1({ dateString });
    }

    // Readonly
    else {
      return renderCellTextUtilReadonly2({ dateString });
    }
  };

  /**
   * Render form item
   */
  const renderFormItem = () => {
    return (
      <DateFormItem
        field={field}
        formItemName={formItemName}
        inputProps={inputProps}
        restFormItem={restFormItem}
        restField={restField}
      />
    );
  };

  /**
   * Render field
   */
  const renderField = () => {
    return (
      <>
        {isEditing && (
          <>
            <DateKeyboardEvents
              x={x}
              y={y}
              field={field}
              tableForm={tableForm}
              formItemName={formItemName}
              setEditingCell={handleSetEditingCell}
              handleOpen={handleOpen}
              handleClose={handleClose}
              handleSave={handleSave}
              closeAndSetLastValue={closeAndSetLastValue}
            />

            {!visibleFormItem && (
              <div className="box-visual-input" onClick={handleOpen}>
                {renderCellText({
                  placeholder: <span className="text-placeholder text-truncate">{SHORT_DATE_FORMAT}</span>
                })}
              </div>
            )}

            {visibleFormItem && renderFormItem()}
          </>
        )}

        {!isEditing && renderCellText()}

        <div
          data-form-item-name={formItemName}
          data-x={x}
          data-y={y}
          className="trigger-save-element"
          onClick={() => handleSave(currentValue)}
        />
      </>
    );
  };

  /**
   * Render field wrapper
   */
  const renderFieldWrapper = () => {
    return (
      <>
        {!isReadOnly && (
          <TriggerFocusElement
            x={x}
            y={y}
            formItemName={formItemName}
            editingCell={editingCell}
            isEditing={isEditing}
            setEditingCell={handleSetEditingCell}
          />
        )}

        <div className={`field-wrapper plan-date-field ${className}`} {...rest}>
          {!isReadOnly ? renderField() : renderCellText()}
        </div>
      </>
    );
  };

  /**
   * Return html
   */
  if (disableEditingCells) {
    return renderCellText();
  }

  if (row?.isNew) {
    return (
      <div className={`field-wrapper is-new plan-date-field ${className}`} {...rest}>
        {isEditableField ? renderFormItem() : renderCellText()}
      </div>
    );
  }

  return isEditing ? (
    <OutsideClickHandler
      onOutsideClick={e => onOutsideClick({ e, x, y, tableForm, setEditingCell: handleSetEditingCell })}
    >
      {renderFieldWrapper()}
    </OutsideClickHandler>
  ) : (
    renderFieldWrapper()
  );
};

export default PlanDateField;
