import { action, thunk } from 'easy-peasy';
import i18next from 'i18next';
import { notification } from 'antd';

import {
  ENDPOINTS,
  COMPONENT_TYPE,
  SYSTEM_FIELD_LATEST_RESULT,
  SYSTEM_FIELD_STATUS,
  WORK_ITEM_TEST_RESULT_ID
} from '../../../../constants';
import { Http, handleError } from '../../../../core';
import { buildQueryForFilterMongo, getPrePathLinkToTicket } from '../../../../common/utils';

const MESSAGE_UPDATING_TEST_RESULT = 'akaat:testResult.messageUpdatingTestResult';
const MESSAGE_UPDATED_SUCCESSFULLY = 'akaat:message.updatedSuccessfully';

export const model = {
  /**
   * State
   */
  data: null,
  fieldConfig: {},
  fieldRelationList: [],
  allSubFieldList: [],
  total: 0,
  query: null,
  editingTestResult: null,
  collapseKeys: [
    'anchorDetails',
    'anchorExecution',
    'anchorRelatedTickets',
    'anchorActivities',
    'anchorAttachments',
    'anchorPeople',
    'anchorDates',
    'anchorAutomation',
    'anchorTestStep'
  ], // For collapse/expand boxes on detail form
  activeActivityTab: '',
  loadingList: false,
  loadingItem: false,

  /**
   * Action: Set test run field list contain field relation
   */
  setAllSubFieldList: action((state, payload) => {
    if (state.allSubFieldList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.data = [];
      return;
    }

    state.allSubFieldList = payload;
  }),
  /**
   * Action: Set relation field list
   */
  setFieldRelationList: action((state, payload) => {
    if (state.fieldRelationList === undefined || payload === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.fieldRelationList = [];
      return;
    }

    state.fieldRelationList = payload;
  }),

  /**
   * Action: Set test result list
   */
  setTestResultList: action((state, payload) => {
    if (state?.data === undefined || state?.total === undefined) {
      return;
    }

    if (!(Array.isArray(payload?.rows) && payload.rows.length)) {
      state.data = [];
      state.total = 0;
      return;
    }

    state.data = payload.rows;
    state.total = payload.count;
  }),

  setFieldConfig: action((state, payload) => {
    if (!payload || !state) {
      return;
    }

    state.fieldConfig = payload;
  }),

  /**
   * Action: Set query
   */
  setQuery: action((state, payload) => {
    if (state?.query === undefined) {
      return;
    }

    state.query = payload;
  }),

  /**
   * Action: Set editing test result
   */
  setEditingTestResult: action((state, payload) => {
    if (state?.editingTestResult === undefined) {
      return;
    }

    state.editingTestResult = payload;
  }),

  /**
   * Action: Set collapse keys
   */
  setCollapseKeys: action((state, payload) => {
    if (state?.collapseKeys === undefined) {
      return;
    }

    if (!(Array.isArray(payload) && payload.length)) {
      state.collapseKeys = [];
      return;
    }

    state.collapseKeys = payload;
  }),

  /**
   * Action: Set active activity tab
   */
  setActiveActivityTab: action((state, payload) => {
    if (state?.activeActivityTab === undefined) {
      return;
    }

    state.activeActivityTab = payload;
  }),

  /**
   * Action: Set loading list
   */
  setLoadingList: action((state, payload) => {
    if (state?.loadingList === undefined) {
      return;
    }

    state.loadingList = payload;
  }),

  /**
   * Action: Set loading get/update item
   */
  setLoadingItem: action((state, payload) => {
    if (state?.loadingItem === undefined) {
      return;
    }

    state.loadingItem = payload;
  }),

  /**
   * Action: Call api to get test result list
   */
  getTestResultList: thunk(async (action, payload, helpers) => {
    const newPayload = { ...payload };
    const noSetDataToStore = newPayload?.noSetDataToStore;
    delete newPayload.noSetDataToStore;

    try {
      if (!noSetDataToStore) {
        action.setLoadingList(true);
        action.setQuery(payload);
      }

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${
        globalProject?.projectKey
      }${ENDPOINTS.TEST_RESULT}?${buildQueryForFilterMongo(newPayload)}`;

      const res = await Http.get(url).then(res => res.data);

      if (!noSetDataToStore) {
        action.setTestResultList(res?.data);
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      if (!noSetDataToStore) {
        action.setLoadingList(false);
      }
    }
  }),

  /**
   * Action: Call api to get test result by key
   */
  getTestResultByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Get test result by key: No test result key');
      }

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_RESULT}/${payload}`;
      const res = await Http.get(url).then(res => res.data);

      action.setEditingTestResult(res?.data);
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to update test result
   */
  updateTestResult: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Update test result: Invalid Params');
      }

      action.setLoadingItem(true);

      const newPayload = { ...payload };
      const { key, noShowSuccessMessage } = newPayload;

      delete newPayload.key;
      delete newPayload.noShowSuccessMessage;

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TEST_RESULT}/${key}`;
      const res = await Http.put(url, newPayload).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t(MESSAGE_UPDATING_TEST_RESULT),
          description: i18next.t(MESSAGE_UPDATED_SUCCESSFULLY)
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to bulk update test result
   */
  bulkUpdateTestResult: thunk(async (action, payload, helpers) => {
    try {
      const project = helpers.getStoreState()?.global?.globalProject;
      const tenant = helpers.getStoreState()?.global?.globalTenant;

      if (!project?.projectKey || !tenant?.tenantKey) {
        throw new Error('Bulk update test result: Invalid Params');
      }

      const { noShowSuccessMessage, body } = payload;
      delete payload.noShowSuccessMessage;

      action.setLoadingItem(true);

      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${tenant?.tenantKey}/${project?.projectKey}${ENDPOINTS.TEST_RESULT}`;
      const res = await Http.put(url, body).then(res => res.data);

      if (!noShowSuccessMessage) {
        notification.success({
          message: i18next.t(MESSAGE_UPDATING_TEST_RESULT),
          description: i18next.t(MESSAGE_UPDATED_SUCCESSFULLY)
        });
      }

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: Call api to get test result by key
   */
  deleteTestResultByKey: thunk(async (action, payload, helpers) => {
    try {
      if (!payload) {
        throw new Error('Delete test result by key: No test result key');
      }

      action.setLoadingItem(true);

      const globalProject = helpers.getStoreState()?.global?.globalProject;
      const globalTenant = helpers.getStoreState()?.global?.globalTenant;
      const url = `${ENDPOINTS.MANAGER}${ENDPOINTS.TEST_MANAGEMENT}/${globalTenant?.tenantKey}/${globalProject?.projectKey}${ENDPOINTS.TEST_RESULT}/${payload}`;

      const res = await Http.delete(url, {}).then(res => res.data);

      notification.success({
        message: i18next.t('akaat:testResult.messageDeletingTestResult'),
        description: i18next.t('akaat:message.deletedSuccessfully')
      });

      return res;
    } catch (err) {
      handleError(err);
    } finally {
      action.setLoadingItem(false);
    }
  }),

  /**
   * Action: On change collapse
   */
  onChangeCollapse: thunk(async (action, payload, helpers) => {
    if (!payload) {
      throw new Error('On change collapse: There are no key');
    }

    let collapseKeys = helpers.getState()['collapseKeys'];
    let newKeys = [];

    collapseKeys = Array.isArray(collapseKeys) && collapseKeys.length ? [...collapseKeys] : [];

    if (collapseKeys.includes(payload)) {
      newKeys = [...collapseKeys].filter(item => item !== payload);
    } else {
      newKeys = [...collapseKeys, payload];
    }

    action.setCollapseKeys(newKeys);
  }),

  /**
   * Get field list
   */
  getFieldList: thunk(async (action, payload, helpers) => {
    const { visibleDetailRelation, fieldListData, ticketListData, isNotSetFieldRelationList } = payload;

    let newFieldList = [...fieldListData?.[WORK_ITEM_TEST_RESULT_ID]].map(item => {
      const newItem = { ...item };
      if (item.refName === SYSTEM_FIELD_STATUS) {
        newItem.data = {
          ...newItem.data,
          ...ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow
        };

        newItem.workTicketId = WORK_ITEM_TEST_RESULT_ID;
      } else if (item.refName === SYSTEM_FIELD_LATEST_RESULT) {
        newItem.data = {
          ...newItem.data,
          ...ticketListData?.[WORK_ITEM_TEST_RESULT_ID]?.workFlow,
          workTicketId: WORK_ITEM_TEST_RESULT_ID
        };

        newItem.workTicketId = WORK_ITEM_TEST_RESULT_ID;
      } else {
      }

      return newItem;
    });

    const newFieldRelationList = [];

    for (let index = 0; index < fieldListData?.[WORK_ITEM_TEST_RESULT_ID].length; index++) {
      const field = fieldListData?.[WORK_ITEM_TEST_RESULT_ID][index];

      if (field?.componentType === COMPONENT_TYPE.RELATION && visibleDetailRelation) {
        newFieldRelationList.push(field);

        if (fieldListData?.[field?.lookup?.workTicketId]) {
          const list = convertSubRelationField(
            field,
            fieldListData?.[field?.lookup?.workTicketId],
            ticketListData?.[field?.lookup?.workTicketId]?.workFlow
          );

          newFieldList = newFieldList.concat(list);
        } else if (fieldListData && !Object.keys(fieldListData).includes(field?.lookup?.workTicketId)) {
          const res = await helpers.getStoreActions()?.global?.getTicketTypeById(field?.lookup?.workTicketId);
          fieldListData[field?.lookup?.workTicketId] = res?.fields;

          const list = convertSubRelationField(
            field,
            res?.fields,
            ticketListData?.[field?.lookup?.workTicketId]?.workFlow
          );

          newFieldList = newFieldList.concat(list);
        } else {
        }
      }
    }

    // Set relation field
    if (!isNotSetFieldRelationList) {
      action.setFieldRelationList(newFieldRelationList);
    }

    let list = [...newFieldList].filter(
      field => field && !field?.invisible && field.refName !== 'latestResult.testRun'
    );

    if (visibleDetailRelation) {
      list = list.filter(field => field.componentType !== COMPONENT_TYPE.RELATION);
    }

    return list;
  })
};

/**
 * Handle return sub field relation
 */
const convertSubRelationField = (rootField, list = [], workTicketName) => {
  const relationField = [];

  list.forEach(fieldRelation => {
    relationField.push({
      ...fieldRelation,
      refName: `${rootField?.refName}.${fieldRelation.refName}`,
      name: i18next.exists(`akaat:workItem.${fieldRelation.refName}`)
        ? `${workTicketName || rootField?.name} - ${i18next.t(`akaat:workItem.${fieldRelation.refName}`)}`
        : `${workTicketName || rootField?.name} - ${fieldRelation.name}`,
      workTicketId: rootField?.lookup?.workTicketId,
      refNameParent: rootField?.refName,
      isRelationDisplayField: rootField?.data.displayField === fieldRelation.refName,
      prePath: getPrePathLinkToTicket({ workTicketId: rootField?.lookup?.workTicketId })
    });
  });

  return relationField;
};
